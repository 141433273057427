import _ from 'lodash';
import isError from 'lodash.iserror';
import {
	all, call, fork, join, put, select, takeLatest
} from 'redux-saga/effects';
import { createAction } from 'redux-actions';
import {
	createSelector,
	getTranscriptionId,
	getTranscriptsForOrder,
	getTranslationId,
	handleError,
	isDedicatedGfxOrder,
	isDialogueOrder,
	isGfxOnlyOrder,
	isGfxOrder,
	isNarrationOrder
} from './common';
import { RESET_STORES } from './session';
import PreviewService from '../modules/services/preview-service';
import SubtitleService from '../modules/services/subtitle-service';

import { actions as workRequestActions, fetchWorkRequestById, selectors as workRequestSelectors } from './work-requests';
import {
	actions as translationActions,
	fetchMachineTranslations,
	fetchTranslationLanguages,
	fetchTranslationMemories,
	selectors as translationSelectors
} from './translations';
import { actions as languageActions, fetchLanguages, selectors as languageSelectors } from './languages';
import { actions as previewActions, fetchPreviews } from './previews';
import { fetchTranslationById } from '@/store/translations';
import _get from 'lodash.get';

export const STORE_NAME = 'subtitlerStore';

function *loadPageData({ payload }) {
	let { workRequestId, mode } = payload;
	let isPrintMode = mode === 'print';
	let isPrintDateMode = mode === 'printdate';
	let isPrint = isPrintMode || isPrintDateMode;
	let error = null;
	try {
		yield put(internalActions.loadPageRequest(workRequestId));

		let workRequest = yield call(fetchWorkRequestById, workRequestActions.getWorkRequestById(workRequestId));
		if(isError(workRequest)) {
			throw workRequest;
		}
		if(!workRequest) {
			error = 'Oops... An Unexpected error has occurred';
			throw new Error(error);
		}
		if(workRequest.status === 404) {
			error = 'Oops... This Order Does Not Exist';
			throw new Error(error);
		}

		const isGfxMode = isGfxOrder(workRequest);
		const isDedicatedGfxMode = isDedicatedGfxOrder(workRequest);
		const isNarrationMode = isNarrationOrder(workRequest);
		const isDialogueMode = isDialogueOrder(workRequest) || isNarrationMode;

		let languagesTask;
		let languages = yield select((state) => languageSelectors.getLanguages(state));
		let language = _.find(languages, { code: workRequest.language });
		if( !languages || !language ) {
			languagesTask = yield fork(fetchLanguages, languageActions.getLanguages());
		}

		let { asset, auto: automatedLocalization } = workRequest;

		let ovTranslationsTask;
		let graphicsTranslationsTask;
		let dialogueTranslationsTask;
		let ovTranslations;
		let graphicsTranslations;
		let dialogueTranslations;
		let translations;
		let translationLines;

		if(languagesTask) {
			languages = yield join(languagesTask);
			language = _.find(languages, { code: workRequest.language });

			if(!language) {
				throw new Error('Could not find language data for the work request');
			}
		}

		// Join our other tasks that happened in parallel

		if(!isPrintDateMode) {

			if( (isDialogueMode && !asset?.transcription?._id) || (isGfxMode && !asset?.transcription?.graphics)) {
				throw new Error('Asset does not have an OV transcription');
			}

			let transcripts = getTranscriptsForOrder(workRequest);

			if(transcripts._id) {
				ovTranslationsTask = yield fork(
					fetchTranslationById,
					translationActions.getTranslationById(asset.transcription._id)
				);
			}

			if(transcripts.graphics) {
				graphicsTranslationsTask = yield fork(
					fetchTranslationById,
					translationActions.getTranslationById(asset.transcription.graphics)
				);
			}

			if(transcripts.autogfx) {
				graphicsTranslationsTask = yield fork(
					fetchTranslationById,
					translationActions.getTranslationById(asset.transcription.autogfx)
				);
			}

			if(transcripts.dialogue) {
				dialogueTranslationsTask = yield fork(
					fetchTranslationById,
					translationActions.getTranslationById(asset.transcription.dialogue)
				);
			}

			// Fetch most recent, english preview
			let previewTask = yield fork(fetchTranslationPreview, asset._id);

			let translationLanguagesTask = yield fork(
				fetchTranslationLanguages,
				translationActions.getTranslationLanguages()
			);

			// Join forked tasks
			let preview = yield join(previewTask);
			if( isError(preview) ) {
				throw preview;
			}

			ovTranslations = ovTranslationsTask ? yield join(ovTranslationsTask) : null;
			if( isError(ovTranslations) ) {
				throw ovTranslations;
			}
			graphicsTranslations = graphicsTranslationsTask ? yield join(graphicsTranslationsTask) : null;
			if(isError(graphicsTranslations)) {
				throw graphicsTranslations;
			}
			dialogueTranslations = dialogueTranslationsTask ? yield join(dialogueTranslationsTask) : null;
			if(isError(dialogueTranslations)) {
				throw dialogueTranslations;
			}

			if(isGfxMode && !isDialogueMode && !ovTranslations) {
				ovTranslations = graphicsTranslations;
			}

			let translationLanguages = yield join(translationLanguagesTask);
			if( isError(translationLanguages) ) {
				throw translationLanguages;
			}

			if( graphicsTranslations && !ovTranslations) {
				if(isDialogueMode && dialogueTranslations) {
					ovTranslations = {
						...dialogueTranslations,
						lines: SubtitleService.sortLinesByRange([
							...graphicsTranslations.lines.map((e) => ({ ...e, gfx: true, automatedLocalization })),
							...dialogueTranslations.lines
						], automatedLocalization)
					};
				}
				else {
					ovTranslations = graphicsTranslations;
				}
			}

			// translationLines are the lines edited by the user
			if(transcripts._id) {
				if(isPrint) {
					translationLines = workRequest.translation?.printLines || [];
				}
				else if(!(transcripts.autogfx || transcripts.graphics)) {
					translationLines = workRequest.translation?.lines || [];
				}
				else {
					translationLines = SubtitleService.sortLinesByTimeCodes([
						...workRequest.translation?.lines,
						...workRequest.graphicsTranslation?.lines
					]);
				}
			}
			else if( transcripts.autogfx || transcripts.graphics) {
				if(transcripts.dialogue) {
					translationLines = SubtitleService.sortLinesByTimeCodes([
						...workRequest.translation?.lines,
						...workRequest.graphicsTranslation?.lines
					]);
				}
				else {
					translationLines = workRequest.graphicsTranslation?.lines || [];
				}
			}
			else {
				translationLines = workRequest.translation?.lines || [];
			}

			// check if the line is merged, then merge the ovTranslations to match the saved translations
			if(ovTranslations.lines.length > translationLines.length) {
				translationLines.forEach((translation, index) => {
					if(translation.merged) {
						// remove the merged cells from the array
						ovTranslations.lines.splice(index, translation.merged.length, {
							...ovTranslations.lines[index],
							text: ovTranslations.lines.slice(index, index + translation.merged.length)
								.map((e) => e?.text ?? '')
								.join('\n'), number: index+1
						} );
					}
					else if(ovTranslations.lines[index]) {
						ovTranslations.lines[index].number = index+1;
					}
				});
			}

			// translations are the reference ov lines displayed for the user
			if( isPrintDateMode ) {
				translations = workRequest.translation?.date;
			}
			else if( isPrintMode ) {
				translations = SubtitleService.map3xPrintTranslations(translationLines, ovTranslations?.printLines);
			}
			else if(transcripts.autogfx) {
				if(transcripts.dialogue) {
					translations = SubtitleService.map3xTranslations(
						translationLines,
						ovTranslations?.lines
					).map((line) => ({ ...line, automatedLocalization }));
				}
				else {
					translations = SubtitleService.map3xTranslations(
						translationLines,
						graphicsTranslations?.lines,
					).map((line) => ({ ...line, gfx: true, automatedLocalization }));
				}
			}
			else if(transcripts.graphics) {
				if(transcripts.dialogue) {
					translations = SubtitleService.map3xTranslations(
						translationLines,
						ovTranslations.lines,
						graphicsTranslations.lines,
						dialogueTranslations?.lines
					);
				}
				else {
					translations = SubtitleService.map3xTranslations(
						translationLines,
						graphicsTranslations.lines
					).map((line) => ({ ...line, gfx: true, showSubs: !isDedicatedGfxMode }));
				}
			}
			else if( transcripts._id ) {
				translations = SubtitleService.map3xTranslations(
					translationLines,
					ovTranslations?.lines,
				);
				if(workRequest.auto) {
					translations.map((line) => ({ ...line, automatedLocalization }));
				}
			}

			let translationFont = _get(language, 'translationFonts[0]', false) || language.font(workRequest.studio);
			let vtt = !isPrint && translations ? SubtitleService.subsToEncodedVtt(translations) : '';
			let machineTranslations = [];

			if(translations.filter((t) => t.translationFrom==='mt').length) {
				machineTranslations = translations.map((e) => e.translationFrom==='mt'? e.translationText:e.ovText);
			}

			let tmTranslations = [];
			if(translations.filter((t) => t.translationFrom==='tm').length) {
				tmTranslations = translations.map((e) => e.translationFrom==='tm'? e.translationText:null);
			}

			if(!translations) {
				throw new Error('This Work Request Does Not Contain Any Translations');
			}

			let subtitles = {
				workRequestId,
				asset,
				language,
				preview,
				translations,
				translationFont,
				tmTranslations,
				machineTranslations,
				translationLanguages,
				ovTranslations,
				vtt
			};

			yield put(internalActions.loadPageSuccess(subtitles));
		}
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadPageFailure({ id: workRequestId, error: error || err }));
		return err;
	}
}

function *loadMachineTranslations({ payload }) {

	let workRequestId = payload.workRequestId;
	let mode = payload.mode;
	let language = payload.language.includes('-') ? payload.language.split('-') : payload.language;

	try {
		yield put(internalActions.loadMachineTranslationsRequest({ workRequestId }));

		let isPrintDateMode = mode === 'printdate';
		let machineTranslationsTask;
		let workRequest = yield select((state) => workRequestSelectors.getWorkRequestById(state, workRequestId));
		let id = getTranscriptionId(workRequest);
		if(Array.isArray(id)) {
			let [dialogue, graphics] = id;
			if(Array.isArray(language)) { // machine translations for dual languages - max 2 languages
				machineTranslationsTask = !isPrintDateMode
					? yield all([
						fork(
							fetchMachineTranslations,
							translationActions.getMachineTranslations({ id: dialogue, language: language[0] })
						),
						fork(
							fetchMachineTranslations,
							translationActions.getMachineTranslations({ id: dialogue, language: language[1] })
						),
						fork(
							fetchMachineTranslations,
							translationActions.getMachineTranslations({ id: graphics, language: language[0] })
						),
						fork(
							fetchMachineTranslations,
							translationActions.getMachineTranslations({ id: graphics, language: language[1] })
						)
					])
					: null;
			}
			else {
				machineTranslationsTask = !isPrintDateMode
					? yield all([
						fork(
							fetchMachineTranslations,
							translationActions.getMachineTranslations({ id: dialogue, language })
						),
						fork(
							fetchMachineTranslations,
							translationActions.getMachineTranslations({ id: graphics, language })
						)
					])
					: null;
			}
		}
		else if(Array.isArray(language)) { // machine translations for dual languages
			machineTranslationsTask = !isPrintDateMode
				? yield all([
					fork(
						fetchMachineTranslations,
						translationActions.getMachineTranslations({ id, language: language[0] })
					),
					fork(
						fetchMachineTranslations,
						translationActions.getMachineTranslations({ id, language: language[1] })
					)
				])
				: null;
		}
		else {
			machineTranslationsTask = !isPrintDateMode
				? yield fork(
					fetchMachineTranslations,
					translationActions.getMachineTranslations({ id, language })
				)
				: null;
		}

		let machineTranslations = !isPrintDateMode ? yield join(machineTranslationsTask) : [];
		if( isError(machineTranslations) ) {
			throw machineTranslations;
		}
		if(!machineTranslations || !machineTranslations.length) {
			throw new Error('machine translations failed to load');
		}

		if(Array.isArray(machineTranslations[0]) && Array.isArray(machineTranslations[1])) {

			// if dual languages for normal orders (no graphics and dialogue)
			if(Array.isArray(language) && machineTranslations.length === 2) {
				machineTranslations = machineTranslations[0]
					.map((line, idx) => line + '<span></span>' + machineTranslations[1][idx]);
			}
			else {
				let dialogueTranslations = yield select(
					(state) => translationSelectors.getTranslationById(state, workRequest.asset.transcription.dialogue)
				);
				if(dialogueTranslations) {
					if(Array.isArray(language) && machineTranslations.length === 4) { // dual languages case
						// machineTranslation[0] && machineTranslation[1] : dialogue in both languages
						const dialogueMachineTranslationsLang1 = machineTranslations[0];
						const dialogueMachineTranslationsLang2 = machineTranslations[1];
						dialogueTranslations.lines.forEach( (line, i) => {
							line.text = dialogueMachineTranslationsLang1[i] + '<span></span>' + dialogueMachineTranslationsLang2[i];
						});
					}
					else {
						const dialogueMachineTranslations = machineTranslations[0];
						dialogueTranslations.lines.forEach( (line, i) => {
							line.text = dialogueMachineTranslations[i];
						});
					}
				}

				let gfxTranslations = yield select(
					(state) => translationSelectors.getTranslationById(
						state,
						workRequest.auto
							? workRequest.asset.transcription.autogfx
							: workRequest.asset.transcription.graphics
					)
				);

				if(gfxTranslations) {
					if( Array.isArray(language) && machineTranslations.length === 4 ) { // dual languages case
						// machineTranslation[2] && machineTranslation[3] : graphics in both languages
						const gfxMachineTranslationsLang1 = machineTranslations[2];
						const gfxMachineTranslationsLang2 = machineTranslations[3];
						gfxTranslations.lines.forEach( (line, i) => {
							line.text = gfxMachineTranslationsLang1[i] + '<span></span>' + gfxMachineTranslationsLang2[i];
						});
					}
					else {
						const gfxMachineTranslations = machineTranslations[1];
						gfxTranslations.lines.forEach( (line, i) => {
							line.text = gfxMachineTranslations[i];
						});
					}

				}

				let translatedLines = SubtitleService.sortLinesByTimeCodes([
					...dialogueTranslations.lines,
					...gfxTranslations.lines
				]);
				machineTranslations = translatedLines.map((line) => line?.text);
			}
		}
		yield put(internalActions.loadMachineTranslationsSuccess({ workRequestId, machineTranslations }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadMachineTranslationsFailure({ id: workRequestId, error: err }));
		return err;
	}
}

function *loadTranslationMemories({ payload }) {
	let workRequestId = payload.workRequestId;
	let language = payload.language;

	try {
		yield put(internalActions.loadTranslationMemoriesRequest({ workRequestId }));

		let workRequest = yield select((state) => workRequestSelectors.getWorkRequestById(state, workRequestId));

		let translationMemoriesTask = null;
		let id = getTranslationId(workRequest);
		if(Array.isArray(id)) {
			let [translation, graphicsTranslation] = id;
			translationMemoriesTask = yield all([
				fork(
					fetchTranslationMemories,
					translationActions.getTranslationMemories({ id: translation, language })
				),
				fork(
					fetchTranslationMemories,
					translationActions.getTranslationMemories({ id: graphicsTranslation, language })
				)
			]);
		}
		else {
			translationMemoriesTask = yield fork(
				fetchTranslationMemories,
				translationActions.getTranslationMemories({ id, language })
			);
		}

		let translationMemories = translationMemoriesTask
			? yield join(translationMemoriesTask)
			: [];

		if( isError(translationMemories) ) {
			throw translationMemories;
		}
		if(Array.isArray(translationMemories[0]) && Array.isArray(translationMemories[1])) {

			let translation = workRequest.translation;
			if(translation) {
				const memories = translationMemories[0];
				translation.lines.forEach( (line, i) => {
					line.text = memories[i] || line.text;
				});
			}

			let graphicsTranslation = workRequest.graphicsTranslation;

			if(graphicsTranslation) {
				const graphicsMemories = translationMemories[1];
				graphicsTranslation.lines.forEach( (line, i) => {
					line.text = graphicsMemories[i] || line.text;
				});
			}

			let translatedLines = SubtitleService.sortLinesByTimeCodes([
				...translation.lines,
				...graphicsTranslation.lines
			]);
			translationMemories = translatedLines.map((line) => line.text);

		}

		yield put(internalActions.loadTranslationMemoriesSuccess({
			workRequestId,
			tmTranslations: translationMemories
		}));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadTranslationMemoriesFailure({ id: workRequestId, error: err }));
		return err;
	}
}

function *fetchTranslationPreview(assetId) {
	try {
		let previews = yield call(fetchPreviews, previewActions.getAssetPreviews({ assetId, type: 'none' }));
		if( isError(previews) ) {
			throw previews;
		}

		let preview = _(previews)
			.filter({ language: 'ENG' })
			.sortBy(NoneDubSub, '-created')
			.first();

		if( !preview ) {
			return null;
		}

		let signedPreview = yield PreviewService.getSignedPreviews(assetId, 'ENG', [preview._id]);

		return signedPreview.length ? signedPreview[0] : null;
	}
	catch(e) {
		return handleError(e);
	}

	function NoneDubSub(preview) {
		return ['none', 'dub', 'sub'].indexOf(preview.type);
	}
}

function *importTranslations({ payload }) {
	let { workRequestId, file } = payload;

	try {
		yield put(internalActions.importTranslationsRequest({ workRequestId, file }));

		let workRequest = yield select((state) => workRequestSelectors.getWorkRequestById(state, workRequestId));

		let ovTranslations;
		let graphicsTranslations;

		if(isGfxOnlyOrder(workRequest)) {
			graphicsTranslations = yield select(
				(state) => translationSelectors.getTranslationById(state, workRequest.asset.transcription.graphics)
			);
		}
		else if(isDedicatedGfxOrder(workRequest)
			&& (isDedicatedGfxOrder(workRequest) || isNarrationOrder(workRequest))) {
			graphicsTranslations = yield select(
				(state) => translationSelectors.getTranslationById(state, workRequest.asset.transcription.graphics)
			);
			ovTranslations = yield select(
				(state) => translationSelectors.getTranslationById(state, workRequest.asset.transcription.dialogue)
			);
		}
		else {
			ovTranslations = yield select(
				(state) => translationSelectors.getTranslationById(state, workRequest.asset.transcription._id)
			);
		}

		const lines = graphicsTranslations && ovTranslations
			? SubtitleService.sortLinesByTimeCodes([...graphicsTranslations.lines, ...ovTranslations.lines])
			: graphicsTranslations ? graphicsTranslations.lines : ovTranslations.lines;

		let translations = yield SubtitleService.importSubtitles(file, lines);

		let subtitles = {
			workRequestId,
			translations
		};

		yield put(internalActions.importTranslationsSuccess(subtitles));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.importTranslationsFailure({ id: workRequestId, error: err }));
		return err;
	}
}

export const LOAD_PAGE = 'subtitler.load-page';
export const LOAD_PAGE_REQUEST = 'subtitler.load-page.request';
export const LOAD_PAGE_SUCCESS = 'subtitler.load-page.success';
export const LOAD_PAGE_FAILURE = 'subtitler.load-page.failure';

export const IMPORT_TRANSLATIONS = 'subtitler.translations.import';
export const IMPORT_TRANSLATIONS_REQUEST = 'subtitler.translations.import.request';
export const IMPORT_TRANSLATIONS_SUCCESS = 'subtitler.translations.import.success';
export const IMPORT_TRANSLATIONS_FAILURE = 'subtitler.translations.import.failure';

export const UPDATE_TRANSLATIONS = 'subtitler.translations.update';

export const LOAD_MACHINE_TRANSLATIONS = 'subtitler.machine-translations.load';
export const LOAD_MACHINE_TRANSLATIONS_REQUEST = 'subtitler.machine-translations.load.request';
export const LOAD_MACHINE_TRANSLATIONS_SUCCESS = 'subtitler.machine-translations.load.success';
export const LOAD_MACHINE_TRANSLATIONS_FAILURE = 'subtitler.machine-translations.load.failure';

export const LOAD_MEMORIES_TRANSLATIONS = 'subtitler.translation-memories.load';
export const LOAD_MEMORIES_TRANSLATIONS_REQUEST = 'subtitler.translation-memories.load.request';
export const LOAD_MEMORIES_TRANSLATIONS_SUCCESS = 'subtitler.translation-memories.load.success';
export const LOAD_MEMORIES_TRANSLATIONS_FAILURE = 'subtitler.translation-memories.load.failure';

export const INITIAL_STATE = {
	isLoading: false,
	loadError: null,
	isImporting: false,
	subtitles: {},
	isMachineTranslationsLoading: false
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case LOAD_PAGE_REQUEST: {
			return {
				...state,
				isLoading: true,
				loadError: null,
				// Reset stored subtitles as we only ever need one
				subtitles: {}
			};
		}

		case LOAD_PAGE_SUCCESS: {
			let subtitles = action.payload;

			return {
				...state,
				isLoading: false,
				loadError: null,
				subtitles: {
					[subtitles.workRequestId]: subtitles
				}
			};
		}

		case LOAD_PAGE_FAILURE: {
			return {
				...state,
				isLoading: false,
				loadError: action.payload
			};
		}

		case IMPORT_TRANSLATIONS_REQUEST: {
			return {
				...state,
				isImporting: true
			};
		}

		case IMPORT_TRANSLATIONS_SUCCESS: {
			let subtitles = action.payload;

			return {
				...state,
				subtitles: {
					[subtitles.workRequestId]: {
						...state.subtitles[subtitles.workRequestId],
						...subtitles
					}
				},
				isImporting: false
			};
		}

		case IMPORT_TRANSLATIONS_FAILURE: {
			return {
				...state,
				isImporting: false
			};
		}

		case UPDATE_TRANSLATIONS: {
			let { workRequestId, translations } = action.payload;
			let subtitles = {
				[workRequestId]: {
					...state.subtitles[workRequestId],
					translations
				}
			};

			return {
				...state,
				subtitles
			};
		}

		case LOAD_MACHINE_TRANSLATIONS_REQUEST: {
			return {
				...state,
				isMachineTranslationsLoading: true
			};
		}

		case LOAD_MACHINE_TRANSLATIONS_SUCCESS: {
			const { workRequestId, machineTranslations } = action.payload;

			return {
				...state,
				subtitles: {
					[workRequestId]: {
						...state.subtitles[workRequestId],
						machineTranslations
					}
				},
				isMachineTranslationsLoading: false
			};
		}

		case LOAD_MACHINE_TRANSLATIONS_FAILURE: {
			return {
				...state,
				isMachineTranslationsLoading: false
			};
		}

		case LOAD_MEMORIES_TRANSLATIONS_REQUEST: {
			const { workRequestId } = action.payload;

			return {
				...state,
				subtitles: {
					[workRequestId]: {
						...state.subtitles[workRequestId],
						tmTranslations: [],
						isTranslationMemoriesLoading: true,
						isTranslationMemoriesFetched: false
					}
				}
			};
		}

		case LOAD_MEMORIES_TRANSLATIONS_SUCCESS: {
			const { workRequestId, tmTranslations } = action.payload;

			return {
				...state,
				subtitles: {
					[workRequestId]: {
						...state.subtitles[workRequestId],
						tmTranslations: tmTranslations,
						isTranslationMemoriesLoading: false,
						isTranslationMemoriesFetched: true
					}
				}
			};
		}

		case LOAD_MEMORIES_TRANSLATIONS_FAILURE: {
			const { workRequestId } = action.payload;

			return {
				...state,
				subtitles: {
					[workRequestId]: {
						...state.subtitles[workRequestId],
						isTranslationMemoriesLoading: false,
						isTranslationMemoriesFetched: false
					}
				}
			};
		}

		case RESET_STORES:
			return INITIAL_STATE;

		default:
			return state;
	}
}

export const actions = {
	loadPageData: createAction(LOAD_PAGE),
	importTranslations: createAction(IMPORT_TRANSLATIONS),
	updateTranslations: createAction(UPDATE_TRANSLATIONS),
	loadMachineTranslations: createAction(LOAD_MACHINE_TRANSLATIONS),
	loadTranslationMemories: createAction(LOAD_MEMORIES_TRANSLATIONS)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	loadPageRequest: createAction(LOAD_PAGE_REQUEST),
	loadPageSuccess: createAction(LOAD_PAGE_SUCCESS),
	loadPageFailure: createAction(LOAD_PAGE_FAILURE),
	importTranslationsRequest: createAction(IMPORT_TRANSLATIONS_REQUEST),
	importTranslationsSuccess: createAction(IMPORT_TRANSLATIONS_SUCCESS),
	importTranslationsFailure: createAction(IMPORT_TRANSLATIONS_FAILURE),
	loadMachineTranslationsRequest: createAction(LOAD_MACHINE_TRANSLATIONS_REQUEST),
	loadMachineTranslationsSuccess: createAction(LOAD_MACHINE_TRANSLATIONS_SUCCESS),
	loadMachineTranslationsFailure: createAction(LOAD_MACHINE_TRANSLATIONS_FAILURE),
	loadTranslationMemoriesRequest: createAction(LOAD_MEMORIES_TRANSLATIONS_REQUEST),
	loadTranslationMemoriesSuccess: createAction(LOAD_MEMORIES_TRANSLATIONS_SUCCESS),
	loadTranslationMemoriesFailure: createAction(LOAD_MEMORIES_TRANSLATIONS_FAILURE)
};

export const selectors = {
	isReady: createSelector(STORE_NAME, isReady),
	didLoadFail: createSelector(STORE_NAME, didLoadFail),
	loadError: createSelector(STORE_NAME, loadError),
	isImportingTranslations: createSelector(STORE_NAME, isImportingTranslations),
	getSubtitles: createSelector(STORE_NAME, getSubtitles),
	isMachineTranslationsLoading: createSelector(STORE_NAME, isMachineTranslationsLoading),
	isTranslationMemoriesLoading: createSelector(STORE_NAME, isTranslationMemoriesLoading),
	isTranslationMemoriesFetched: createSelector(STORE_NAME, isTranslationMemoriesFetched)
};

function isReady(state, workRequestId) {
	return !state.isLoading && !!getSubtitles(state, workRequestId);
}

function didLoadFail(state) {
	return !!state.loadError;
}

function loadError(state) {
	return state.loadError;
}

function isImportingTranslations(state) {
	return state.isImporting;
}

function getSubtitles(state, workRequestId) {
	return state.subtitles[workRequestId];
}

function isMachineTranslationsLoading(state) {
	return state.isMachineTranslationsLoading;
}

function isTranslationMemoriesLoading(state, workRequestId) {
	return state.subtitles[workRequestId]?.isTranslationMemoriesLoading || false;
}

function isTranslationMemoriesFetched(state, workRequestId) {
	let container = state.subtitles[workRequestId];

	if(!container) {
		return false;
	}

	return container.isTranslationMemoriesFetched || !!container.tmTranslations.length;
}

export function *watchSubtitlerPage() {
	yield takeLatest(LOAD_PAGE, loadPageData);
	yield takeLatest(IMPORT_TRANSLATIONS, importTranslations);
	yield takeLatest(LOAD_MACHINE_TRANSLATIONS, loadMachineTranslations);
	yield takeLatest(LOAD_MEMORIES_TRANSLATIONS, loadTranslationMemories);
}
