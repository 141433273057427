import _ from 'lodash';
import _get from 'lodash.get';
import isError from 'lodash.iserror';
import uuid from 'uuid/v4';
import slugify from 'slugify';
import {
	takeEvery,
	takeLeading,
	all,
	call,
	fork,
	join,
	put,
	select
} from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import { createAction } from 'redux-actions';
import { createSelector, updateDispatcher, handleError } from './common';
import IngestService from '../modules/services/ingest-service';
import OfflineService from '../modules/services/offline-service';
import UploadService from '../modules/services/upload-service';
import RouteService from '../modules/services/route-service';
import PreviewService from '../modules/services/preview-service';
import AssetService from '../modules/services/asset-service';
import WorkRequestOrderService from '../modules/services/work-request-order-service';
import WorkRequestService from '../modules/services/work-request-service';
import TranslationService from '../modules/services/translation-service';

import {
	actions as languagesActions, fetchLanguages,
	selectors as languagesSelectors
} from './languages';
import {
	actions as territoriesActions, fetchTerritories,
	selectors as territoriesSelectors
} from './territories';
import {
	actions as workRequestsActions, fetchWorkRequestById,
	selectors as workRequestsSelectors, updateWorkRequest
} from './work-requests';
import {
	actions as ingestsActions, fetchIngestsByWorkRequest,
	selectors as ingestsSelectors
} from './ingests';
import {
	selectors as commentsSelectors
} from './comments';
import {
	selectors as sessionSelectors
} from './session';
import {
	actions as previewActions, fetchPreviews
} from './previews';

export const STORE_NAME = 'workRequestPageStore';

function *loadPageData({ payload }) {
	try {
		yield put(internalActions.loadPageRequest());

		let { workRequestId } = payload;

		let ingestsTask = yield fork(
			fetchIngestsByWorkRequest,
			ingestsActions.getIngestsByWorkRequest({ workRequestId })
		);

		let workRequest = yield call(fetchWorkRequestById, workRequestsActions.getWorkRequestById(workRequestId));

		if( isError(workRequest) || workRequest?.status >= 400 || !workRequest || !workRequest.asset._id ) {
			throw new Error('Failed to load page resources', { cause: workRequest });
		}

		// Fetch most recent, english preview
		let preview = yield call(fetchTranslationPreview, workRequest?.asset._id);
		if( isError(preview) ) {
			throw new Error('Failed to load preview');
		}

		let ingests = yield join(ingestsTask);
		if( isError(ingests) ) {
			throw ingests;
		}

		yield put(internalActions.loadPageSuccess({ workRequest, preview }));
	}
	catch(e) {
		const err = handleError(e);
		// eslint-disable-next-line multiline-ternary
		yield put(!e.cause ? internalActions.loadPageFailure({ error: err }) : internalActions.loadPageFailure({
			error: {
				message: e.cause.data?.message || e.cause.message,
				status: e.cause.status || e.cause.message.includes('404')? 404 : 400
			}
		}));
		return err;
	}
}

function *loadSupplementalData() {
	try {
		yield put(internalActions.loadSupplementalRequest());

		let languagesTask = yield fork(fetchLanguages, languagesActions.getLanguages());
		let territoriesTask = yield fork(fetchTerritories, territoriesActions.getTerritories());

		let languages = yield join(languagesTask);
		let territories = yield join(territoriesTask);

		if( isError(languages) || isError(territories) ) {
			throw new Error('Failed to load supplemental page resources');
		}

		yield put(internalActions.loadSupplementalSuccess());
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadSupplementalFailure({ error: err }));
		return err;
	}
}

function *startOrder({ payload }) {
	let { workRequestId } = payload;
	try {
		yield put(internalActions.startOrderRequest({ workRequestId }));

		let update = { status: 'in-progress' };
		let action = workRequestsActions.updateWorkRequest({ id: workRequestId, data: update });
		let workRequest = yield call(updateWorkRequest, action);

		if( isError(workRequest) ) {
			throw workRequest;
		}

		yield put(internalActions.startOrderSuccess({ workRequestId }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.startOrderFailure({ workRequestId, error: err }));
		return err;
	}
}

function *awaitingMaterials({ payload }) {
	let { workRequestId } = payload;
	try {
		yield put(internalActions.awaitingMaterialRequest({ workRequestId }));

		let update = { status: 'awaiting-materials' };
		let action = workRequestsActions.updateWorkRequest({ id: workRequestId, data: update });
		let workRequest = yield call(updateWorkRequest, action);

		if( isError(workRequest) ) {
			throw workRequest;
		}

		yield put(internalActions.awaitingMaterialSuccess({ workRequestId }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.awaitingMaterialFailure({ workRequestId, error: err }));
		return err;
	}
}

function *uploadOffline({ payload }) {
	let { workRequestId, tagName, file } = payload;
	try {
		yield put(internalActions.uploadOfflineRequest({ workRequestId, tagName }));

		let uploadResult = yield call(uploadOfflineFile, workRequestId, tagName, file);
		if( isError(uploadResult.result) ) {
			throw uploadResult.result;
		}

		let path = uploadResult.result;
		yield OfflineService.createOffline(workRequestId, tagName, path);

		// TODO: Consider wrapping this so that a failure triggers a manual update of the store
		// Update our copy of the work request but don't let the failure crash the process
		yield call(fetchWorkRequestById, workRequestsActions.getWorkRequestById(workRequestId));

		yield put(internalActions.uploadOfflineSuccess({ workRequestId, tagName }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.uploadOfflineFailure({ workRequestId, tagName, error: err }));
		return err;
	}
}

function *replaceOffline({ payload }) {
	let { workRequestId, tagName, file, offlineId, reason } = payload;
	try {
		yield put(internalActions.replaceOfflineRequest({ workRequestId, offlineId }));

		let uploadResult = yield call(uploadOfflineFile, workRequestId, tagName, file);
		if( isError(uploadResult.result) ) {
			throw uploadResult.result;
		}

		let path = uploadResult.result;
		yield OfflineService.update(offlineId, { path, replacementReason: reason });

		// TODO: Consider wrapping this so that a failure triggers a manual update of the store
		// Update our copy of the work request but don't let the failure crash the process
		yield call(fetchWorkRequestById, workRequestsActions.getWorkRequestById(workRequestId));

		yield put(internalActions.replaceOfflineSuccess({ workRequestId, tagName }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.replaceOfflineFailure({ workRequestId, tagName, error: err }));
		return err;
	}
}

function *submitForReview({ payload }) {
	let { workRequestId } = payload;
	try {
		yield put(internalActions.submitForReviewRequest({ workRequestId }));

		let update = { status: 'for-review' };
		yield call(updateWorkRequest, workRequestsActions.updateWorkRequest({ id: workRequestId, data: update }));

		yield put(internalActions.submitForReviewSuccess({ workRequestId }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.submitForReviewFailure({ workRequestId, error: err }));
		return err;
	}
}

function *approveOfflines({ payload }) {
	let { workRequestId } = payload;
	try {
		yield put(internalActions.approveOfflinesRequest({ workRequestId }));

		let update = { status: 'approved' };
		yield call(updateWorkRequest, workRequestsActions.updateWorkRequest({ id: workRequestId, data: update }));

		yield put(internalActions.approveOfflinesSuccess({ workRequestId }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.approveOfflinesFailure({ workRequestId, error: err }));
		return err;
	}
}

function *rejectOfflines({ payload }) {
	let { workRequest } = payload;
	let workRequestId = workRequest._id;
	try {
		yield put(internalActions.rejectOfflinesRequest({ workRequestId }));

		// Verify the user has left a comment
		let hasLeftRecentComment = yield call(verifyRejectionComment, workRequestId);
		if( isError(hasLeftRecentComment) ) {
			throw hasLeftRecentComment;
		}

		if( !workRequest.auto && !workRequest.autosubs && !hasLeftRecentComment ) {
			throw new Error('Must leave a comment explaining change request');
		}

		let update = ( workRequest.auto || workRequest.autosubs ) ? { status: 'incomplete' } : { status: 'rejected' };
		yield call(updateWorkRequest, workRequestsActions.updateWorkRequest({ id: workRequestId, data: update }));

		yield put(internalActions.rejectOfflinesSuccess({ workRequestId }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.rejectOfflinesFailure({ workRequestId, error: err }));
		return err;
	}
}

function *verifyRejectionComment(workRequestId) {
	// Get current user
	let currentUser = yield select(sessionSelectors.getSession);

	// Get work request
	let workRequest = yield select(workRequestsSelectors.getWorkRequestById, workRequestId);
	let mostRecentReviewStep = _.filter(workRequest.history, { status: 'for-review' }).pop();

	// Get most recent offline for each tag
	let sortedOfflines = _.sortBy(workRequest.offlines, 'date');

	// Check that the user has posted at least one comment for most recent offline(s)
	let offlinesToCheck = [];

	if( workRequest.tags.length > 0 ) {
		// if work request has tags, check most recent of each
		for( let tag of workRequest.tags ) {
			let offline = _.filter(sortedOfflines, { tag: tag.name }).pop();
			offlinesToCheck.push(offline);
		}
	}
	else {
		// if no tags, check most recent offline
		offlinesToCheck.push(sortedOfflines.pop());
	}

	// check offlines for comments
	for( let offline of offlinesToCheck ) {
		let comments = yield select(commentsSelectors.getOfflineComments, offline._id);
		let replies = _.flatten(comments, 'replies');

		let allComments = [
			...comments,
			...replies
		];

		let userHasPostedCommment = _.some(allComments, (comment) => {
			return comment.user._id === currentUser._id && comment.created > mostRecentReviewStep.date;
		});

		if( userHasPostedCommment ) {
			return true;
		}
	}

	return false;
}

function *confirmChangeRequest({ payload }) {
	let { workRequestId } = payload;
	try {
		yield put(internalActions.confirmChangeRequestRequest({ workRequestId }));

		let update = { status: 'in-progress' };
		yield call(updateWorkRequest, workRequestsActions.updateWorkRequest({ id: workRequestId, data: update }));

		yield put(internalActions.confirmChangeRequestSuccess({ workRequestId }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.confirmChangeRequestFailure({ workRequestId, error: err }));
		return err;
	}
}

function *uploadFinals({ payload }) {
	let { workRequestId, tagName, files } = payload;

	let workRequest = yield select(workRequestsSelectors.getWorkRequestById, workRequestId);
	let tagSlug;
	if( tagName ) {
		tagSlug = slugify(tagName);
	}

	let context = {
		workRequest: workRequestId,
		asset: workRequest.asset._id,
		project: workRequest.project._id,
		assetType: workRequest.assetType,
		tag: tagSlug,
		group: Date.now()
	};

	let tasks = _.map(files, (file) => call(
		uploadFinal,
		actions.uploadFinal({ workRequestId, tagName, file, context })
	));
	yield all(tasks);
}

function *uploadFinal({ payload }) {
	let { workRequestId, tagName, file, context } = payload;
	let uploadId = uuid();

	if( !context ) {
		let workRequest = yield select(workRequestsSelectors.getWorkRequestById, workRequestId);
		let tagSlug;
		if( tagName ) {
			tagSlug = slugify(tagName);
		}

		context = {
			workRequest: workRequestId,
			asset: workRequest.asset._id,
			project: workRequest.project._id,
			assetType: workRequest.assetType,
			tag: tagName,
			tagSlug: tagSlug,
			group: Date.now()
		};
	}

	try {
		yield put(internalActions.uploadFinalRequest({ workRequestId, tagName, uploadId }));

		const { size } = file;

		const url = RouteService.stateHref('ingest.view', { id: ':ingest' }, { absolute: true });
		const urls = {
			view: decodeURIComponent(url)
		};

		let ingest = yield IngestService.create({
			context,
			size,
			urls
		});

		let ingestId = ingest._id;
		let uploadResult = yield call(uploadFinalIngest, file, { uploadId, ingestId, context });
		if( isError(uploadResult.result) ) {
			throw uploadResult.result;
		}

		ingest = yield IngestService.update(ingest._id, {
			status: 'uploaded',
			source: {
				url: uploadResult.result
			}
		});

		yield put(ingestsActions.addIngestManually({ ingest }));

		yield put(internalActions.uploadFinalSuccess({ workRequestId, tagName, uploadId }));
		return ingest;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.uploadFinalFailure({ workRequestId, tagName, uploadId, error: err }));
		return err;
	}
}

function *completeOrder({ payload }) {
	let { workRequestId } = payload;
	try {
		yield put(internalActions.completeOrderRequest({ workRequestId }));

		let update = { status: 'complete' };
		yield call(updateWorkRequest, workRequestsActions.updateWorkRequest({ id: workRequestId, data: update }));

		yield put(internalActions.completeOrderSuccess({ workRequestId }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.completeOrderFailure({ workRequestId, error: err }));
		return err;
	}
}

function *cancelOrder({ payload }) {
	let { workRequestId } = payload;
	try {
		yield put(internalActions.cancelOrderRequest({ workRequestId }));

		let update = { status: 'cancelled' };
		yield call(updateWorkRequest, workRequestsActions.updateWorkRequest({ id: workRequestId, data: update }));

		yield put(internalActions.cancelOrderSuccess({ workRequestId }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.cancelOrderFailure({ workRequestId, error: err }));
		return err;
	}
}

function *uploadOfflineFile(workRequestId, tagName, file) {
	let uploadRequest = {
		workRequestId,
		tagName,
		file
	};

	// Create an event channel so that our async updates can dispatch update events.
	// The inner upload method always resolves, that way parallel tasks are not
	let resultPromise;
	let channel = eventChannel((emit) => {
		let emitUpdate = (update) => {
			emit(update);
		};

		resultPromise = UploadService.uploadOffline(uploadRequest.file, uploadRequest, emitUpdate)
			.then((result) => {
				return {
					uploadRequest,
					result
				};
			})
			.catch((e) => {
				let err = isError(e) ? e : new Error(e);

				// Do not emit Error directly as it will crash the channel
				emit({ error: err });
				return {
					uploadRequest,
					result: err
				};
			})
			.finally(() => {
				// End this channel
				emit(END);
			});

		return _.noop;
	});

	// We have to fork the update dispatcher because the when a channel ends, the generator
	// function is ended at the `take` and no additional code is run. We don't want this
	// generator function to end because we need to be able to return the promise to the
	// calling function. Can't do that when a generator function just ends.
	yield fork(updateDispatcher, channel, uploadRequest, (update) => {
		return internalActions.uploadOfflineUpdate({ workRequestId, tagName, progress: update.progress });
	});

	let result = yield resultPromise;
	return result;
}

function *uploadFinalIngest(file, params) {
	let { uploadId, ingestId, context } = params;
	let workRequestId = context.workRequest;
	let tagName = context.tag;
	let uploadRequest = {
		ingestId,
		file
	};

	// Create an event channel so that our async updates can dispatch update events.
	// The inner upload method always resolves, that way parallel tasks are not
	let resultPromise;
	let channel = eventChannel((emit) => {
		let emitUpdate = (update) => {
			emit(update);
		};

		resultPromise = UploadService.uploadFileIngest(uploadRequest.file, uploadRequest, emitUpdate)
			.then((result) => {
				return {
					uploadRequest,
					result
				};
			})
			.catch((e) => {
				let err = isError(e) ? e : new Error(e);

				// Do not emit Error directly as it will crash the channel
				emit({ error: err });
				return {
					uploadRequest,
					result: err
				};
			})
			.finally(() => {
				// End this channel
				emit(END);
			});

		return _.noop;
	});

	// We have to fork the update dispatcher because the when a channel ends, the generator
	// function is ended at the `take` and no additional code is run. We don't want this
	// generator function to end because we need to be able to return the promise to the
	// calling function. Can't do that when a generator function just ends.
	yield fork(updateDispatcher, channel, uploadRequest, (update) => {
		return internalActions.uploadFinalUpdate({ workRequestId, tagName, uploadId, progress: update.progress });
	});

	let uploadResult = yield resultPromise;
	return uploadResult;
}

function *removeOffline({ payload }) {
	const { workRequestId, offlineId } = payload;

	let workRequest = yield select(workRequestsSelectors.getWorkRequestById, workRequestId);

	try {
		yield put(internalActions.removeOfflineRequest({ workRequestId, offlineId }));

		const offline = workRequest.offlines.find((offline) => offline._id === offlineId);

		yield OfflineService.deleteOffline(offline);

		workRequest = {
			...workRequest,
			offlines: workRequest.offlines.filter((offline) => offline._id !== offlineId)
		};

		// Update local work request store
		yield put(workRequestsActions.manualUpdateWorkRequest(workRequest));

		yield put(internalActions.removeOfflineSuccess({ workRequestId }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.removeOfflineFailure({ workRequestId, error: err }));
		return err;
	}
}

function *fetchTranslationPreview(assetId) {
	try {
		let previews = yield call(fetchPreviews, previewActions.getAssetPreviews({ assetId, type: 'none' }));
		if( isError(previews) ) {
			throw previews;
		}

		let preview = _(previews)
			.filter({ language: 'ENG' })
			.sortBy(NoneDubSub, '-created')
			.first();

		if( !preview ) {
			return null;
		}

		let signedPreview = yield PreviewService.getSignedPreviews(assetId, 'ENG', [preview._id]);

		return signedPreview.length ? signedPreview[0] : null;
	}
	catch(e) {
		const err = handleError(e);
		return err;
	}

	function NoneDubSub(preview) {
		return ['none', 'dub', 'sub'].indexOf(preview.type);
	}
}

function *editOrder({ payload }) {
	const { section } = payload;
	yield put(internalActions.editOrderStart(section));
}

function *cancelEditOrder() {
	yield put(internalActions.editOrderFinished());
}

function *updateOrder({ payload }) {
	const { order, newDueDate } = payload;
	const { _id: workRequestId } = order;

	const workRequest = yield select(workRequestsSelectors.getWorkRequestById, workRequestId);
	const { asset } = workRequest;

	const section = yield select(selectors.getEditingSection);

	const isPrintAsset = AssetService.isPrintAsset(asset);
	const isRoadshow = AssetService.isRoadshowAsset(asset);
	const isStandardAV = !isPrintAsset && !isRoadshow;

	try {
		yield put(internalActions.updateOrderRequest());

		const update = yield WorkRequestOrderService.toWorkRequestPatch(
			section,
			order,
			workRequest,
			asset,
			newDueDate
		);

		let updatedWorkRequest = yield WorkRequestService.update(workRequestId, update);

		// if creating new translation with type 'upload', upload file
		if( !isPrintAsset && !workRequest.translation && (order.localization.translationFile instanceof File)) {
			yield put(internalActions.updateOrderTranslationsStart());

			const uploadRequest = {
				translationId: updatedWorkRequest.translation._id,
				file: order.localization.translationFile
			};

			const uploadedFile = yield UploadService.uploadTranslation(
				order.localization.translationFile,
				uploadRequest,
				_.noop
			);

			try {
				yield TranslationService.updateTranslation(
					updatedWorkRequest.translation._id,
					{ file: uploadedFile }
				);

				yield put(internalActions.updateOrderTranslationsSuccess());
			}
			catch(err) {
				yield put(internalActions.updateOrderTranslationsFailure());
			}
			finally {
				updatedWorkRequest.translation.file = uploadedFile;
			}
		}

		if( isStandardAV ) {
			// update offlines if tag names changed
			if( workRequest.offlines.length ) {
				for( let i = 0; i < workRequest.offlines.length; i++ ) {
					const offline = workRequest.offlines[i];
					const tag = order.tags?.tags[i];

					if( offline.tag !== tag?.tag ) {
						yield OfflineService.update(offline._id, { tag: tag.tag });
					}
				}
			}

			// upload any new tag files
			for( let i = 0; i < order.tags?.tags.length; i++ ) {
				const tag = order.tags.tags[i];
				for( let j = 0; j < tag.files.length; j++ ) {
					const file = tag.files[j];
					if( typeof file !== 'string' ) {
						yield put(workRequestsActions.addTagFile({ workRequestId, tag: tag.tag, file }));
					}
				}
			}
		}

		yield call(fetchWorkRequestById, workRequestsActions.getWorkRequestById(workRequestId));

		yield put(internalActions.updateOrderComplete());
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.updateOrderFailure(err));
		return err;
	}
}

function *updateIngestFiles({ payload }) {
	const { workRequestId } = payload;

	try {
		yield put(internalActions.updateIngestFilesRequest({ workRequestId }));

		const ingests = yield call(
			fetchIngestsByWorkRequest,
			ingestsActions.getIngestsByWorkRequest({ workRequestId })
		);
		if( isError(ingests) ) {
			throw ingests;
		}

		yield put(internalActions.updateIngestFilesSuccess({ workRequestId }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.updateIngestFilesFailure({ workRequestId, err }));
		return err;
	}
}

export const LOAD_PAGE = 'work-request-page.load-page';
export const LOAD_PAGE_REQUEST = 'work-request-page.load-page.request';
export const LOAD_PAGE_SUCCESS = 'work-request-page.load-page.success';
export const LOAD_PAGE_FAILURE = 'work-request-page.load-page.failure';

export const LOAD_SUPPLEMENTAL = 'work-request-page.load-supplemental';
export const LOAD_SUPPLEMENTAL_REQUEST = 'work-request-page.load-supplemental.request';
export const LOAD_SUPPLEMENTAL_SUCCESS = 'work-request-page.load-supplemental.success';
export const LOAD_SUPPLEMENTAL_FAILURE = 'work-request-page.load-supplemental.failure';

export const START_ORDER = 'work-request-page.start-order';
export const START_ORDER_REQUEST = 'work-request-page.start-order.request';
export const START_ORDER_SUCCESS = 'work-request-page.start-order.success';
export const START_ORDER_FAILURE = 'work-request-page.start-order.failure';

export const AWAITING_MATERIALS = 'work-request-page.awaiting-materials';
export const AWAITING_MATERIALS_REQUEST = 'work-request-page.awaiting-materials.request';
export const AWAITING_MATERIALS_SUCCESS = 'work-request-page.awaiting-materials.success';
export const AWAITING_MATERIALS_FAILURE = 'work-request-page.awaiting-materials.failure';

export const REPLACE_OFFLINE = 'work-request-page.replace-offline';
export const REPLACE_OFFLINE_REQUEST = 'work-request-page.replace-offline.request';
export const REPLACE_OFFLINE_UPDATE = 'work-request-page.replace-offline.update';
export const REPLACE_OFFLINE_SUCCESS = 'work-request-page.replace-offline.success';
export const REPLACE_OFFLINE_FAILURE = 'work-request-page.replace-offline.failure';

export const UPLOAD_OFFLINE = 'work-request-page.upload-offline';
export const UPLOAD_OFFLINE_REQUEST = 'work-request-page.upload-offline.request';
export const UPLOAD_OFFLINE_UPDATE = 'work-request-page.upload-offline.update';
export const UPLOAD_OFFLINE_SUCCESS = 'work-request-page.upload-offline.success';
export const UPLOAD_OFFLINE_FAILURE = 'work-request-page.upload-offline.failure';

export const SUBMIT_FOR_REVIEW = 'work-request-page.submit-for-review';
export const SUBMIT_FOR_REVIEW_REQUEST = 'work-request-page.submit-for-review.request';
export const SUBMIT_FOR_REVIEW_SUCCESS = 'work-request-page.submit-for-review.success';
export const SUBMIT_FOR_REVIEW_FAILURE = 'work-request-page.submit-for-review.failure';

export const APPROVE_OFFLINES = 'work-request-page.approve-offlines';
export const APPROVE_OFFLINES_REQUEST = 'work-request-page.approve-offlines.request';
export const APPROVE_OFFLINES_SUCCESS = 'work-request-page.approve-offlines.success';
export const APPROVE_OFFLINES_FAILURE = 'work-request-page.approve-offlines.failure';

export const REJECT_OFFLINES = 'work-request-page.reject-offlines';
export const REJECT_OFFLINES_REQUEST = 'work-request-page.reject-offlines.request';
export const REJECT_OFFLINES_SUCCESS = 'work-request-page.reject-offlines.success';
export const REJECT_OFFLINES_FAILURE = 'work-request-page.reject-offlines.failure';

export const CONFIRM_CHANGE_REQUEST = 'work-request-page.confirm-change-request';
export const CONFIRM_CHANGE_REQUEST_REQUEST = 'work-request-page.confirm-change-request.request';
export const CONFIRM_CHANGE_REQUEST_SUCCESS = 'work-request-page.confirm-change-request.success';
export const CONFIRM_CHANGE_REQUEST_FAILURE = 'work-request-page.confirm-change-request.failure';

export const UPLOAD_FINALS = 'work-request-page.upload-finals';
export const UPLOAD_FINAL = 'work-request-page.upload-final';
export const UPLOAD_FINAL_REQUEST = 'work-request-page.upload-final.request';
export const UPLOAD_FINAL_SUCCESS = 'work-request-page.upload-final.success';
export const UPLOAD_FINAL_FAILURE = 'work-request-page.upload-final.failure';
export const UPLOAD_FINAL_UPDATE = 'work-request-page.upload-final.update';
export const UPLOAD_FINAL_RETRY = 'work-request-page.upload-final.retry';
export const UPLOAD_FINAL_CANCEL = 'work-request-page.upload-final.cancel';

export const COMPLETE_ORDER = 'work-request-page.complete-order';
export const COMPLETE_ORDER_REQUEST = 'work-request-page.complete-order.request';
export const COMPLETE_ORDER_SUCCESS = 'work-request-page.complete-order.success';
export const COMPLETE_ORDER_FAILURE = 'work-request-page.complete-order.failure';

export const CANCEL_ORDER = 'work-request-page.cancel-order';
export const CANCEL_ORDER_REQUEST = 'work-request-page.cancel-order.request';
export const CANCEL_ORDER_SUCCESS = 'work-request-page.cancel-order.success';
export const CANCEL_ORDER_FAILURE = 'work-request-page.cancel-order.failure';

export const REMOVE_OFFLINE = 'work-request-page.remove-offline';
export const REMOVE_OFFLINE_REQUEST = 'work-request-page.remove-offline.request';
export const REMOVE_OFFLINE_SUCCESS = 'work-request-page.remove-offline.success';
export const REMOVE_OFFLINE_FAILURE = 'work-request-page.remove-offline.failure';

export const UPDATE_INGEST_FILES = 'work-request-page.update-ingest-files';
export const UPDATE_INGEST_FILES_REQUEST = 'work-request-page.update-ingest-files.request';
export const UPDATE_INGEST_FILES_SUCCESS = 'work-request-page.update-ingest-files.success';
export const UPDATE_INGEST_FILES_FAILURE = 'work-request-page.update-ingest-files.failure';

export const EDIT_ORDER = 'work-request-page.edit-order';
export const CANCEL_EDIT_ORDER = 'work-request-page.cancel-edit-order';
export const EDIT_ORDER_START = 'work-request-page.edit-order.start';
export const EDIT_ORDER_FINISHED = 'work-request-page.edit-order.finished';

export const UPDATE_ORDER = 'work-request-page.update-order';
export const UPDATE_ORDER_REQUEST = 'work-request-page.update-order.request';
export const UPDATE_ORDER_SUCCESS = 'work-request-page.update-order.success';
export const UPDATE_ORDER_FAILURE = 'work-request-page.update-order.failure';
export const UPDATE_ORDER_TRANSLATIONS_START = 'work-request-page.update-order.translations-start';
export const UPDATE_ORDER_TRANSLATIONS_SUCCESS = 'work-request-page.update-order.translations-success';
export const UPDATE_ORDER_TRANSLATIONS_FAILURE = 'work-request-page.update-order.translations-failure';
export const UPDATE_ORDER_COMPLETE = 'work-request-page.update-order.complete';

export const INITIAL_STATE = {
	isLoading: false,
	isLoadingSupplemental: false,
	loadError: null,
	loadSupplementalError: null,

	// isUpdatingOrder can be a boolean OR an object
	isUpdatingOrder: false,
	isEditingOrder: false,
	editingSection: null,

	// This is used for tracking asynchronous work for individual work requests
	workRequestTracking: {}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case LOAD_PAGE_REQUEST: {
			return {
				...state,
				isLoading: true,
				loadError: null
			};
		}

		case LOAD_PAGE_SUCCESS: {
			let { workRequest, preview } = action.payload;

			return {
				...state,
				isLoading: false,
				loadError: null,
				previewByWorkRequestId: {
					[workRequest._id]: preview
				}
			};
		}

		case LOAD_PAGE_FAILURE: {
			return {
				...state,
				isLoading: false,
				loadError: action.payload.error
			};
		}

		case LOAD_SUPPLEMENTAL_REQUEST: {
			return {
				...state,
				isLoadingSupplemental: true,
				loadSupplementalError: null
			};
		}

		case LOAD_SUPPLEMENTAL_SUCCESS: {
			return {
				...state,
				isLoadingSupplemental: false,
				loadSupplementalError: null
			};
		}

		case LOAD_SUPPLEMENTAL_FAILURE: {
			return {
				...state,
				isLoadingSupplemental: false,
				loadSupplementalError: action.payload
			};
		}

		case START_ORDER_REQUEST: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isStartingOrder: true,
						startOrderError: null
					}
				}
			};
		}

		case START_ORDER_SUCCESS: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			// Remove keys to keep the store from bloating. Missing keys are implicit false
			let updatedContainer = _.omit(existingContainer, ['isStartingOrder', 'startOrderError']);

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: updatedContainer
				}
			};
		}

		case START_ORDER_FAILURE: {
			let { workRequestId, error } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isStartingOrder: false,
						startOrderError: error
					}
				}
			};
		}

		case AWAITING_MATERIALS_REQUEST: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isAwaitingMaterials: true,
						awaitingMaterialsError: null
					}
				}
			};
		}

		case AWAITING_MATERIALS_SUCCESS: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			// Remove keys to keep the store from bloating. Missing keys are implicit false
			let updatedContainer = _.omit(existingContainer, ['isAwaitingMaterials', 'awaitingMaterialsError']);

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: updatedContainer
				}
			};
		}

		case AWAITING_MATERIALS_FAILURE: {
			let { workRequestId, error } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isAwaitingMaterials: false,
						awaitingMaterialsError: error
					}
				}
			};
		}

		case UPLOAD_OFFLINE_REQUEST: {
			let { workRequestId, tagName='$$NoTags$$' } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});
			let existingUploads = _get(existingContainer, ['isUploadingOfflines'], {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isUploadingOfflines: {
							...existingUploads,
							[tagName]: {
								progress: 0
							}
						}
					}
				}
			};
		}

		case UPLOAD_OFFLINE_UPDATE: {
			let { workRequestId, tagName='$$NoTags$$', progress } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});
			let existingUploads = _get(existingContainer, ['isUploadingOfflines'], {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isUploadingOfflines: {
							...existingUploads,
							[tagName]: {
								progress
							}
						}
					}
				}
			};
		}

		case UPLOAD_OFFLINE_SUCCESS: {
			let { workRequestId, tagName='$$NoTags$$' } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});
			let existingUploads = _get(existingContainer, ['isUploadingOfflines'], {});

			let isUploadingOfflines = _.omit(existingUploads, [tagName]);

			let nextState = {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isUploadingOfflines
					}
				}
			};

			if( _.isEmpty(isUploadingOfflines) ) {
				delete nextState.workRequestTracking[workRequestId].isUploadingOfflines;
			}

			if( _.isEmpty(nextState.workRequestTracking[workRequestId]) ) {
				delete nextState.workRequestTracking[workRequestId];
			}

			return nextState;
		}

		case UPLOAD_OFFLINE_FAILURE: {
			let { workRequestId, tagName='$$NoTags$$', error } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});
			let existingUploads = _get(existingContainer, ['isUploadingOfflines'], {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isUploadingOfflines: {
							...existingUploads,
							[tagName]: {
								progress: 0,
								error
							}
						}
					}
				}
			};
		}

		case SUBMIT_FOR_REVIEW_REQUEST: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isSubmittingForReview: true,
						submitForReviewError: null
					}
				}
			};
		}

		case SUBMIT_FOR_REVIEW_SUCCESS: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			// Remove keys to keep the store from bloating. Missing keys are implicit false
			let updatedContainer = _.omit(existingContainer, ['isSubmittingForReview', 'submitForReviewError']);

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: updatedContainer
				}
			};
		}

		case SUBMIT_FOR_REVIEW_FAILURE: {
			let { workRequestId, error } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isSubmittingForReview: false,
						submitForReviewError: error
					}
				}
			};
		}

		case APPROVE_OFFLINES_REQUEST: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isApprovingOfflines: true,
						approveOfflinesError: null
					}
				}
			};
		}

		case APPROVE_OFFLINES_SUCCESS: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			// Remove keys to keep the store from bloating. Missing keys are implicit false
			let updatedContainer = _.omit(existingContainer, ['isApprovingOfflines', 'approveOfflinesError']);

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: updatedContainer
				}
			};
		}

		case APPROVE_OFFLINES_FAILURE: {
			let { workRequestId, error } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isApprovingOfflines: false,
						approveOfflinesError: error
					}
				}
			};
		}

		case REJECT_OFFLINES_REQUEST: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isRejectingOfflines: true,
						rejectOfflinesError: null
					}
				}
			};
		}

		case REJECT_OFFLINES_SUCCESS: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			// Remove keys to keep the store from bloating. Missing keys are implicit false
			let updatedContainer = _.omit(existingContainer, ['isRejectingOfflines', 'rejectOfflinesError']);

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: updatedContainer
				}
			};
		}

		case REJECT_OFFLINES_FAILURE: {
			let { workRequestId, error } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isRejectingOfflines: false,
						rejectOfflinesError: error
					}
				}
			};
		}

		case CONFIRM_CHANGE_REQUEST_REQUEST: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isConfirmingChangeRequest: true,
						confirmChangeRequestError: null
					}
				}
			};
		}

		case CONFIRM_CHANGE_REQUEST_SUCCESS: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			// Remove keys to keep the store from bloating. Missing keys are implicit false
			let updatedContainer = _.omit(existingContainer, ['isConfirmingChangeRequest', 'confirmChangeRequestError']);

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: updatedContainer
				}
			};
		}

		case CONFIRM_CHANGE_REQUEST_FAILURE: {
			let { workRequestId, error } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isConfirmingChangeRequest: false,
						confirmChangeRequestError: error
					}
				}
			};
		}

		case UPLOAD_FINAL_REQUEST: {
			let { workRequestId, tagName='$$NoTags$$', uploadId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});
			let existingUploads = _get(existingContainer, ['isUploadingFinals'], {});
			let existingTagUploads = _get(existingUploads, [tagName], {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isUploadingFinals: {
							...existingUploads,
							[slugify(tagName)]: {
								...existingTagUploads,
								[uploadId]: {
									progress: 0
								}
							}
						}
					}
				}
			};
		}

		case UPLOAD_FINAL_SUCCESS: {
			let { workRequestId, tagName='$$NoTags$$', uploadId } = action.payload;
			let trackingContainer = _get(state.workRequestTracking, workRequestId, {});
			let isUploadingFinals = _get(trackingContainer, ['isUploadingFinals'], {});
			let tagUploads = _get(isUploadingFinals, [tagName], {});

			// Remove upload status from final uploads for the given tag
			tagUploads = _.omit(tagUploads, [uploadId]);

			// If no more uploads are occuring for this tag, remove container for tag uploads
			if( _.isEmpty(tagUploads) ) {
				isUploadingFinals = _.omit(isUploadingFinals, [tagName]);
			}

			let nextState = {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...trackingContainer,
						isUploadingFinals
					}
				}
			};

			// If there are no more uploads for any tag, remove the isUploadingFinals container
			if( _.isEmpty(isUploadingFinals) ) {
				delete nextState.workRequestTracking[workRequestId].isUploadingFinals;
			}

			// If this work request has no more tracking, remove the container
			if( _.isEmpty(nextState.workRequestTracking[workRequestId]) ) {
				delete nextState.workRequestTracking[workRequestId];
			}

			return nextState;
		}

		case UPLOAD_FINAL_UPDATE: {
			let { workRequestId, tagName='$$NoTags$$', uploadId, progress } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});
			let existingUploads = _get(existingContainer, ['isUploadingFinals'], {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isUploadingFinals: {
							...existingUploads,
							[tagName]: {
								[uploadId]: {
									progress
								}
							}
						}
					}
				}
			};
		}

		case UPLOAD_FINAL_FAILURE: {
			let { workRequestId, tagName='$$NoTags$$', uploadId, error } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});
			let existingUploads = _get(existingContainer, ['isUploadingFinals'], {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isUploadingFinals: {
							...existingUploads,
							[tagName]: {
								[uploadId]: {
									progress: 0,
									error
								}
							}
						}
					}
				}
			};
		}

		case COMPLETE_ORDER_REQUEST: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isCompletingOrder: true,
						completeOrderError: null
					}
				}
			};
		}

		case COMPLETE_ORDER_SUCCESS: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			// Remove keys to keep the store from bloating. Missing keys are implicit false
			let updatedContainer = _.omit(existingContainer, ['isCompletingOrder', 'completeOrderError']);

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: updatedContainer
				}
			};
		}

		case COMPLETE_ORDER_FAILURE: {
			let { workRequestId, error } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isCompletingOrder: false,
						completeOrderError: error
					}
				}
			};
		}

		case CANCEL_ORDER_REQUEST: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isCancelingOrder: true,
						cancelOrderError: null
					}
				}
			};
		}

		case CANCEL_ORDER_SUCCESS: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			// Remove keys to keep the store from bloating. Missing keys are implicit false
			let updatedContainer = _.omit(existingContainer, ['isCancelingOrder', 'cancelOrderError']);

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: updatedContainer
				}
			};
		}

		case CANCEL_ORDER_FAILURE: {
			let { workRequestId, error } = action.payload;
			let existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isCancelingOrder: false,
						cancelOrderError: error
					}
				}
			};
		}

		case REMOVE_OFFLINE_REQUEST: {
			const { workRequestId, offlineId } = action.payload;
			const existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isRemovingOffline: offlineId,
						removeOfflineError: null
					}
				}
			};
		}

		case REMOVE_OFFLINE_SUCCESS: {
			const { workRequestId } = action.payload;
			const existingContainer = _get(state.workRequestTracking, workRequestId, {});

			// Remove keys to keep the store from bloating. Missing keys are implicit false
			const updatedContainer = _.omit(existingContainer, ['isRemovingOffline', 'removeOfflineError']);

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: updatedContainer
				}
			};
		}

		case REMOVE_OFFLINE_FAILURE: {
			const { workRequestId, error } = action.payload;
			const existingContainer = _get(state.workRequestTracking, workRequestId, {});

			// Remove keys to keep the store from bloating. Missing keys are implicit false
			const updatedContainer = _.omit(existingContainer, 'isRemovingOffline');

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...updatedContainer,
						removeOfflineError: error
					}
				}
			};
		}

		case UPDATE_INGEST_FILES_REQUEST: {
			const { workRequestId } = action.payload;
			const existingContainer = _get(state.workRequestTracking, workRequestId, {});

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...existingContainer,
						isUpdatingIngestFiles: true,
						updateIngestFilesError: null
					}
				}
			};
		}

		case UPDATE_INGEST_FILES_SUCCESS: {
			const { workRequestId } = action.payload;
			const existingContainer = _get(state.workRequestTracking, workRequestId, {});

			// Remove keys to keep the store from bloating. Missing keys are implicit false
			const updatedContainer = _.omit(existingContainer, ['isUpdatingIngestFiles', 'updateIngestFilesError']);

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: updatedContainer
				}
			};
		}

		case UPDATE_INGEST_FILES_FAILURE: {
			const { workRequestId, err } = action.payload;
			const existingContainer = _get(state.workRequestTracking, workRequestId, {});

			// Remove keys to keep the store from bloating. Missing keys are implicit false
			const updatedContainer = _.omit(existingContainer, 'isUpdatingIngestFiles');

			return {
				...state,
				workRequestTracking: {
					...state.workRequestTracking,
					[workRequestId]: {
						...updatedContainer,
						updateIngestFilesError: err
					}
				}
			};
		}

		case EDIT_ORDER_START: {
			const section = action.payload;
			return {
				...state,
				isEditingOrder: true,
				editingSection: section
			};
		}

		case EDIT_ORDER_FINISHED: {
			return {
				...state,
				isEditingOrder: false,
				editingSection: null
			};
		}

		case UPDATE_ORDER_REQUEST: {
			return {
				...state,
				isUpdatingOrder: {
					...state.isUpdatingOrder,
					isUpdating: true,
					isUpdatingTranslations: false,
					didFailUpdatingOrder: false,
					didFailTranslations: false
				}
			};
		}

		case UPDATE_ORDER_TRANSLATIONS_START: {
			return {
				...state,
				isUpdatingOrder: {
					...state.isUpdatingOrder,
					isUpdatingTranslations: true
				}
			};
		}

		case UPDATE_ORDER_TRANSLATIONS_FAILURE: {
			return {
				...state,
				isUpdatingOrder: {
					...state.isUpdatingOrder,
					isUpdatingTranslations: false,
					didFailTranslations: true
				}
			};
		}

		case UPDATE_ORDER_TRANSLATIONS_SUCCESS: {
			return {
				...state,
				isUpdatingOrder: {
					...state.isUpdatingOrder,
					isUpdatingTranslations: false,
					didFailTranslations: false
				}
			};
		}

		case UPDATE_ORDER_SUCCESS: {
			return {
				...state,
				isPlacingOrder: {
					isUpdating: false,
					isUpdatingTranslations: false,
					didFailUpdatingOrder: false,
					didFailTranslations: false
				}
			};
		}

		case UPDATE_ORDER_FAILURE: {
			return {
				...state,
				isPlacingOrder: {
					...state.isUpdatingOrder,
					isUpdating: false,
					didFailUpdatingOrder: true
				}
			};
		}

		case UPDATE_ORDER_COMPLETE: {
			return {
				...state,
				isUpdatingOrder: false,
				isEditingOrder: false,
				editingSection: null
			};
		}

		default:
			return state;
	}
}

export const actions = {
	loadPageData: createAction(LOAD_PAGE),
	loadSupplementalData: createAction(LOAD_SUPPLEMENTAL),
	startOrder: createAction(START_ORDER),
	awaitingMaterials: createAction(AWAITING_MATERIALS),
	uploadOffline: createAction(UPLOAD_OFFLINE),
	replaceOffline: createAction(REPLACE_OFFLINE),
	submitForReview: createAction(SUBMIT_FOR_REVIEW),
	approveOfflines: createAction(APPROVE_OFFLINES),
	rejectOfflines: createAction(REJECT_OFFLINES),
	confirmChangeRequest: createAction(CONFIRM_CHANGE_REQUEST),
	uploadFinals: createAction(UPLOAD_FINALS),
	uploadFinal: createAction(UPLOAD_FINAL),
	retryUploadFinal: createAction(UPLOAD_FINAL_RETRY),
	cancelUploadFinal: createAction(UPLOAD_FINAL_CANCEL),
	completeOrder: createAction(COMPLETE_ORDER),
	cancelOrder: createAction(CANCEL_ORDER),
	removeOffline: createAction(REMOVE_OFFLINE),
	updateIngestFiles: createAction(UPDATE_INGEST_FILES),
	editOrder: createAction(EDIT_ORDER),
	cancelEditOrder: createAction(CANCEL_EDIT_ORDER),
	updateOrder: createAction(UPDATE_ORDER)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	loadPageRequest: createAction(LOAD_PAGE_REQUEST),
	loadPageSuccess: createAction(LOAD_PAGE_SUCCESS),
	loadPageFailure: createAction(LOAD_PAGE_FAILURE),
	loadSupplementalRequest: createAction(LOAD_SUPPLEMENTAL_REQUEST),
	loadSupplementalSuccess: createAction(LOAD_SUPPLEMENTAL_SUCCESS),
	loadSupplementalFailure: createAction(LOAD_SUPPLEMENTAL_FAILURE),
	startOrderRequest: createAction(START_ORDER_REQUEST),
	startOrderSuccess: createAction(START_ORDER_SUCCESS),
	startOrderFailure: createAction(START_ORDER_FAILURE),
	awaitingMaterialRequest: createAction(AWAITING_MATERIALS_REQUEST),
	awaitingMaterialSuccess: createAction(AWAITING_MATERIALS_SUCCESS),
	awaitingMaterialFailure: createAction(AWAITING_MATERIALS_FAILURE),
	uploadOfflineRequest: createAction(UPLOAD_OFFLINE_REQUEST),
	uploadOfflineSuccess: createAction(UPLOAD_OFFLINE_SUCCESS),
	uploadOfflineUpdate: createAction(UPLOAD_OFFLINE_UPDATE),
	uploadOfflineFailure: createAction(UPLOAD_OFFLINE_FAILURE),
	replaceOfflineRequest: createAction(REPLACE_OFFLINE_REQUEST),
	replaceOfflineSuccess: createAction(REPLACE_OFFLINE_SUCCESS),
	replaceOfflineUpdate: createAction(REPLACE_OFFLINE_UPDATE),
	replaceOfflineFailure: createAction(REPLACE_OFFLINE_FAILURE),
	submitForReviewRequest: createAction(SUBMIT_FOR_REVIEW_REQUEST),
	submitForReviewSuccess: createAction(SUBMIT_FOR_REVIEW_SUCCESS),
	submitForReviewFailure: createAction(SUBMIT_FOR_REVIEW_FAILURE),
	approveOfflinesRequest: createAction(APPROVE_OFFLINES_REQUEST),
	approveOfflinesSuccess: createAction(APPROVE_OFFLINES_SUCCESS),
	approveOfflinesFailure: createAction(APPROVE_OFFLINES_FAILURE),
	rejectOfflinesRequest: createAction(REJECT_OFFLINES_REQUEST),
	rejectOfflinesSuccess: createAction(REJECT_OFFLINES_SUCCESS),
	rejectOfflinesFailure: createAction(REJECT_OFFLINES_FAILURE),
	confirmChangeRequestRequest: createAction(CONFIRM_CHANGE_REQUEST_REQUEST),
	confirmChangeRequestSuccess: createAction(CONFIRM_CHANGE_REQUEST_SUCCESS),
	confirmChangeRequestFailure: createAction(CONFIRM_CHANGE_REQUEST_FAILURE),
	uploadFinalRequest: createAction(UPLOAD_FINAL_REQUEST),
	uploadFinalSuccess: createAction(UPLOAD_FINAL_SUCCESS),
	uploadFinalFailure: createAction(UPLOAD_FINAL_FAILURE),
	uploadFinalUpdate: createAction(UPLOAD_FINAL_UPDATE),
	completeOrderRequest: createAction(COMPLETE_ORDER_REQUEST),
	completeOrderSuccess: createAction(COMPLETE_ORDER_SUCCESS),
	completeOrderFailure: createAction(COMPLETE_ORDER_FAILURE),
	cancelOrderRequest: createAction(CANCEL_ORDER_REQUEST),
	cancelOrderSuccess: createAction(CANCEL_ORDER_SUCCESS),
	cancelOrderFailure: createAction(CANCEL_ORDER_FAILURE),
	removeOfflineRequest: createAction(REMOVE_OFFLINE_REQUEST),
	removeOfflineSuccess: createAction(REMOVE_OFFLINE_SUCCESS),
	removeOfflineFailure: createAction(REMOVE_OFFLINE_FAILURE),
	updateIngestFilesRequest: createAction(UPDATE_INGEST_FILES_REQUEST),
	updateIngestFilesSuccess: createAction(UPDATE_INGEST_FILES_SUCCESS),
	updateIngestFilesFailure: createAction(UPDATE_INGEST_FILES_FAILURE),
	editOrderStart: createAction(EDIT_ORDER_START),
	editOrderFinished: createAction(EDIT_ORDER_FINISHED),
	updateOrderRequest: createAction(UPDATE_ORDER_REQUEST),
	updateOrderSuccess: createAction(UPDATE_ORDER_SUCCESS),
	updateOrderFailure: createAction(UPDATE_ORDER_FAILURE),
	updateOrderTranslationsStart: createAction(UPDATE_ORDER_TRANSLATIONS_START),
	updateOrderTranslationsSuccess: createAction(UPDATE_ORDER_TRANSLATIONS_SUCCESS),
	updateOrderTranslationsFailure: createAction(UPDATE_ORDER_TRANSLATIONS_FAILURE),
	updateOrderComplete: createAction(UPDATE_ORDER_COMPLETE)
};

export const selectors = {
	isReady,
	isSupplementalLoaded,
	isLoading: createSelector(STORE_NAME, isLoading),
	didLoadFail: createSelector(STORE_NAME, didLoadFail),
	loadErrors: createSelector(STORE_NAME, loadErrors),
	isStartingOrder: createSelector(STORE_NAME, isStartingOrder),
	isAwaitingMaterials: createSelector(STORE_NAME, isAwaitingMaterials),
	didStartOrderFail: createSelector(STORE_NAME, didStartOrderFail),
	isUploadingOfflines: createSelector(STORE_NAME, isUploadingOfflines),
	isSubmittingForReview: createSelector(STORE_NAME, isSubmittingForReview),
	isApprovingOfflines: createSelector(STORE_NAME, isApprovingOfflines),
	isRejectingOfflines: createSelector(STORE_NAME, isRejectingOfflines),
	isUpdatingIngestFiles: createSelector(STORE_NAME, isUpdatingIngestFiles),
	isConfirmingChangeRequest: createSelector(STORE_NAME, isConfirmingChangeRequest),
	isUploadingFinals: createSelector(STORE_NAME, isUploadingFinals),
	isCompletingOrder: createSelector(STORE_NAME, isCompletingOrder),
	isCancelingOrder: createSelector(STORE_NAME, isCancelingOrder),
	isRemovingOffline: createSelector(STORE_NAME, isRemovingOffline),
	getPreviewByWorkRequestId: createSelector(STORE_NAME, getPreviewByWorkRequestId),
	isEditingOrder: createSelector(STORE_NAME, isEditingOrder),
	getEditingSection: createSelector(STORE_NAME, getEditingSection),
	isUpdatingOrder: createSelector(STORE_NAME, isUpdatingOrder)
};

function isReady(state, id) {
	let isWorkRequestLoaded = workRequestsSelectors.isLoaded(state, id);

	if( !isWorkRequestLoaded ) {
		return false;
	}

	return ingestsSelectors.areWorkRequestIngestsLoaded(state, id)
		&& isSupplementalLoaded(state);
}

function isSupplementalLoaded(state) {
	return languagesSelectors.isLoaded(state)
		&& territoriesSelectors.isLoaded(state);
}

function isLoading(state) {
	return state.isLoading || state.isLoadingSupplemental;
}

function didLoadFail(state) {
	return !!state.loadError || !!state.loadSupplementalError;
}

function loadErrors(state) {
	return state.loadError || state.loadSupplementalError;
}

function isStartingOrder(state, workRequestId) {
	return _get(state.workRequestTracking, [workRequestId, 'isStartingOrder'], false);
}

function isAwaitingMaterials(state, workRequestId) {
	return _get(state.workRequestTracking, [workRequestId, 'isAwaitingMaterials'], false);
}

function didStartOrderFail(state, workRequestId) {
	return !!_get(state.workRequestTracking, [workRequestId, 'startOrderError'], false);
}

function isUploadingOfflines(state, workRequestId) {
	return _get(state.workRequestTracking, [workRequestId, 'isUploadingOfflines'], {});
}

function isSubmittingForReview(state, workRequestId) {
	return !!_get(state.workRequestTracking, [workRequestId, 'isSubmittingForReview'], false);
}

function isApprovingOfflines(state, workRequestId) {
	return !!_get(state.workRequestTracking, [workRequestId, 'isApprovingOfflines'], false);
}

function isRejectingOfflines(state, workRequestId) {
	return !!_get(state.workRequestTracking, [workRequestId, 'isRejectingOfflines'], false);
}

function isUpdatingIngestFiles(state, workRequestId) {
	return !!_get(state.workRequestTracking, [workRequestId, 'isUpdatingIngestFiles'], false);
}

function isConfirmingChangeRequest(state, workRequestId) {
	return !!_get(state.workRequestTracking, [workRequestId, 'isConfirmingChangeRequest'], false);
}

function isUploadingFinals(state, workRequestId) {
	return _get(state.workRequestTracking, [workRequestId, 'isUploadingFinals'], false);
}

function isCompletingOrder(state, workRequestId) {
	return !!_get(state.workRequestTracking, [workRequestId, 'isCompletingOrder'], false);
}

function isCancelingOrder(state, workRequestId) {
	return !!_get(state.workRequestTracking, [workRequestId, 'isCancelingOrder'], false);
}

function isRemovingOffline(state, workRequestId) {
	return _get(state.workRequestTracking, [workRequestId, 'isRemovingOffline'], null);
}

function getPreviewByWorkRequestId(state, workRequestId) {
	return state.previewByWorkRequestId?.[workRequestId];
}

function isEditingOrder(state) {
	return state.isEditingOrder;
}

function getEditingSection(state) {
	return state.editingSection;
}

function isUpdatingOrder(state) {
	return state.isUpdatingOrder.isUpdating || false;
}

// TODO: Refactor status changes to a single saga/reducer
export function *watchWorkRequestPage() {
	yield takeLeading(LOAD_PAGE, loadPageData);
	yield takeLeading(LOAD_SUPPLEMENTAL, loadSupplementalData);
	yield takeEvery(START_ORDER, startOrder);
	yield takeEvery(AWAITING_MATERIALS, awaitingMaterials);
	yield takeEvery(UPLOAD_OFFLINE, uploadOffline);
	yield takeEvery(REPLACE_OFFLINE, replaceOffline);
	yield takeEvery(SUBMIT_FOR_REVIEW, submitForReview);
	yield takeEvery(APPROVE_OFFLINES, approveOfflines);
	yield takeEvery(REJECT_OFFLINES, rejectOfflines);
	yield takeEvery(CONFIRM_CHANGE_REQUEST, confirmChangeRequest);
	yield takeEvery(UPLOAD_FINALS, uploadFinals);
	yield takeEvery(UPLOAD_FINAL, uploadFinal);
	yield takeEvery(COMPLETE_ORDER, completeOrder);
	yield takeEvery(CANCEL_ORDER, cancelOrder);
	yield takeEvery(REMOVE_OFFLINE, removeOffline);
	yield takeEvery(UPDATE_INGEST_FILES, updateIngestFiles);
	yield takeEvery(EDIT_ORDER, editOrder);
	yield takeEvery(CANCEL_EDIT_ORDER, cancelEditOrder);
	yield takeEvery(UPDATE_ORDER, updateOrder);
}
