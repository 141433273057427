import _ from 'lodash';
import isError from 'lodash.iserror';
import { createAction } from 'redux-actions';
import { select, put, takeLatest } from 'redux-saga/effects';
import { handleError } from './common';
import ShareService from '@/modules/services/share-service';
import LanguageService from '@/modules/services/language-service';

import {
	selectors as languageSelectors,
	actions as languageActions
} from './languages';

export const STORE_NAME = 'sharesPageStore';
export const PAGE_SIZE = 10;

// Labellize the languages in the shares
function *labelLanguages(shares) {
	let allLanguages = yield select(languageSelectors.getLanguages);

	if( !allLanguages.length ) {
		yield put(languageActions.getLanguages());
		allLanguages = yield LanguageService.getAllLanguages();
	}

	return _.map(shares, (share) => {
		return {
			...share,
			languages: _.map(share.languages, (language) => {
				return _.find(allLanguages, { code: language });
			})
		};
	});
}

// When the user wants to retrieve the next page of sent shares (use this for page 0 as well)
function *fetchNextSentSharesPage({ payload }) {
	try {
		let { userId } = payload;

		let query = setRequestQuery(userId, 'sent');

		let page = yield select((state) => state[STORE_NAME].allShares.sent.currentPage);
		let start = page * PAGE_SIZE;
		let end = (page + 1) * PAGE_SIZE - 1;

		yield put(internalActions.fetchNextSentSharesPageRequest(userId));

		let sharesPromise = ShareService.getSharesByQueryAndPage({ query, start, end });
		let shares = yield sharesPromise;

		let languagesLabelledShares = yield labelLanguages(shares.data);

		if( isError(shares) ) {
			throw new Error('Failed to load shares page');
		}

		let responseHeaders = shares.headers;
		let total = getPaginationTotal(responseHeaders['x-content-range']);
		let nextPage = page + 1;

		yield put(internalActions.fetchNextSentSharesPageSuccess({ shares: languagesLabelledShares, total, nextPage }));
		return shares;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.fetchNextSentSharesPageFailure(err));
		return err;
	}
}

// When the user wants to retrieve the next page of received shares (use this for page 0 as well)
function *fetchNextReceivedSharesPage({ payload }) {
	try {
		let { userId } = payload;

		let query = setRequestQuery(userId, 'received');

		let page = yield select((state) => state[STORE_NAME].allShares.received.currentPage);
		let start = page * PAGE_SIZE;
		let end = (page + 1) * PAGE_SIZE - 1;

		yield put(internalActions.fetchNextReceivedSharesPageRequest(userId));

		let sharesPromise = ShareService.getSharesByQueryAndPage({ query, start, end });
		let shares = yield sharesPromise;

		if( isError(shares) ) {
			throw new Error('Failed to load shares page');
		}

		let languagesLabelledShares = yield labelLanguages(shares.data);

		let responseHeaders = shares.headers;
		let total = getPaginationTotal(responseHeaders['x-content-range']);
		let nextPage = page + 1;

		yield put(internalActions.fetchNextReceivedSharesPageSuccess({
			shares: languagesLabelledShares,
			total,
			nextPage
		}));
		return shares;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.fetchNextReceivedSharesPageFailure(err));
		return err;
	}
}

// When the user needs to update their sent shares store with only new records (uses last updated at)
function *fetchNewSentShares({ payload }) {
	try {
		let { userId } = payload;

		let query = setRequestQuery(userId, 'sent');

		let mostRecentLoadAt = yield select((state) => state[STORE_NAME].allShares.sent.mostRecentLoadAt);
		if( !mostRecentLoadAt ) {
			return;
		}

		yield put(internalActions.fetchNewSentSharesRequest(userId));

		// Convert back to date/time as the API uses it (drop the milliseconds)
		mostRecentLoadAt = ~~(mostRecentLoadAt/1000);
		query.created = {
			from: mostRecentLoadAt
		};

		let shares = yield ShareService.getSharesByQuery(query);

		if( isError(shares) ) {
			throw new Error('Failed to load new shares');
		}

		let languagesLabelledShares = yield labelLanguages(shares);

		yield put(internalActions.fetchNewSentSharesSuccess({ shares: languagesLabelledShares }));
		return shares;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.fetchNewSentSharesFailure(err));
		return err;
	}
}

// When the user needs to update their received shares store with only new records (uses last updated at)
function *fetchNewReceivedShares({ payload }) {
	try {
		let { userId } = payload;

		let query = setRequestQuery(userId, 'received');

		let mostRecentLoadAt = yield select((state) => state[STORE_NAME].allShares.received.mostRecentLoadAt);
		if( !mostRecentLoadAt ) {
			return;
		}

		yield put(internalActions.fetchNewReceivedSharesRequest(userId));

		// Convert back to date/time as the API uses it (drop the milliseconds)
		mostRecentLoadAt = ~~(mostRecentLoadAt/1000);
		query.created = {
			from: mostRecentLoadAt
		};

		let shares = yield ShareService.getSharesByQuery(query);

		if( isError(shares) ) {
			throw new Error('Failed to load new shares');
		}

		let languagesLabelledShares = yield labelLanguages(shares);

		yield put(internalActions.fetchNewReceivedSharesSuccess({ shares: languagesLabelledShares }));
		return shares;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.fetchNewReceivedSharesFailure(err));
		return err;
	}
}

// When the user performs a server-side search
function *searchSentShares({ payload }) {
	try {
		let { userId, searchText } = payload;

		let query = setRequestQuery(userId, 'sent', searchText);

		let start = 0;
		let end = PAGE_SIZE - 1;

		yield put(internalActions.searchSentSharesRequest({ userId, searchText }));

		let sharesPromise = ShareService.getSharesByQueryAndPage({ query, start, end });
		let shares = yield sharesPromise;

		if( isError(shares) ) {
			throw new Error('Failed to load shares');
		}

		let languagesLabelledShares = yield labelLanguages(shares);

		let responseHeaders = sharesPromise.$response.headers();
		let total = getPaginationTotal(responseHeaders['x-content-range']);
		let nextPage = 1;

		yield put(internalActions.searchSentSharesSuccess({ shares: languagesLabelledShares, total, nextPage }));
		return shares;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.searchSentSharesFailure(err));
		return err;
	}
}

// When the user performs a server-side search
function *searchReceivedShares({ payload }) {
	try {
		let { userId, searchText } = payload;

		let query = setRequestQuery(userId, 'received', searchText);

		let start = 0;
		let end = PAGE_SIZE - 1;

		yield put(internalActions.searchReceivedSharesRequest({ userId, searchText }));

		let sharesPromise = ShareService.getSharesByQueryAndPage({ query, start, end });
		let shares = yield sharesPromise;

		if( isError(shares) ) {
			throw new Error('Failed to load shares');
		}

		let languagesLabelledShares = yield labelLanguages(shares);

		let responseHeaders = sharesPromise.$response.headers();
		let total = getPaginationTotal(responseHeaders['x-content-range']);
		let nextPage = 1;

		yield put(internalActions.searchReceivedSharesSuccess({ shares: languagesLabelledShares, total, nextPage }));
		return shares;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.searchReceivedSharesFailure(err));
		return err;
	}
}

function *fetchNextSentSearchPage({ payload }) {
	try {
		let { userId, searchText } = payload;

		let query = setRequestQuery(userId, 'sent', searchText);

		let page = yield select((state) => state[STORE_NAME].searchShares.sent.currentPage);
		let start = page * PAGE_SIZE;
		let end = (page + 1) * PAGE_SIZE - 1;

		yield put(internalActions.fetchNextSentSearchRequest({ userId, searchText }));

		let sharesPromise = ShareService.getSharesByQueryAndPage({ query, start, end });
		let shares = yield sharesPromise;

		if( isError(shares) ) {
			throw new Error('Failed to load shares page');
		}

		let languagesLabelledShares = yield labelLanguages(shares);

		let responseHeaders = sharesPromise.$response.headers();
		let total = getPaginationTotal(responseHeaders['x-content-range']);
		let nextPage = 1;

		yield put(internalActions.fetchNextSentSearchSuccess({ shares: languagesLabelledShares, total, nextPage }));
		return shares;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.fetchNextSentSearchFailure(err));
		return err;
	}
}

function *fetchNextReceivedSearchPage({ payload }) {
	try {
		let { userId, searchText } = payload;

		let query = setRequestQuery(userId, 'received', searchText);

		let page = yield select((state) => state[STORE_NAME].searchShares.received.currentPage);
		let start = page * PAGE_SIZE;
		let end = (page + 1) * PAGE_SIZE - 1;

		yield put(internalActions.fetchNextReceivedSearchRequest({ userId, searchText }));

		let sharesPromise = ShareService.getSharesByQueryAndPage({ query, start, end });
		let shares = yield sharesPromise;

		if( isError(shares) ) {
			throw new Error('Failed to load shares page');
		}

		let languagesLabelledShares = yield labelLanguages(shares);

		let responseHeaders = sharesPromise.$response.headers();
		let total = getPaginationTotal(responseHeaders['x-content-range']);
		let nextPage = 1;

		yield put(internalActions.fetchNextReceivedSearchSuccess({ shares: languagesLabelledShares, total, nextPage }));
		return shares;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.fetchNextReceivedSearchFailure(err));
		return err;
	}
}

function getPaginationTotal(headerValue = '') {
	const rangePattern = /^resources \d+-\d+\/(\d+|\*)/;
	let matches = headerValue.match(rangePattern);

	if( matches === null || _.isUndefined(matches[1]) || matches[1] === '*' ) {
		return 0;
	}

	return +matches[1];
}

function setRequestQuery(userId, direction, searchText) {
	let query = {
		user: userId,
		order: {
			_id: 'desc'
		}
	};

	if( direction === 'sent' ) {
		query.from = true;
	}

	if( direction === 'received' ) {
		query.to = true;
	}

	if( searchText ) {
		query.q = searchText;
	}

	return query;
}

export const FETCH_NEXT_SENT_SHARES_PAGE = 'shares.sent.fetch-next-page';
export const FETCH_NEXT_SENT_SHARES_PAGE_REQUEST = 'shares.sent.fetch-next-page.request';
export const FETCH_NEXT_SENT_SHARES_PAGE_SUCCESS = 'shares.sent.fetch-next-page.success';
export const FETCH_NEXT_SENT_SHARES_PAGE_FAILURE = 'shares.sent.fetch-next-page.failure';

export const FETCH_NEW_SENT_SHARES = 'shares.sent.fetch-new';
export const FETCH_NEW_SENT_SHARES_REQUEST = 'shares.sent.fetch-new.request';
export const FETCH_NEW_SENT_SHARES_SUCCESS = 'shares.sent.fetch-new.success';
export const FETCH_NEW_SENT_SHARES_FAILURE = 'shares.sent.fetch-new.failure';

export const SEARCH_SENT_SHARES = 'shares.sent.search';
export const SEARCH_SENT_SHARES_REQUEST = 'shares.sent.search.request';
export const SEARCH_SENT_SHARES_SUCCESS = 'shares.sent.search.success';
export const SEARCH_SENT_SHARES_FAILURE = 'shares.sent.search.failure';

export const FETCH_NEXT_SENT_SEARCH = 'shares.sent.fetch-next-search';
export const FETCH_NEXT_SENT_SEARCH_REQUEST = 'shares.sent.fetch-next-search.request';
export const FETCH_NEXT_SENT_SEARCH_SUCCESS = 'shares.sent.fetch-next-search.successs';
export const FETCH_NEXT_SENT_SEARCH_FAILURE = 'shares.sent.fetch-next-search.failure';

export const FETCH_NEXT_RECEIVED_SHARES_PAGE = 'shares.received.fetch-next-page';
export const FETCH_NEXT_RECEIVED_SHARES_PAGE_REQUEST = 'shares.received.fetch-next-page.request';
export const FETCH_NEXT_RECEIVED_SHARES_PAGE_SUCCESS = 'shares.received.fetch-next-page.success';
export const FETCH_NEXT_RECEIVED_SHARES_PAGE_FAILURE = 'shares.received.fetch-next-page.failure';

export const FETCH_NEW_RECEIVED_SHARES = 'shares.received.fetch-new';
export const FETCH_NEW_RECEIVED_SHARES_REQUEST = 'shares.received.fetch-new.request';
export const FETCH_NEW_RECEIVED_SHARES_SUCCESS = 'shares.received.fetch-new.success';
export const FETCH_NEW_RECEIVED_SHARES_FAILURE = 'shares.received.fetch-new.failure';

export const SEARCH_RECEIVED_SHARES = 'shares.received.search';
export const SEARCH_RECEIVED_SHARES_REQUEST = 'shares.received.search.request';
export const SEARCH_RECEIVED_SHARES_SUCCESS = 'shares.received.search.success';
export const SEARCH_RECEIVED_SHARES_FAILURE = 'shares.received.search.failure';

export const FETCH_NEXT_RECEIVED_SEARCH = 'shares.received.fetch-next-search';
export const FETCH_NEXT_RECEIVED_SEARCH_REQUEST = 'shares.received.fetch-next-search.request';
export const FETCH_NEXT_RECEIVED_SEARCH_SUCCESS = 'shares.received.fetch-next-search.successs';
export const FETCH_NEXT_RECEIVED_SEARCH_FAILURE = 'shares.received.fetch-next-search.failure';

export const UPDATE_SENT_SHARE = 'shares.sent.update.manual';
export const UPDATE_RECEIVED_SHARE = 'shares.received.update.manual';

export const INITIAL_STATE = {
	allShares: {
		sent: {
			shares: [],
			currentPage: 0,
			isLoadingNextPage: false,
			loadFailed: false,
			mostRecentLoadAt: null,
			hasMoreShares: true
		},
		received: {
			shares: [],
			currentPage: 0,
			isLoadingNextPage: false,
			loadFailed: false,
			mostRecentLoadAt: null,
			hasMoreShares: true
		}
	},
	searchShares: {
		sent: {
			shares: [],
			currentPage: 0,
			isLoading: false,
			isLoadingNext: false,
			loadFailed: false,
			hasMoreShares: true
		},
		received: {
			shares: [],
			currentPage: 0,
			isLoading: false,
			isLoadingNext: false,
			loadFailed: false,
			hasMoreShares: true
		}
	},
	newShares: {
		sent: {
			isLoading: false,
			loadFailed: false
		},
		received: {
			isLoading: false,
			loadFailed: false
		}
	}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case FETCH_NEXT_SENT_SHARES_PAGE_REQUEST: {
			return {
				...state,
				allShares: {
					...state.allShares,
					sent: {
						...state.allShares.sent,
						isLoadingNextPage: true
					}
				}
			};
		}

		case FETCH_NEXT_RECEIVED_SHARES_PAGE_REQUEST: {
			return {
				...state,
				allShares: {
					...state.allShares,
					received: {
						...state.allShares.received,
						isLoadingNextPage: true
					}
				}
			};
		}

		case FETCH_NEXT_SENT_SHARES_PAGE_SUCCESS: {
			let now = new Date();
			let { shares, total, nextPage } = action.payload;
			let mostRecentLoadAt = state.allShares.sent.mostRecentLoadAt;

			if( !mostRecentLoadAt ) {
				mostRecentLoadAt = now;
			}

			shares = [].concat(state.allShares.sent.shares, shares);
			shares = _.uniq(shares, '_id');
			shares = _.sortBy(shares, ['-created', 'name']);

			let hasMoreShares = shares.length < total;

			return {
				...state,
				allShares: {
					...state.allShares,
					sent: {
						isLoadingNextPage: false,
						currentPage: nextPage,
						mostRecentLoadAt,
						hasMoreShares,
						shares
					}
				}
			};
		}

		case FETCH_NEXT_RECEIVED_SHARES_PAGE_SUCCESS: {
			let now = new Date();
			let { shares, total, nextPage } = action.payload;
			let mostRecentLoadAt = state.allShares.received.mostRecentLoadAt;

			if( !mostRecentLoadAt ) {
				mostRecentLoadAt = now;
			}

			shares = [].concat(state.allShares.received.shares, shares);
			shares = _.uniq(shares, '_id');
			shares = _.sortBy(shares, ['-created', 'name']);

			let hasMoreShares = shares.length < total;

			return {
				...state,
				allShares: {
					...state.allShares,
					received: {
						isLoadingNextPage: false,
						currentPage: nextPage,
						mostRecentLoadAt,
						hasMoreShares,
						shares
					}
				}
			};
		}

		case FETCH_NEXT_SENT_SHARES_PAGE_FAILURE: {
			return {
				...state,
				allShares: {
					...state.allShares,
					sent: {
						...state.allShares.sent,
						isLoadingNextPage: false,
						loadFailed: true
					}
				}
			};
		}

		case FETCH_NEXT_RECEIVED_SHARES_PAGE_FAILURE: {
			return {
				...state,
				allShares: {
					...state.allShares,
					received: {
						...state.allShares.received,
						isLoadingNextPage: false,
						loadFailed: true
					}
				}
			};
		}

		case FETCH_NEW_SENT_SHARES_REQUEST: {
			return {
				...state,
				newShares: {
					...state.newShares,
					sent: {
						isLoading: true
					}
				}
			};
		}

		case FETCH_NEW_RECEIVED_SHARES_REQUEST: {
			return {
				...state,
				newShares: {
					...state.newShares,
					received: {
						isLoading: true
					}
				}
			};
		}

		case FETCH_NEW_SENT_SHARES_SUCCESS: {
			let now = new Date();
			let { shares } = action.payload;

			shares = [].concat(shares, state.allShares.sent.shares);
			shares = _.uniq(shares, '_id');
			shares = _.sortBy(shares, ['-created', 'name']);

			return {
				...state,
				allShares: {
					...state.allShares,
					sent: {
						mostRecentLoadAt: now,
						shares
					}
				},
				newShares: {
					...state.newShares,
					sent: {
						isLoading: false
					}
				}
			};
		}

		case FETCH_NEW_RECEIVED_SHARES_SUCCESS: {
			let now = new Date();
			let { shares } = action.payload;

			shares = [].concat(shares, state.allShares.received.shares);
			shares = _.uniq(shares, '_id');
			shares = _.sortBy(shares, ['-created', 'name']);

			return {
				...state,
				allShares: {
					...state.allShares,
					received: {
						mostRecentLoadAt: now,
						shares
					}
				},
				newShares: {
					...state.newShares,
					received: {
						isLoading: false
					}
				}
			};
		}

		case FETCH_NEW_SENT_SHARES_FAILURE: {
			return {
				...state,
				newShares: {
					...state.newShares,
					sent: {
						...state.newShares.sent,
						isLoading: false,
						loadFailed: true
					}
				}
			};
		}

		case FETCH_NEW_RECEIVED_SHARES_FAILURE: {
			return {
				...state,
				newShares: {
					...state.newShares,
					received: {
						...state.newShares.received,
						isLoading: false,
						loadFailed: true
					}
				}
			};
		}

		case SEARCH_SENT_SHARES_REQUEST: {
			return {
				...state,
				searchShares: {
					...state.searchShares,
					sent: {
						isLoading: true,
						isLoadingNext: true,
						shares: [],
						currentPage: 0,
						hasMoreShares: true
					}
				}
			};
		}

		case SEARCH_RECEIVED_SHARES_REQUEST: {
			return {
				...state,
				searchShares: {
					...state.searchShares,
					received: {
						isLoading: true,
						isLoadingNext: true,
						shares: [],
						currentPage: 0,
						hasMoreShares: true
					}
				}
			};
		}

		case SEARCH_SENT_SHARES_SUCCESS: {
			let { shares, total, nextPage } = action.payload;
			let hasMoreShares = shares.length < total;

			return {
				...state,
				searchShares: {
					...state.searchShares,
					sent: {
						...state.searchShares.sent,
						isLoading: false,
						isLoadingNext: false,
						shares: shares,
						currentPage: nextPage,
						hasMoreShares,
						total
					}
				}
			};
		}

		case SEARCH_RECEIVED_SHARES_SUCCESS: {
			let { shares, total, nextPage } = action.payload;
			let hasMoreShares = shares.length < total;

			return {
				...state,
				searchShares: {
					...state.searchShares,
					received: {
						...state.searchShares.received,
						isLoading: false,
						isLoadingNext: false,
						shares: shares,
						currentPage: nextPage,
						hasMoreShares,
						total
					}
				}
			};
		}

		case SEARCH_SENT_SHARES_FAILURE: {
			return {
				...state,
				searchShares: {
					...state.searchShares,
					sent: {
						...state.searchShares.sent,
						isLoading: false,
						isLoadingNext: false,
						loadFailed: true
					}
				}
			};
		}

		case SEARCH_RECEIVED_SHARES_FAILURE: {
			return {
				...state,
				searchShares: {
					...state.searchShares,
					received: {
						...state.searchShares.received,
						isLoading: false,
						isLoadingNext: false,
						loadFailed: true
					}
				}
			};
		}

		case FETCH_NEXT_SENT_SEARCH_REQUEST: {
			return {
				...state,
				searchShares: {
					...state.searchShares,
					sent: {
						...state.searchShares.sent,
						isLoadingNext: true
					}
				}
			};
		}

		case FETCH_NEXT_RECEIVED_SEARCH_REQUEST: {
			return {
				...state,
				searchShares: {
					...state.searchShares,
					received: {
						...state.searchShares.received,
						isLoadingNext: true
					}
				}
			};
		}

		case FETCH_NEXT_SENT_SEARCH_SUCCESS: {
			let { shares, total, nextPage } = action.payload;

			shares = [].concat(state.searchShares.sent.shares, shares);
			shares = _.uniq(shares, '_id');
			shares = _.sortBy(shares, ['-created', 'name']);

			let hasMoreShares = shares.length < total;

			return {
				...state,
				searchShares: {
					...state.searchShares,
					sent: {
						...state.searchShares.sent,
						isLoadingNext: false,
						shares: shares,
						currentPage: nextPage,
						hasMoreShares,
						total
					}
				}
			};
		}

		case FETCH_NEXT_RECEIVED_SEARCH_SUCCESS: {
			let { shares, total, nextPage } = action.payload;

			shares = [].concat(state.searchShares.received.shares, shares);
			shares = _.uniq(shares, '_id');
			shares = _.sortBy(shares, ['-created', 'name']);

			let hasMoreShares = shares.length < total;

			return {
				...state,
				searchShares: {
					...state.searchShares,
					received: {
						...state.searchShares.received,
						isLoadingNext: false,
						shares: shares,
						currentPage: nextPage,
						hasMoreShares,
						total
					}
				}
			};
		}

		case FETCH_NEXT_SENT_SEARCH_FAILURE: {
			return {
				...state,
				searchShares: {
					...state.searchShares,
					sent: {
						...state.searchShares.sent,
						isLoadingNext: false,
						loadFailed: true
					}
				}
			};
		}

		case FETCH_NEXT_RECEIVED_SEARCH_FAILURE: {
			return {
				...state,
				searchShares: {
					...state.searchShares,
					received: {
						...state.searchShares.received,
						isLoadingNext: false,
						loadFailed: true
					}
				}
			};
		}

		case UPDATE_SENT_SHARE: {
			const share = action.payload;
			let sentShares = [
				...state.allShares.sent.shares
			];
			const index = sentShares.findIndex((s) => s._id === share._id);
			sentShares[index] = share;

			return {
				...state,
				allShares: {
					...state.allShares,
					sent: {
						...state.allShares.sent,
						shares: sentShares
					}
				}
			};
		}

		case UPDATE_RECEIVED_SHARE: {
			const share = action.payload;
			let receivedShares = [
				...state.allShares.received.shares
			];
			const index = receivedShares.findIndex((s) => s._id === share._id);
			receivedShares[index] = share;

			return {
				...state,
				allShares: {
					...state.allShares,
					sent: {
						...state.allShares.sent,
						shares: receivedShares
					}
				}
			};
		}

		default:
			return state;
	}
}

export const actions = {
	fetchNextSentSharesPage: createAction(FETCH_NEXT_SENT_SHARES_PAGE, (userId) => ({ userId })),
	fetchNextReceivedSharesPage: createAction(FETCH_NEXT_RECEIVED_SHARES_PAGE, (userId) => ({ userId })),
	fetchNewSentShares: createAction(FETCH_NEW_SENT_SHARES, (userId) => ({ userId })),
	fetchNewReceivedShares: createAction(FETCH_NEW_RECEIVED_SHARES, (userId) => ({ userId })),
	searchSentShares: createAction(SEARCH_SENT_SHARES, (userId, query) => ({ userId, query })),
	searchReceivedShares: createAction(SEARCH_RECEIVED_SHARES, (userId, query) => ({ userId, query })),
	fetchNextSentSearchShares: createAction(FETCH_NEXT_SENT_SEARCH, (userId, query) => ({ userId, query })),
	fetchNextReceivedSearchShares: createAction(FETCH_NEXT_RECEIVED_SEARCH, (userId, query) => ({ userId, query })),
	updateSentShare: createAction(UPDATE_SENT_SHARE),
	updateReceivedShare: createAction(UPDATE_RECEIVED_SHARE)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	fetchNextSentSharesPageRequest: createAction(FETCH_NEXT_SENT_SHARES_PAGE_REQUEST),
	fetchNextSentSharesPageSuccess: createAction(FETCH_NEXT_SENT_SHARES_PAGE_SUCCESS),
	fetchNextSentSharesPageFailure: createAction(FETCH_NEXT_SENT_SHARES_PAGE_FAILURE),

	fetchNextReceivedSharesPageRequest: createAction(FETCH_NEXT_RECEIVED_SHARES_PAGE_REQUEST),
	fetchNextReceivedSharesPageSuccess: createAction(FETCH_NEXT_RECEIVED_SHARES_PAGE_SUCCESS),
	fetchNextReceivedSharesPageFailure: createAction(FETCH_NEXT_RECEIVED_SHARES_PAGE_FAILURE),

	fetchNewSentSharesRequest: createAction(FETCH_NEW_SENT_SHARES_REQUEST),
	fetchNewSentSharesSuccess: createAction(FETCH_NEW_SENT_SHARES_SUCCESS),
	fetchNewSentSharesFailure: createAction(FETCH_NEW_SENT_SHARES_FAILURE),

	fetchNewReceivedSharesRequest: createAction(FETCH_NEW_RECEIVED_SHARES_REQUEST),
	fetchNewReceivedSharesSuccess: createAction(FETCH_NEW_RECEIVED_SHARES_SUCCESS),
	fetchNewReceivedSharesFailure: createAction(FETCH_NEW_RECEIVED_SHARES_FAILURE),

	searchSentSharesRequest: createAction(SEARCH_SENT_SHARES_REQUEST),
	searchSentSharesSuccess: createAction(SEARCH_SENT_SHARES_SUCCESS),
	searchSentSharesFailure: createAction(SEARCH_SENT_SHARES_FAILURE),

	searchReceivedSharesRequest: createAction(SEARCH_RECEIVED_SHARES_REQUEST),
	searchReceivedSharesSuccess: createAction(SEARCH_RECEIVED_SHARES_SUCCESS),
	searchReceivedSharesFailure: createAction(SEARCH_RECEIVED_SHARES_FAILURE),

	fetchNextSentSearchRequest: createAction(FETCH_NEXT_SENT_SEARCH_REQUEST),
	fetchNextSentSearchSuccess: createAction(FETCH_NEXT_SENT_SEARCH_SUCCESS),
	fetchNextSentSearchFailure: createAction(FETCH_NEXT_SENT_SEARCH_FAILURE),

	fetchNextReceivedSearchRequest: createAction(FETCH_NEXT_RECEIVED_SEARCH_REQUEST),
	fetchNextReceivedSearchSuccess: createAction(FETCH_NEXT_RECEIVED_SEARCH_SUCCESS),
	fetchNextReceivedSearchFailure: createAction(FETCH_NEXT_RECEIVED_SEARCH_FAILURE)
};

export const selectors = {
	getShares: createSelector(getShares),
	isLoadingNextPage: createSelector(isLoadingNextPage),
	isPageLoadError: createSelector(isPageLoadError),
	hasMoreShares: createSelector(hasMoreShares),
	getMostRecentLoadAt: createSelector(getMostRecentLoadAt),
	isLoadingNewShares: createSelector(isLoadingNewShares),
	isNewSharesLoadError: createSelector(isNewSharesLoadError),
	getSearchedShares: createSelector(getSearchedShares),
	isLoadingSearchResults: createSelector(isLoadingSearchResults),
	isSearchError: createSelector(isSearchError),
	isLoadingNextSearchResults: createSelector(isLoadingNextSearchResults),
	hasMoreSearchResults: createSelector(hasMoreSearchResults)
};

function getShares(state, direction) {
	return state.allShares[direction].shares;
}

function isLoadingNextPage(state, direction) {
	return state.allShares[direction].isLoadingNextPage;
}

function isPageLoadError(state, direction) {
	return state.allShares[direction].loadFailed;
}

function hasMoreShares(state, direction) {
	return state.allShares[direction].hasMoreShares;
}

function getMostRecentLoadAt(state, direction) {
	return state.allShares[direction].mostRecentLoadAt;
}

function isLoadingNewShares(state, direction) {
	return state.newShares[direction].isLoading;
}

function isNewSharesLoadError(state, direction) {
	return state.newShares[direction].loadFailed;
}

function getSearchedShares(state, direction) {
	return state.searchShares[direction].shares;
}

function isLoadingSearchResults(state, direction) {
	return state.searchShares[direction].isLoading;
}

function isSearchError(state, direction) {
	return state.searchShares[direction].loadFailed;
}

function isLoadingNextSearchResults(state, direction) {
	return state.searchShares[direction].isLoadingNext;
}

function hasMoreSearchResults(state, direction) {
	return state.searchShares[direction].hasMoreShares;
}

/**
 * Helper method that grabs our specific part of the global store, then passes it into the given selector.
 * This makes it so that each selector doesn't have to grab the sub-state itself, reducing code duplication.
 */
function createSelector(selector) {
	return function selectorWrapper(state, ...rest) {
		let sharesStore = state[STORE_NAME];
		return selector(sharesStore, ...rest);
	};
}

export function *watchSharesPage() {
	yield takeLatest(FETCH_NEXT_SENT_SHARES_PAGE, fetchNextSentSharesPage);
	yield takeLatest(FETCH_NEW_SENT_SHARES, fetchNewSentShares);
	yield takeLatest(SEARCH_SENT_SHARES, searchSentShares);
	yield takeLatest(FETCH_NEXT_SENT_SEARCH, fetchNextSentSearchPage);
	yield takeLatest(FETCH_NEXT_RECEIVED_SHARES_PAGE, fetchNextReceivedSharesPage);
	yield takeLatest(FETCH_NEW_RECEIVED_SHARES, fetchNewReceivedShares);
	yield takeLatest(SEARCH_RECEIVED_SHARES, searchReceivedShares);
	yield takeLatest(FETCH_NEXT_RECEIVED_SEARCH, fetchNextReceivedSearchPage);
}
