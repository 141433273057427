import _ from 'lodash';
import _get from 'lodash.get';
import { createAction } from 'redux-actions';
import { put, takeEvery } from 'redux-saga/effects';
import { RESET_STORES } from './session';
import { handleError } from './common';
import FileService from '@/modules/services/file-service';

export const STORE_NAME = 'filesStore';

function *fetchFiles({ payload }) {
	let { assetId, language } = payload;

	try {
		yield put(internalActions.getAssetFilesRequest({ assetId, language }));
		const files = yield FileService.getAllFiles({ asset: assetId, language });
		yield put(internalActions.getAssetFilesSuccess({ assetId, language, files }));
		return files;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getAssetFilesFailure({ assetId, language, err }));
		return err;
	}
}

export const FETCH_FILES = 'files.fetch';
export const FETCH_FILES_REQUEST = 'files.fetch.request';
export const FETCH_FILES_SUCCESS = 'files.fetch.success';
export const FETCH_FILES_FAILURE = 'files.fetch.failure';

export const INITIAL_STATE = {
	files: {},
	filesByAsset: {}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case RESET_STORES:
			return INITIAL_STATE;

		case FETCH_FILES_REQUEST: {
			let { assetId, language } = action.payload;

			let languageContainer = state.filesByAsset[assetId] || { filesByLanguage: {} };
			let filesByLanguage = languageContainer.filesByLanguage;

			return {
				...state,
				filesByAsset: {
					...state.filesByAsset,
					[assetId]: {
						...languageContainer,
						filesByLanguage: {
							...filesByLanguage,
							[language]: {
								// Place lastLoadedAt: null and files: [] first
								// so that its overwritten if it already exists
								lastLoadedAt: null,
								files: [],
								...filesByLanguage[language],
								isLoading: true
							}
						}
					}
				}
			};
		}

		case FETCH_FILES_SUCCESS: {
			let { assetId, language, files } = action.payload;

			let languageContainer = state.filesByAsset[assetId] || { filesByLanguage: {} };
			let filesByLanguage = languageContainer.filesByLanguage;

			let now = new Date();
			let fileIds = _.map(files, '_id');
			let transformedFiles = _.reduce(files, (result, file) => {
				result[file._id] = {
					isLoading: false,
					lastLoadedAt: now,
					data: file
				};

				return result;
			}, {});

			// Remove all existing files for the given asset and language (they will be replaced with our new results)
			let removeFileIds = _get(state.filesByAsset, [assetId, 'filesByLanguage', language, 'files'], []);
			let filteredFiles = _.reduce(state.files, (result, file, fileId) => {
				if( removeFileIds.includes(fileId) ) {
					return result;
				}

				result[fileId] = file;
				return result;
			}, {});

			return {
				...state,
				files: {
					...filteredFiles,
					...transformedFiles
				},
				filesByAsset: {
					...state.filesByAsset,
					[assetId]: {
						...languageContainer,
						filesByLanguage: {
							...filesByLanguage,
							[language]: {
								files: fileIds,
								lastLoadedAt: now,
								isLoading: false
							}
						}
					}
				}
			};
		}

		case FETCH_FILES_FAILURE: {
			let { assetId, language } = action.payload;

			let languageContainer = state.filesByAsset[assetId] || { filesByLanguage: {} };
			let filesByLanguage = languageContainer.filesByLanguage;
			let filesForLanguage = filesByLanguage[language] || {};

			return {
				...state,
				filesByAsset: {
					...state.filesByAsset,
					[assetId]: {
						...languageContainer,
						filesByLanguage: {
							...filesByLanguage,
							[language]: {
								...filesForLanguage,
								isLoading: false
							}
						}
					}
				}
			};
		}

		default:
			return state;
	}
}

export const actions = {
	getAssetFiles: createAction(FETCH_FILES)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	getAssetFilesRequest: createAction(FETCH_FILES_REQUEST),
	getAssetFilesSuccess: createAction(FETCH_FILES_SUCCESS),
	getAssetFilesFailure: createAction(FETCH_FILES_FAILURE)
};

export const selectors = {
	areAssetFilesLoading: createSelector(areAssetFilesLoading),
	areAssetFilesLoaded: createSelector(areAssetFilesLoaded),
	getAssetFilesLastLoadedAt: createSelector(getAssetFilesLastLoadedAt),
	getAssetFilesForLanguage: createSelector(getAssetFilesForLanguage),
	getAssetFilesByLanguage: createSelector(getAssetFilesByLanguage)
};

function getAssetFilesByLanguage(state, assetId) {
	let assetContainer = state.filesByAsset[assetId];
	if( !assetContainer ) {
		return {};
	}

	if( !assetContainer.filesByLanguage ) {
		return {};
	}

	let assetFilesByLanguage = {
		...assetContainer.filesByLanguage
	};

	return _.mapValues(assetFilesByLanguage, (languageContainer) => {
		return {
			isLoading: languageContainer.isLoading,
			lastLoadedAt: languageContainer.lastLoadedAt,
			files: _.map(languageContainer.files, (fileId) => state.files[fileId].data)
		};
	});
}

function areAssetFilesLoading(state, assetId, language) {
	let assetContainer = state.filesByAsset[assetId];
	if( !assetContainer ) {
		return false;
	}

	let languageContainer = assetContainer.filesByLanguage[language];
	if( !languageContainer ) {
		return false;
	}

	return !!languageContainer.isLoading[language];
}

function areAssetFilesLoaded(state, assetId, language) {
	let assetContainer = state.filesByAsset[assetId];
	if( !assetContainer ) {
		return false;
	}

	let languageContainer = assetContainer.filesByLanguage[language];
	if( !languageContainer ) {
		return false;
	}

	return !!languageContainer.lastLoadedAt;
}

function getAssetFilesLastLoadedAt(state, assetId, language) {
	let assetContainer = state.filesByAsset[assetId];
	if( !assetContainer ) {
		return null;
	}

	let languageContainer = assetContainer.filesByLanguage[language];
	if( !languageContainer ) {
		return false;
	}

	return languageContainer.lastLoadedAt;
}

function getAssetFilesForLanguage(state, assetId, language) {
	let assetContainer = state.filesByAsset[assetId];
	if( !assetContainer ) {
		return [];
	}

	let languageContainer = assetContainer.filesByLanguage[language];
	if( !languageContainer ) {
		return false;
	}

	let assets = _.map(languageContainer.files, (fileId) => state.files[fileId].data);
	if( language ) {
		assets = _.filter(assets, { language });
	}

	return assets;
}

/**
 * Helper method that grabs our specific part of the global store, then passes it into the given selector.
 * This makes it so that each selector doesn't have to grab the sub-state itself, reducing code duplication.
 */
function createSelector(selector) {
	return function selectorWrapper(state, ...rest) {
		let filesStore = state[STORE_NAME];
		return selector(filesStore, ...rest);
	};
}

export function *watchFiles() {
	yield takeEvery(FETCH_FILES, fetchFiles);
}
