import _ from 'lodash';
import isError from 'lodash.iserror';
import { createAction } from 'redux-actions';
import { call, put, fork, join, takeLatest } from 'redux-saga/effects';
import { handleError } from './common';

import {
	actions as projectsActions,
	selectors as projectsSelectors,
	fetchProjectById
} from './projects';
import {
	actions as assetsActions,
	selectors as assetsSelectors,
	fetchAsset,
	fetchProjectAssets
} from './assets';
import {
	actions as previewsActions,
	selectors as previewsSelectors,
	fetchPreviews
} from './previews';
import {
	actions as sessionActions,
	selectors as sessionSelectors,
	fetchSession
} from './session';

export const STORE_NAME = 'assetPageStore';

function *refetchAsset({ payload: assetId }) {
	try {
		yield put(internalActions.refetchAssetRequest());

		let assetTask = yield fork(fetchAsset, assetsActions.getAssetById({ assetId }));
		let asset = yield join(assetTask);

		if( isError(asset) ) {
			throw new Error('Failed to load asset data');
		}

		yield put(internalActions.refetchAssetSuccess());
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.refetchAssetFailure(err));
		return err;
	}
}
function *loadPageData({ payload: assetId }) {
	try {
		yield put(internalActions.loadPageRequest());

		let assetTask = yield fork(fetchAsset, assetsActions.getAssetById({ assetId }));
		let previewsTask = yield fork(fetchPreviews, previewsActions.getAssetPreviews({ assetId }));
		let sessionTask = yield fork(fetchSession, sessionActions.getSession());

		let asset = yield join(assetTask);
		let previews = yield join(previewsTask);
		let session = yield join(sessionTask);

		if( isError(asset) || isError(previews) || isError(session) ) {
			throw new Error('Failed to load asset page data');
		}

		yield put(internalActions.loadPageSuccess());
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadPageFailure(err));
		return err;
	}
}

function *loadSupplementalData({ payload: projectId }) {
	try {
		yield put(internalActions.loadSupplementalDataRequest());

		let project = yield call(fetchProjectById, projectsActions.getProjectById(projectId));

		if( isError(project) ) {
			throw new Error('Failed to load related assets data. Project data failed to load.');
		}

		let assets = yield call(fetchProjectAssets, assetsActions.getProjectAssets({ projectId }));

		if( isError(assets) ) {
			throw new Error('Failed to load related assets data');
		}

		yield put(internalActions.loadSupplementalDataSuccess());
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadSupplementalDataFailure(err));
		return err;
	}
}

export const LOAD_PAGE = 'asset-page.load-page';
export const LOAD_PAGE_REQUEST = 'asset-page.load-page.request';
export const LOAD_PAGE_SUCCESS = 'asset-page.load-page.success';
export const LOAD_PAGE_FAILURE = 'asset-page.load-page.failure';

export const REFETCH_ASSET = 'asset-page.refetch-asset';
export const REFETCH_ASSET_REQUEST = 'asset-page.refetch-asset.request';
export const REFETCH_ASSET_SUCCESS = 'asset-page.refetch-asset.success';
export const REFETCH_ASSET_FAILURE = 'asset-page.refetch-asset.failure';

export const LOAD_SUPPLEMENTAL = 'asset-page.load-supplemental';
export const LOAD_SUPPLEMENTAL_REQUEST = 'asset-page.load-supplemental.request';
export const LOAD_SUPPLEMENTAL_SUCCESS = 'asset-page.load-supplemental.success';
export const LOAD_SUPPLEMENTAL_FAILURE = 'asset-page.load-supplemental.failure';

export const INITIAL_STATE = {
	isLoading: false,
	loadFailed: false,
	isSupplementalDataLoading: false,
	didSupplementalDataFail: false
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case LOAD_PAGE_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case LOAD_PAGE_SUCCESS:
			return {
				...state,
				isLoading: false,
				loadFailed: false
			};

		case LOAD_PAGE_FAILURE:
			return {
				...state,
				isLoading: false,
				loadFailed: true
			};

		case REFETCH_ASSET_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case REFETCH_ASSET_SUCCESS:
			return {
				...state,
				isLoading: false,
				loadFailed: false
			};

		case REFETCH_ASSET_FAILURE:
			return {
				...state,
				isLoading: false,
				loadFailed: true
			};

		case LOAD_SUPPLEMENTAL_REQUEST:
			return {
				...state,
				isSupplementalDataLoading: true
			};

		case LOAD_SUPPLEMENTAL_SUCCESS:
			return {
				...state,
				isSupplementalDataLoading: false,
				didSupplementalDataFail: false
			};

		case LOAD_SUPPLEMENTAL_FAILURE:
			return {
				...state,
				isSupplementalDataLoading: false,
				didSupplementalDataFail: true
			};

		default:
			return state;
	}
}

export const actions = {
	loadPage: createAction(LOAD_PAGE),
	refetchAsset: createAction(REFETCH_ASSET),
	loadSupplementalData: createAction(LOAD_SUPPLEMENTAL)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	loadPageRequest: createAction(LOAD_PAGE_REQUEST),
	loadPageSuccess: createAction(LOAD_PAGE_SUCCESS),
	loadPageFailure: createAction(LOAD_PAGE_FAILURE),
	refetchAssetRequest: createAction(REFETCH_ASSET_REQUEST),
	refetchAssetSuccess: createAction(REFETCH_ASSET_SUCCESS),
	refetchAssetFailure: createAction(REFETCH_ASSET_FAILURE),
	loadSupplementalDataRequest: createAction(LOAD_SUPPLEMENTAL_REQUEST),
	loadSupplementalDataSuccess: createAction(LOAD_SUPPLEMENTAL_SUCCESS),
	loadSupplementalDataFailure: createAction(LOAD_SUPPLEMENTAL_FAILURE)
};

export const selectors = {
	isPageLoading,
	isPageReady,
	isPageLoadError,
	isSupplementalDataError,
	doesPageNeedRefresh,
	isSupplementalDataLoading,
	isSupplementalDataReady,
	doesSupplementalDataNeedRefresh,
	getSimilarAssets
};

function isPageLoading(state) {
	let { assetPageStore } = state;

	return assetPageStore.isLoading;
}

function isPageReady(state, assetId) {
	let isAssetLoaded = assetsSelectors.isAssetLoaded(state, assetId);
	let arePreviewsLoaded = previewsSelectors.areAssetPreviewsLoaded(state, assetId);
	let isSessionLoaded = sessionSelectors.isLoaded(state);

	if( !isAssetLoaded || !arePreviewsLoaded || !isSessionLoaded ) {
		return false;
	}

	let assetLastLoadedAt = assetsSelectors.getAssetLastLoadedAt(state, assetId);
	let previewsLastLoadedAt = previewsSelectors.getAssetPreviewsLastLoadedAt(state, assetId);
	let sessionDataLastLoadedAt = sessionSelectors.getLastLoadedAt(state);

	let now = new Date();
	let isAssetsDataStale = now - assetLastLoadedAt > 7200000;
	let isPreviewsDataStale = now - previewsLastLoadedAt > 7200000;
	let isSessionDataStale = now - sessionDataLastLoadedAt > 7200000;

	// If the data has been loaded within the last past two hours, its ready
	return !isAssetsDataStale && !isPreviewsDataStale && !isSessionDataStale;
}

function isPageLoadError(state) {
	let { assetPageStore } = state;
	return assetPageStore.loadFailed;
}

function isSupplementalDataError(state) {
	let { assetPageStore } = state;
	return assetPageStore.didSupplementalDataFail;
}

function doesPageNeedRefresh(state, assetId) {
	let isAssetLoaded = assetsSelectors.isAssetLoaded(state, assetId);
	let arePreviewsLoaded = previewsSelectors.areAssetPreviewsLoaded(state, assetId);
	let isSessionLoaded = sessionSelectors.isLoaded(state);

	if( !isAssetLoaded || !arePreviewsLoaded || !isSessionLoaded ) {
		return true;
	}

	let assetLastLoadedAt = assetsSelectors.getAssetLastLoadedAt(state, assetId);
	let previewsLastLoadedAt = previewsSelectors.getAssetPreviewsLastLoadedAt(state, assetId);
	let sessionDataLastLoadedAt = sessionSelectors.getLastLoadedAt(state);

	let now = new Date();
	let isAssetsDataStale = now - assetLastLoadedAt > 1800000;
	let isPreviewsDataStale = now - previewsLastLoadedAt > 1800000;
	let isSessionDataStale = now - sessionDataLastLoadedAt > 1800000;

	// If the data has been loaded within the last past two hours, its ready
	return isAssetsDataStale || isPreviewsDataStale || isSessionDataStale;
}

function isSupplementalDataLoading(state) {
	return state[STORE_NAME].isSupplementalDataLoading;
}

function isSupplementalDataReady(state, projectId) {
	let project = projectsSelectors.getProjectById(state, projectId);

	if( !project ) {
		return false;
	}

	let areAssetsLoaded = assetsSelectors.areProjectAssetsLoaded(state, project._id);

	if( !areAssetsLoaded ) {
		return false;
	}

	let assetsLastLoadedAt = assetsSelectors.getProjectAssetsLastLoadedAt(state, project._id);

	let now = new Date();
	let isAssetsDataStale = now - assetsLastLoadedAt > 7200000;

	return !isAssetsDataStale;
}

function doesSupplementalDataNeedRefresh(state, projectId) {
	let project = projectsSelectors.getProjectById(state, projectId);

	if( !project ) {
		return true;
	}

	let areAssetsLoaded = assetsSelectors.areProjectAssetsLoaded(state, project._id);

	if( !areAssetsLoaded ) {
		return true;
	}

	let assetsLastLoadedAt = assetsSelectors.getProjectAssetsLastLoadedAt(state, project._id);

	let now = new Date();
	let isAssetsDataStale = now - assetsLastLoadedAt > 1800000;

	return isAssetsDataStale;
}

function getSimilarAssets(state, asset) {
	if( !asset ) {
		return [];
	}
	let assets = assetsSelectors.getProjectAssets(state, asset.project);

	let filteredAssets = _.filter(assets, (a) => a.type === asset.type && a._id !== asset._id);

	return filteredAssets;
}

export function *watchAssetPage() {
	yield takeLatest(LOAD_PAGE, loadPageData);
	yield takeLatest(REFETCH_ASSET, refetchAsset);
	yield takeLatest(LOAD_SUPPLEMENTAL, loadSupplementalData);
}
