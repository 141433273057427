import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import Spinner from '../spinner';

const BaseComponent = styled.button`
	display: inline-block;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	border: 0.125rem solid transparent;
	border-radius: 0.25rem;
	background-color: transparent;
	cursor: pointer;
	padding: 0.375rem 1rem;
	vertical-align: middle;
	text-align: center;
	color: ${({ theme }) => theme['white']};
	line-height: 1.5rem;
	font-family: ${({ theme }) => theme['base-font-family']};
	font-size: 1rem;
	font-weight: 500;
	white-space: nowrap;

	&:focus {
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
	}

	&.primary {
		background-color: ${({ theme, darken }) => darken ? theme['blue-darken-10'] : theme['blue-base']};

		&:hover {
			background-color: ${({ theme, darken }) => darken ? theme['blue-darken-20'] : theme['blue-darken-10']};
		}
	}

	&.secondary {
		background-color: ${({ theme }) => theme['midnight-lighten-15']};

		&:hover {
			background-color: ${({ theme, lighten }) => lighten ? theme['midnight-lighten-10'] : theme['midnight-lighten-5']};
		}
	}

	&.warning {
		background-color: ${({ theme, darken }) => darken ? theme['yellow-darken-10'] : theme['yellow-base']};

		&:hover {
			background-color: ${({ theme, darken }) => darken ? theme['yellow-darken-20'] : theme['yellow-darken-10']};
		}
	}

	&.danger {
		background-color: ${({ theme }) => theme['red-base']};

		&:hover {
			background-color: ${({ theme }) => theme['red-darken-10']};
		}
	}

	&.ghost-primary {
		color: ${({ theme }) => theme['blue-base']};
		background-color: transparent;
		border-color: ${({ theme }) => theme['blue-base']};

		&:hover {
			color: ${({ theme }) => theme['white']};
			background-color: ${({ theme }) => theme['blue-base']};
			border-color: ${({ theme }) => theme['blue-base']};
		}
	}

	&.ghost-secondary {
		color: ${({ theme }) => theme['midnight-lighten-10']};
		background-color: transparent;
		border-color: ${({ theme }) => theme['midnight-lighten-10']};

		&:hover {
			color: ${({ theme }) => theme['white']};
			background-color: ${({ theme }) => theme['midnight-lighten-10']};
			border-color: ${({ theme }) => theme['midnight-lighten-10']};
		}
	}

	&.ghost-warning {
		color: ${({ theme }) => theme['yellow-base']};
		background-color: transparent;
		border-color: ${({ theme }) => theme['yellow-base']};

		&:hover {
			color: ${({ theme }) => theme['white']};
			background-color: ${({ theme }) => theme['yellow-base']};
			border-color: ${({ theme }) => theme['yellow-base']};
		}
	}

	&.ghost-danger {
		color: ${({ theme }) => theme['red-base']};
		background-color: transparent;
		border-color: ${({ theme }) => theme['red-base']};

		&:hover {
			color: ${({ theme }) => theme['white']};
			background-color: ${({ theme }) => theme['red-base']};
			border-color: ${({ theme }) => theme['red-base']};
		}
	}

	&.blank {
		background-color: transparent;

		&:hover {
			background-color: transparent;
		}
	}

	&.lg {
		padding: 0.5rem 1.25rem;
		font-size: 1.25rem;
		line-height: 1.5;
		border-radius: 0.3rem;
	}

	&.sm {
		padding: 0.25rem 0.75rem;
		font-size: 0.875rem;
		line-height: 1.5;
		border-radius: 0.2rem;
	}

	&.xs {
		padding: 0.25rem 0.625rem;
		font-size: 0.75rem;
		line-height: 1;
		border-radius: 0.2rem;
	}

	&.block {
		display: block;
		width: 100%;
	}

	&.disabled,
	&[disabled] {
		opacity: 0.25;
		pointer-events: none;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

const PaddedSpinner = styled(Spinner)`
	position: relative;
	top: 1px;
	margin-left: -0.625rem;
	margin-right: 0.25rem;
`;

class Button extends PureComponent {
	static propTypes = {
		variant: PropTypes.oneOf([
			'primary', 'secondary', 'warning', 'danger', 'ghost-primary'
		]),
		size: PropTypes.string,
		block: PropTypes.bool,
		disabled: PropTypes.bool,
		type: PropTypes.string,
		loading: PropTypes.bool,
		lighten: PropTypes.bool,
		darken: PropTypes.bool,
		blank: PropTypes.bool
	};

	static defaultProps = {
		variant: 'primary',
		disabled: false,
		type: 'button',
		loading: false,
		lighten: false,
		darken: false,
		blank: false
	};

	renderSpinner = () => {
		const { children, loading, block } = this.props;

		return (
			<Content block={block}>
				{ loading && (
					<PaddedSpinner small />
				)}

				{ children }
			</Content>
		);
	}

	render() {
		const {
			className,
			variant,
			size,
			block,
			disabled,
			blank,
			type,
			loading,
			children,
			...props
		} = this.props;

		const classes = classNames(
			className,
			variant,
			block && 'block',
			size && `${size}`,
			disabled && 'disabled',
			blank && 'blank'
		);

		return (
			<BaseComponent {...props} className={classes} type={type}>
				{ loading ? this.renderSpinner() : children }
			</BaseComponent>
		);
	}
}

export default Button;
