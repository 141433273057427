const IdService = {
	convertToId(object) {
		if( !object._id ) {
			return object;
		}
		const { _id: id, ...rest } = object;
		return {
			...rest,
			id
		};
	},

	convertFromId(object) {
		if( !object.id ) {
			return object;
		}
		const { id: _id, ...rest } = object;
		return {
			...rest,
			_id
		};
	},

	convertToIds(array) {
		return array.map((item) => {
			if( typeof item === 'object' ) {
				return IdService.convertToId(item);
			}
			return item;
		});
	},

	convertFromIds(array) {
		return array.map((item) => {
			if( typeof item === 'object' ) {
				return IdService.convertFromId(item);
			}
			return item;
		});
	}
};

export default IdService;
