import _ from 'lodash';
import isError from 'lodash.iserror';
import { eventChannel, END } from 'redux-saga';
import { createAction } from 'redux-actions';
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import uuid from 'uuid/v4';
import { createSelector, updateDispatcher, handleError } from './common';
import IngestService from '../modules/services/ingest-service';
import UploadService from '../modules/services/upload-service';
import FileService from '../modules/services/file-service';
import RouteService from '../modules/services/route-service';
import AssetService from '../modules/services/asset-service';
import ToastService from '../modules/services/toast-service';

import {
	actions as ingestsActions
} from './ingests';

export const STORE_NAME = 'ingestUploadStore';

function *uploadGraphicsProject({ payload }) {

	const { file, ingest } = payload;
	const uploadId = uuid();
	const assetId = ingest.asset.id;
	const ingestId = ingest.id;
	const name = FileService.getFileName(file.name);

	try {
		yield put(internalActions.uploadGraphicsProjectRequest({ uploadId, name }));

		const uploadResult = yield call(uploadFileGraphicsProject, file, { uploadId, ingestId, assetId });

		if( isError(uploadResult.result) ) {
			throw uploadResult.result;
		}

		const files = ingest.asset?.file || {};

		const asset = yield call(
			AssetService.updateAsset,
			assetId,
			{
				file: {
					...files,
					graphicsProject: uploadResult.result
				}
			}
		);
		if( isError(asset) ) {
			ToastService.create('error', 'Asset could not be updated successfully, please try again');
			throw asset;
		}
		ToastService.create('success', 'File uploaded successfully');

		const analysis = yield call(
			AssetService.triggerGraphicsAnalysis,
			assetId
		);
		if( isError(analysis) ) {
			ToastService.create('error', 'Graphics Analysis Could Not Be Launched Successfully, Please Try Again');
			throw analysis;
		}
		ToastService.create('success', 'Graphics Analysis Launched Successfully');

		return;
	}
	catch(e) {
		yield handleError(e);
	}
}

function *uploadFileGraphicsProject(file, params) {
	const { uploadId, ingestId, assetId } = params;
	const uploadRequest = {
		ingestId,
		assetId,
		file
	};

	// Create an event channel so that our async updates can dispatch update events.
	// The inner upload method always resolves, that way parallel tasks are not
	let resultPromise;
	const channel = eventChannel((emit) => {
		const emitUpdate = (update) => {
			emit(update);
		};

		resultPromise = UploadService.uploadFileGraphicsProject(uploadRequest.file, uploadRequest, emitUpdate)
			.then((result) => {
				return {
					uploadRequest,
					result
				};
			})
			.catch((e) => {
				const err = isError(e) ? e : new Error(e);

				// Do not emit Error directly as it will crash the channel
				emit({ error: err });
				return {
					uploadRequest,
					result: err
				};
			})
			.finally(() => {
				// End this channel
				emit(END);
			});

		return _.noop;
	});

	// We have to fork the update dispatcher because the when a channel ends, the generator
	// function is ended at the `take` and no additional code is run. We don't want this
	// generator function to end because we need to be able to return the promise to the
	// calling function. Can't do that when a generator function just ends.
	yield fork(updateDispatcher, channel, uploadRequest, (update) => {
		return internalActions.uploadGraphicsProjectUpdate({ uploadId, progress: update.progress });
	});

	const uploadResult = yield resultPromise;
	return uploadResult;
}

function *uploadFiles({ payload }) {
	const { files, context } = payload;

	const tasks = files.map((file) => call(
		uploadFile,
		actions.uploadFile({ file, context })
	));

	yield all(tasks);
}

function *uploadFile({ payload }) {
	const { file, context } = payload;
	const uploadId = uuid();
	const name = FileService.getFileName(file.name);

	try {
		yield put(internalActions.uploadFileRequest({ uploadId, name }));

		const { size } = file;

		const url = RouteService.stateHref('ingest.view', { id: ':ingest' }, { absolute: true });
		const urls = {
			view: decodeURIComponent(url)
		};

		let ingest = yield IngestService.create({
			context,
			size,
			urls
		});

		const ingestId = ingest._id;
		const uploadResult = yield call(uploadFileIngest, file, { uploadId, ingestId });

		if( isError(uploadResult.result) ) {
			throw uploadResult.result;
		}

		ingest = yield IngestService.update(ingest._id, {
			status: 'uploaded',
			source: {
				url: uploadResult.result
			}
		});

		yield put(ingestsActions.addIngestManually({ ingest }));

		yield put(internalActions.uploadFileSuccess());
		return ingest;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.uploadFileFailure({ uploadId, error: err }));
		return err;
	}
}

function *uploadFileIngest(file, params) {
	const { uploadId, ingestId } = params;
	const uploadRequest = {
		ingestId,
		file
	};

	// Create an event channel so that our async updates can dispatch update events.
	// The inner upload method always resolves, that way parallel tasks are not
	let resultPromise;
	const channel = eventChannel((emit) => {
		const emitUpdate = (update) => {
			emit(update);
		};

		resultPromise = UploadService.uploadFileIngest(uploadRequest.file, uploadRequest, emitUpdate)
			.then((result) => {
				return {
					uploadRequest,
					result
				};
			})
			.catch((e) => {
				const err = isError(e) ? e : new Error(e);

				// Do not emit Error directly as it will crash the channel
				emit({ error: err });
				return {
					uploadRequest,
					result: err
				};
			})
			.finally(() => {
				// End this channel
				emit(END);
			});

		return _.noop;
	});

	// We have to fork the update dispatcher because the when a channel ends, the generator
	// function is ended at the `take` and no additional code is run. We don't want this
	// generator function to end because we need to be able to return the promise to the
	// calling function. Can't do that when a generator function just ends.
	yield fork(updateDispatcher, channel, uploadRequest, (update) => {
		return internalActions.uploadFileUpdate({ uploadId, progress: update.progress });
	});

	const uploadResult = yield resultPromise;
	return uploadResult;
}

export const UPLOAD_FILES = 'ingest-upload.upload-files';
export const UPLOAD_FILE = 'ingest-upload.upload-file';
export const UPLOAD_FILE_REQUEST = 'ingest-upload.upload-file.request';
export const UPLOAD_FILE_SUCCESS = 'ingest-upload.upload-file.success';
export const UPLOAD_FILE_FAILURE = 'ingest-upload.upload-file.failure';
export const UPLOAD_FILE_UPDATE = 'ingest-upload.upload-file.update';
export const UPLOAD_FILE_RETRY = 'ingest-upload.upload-file.retry';
export const UPLOAD_FILE_CANCEL = 'ingest-upload.upload-file.cancel';
export const CLEAR_COMPLETED_UPLOADS = 'ingest-upload.clear.completed';
export const UPLOAD_GRAPHICS_PROJECT = 'ingest-upload.upload-graphics-project';
export const UPLOAD_GRAPHICS_PROJECT_REQUEST = 'ingest-upload.upload-graphics-project.request';
export const UPLOAD_GRAPHICS_PROJECT_SUCCESS = 'ingest-upload.upload-graphics-project.success';
export const UPLOAD_GRAPHICS_PROJECT_FAILURE = 'ingest-upload.upload-graphics-project.failure';
export const UPLOAD_GRAPHICS_PROJECT_UPDATE = 'ingest-upload.upload-graphics-project.update';

export const INITIAL_STATE = {
	graphicsProjects: {}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case UPLOAD_FILE_REQUEST: {
			const { uploadId, name } = action.payload;

			return {
				...state,
				[uploadId]: {
					name,
					progress: 0
				}
			};
		}

		case UPLOAD_FILE_SUCCESS: {
			return {
				...state
			};
		}

		case UPLOAD_FILE_UPDATE: {
			const { uploadId, progress } = action.payload;

			return {
				...state,
				[uploadId]: {
					...state[uploadId],
					progress
				}
			};
		}

		case UPLOAD_FILE_FAILURE: {
			const { uploadId, error } = action.payload;

			return {
				...state,
				[uploadId]: {
					...state[uploadId],
					progress: 0,
					error
				}
			};
		}

		case UPLOAD_GRAPHICS_PROJECT_REQUEST: {
			const { uploadId, name } = action.payload;

			return {
				...state,
				graphicsProjects: {
					...state.graphicsProjects,
					[uploadId]: {
						name,
						progress: 0
					}
				}
			};
		}

		case UPLOAD_GRAPHICS_PROJECT_SUCCESS: {
			return {
				...state
			};
		}

		case UPLOAD_GRAPHICS_PROJECT_UPDATE: {
			const { uploadId, progress } = action.payload;

			return {
				...state,
				graphicsProjects: {
					...state.graphicsProjects,
					[uploadId]: {
						...state.graphicsProjects[uploadId],
						progress
					}
				}
			};
		}

		case UPLOAD_GRAPHICS_PROJECT_FAILURE: {
			const { uploadId, error } = action.payload;

			return {
				...state,
				graphicsProjects: {
					...state.graphicsProjects,
					[uploadId]: {
						...state[uploadId],
						progress: 0,
						error
					}
				}
			};
		}

		case CLEAR_COMPLETED_UPLOADS: {
			let newState = {
				...state
			};

			for( const id in newState ) {
				if( newState.hasOwnProperty(id) ) {
					if( newState[id].progress === 1 ) {
						delete newState[id];
					}
				}
			}

			return newState;
		}

		default:
			return state;
	}
}

export const actions = {
	uploadFiles: createAction(UPLOAD_FILES),
	uploadFile: createAction(UPLOAD_FILE),
	retryUploadFile: createAction(UPLOAD_FILE_RETRY),
	cancelUploadFile: createAction(UPLOAD_FILE_CANCEL),
	clearCompleted: createAction(CLEAR_COMPLETED_UPLOADS),
	uploadGraphicsProject: createAction(UPLOAD_GRAPHICS_PROJECT)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	uploadFileRequest: createAction(UPLOAD_FILE_REQUEST),
	uploadFileSuccess: createAction(UPLOAD_FILE_SUCCESS),
	uploadFileFailure: createAction(UPLOAD_FILE_FAILURE),
	uploadFileUpdate: createAction(UPLOAD_FILE_UPDATE),
	uploadGraphicsProjectRequest: createAction(UPLOAD_GRAPHICS_PROJECT_REQUEST),
	uploadGraphicsProjectSuccess: createAction(UPLOAD_GRAPHICS_PROJECT_SUCCESS),
	uploadGraphicsProjectFailure: createAction(UPLOAD_GRAPHICS_PROJECT_FAILURE),
	uploadGraphicsProjectUpdate: createAction(UPLOAD_GRAPHICS_PROJECT_UPDATE)
};

export const selectors = {
	getIngestUploads: createSelector(STORE_NAME, getIngestUploads),
	getGraphicsProjectUploads: createSelector(STORE_NAME, getGraphicsProjectUploads),
	isUploadingGraphicsProject: createSelector(STORE_NAME, isUploadingGraphicsProject)
};

function getIngestUploads(state) {
	return _.difference(Object.keys(state), Object.keys(INITIAL_STATE)).map((id) => ({
		id,
		...state[id]
	}));
}

function getGraphicsProjectUploads(state) {
	let graphicsProjectUploads = Object.keys(state.graphicsProjects) || [];
	return _.map(graphicsProjectUploads, (id) => ({
		id,
		...state.graphicsProjects[id]
	}));
}

function isUploadingGraphicsProject(state) {
	let progresses = _.map(state.graphicsProjects, 'progress');
	return _.some(progresses, (progress) => progress > 0 && progress < 1);
}

export function *watchIngestUpload() {
	yield takeEvery(UPLOAD_FILES, uploadFiles);
	yield takeEvery(UPLOAD_FILE, uploadFile);
	yield takeEvery(UPLOAD_GRAPHICS_PROJECT, uploadGraphicsProject);
}
