import api from './api-service';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/nextjs';
import { POSTHOG_ENABLED } from '@/config/config.3x';
import Cookies from 'js-cookie';
import FlagService from './flag-service';

const STORE_KEY = 'pixwel.api.credentials';

const AuthService = {

	login: (credentials) => {

		const isToken = !!credentials.token;
		const isTwoFactor = credentials.isTwoFactor;

		if(isToken && isTwoFactor) {
			AuthService.setToken({
				username: credentials.username,
				token: credentials.token
			});
		}

		credentials = !isToken
			? credentials
			: {
				username: credentials.username,
				password: 'token-' + credentials.token
			};

		const url = `${window.location.origin}/login/:token`;
		const params = {};
		const headers = {
			'Authorization': `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`
		};
		if(!isTwoFactor) {
			params.url = url;
		}
		return api.client.get('/session', { headers })
			.then(( { data: session }) => {
				if(!session) {
					throw new Error(isToken ? 'token-expired' : 'access-denied');
				}

				if(session.token) {
					AuthService.setToken({
						username: credentials.username,
						token: session.token
					});
				}

				// Do not proceed with Logger configuration if session is valid yet (e.g. 2FA session response)
				if( !session._id ) {
					return session;
				}

				if(POSTHOG_ENABLED) {
					FlagService.identify(session);
				}

				LogRocket?.identify(session._id, {
					name: session.name,
					email: session.emailNormal,
					isImpersonating: AuthService.getToken().isImpersonating || false
				});

				LogRocket.getSessionURL((sessionURL) => {
					document.cookie = `LogRocket=${sessionURL}`;
					Sentry.configureScope((scope) => {
						scope.setExtra('sessionURL', sessionURL);
					});
				});

				Sentry.setUser({
					email: session.emailNormal,
					username: session.name
				});

				return session;
			});
	},

	logout: () => {
		return api.client.delete('/session').then((response) => {
			AuthService.removeToken();
			window.location.href='/';
			return response?.data || response || {};
		});
	},

	become: async(userId) => {
		try {
			const actual = AuthService.getToken();
			const result = await api.client.patch('/session', { _id: userId });

			if(!result?.data?.username || !result?.data?.token) {
				throw new Error('User not found');
			}

			const session = result?.data || {};
			await AuthService.removeToken();
			await AuthService.setToken({
				isImpersonating: true,
				actual,
				username: session.username,
				token: session.token
			});

			window.location.reload();
		}
		catch(err) {
			window.location.reload();
		}
	},

	isImpersonating: () => {
		return AuthService.getToken().isImpersonating || false;
	},

	switchBack: async() => {
		const username = AuthService.getToken()?.actual?.username || null;
		const token = AuthService.getToken()?.actual?.token || null;

		if(username && token) {
			await AuthService.setToken({
				isImpersonating: false,
				username,
				token
			});
		}
		else {
			await AuthService.removeToken();
		}
		window.location.reload();
	},

	resetPassword: async(token, password) => {
		await api.client.post('password-reset', { token, password });
	},

	twoFactorLogin: async(token) => {
		return await api.client.post('/session', { token });
	},

	removeToken: () => {
		Cookies.remove(STORE_KEY);
	},

	resumeSession: async() => {
		const token = AuthService.getToken();
		if(!token) {
			throw new Error('no-token');
		}
		return await AuthService.login({
			username: token.username,
			token: token.token
		});
	},

	getToken: () => {
		const token = Cookies.getJSON(STORE_KEY);
		if(!token || !token.username || !token.token) {
			return false;
		}
		return token;
	},

	setToken: (credentials) => {
		return Cookies.set(STORE_KEY, credentials);
	}
};

export default AuthService;
