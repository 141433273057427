import _ from 'lodash';
import api from './api-service';
import _get from 'lodash.get';

const TERRITORIES_QUERY = `query getTerritories($offset: Int, $limit: Int, $search: String) {
  territories (offset: $offset, limit: $limit, search: $search) {
  	totalCount
    nodes {
      id
      name
      code
      standard
      countries
      languages
    }
  }
}`;

export const ERROR_TERRITORIES_UNKNOWN = 'error.territory.unknown';

const TerritoryService = {
	getSelectableTerritories(territories) {
		return _.filter(territories, (territory) => {
			return ['WW', 'AC'].indexOf(territory.code) === -1;
		});
	},

	getTerritoriesWithCodes(territories) {
		return _.filter(territories, (territory) => territory.code && territory.code !== '--');
	},

	getAllTerritories(params) {
		return api.graphql.request(TERRITORIES_QUERY, params).then((res) => {
			return params? res.territories : res.territories.nodes;
		});
	},
	postTerritory(data) {
		return new Promise((resolve, reject) => {
			api.client.post(`/territories`, data)
				.then(resolve)
				.catch((err) => {
					reject({
						code: ERROR_TERRITORIES_UNKNOWN,
						message: Object.keys(err.response.data).map((key) => _get(err, `response.data.${key}[0]`,
							'Failed to create a new territory'))[0],
						innerError: err?.response?.data || err
					});
				});
		});
	},

	patchTerritory(data) {
		return new Promise((resolve, reject) => {
			api.client.patch(`/territories/${data.id}`, data)
				.then(resolve)
				.catch((err) => {
					reject({
						code: ERROR_TERRITORIES_UNKNOWN,
						message: _get(err, 'response.data.message', Object.keys(err.response.data).map((key) => _get(err, `response.data.${key}[0]`,
							'Failed to create a new territory'))[0]),
						innerError: err?.response?.data || err
					});
				});
		});
	},

	deleteTerritory({ id }) {
		return new Promise((resolve, reject) => {
			api.client.delete(`/territories/${id}`)
				.then(resolve)
				.catch((err) => {
					reject({
						code: ERROR_TERRITORIES_UNKNOWN,
						message: _get(err, 'response.data.message', 'An error has occurred while trying to delete this territory, Please try again later'),
						innerError: err?.response?.data || err
					});
				});
		});
	}

};

export default TerritoryService;
