"use client";

import dynamic from "next/dynamic";
import "@/modules/pixwel/logrocket-setup";
import "react-dates/initialize";
import theme from "@/theme";
import { Provider as ReduxProvider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import store from "@/store";

import ReactModal from "react-modal";
import { ToastContainer } from "react-toastify";

import ConnectionSpeedChecker from "./connection-speed";

import LocaleService from "@/modules/services/locale-service";
import FlagService from "@/modules/services/flag-service";
import UpdateChecker from '@/modules/components/global/update-checker';

import moment from "moment";
import momentLocalizer from "react-widgets-moment";

import StyledComponentsRegistry from "./registry";

LocaleService.initialize();
FlagService.init();

moment.locale("en");
momentLocalizer();

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			// staleTime: 5 * (60 * 1000), // 5 mins
			cacheTime: 10 * (60 * 1000), // 10 mins
		},
	},
});

const ReactQueryDevtools = dynamic(
	() =>
		import("@tanstack/react-query-devtools").then(
			(mod) => mod.ReactQueryDevtools
		),
	{
		ssr: false,
	}
);

export default function Container({ children, session }) {
	ReactModal.setAppElement("body");
	return (
		<StyledComponentsRegistry>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={theme}>
					<ReduxProvider store={store}>
						<>
							{ process.env.NODE_ENV == 'development' && <ReactQueryDevtools initialIsOpen /> }
							<ToastContainer autoClose={4000} hideProgressBar={true} />
							<ConnectionSpeedChecker />
							<UpdateChecker />
							{children}
						</>
					</ReduxProvider>
				</ThemeProvider>
			</QueryClientProvider>
		</StyledComponentsRegistry>
	);
}
