import AuthorizedUploadHandler from './authorized-upload-handler';

const UploadService = {

	uploadTranslation(file, options, updateCb) {
		let { translationId } = options;

		let transformedOptions = {
			file,
			updateCb,
			request: {
				usage: 'customTranslation',
				name: 'attachments',
				translation: translationId
			}
		};

		return AuthorizedUploadHandler(transformedOptions);
	},

	uploadFile(file, options, updateCb) {
		let { workRequestId } = options;

		let transformedOptions = {
			file,
			updateCb,
			request: {
				usage: 'workRequestAttachment',
				name: 'attachments',
				workRequest: workRequestId
			}
		};

		return AuthorizedUploadHandler(transformedOptions);
	},

	uploadTagFile(file, options, updateCb) {
		let { tagIndex, fileIndex, tag, workRequestId } = options;

		let transformedOptions = {
			tagIndex,
			fileIndex,
			file,
			updateCb,
			request: {
				usage: 'workRequestAttachment',
				name: tag.tag,
				workRequest: workRequestId
			}
		};

		return AuthorizedUploadHandler(transformedOptions);
	},

	uploadOffline(file, options, updateCb) {
		let { workRequestId, tagName } = options;

		let transformedOptions = {
			file,
			updateCb,
			request: {
				usage: 'workRequestOffline',
				workRequest: workRequestId,
				tag: tagName
			}
		};

		return AuthorizedUploadHandler(transformedOptions);
	},

	uploadFileIngest(file, options, updateCb) {
		const { ingestId } = options;

		const transformedOptions = {
			file,
			updateCb,
			request: {
				usage: 'slurpee2',
				ingest: ingestId
			}
		};

		return AuthorizedUploadHandler(transformedOptions);
	},

	uploadFileGraphicsProject(file, options, updateCb) {
		const { ingestId, assetId } = options;

		const transformedOptions = {
			file,
			updateCb,
			request: {
				usage: 'graphicsProject',
				name: 'graphicsProject',
				ingest: ingestId,
				asset: assetId,
				'Content-Type': 'application/zip'
			}
		};

		return AuthorizedUploadHandler(transformedOptions);
	},

	uploadFileAssetAudio(file, options, updateCb) {
		const { assetId, language } = options;

		const transformedOptions = {
			file,
			updateCb,
			request: {
				usage: 'assetAudio',
				name: 'assetAudio',
				asset: assetId,
				language
			}
		};

		return AuthorizedUploadHandler(transformedOptions);
	},

	uploadFileWorkRequestAudio(file, options, updateCb) {

		const transformedOptions = {
			file,
			updateCb,
			request: {
				usage: 'workRequestAudio',
				name: 'workRequestAudio'
			}
		};

		return AuthorizedUploadHandler(transformedOptions);
	}
};

export default UploadService;
