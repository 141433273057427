import _get from 'lodash.get';
import { createAction } from 'redux-actions';
import { put, takeEvery } from 'redux-saga/effects';
import { createSelector, handleError } from './common';
import TranslationService from '../modules/services/translation-service';

export const STORE_NAME = 'translationStore';

export function *fetchTranslationById({ payload: id }) {
	try {
		yield put(internalActions.getTranslationByIdRequest(id));

		let translation = yield TranslationService.getById(id);
		if( translation === null ) {
			throw new Error('Failed to load translation');
		}

		yield put(internalActions.getTranslationByIdSuccess(translation));
		return translation;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getTranslationByIdFailure({ id, err }));
		return err;
	}
}

export function *fetchTranslationMemories({ payload }) {
	let { id, language } = payload;

	try {
		yield put(internalActions.getTranslationMemoriesRequest({ id, language }));

		let translationMemories = yield TranslationService.getTranslationMemories(id, language);
		if( translationMemories === null ) {
			throw new Error('Failed to load translation memories');
		}

		yield put(internalActions.getTranslationMemoriesSuccess({ id, language, translationMemories }));
		return translationMemories;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getTranslationMemoriesFailure({ id, language, err }));
		return err;
	}
}

export function *fetchMachineTranslations({ payload }) {
	let { id, language } = payload;

	try {
		yield put(internalActions.getMachineTranslationsRequest({ id, language }));

		let machineTranslations = yield TranslationService.getMachineTranslations(id, language);
		if( machineTranslations === null ) {
			throw new Error('Failed to load machine translations');
		}

		yield put(internalActions.getMachineTranslationsSuccess({ id, language, machineTranslations }));
		return machineTranslations;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getMachineTranslationsFailure({ id, language, err }));
		return err;
	}
}

export function *fetchTranslationLanguages() {
	try {
		yield put(internalActions.getTranslationLanguagesRequest());

		let translationLanguages = yield TranslationService.getTranslationLanguages();
		if( translationLanguages === null ) {
			throw new Error('Failed to load translation languages');
		}

		yield put(internalActions.getTranslationLanguagesSuccess({ translationLanguages }));
		return translationLanguages;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getTranslationLanguagesFailure({ err }));
		return err;
	}
}

export const FETCH_TRANSLATION_BY_ID = 'translations.fetch.by-id';
export const FETCH_TRANSLATION_BY_ID_REQUEST = 'translations.fetch.by-id.request';
export const FETCH_TRANSLATION_BY_ID_SUCCESS = 'translations.fetch.by-id.success';
export const FETCH_TRANSLATION_BY_ID_FAILURE = 'translations.fetch.by-id.failure';

export const FETCH_TRANSLATION_MEMORIES = 'translation-memories.fetch';
export const FETCH_TRANSLATION_MEMORIES_REQUEST = 'translation-memories.fetch.request';
export const FETCH_TRANSLATION_MEMORIES_SUCCESS = 'translation-memories.fetch.success';
export const FETCH_TRANSLATION_MEMORIES_FAILURE = 'translation-memories.fetch.failure';

export const FETCH_MACHINE_TRANSLATIONS = 'machine-translations.fetch';
export const FETCH_MACHINE_TRANSLATIONS_REQUEST = 'machine-translations.fetch.request';
export const FETCH_MACHINE_TRANSLATIONS_SUCCESS = 'machine-translations.fetch.success';
export const FETCH_MACHINE_TRANSLATIONS_FAILURE = 'machine-translations.fetch.failure';

export const FETCH_TRANSLATION_LANGUAGES = 'translation-languages.fetch';
export const FETCH_TRANSLATION_LANGUAGES_REQUEST = 'translation-languages.fetch.request';
export const FETCH_TRANSLATION_LANGUAGES_SUCCESS = 'translation-languages.fetch.success';
export const FETCH_TRANSLATION_LANGUAGES_FAILURE = 'translation-languages.fetch.failure';

export const INITIAL_STATE = {
	translations: {},
	translationMemories: {},
	machineTranslations: {}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case FETCH_TRANSLATION_BY_ID: {
			let id = action.payload;

			return {
				...state,
				translations: {
					...state.translations,
					[id]: {
						data: null,
						lastLoadedAt: null,
						...state.translations[id],
						isLoading: true,
						loadError: null
					}
				}
			};
		}

		case FETCH_TRANSLATION_BY_ID_SUCCESS: {
			let translation = action.payload;

			return {
				...state,
				translations: {
					...state.translations,
					[translation._id]: {
						data: translation,
						lastLoadedAt: new Date(),
						isLoading: false,
						loadError: null
					}
				}
			};
		}

		case FETCH_TRANSLATION_BY_ID_FAILURE: {
			let { id, error } = action.payload;

			return {
				...state,
				translations: {
					...state.translations,
					[id]: {
						...state.translations[id],
						isLoading: false,
						loadError: error
					}
				}
			};
		}

		case FETCH_TRANSLATION_MEMORIES_REQUEST: {
			let { id, language } = action.payload;
			let existingMemories = _get(state.translationMemories, [id], {});
			let existingLanguageMemories = _get(existingMemories, [language], {});

			return {
				...state,
				translationMemories: {
					...state.translationMemories,
					[id]: {
						...existingMemories,
						[language]: {
							data: null,
							lastLoadedAt: null,
							...existingLanguageMemories,
							isLoading: true
						}
					}
				}
			};
		}

		case FETCH_TRANSLATION_MEMORIES_SUCCESS: {
			let { id, language, translationMemories } = action.payload;

			return {
				...state,
				translationMemories: {
					...state.translationMemories,
					[id]: {
						...state.translationMemories[id],
						[language]: {
							isLoading: false,
							lastLoadedAt: new Date(),
							data: translationMemories
						}
					}
				}
			};
		}

		case FETCH_TRANSLATION_MEMORIES_FAILURE: {
			let { id, language, err } = action.payload;

			return {
				...state,
				translationMemories: {
					...state.translationMemories,
					[id]: {
						...state.translationMemories[id],
						[language]: {
							...state.translationMemories[id][language],
							isLoading: false,
							loadError: err
						}
					}
				}
			};
		}

		case FETCH_MACHINE_TRANSLATIONS_REQUEST: {
			let { id, language } = action.payload;
			let existingMachineTranslations = _get(state.machineTranslations, [id], {});
			let existingMachineTranslationsLanguage = _get(existingMachineTranslations, [language], {});

			return {
				...state,
				machineTranslations: {
					...state.machineTranslations,
					[id]: {
						...existingMachineTranslations,
						[language]: {
							data: null,
							lastLoadedAt: null,
							...existingMachineTranslationsLanguage,
							isLoading: true
						}
					}
				}
			};
		}

		case FETCH_MACHINE_TRANSLATIONS_SUCCESS: {
			let { id, language, machineTranslations } = action.payload;

			return {
				...state,
				machineTranslations: {
					...state.machineTranslations,
					[id]: {
						...state.machineTranslations[id],
						[language]: {
							isLoading: false,
							lastLoadedAt: new Date(),
							data: machineTranslations
						}
					}
				}
			};
		}

		case FETCH_MACHINE_TRANSLATIONS_FAILURE: {
			let { id, language, err } = action.payload;

			return {
				...state,
				machineTranslations: {
					...state.machineTranslations,
					[id]: {
						...state.machineTranslations[id],
						[language]: {
							...state.machineTranslations[id][language],
							isLoading: false,
							loadError: err
						}
					}
				}
			};
		}

		case FETCH_TRANSLATION_LANGUAGES_REQUEST: {
			return {
				...state,
				translationLanguages: {
					isLoading: true
				}
			};
		}

		case FETCH_TRANSLATION_LANGUAGES_SUCCESS: {
			let { translationLanguages } = action.payload;

			return {
				...state,
				translationLanguages: {
					isLoading: false,
					lastLoadedAt: new Date(),
					data: translationLanguages
				}
			};
		}

		case FETCH_TRANSLATION_LANGUAGES_FAILURE: {
			let { err } = action.payload;

			return {
				...state,
				translationLanguages: {
					isLoading: false,
					loadError: err
				}
			};
		}

		default:
			return state;
	}
}

export const actions = {
	getTranslationById: createAction(FETCH_TRANSLATION_BY_ID),
	getTranslationMemories: createAction(FETCH_TRANSLATION_MEMORIES),
	getMachineTranslations: createAction(FETCH_MACHINE_TRANSLATIONS),
	getTranslationLanguages: createAction(FETCH_TRANSLATION_LANGUAGES)
};

/**
 * Actions that should only be invoked internally
 */
export const internalActions = {
	getTranslationByIdRequest: createAction(FETCH_TRANSLATION_BY_ID_REQUEST),
	getTranslationByIdSuccess: createAction(FETCH_TRANSLATION_BY_ID_SUCCESS),
	getTranslationByIdFailure: createAction(FETCH_TRANSLATION_BY_ID_FAILURE),
	getTranslationMemoriesRequest: createAction(FETCH_TRANSLATION_MEMORIES_REQUEST),
	getTranslationMemoriesSuccess: createAction(FETCH_TRANSLATION_MEMORIES_SUCCESS),
	getTranslationMemoriesFailure: createAction(FETCH_TRANSLATION_MEMORIES_FAILURE),
	getMachineTranslationsRequest: createAction(FETCH_MACHINE_TRANSLATIONS_REQUEST),
	getMachineTranslationsSuccess: createAction(FETCH_MACHINE_TRANSLATIONS_SUCCESS),
	getMachineTranslationsFailure: createAction(FETCH_MACHINE_TRANSLATIONS_FAILURE),
	getTranslationLanguagesRequest: createAction(FETCH_TRANSLATION_LANGUAGES_REQUEST),
	getTranslationLanguagesSuccess: createAction(FETCH_TRANSLATION_LANGUAGES_SUCCESS),
	getTranslationLanguagesFailure: createAction(FETCH_TRANSLATION_LANGUAGES_FAILURE)
};

export const selectors = {
	isLoading: createSelector(STORE_NAME, isLoading),
	isLoadingTranslationMemories: createSelector(STORE_NAME, isLoadingTranslationMemories),
	isLoadingMachineTranslations: createSelector(STORE_NAME, isLoadingMachineTranslations),
	isLoadingTranslationLanguages: createSelector(STORE_NAME, isLoadingTranslationLanguages),
	isLoaded: createSelector(STORE_NAME, isLoaded),
	isLoadedTranslationMemories: createSelector(STORE_NAME, isLoadedTranslationMemories),
	isLoadedMachineTranslations: createSelector(STORE_NAME, isLoadedMachineTranslations),
	isLoadedTranslationLanguages: createSelector(STORE_NAME, isLoadedTranslationLanguages),
	didLoadFail: createSelector(STORE_NAME, didLoadFail),
	didLoadTranslationMemoriesFail: createSelector(STORE_NAME, didLoadTranslationMemoriesFail),
	didLoadMachineTranslationsFail: createSelector(STORE_NAME, didLoadMachineTranslationsFail),
	didLoadTranslationLanguagesFail: createSelector(STORE_NAME, didLoadTranslationLanguagesFail),
	getTranslationById: createSelector(STORE_NAME, getTranslationById),
	getTranslationMemories: createSelector(STORE_NAME, getTranslationMemories),
	getMachineTranslations: createSelector(STORE_NAME, getMachineTranslations),
	getTranslationLanguages: createSelector(STORE_NAME, getTranslationLanguages)
};

function getTranslationById(state, id) {
	let translationContainer = state.translations[id];

	if( !translationContainer ) {
		return null;
	}

	return translationContainer.data;
}

function getTranslationMemories(state, id, language) {
	let languageContainer = getTranslationMemoryLanguageContainer(state, id, language);

	return languageContainer ? languageContainer.data : null;
}

function getMachineTranslations(state, id, language) {
	let languageContainer = getMachineTranslationsLanguageContainer(state, id, language);

	return languageContainer ? languageContainer.data : null;
}

function getTranslationLanguages(state) {
	return state.translationLanguages?.data;
}

function isLoading(state, id) {
	let translationContainer = state.translations[id];

	if( !translationContainer ) {
		return false;
	}

	return translationContainer.isLoading;
}

function isLoadingTranslationMemories(state, id, language) {
	let languageContainer = getTranslationMemoryLanguageContainer(state, id, language);

	return languageContainer ? languageContainer.isLoading : false;
}

function isLoadingMachineTranslations(state, id, language) {
	let languageContainer = getMachineTranslationsLanguageContainer(state, id, language);

	return languageContainer ? languageContainer.isLoading : false;
}

function isLoadingTranslationLanguages(state) {
	return state.translationLanguages?.isLoading;
}

function isLoaded(state, id) {
	let translationContainer = state.translations[id];

	if( !translationContainer ) {
		return false;
	}

	return !!translationContainer.lastLoadedAt;
}

function isLoadedTranslationMemories(state, id, language) {
	let languageContainer = getTranslationMemoryLanguageContainer(state, id, language);

	return languageContainer ? !!languageContainer.lastLoadedAt : false;
}

function isLoadedMachineTranslations(state, id, language) {
	let languageContainer = getMachineTranslationsLanguageContainer(state, id, language);

	return languageContainer ? !!languageContainer.lastLoadedAt : false;
}

function isLoadedTranslationLanguages(state) {
	return !!state.translationLanguages?.lastLoadedAt;
}

function didLoadFail(state, id) {
	let translationContainer = state.translations[id];

	if( !translationContainer ) {
		return false;
	}

	return !!translationContainer.loadError;
}

function didLoadTranslationMemoriesFail(state, id, language) {
	let languageContainer = getTranslationMemoryLanguageContainer(state, id, language);

	return languageContainer ? !!languageContainer.loadError : false;
}

function didLoadMachineTranslationsFail(state, id, language) {
	let languageContainer = getMachineTranslationsLanguageContainer(state, id, language);

	return languageContainer ? !!languageContainer.loadError : false;
}

function didLoadTranslationLanguagesFail(state) {
	return !!state.translationLanguages?.loadError;
}

function getTranslationMemoryLanguageContainer(state, id, language) {
	let translationMemoriesContainer = state.translationMemories[id];
	if( !translationMemoriesContainer ) {
		return null;
	}

	let languageContainer = translationMemoriesContainer[language];
	if( !languageContainer ) {
		return null;
	}

	return languageContainer;
}

function getMachineTranslationsLanguageContainer(state, id, language) {
	let machineTranslationsContainer = state.machineTranslations[id];
	if( !machineTranslationsContainer ) {
		return null;
	}

	let languageContainer = machineTranslationsContainer[language];
	if( !languageContainer ) {
		return null;
	}

	return languageContainer;
}

export function *watchTranslations() {
	yield takeEvery(FETCH_TRANSLATION_BY_ID, fetchTranslationById);
	yield takeEvery(FETCH_TRANSLATION_MEMORIES, fetchTranslationMemories);
	yield takeEvery(FETCH_MACHINE_TRANSLATIONS, fetchMachineTranslations);
	yield takeEvery(FETCH_TRANSLATION_LANGUAGES, fetchTranslationLanguages);
}
