import ValidationService from './validation-service';
import api, { buildURL } from './api-service';

export const ERROR_SLUG = 'error.slug';
export const ERROR_PROJECT_UNKNOWN = 'error.project.unknown';

export const PROJECT_CONSTRAINTS = {
	name: {
		presence: {
			allowEmpty: false
		}
	},
	slug: {
		presence: {
			allowEmpty: false
		},
		format: {
			pattern: /^[a-zA-Z0-9-]+$/
		}
	},
	studio: {
		presence: {
			allowEmpty: false
		}
	}
};

const PROJECTS_QUERY = `query getProjects($archived: Boolean, $offset: Int, $limit: Int, $search: String) {
	projects(offset: $offset, limit: $limit, archived: $archived, search: $search) {
		totalCount
	  nodes {
		id
		name
		slug
		studio {
			id
		  	name
			logo
		}
		poster
		archived
		filePrefix
		verified
	  }
	}
  }
`;

const PROJECTS_QUERY_SHORT = `query getProjects($archived: Boolean, $limit: Int, $search: String) {
	projects(limit: $limit, archived: $archived, search: $search) {
	totalCount
	  nodes {
		value : id
		label : name
	  }
	}
}`;

export const ProjectService = {
	createProject(project) {
		return new Promise((resolve, reject) => {
			api.client.post(`/projects`, project)
				.then(resolve)
				.catch((err) => {
					if( err.message === 'Projects can\'t share the same slug' ) {
						reject({
							code: ERROR_SLUG,
							message: 'Slug already in use',
							innerError: err
						});
						return;
					}

					reject({
						code: ERROR_PROJECT_UNKNOWN,
						message: 'Failed to create a new project',
						innerError: err
					});
				});
		});
	},

	patchProject(data) {
		return new Promise((resolve, reject) => {
			api.client.patch(`/projects/${data.slug}`, data)
				.then(resolve)
				.catch((err) => {
					reject({
						code: ERROR_PROJECT_UNKNOWN,
						message: err?.response?.data?.error?.message || 'Failed to update the project',
						innerError: err?.response?.data || err
					});
				});
		});
	},

	archiveProject({ slug, archived }) {
		return new Promise((resolve, reject) => {
			api.client.patch(`/projects/${slug}`, { archived: !archived })
				.then(resolve)
				.catch((err) => {
					reject({
						code: ERROR_PROJECT_UNKNOWN,
						message: err?.response?.data?.error?.message || 'Failed to update the project',
						innerError: err?.response?.data || err
					});
				});
		});
	},

	deleteProject({ slug }) {
		return api.client.delete(`/projects/${slug}`);
	},

	updateProject(id, data) {
		return new Promise((resolve, reject) => {
			api.client.get(`/projects/${id}`)
				.then((res) => {
					api.client.patch(`/projects/${res.data.slug}`, data)
						.then(resolve)
						.catch((err) => {
							reject({
								code: ERROR_PROJECT_UNKNOWN,
								message: 'Failed to update project',
								innerError: err
							});
						});
				})
				.catch((err) => {
					return reject({
						code: ERROR_PROJECT_UNKNOWN,
						message: 'Failed to update project',
						innerError: err
					});
				});
		});
	},

	validateProject(project) {
		return ValidationService.validate(project, PROJECT_CONSTRAINTS);
	},

	getBillingRates({ projectId }) {
		return api.client.get(`/billingrates?project=${projectId}`)
			.then((res) => res.data[0] || res.data);
	},

	$poster() {
		let hasLogo = (this.$links && this.$links.logo && !this.$links.logo.match(/Files$/));
		return hasLogo ? this.$links.logo : '/img/placeholder/poster.gif';
	},

	getProjectById({ id }) {
		return api.client.get(`/projects?id=${id}`)
			.then((res) => {
				return {
					...res?.data[0] || res?.data || {},
					$poster: this.$poster
				};
			});
	},

	getAllProjects(params) {
		if(params) {
			return api.graphql.request(PROJECTS_QUERY, params).then((data) => data?.projects);
		}
		return api.client.get(buildURL('/projects', params))
			.then((res) => res?.data);
	},

	getAllProjectsShort(params) {
		if(params) {
			return api.graphql.request(PROJECTS_QUERY_SHORT, params).then((data) => data.projects);
		}
		return api.client.get('/projects');
	},

	getProjectsBySlug( { slug } ) {
		return api.client.get(`/projects?slug=${slug}`)
			.then((res) => res.data);
	},

	searchProjects({ name }) {
		return api.client.get(`/projects?search=${name}`)
			.then((res) => res.data);
	}
};

export default ProjectService;
