import parseSRT from 'parse-srt';
import stripTags from './strip-tags-service';
import SubtitleService from './subtitle-service';
import TimeFormatService from './time-format-service';
import _ from 'lodash';

const SRTService = {
	parse: (data) => {
		// check for malformed times
		const lines = data.split('\n');
		lines.forEach((line) => {
			if(line.includes(' --> ')) {
				const range = line.split(' --> ');
				if(!TimeFormatService.isValidTime(range[0]) || !TimeFormatService.isValidTime(range[1])) {
					throw new Error('Malformed SRT file');
				}
			}
		});

		return parseSRT(data)
			.map(({ start, end, text }) => ({
				startTime: SubtitleService.offsetToTimecode(start),
				stopTime: SubtitleService.offsetToTimecode(end),
				text: text.replace(/<br \/>/, '\n')
			}));
	},

	export: (lines) => {
		let result = '';
		let i = 1;

		lines.forEach((line) => {
			if(line.text === undefined) {
				return;
			}

			line.text = line.text.replace(/&nbsp;/g, ' ');

			// Replace <div> and <br> tags with newlines. These tags may accidentally be stored in the translation
			let text = _.unescape(line.text.replace(/<br>|<\/div>/g, '\n'));

			// Remove any tags that may have made it into the translation (div tags seem to be the main problem).
			// <b> <i> <u> are the only two supported tags currently
			text = stripTags(text, '<b><i><u>');

			result += (i++) + '\n';
			result += TimeFormatService.startTime(line) + ' --> ' + TimeFormatService.stopTime(line) + '\n';
			result += text + '\n\n';
		});
		return result;
	}
};

export default SRTService;
