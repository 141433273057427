import _ from 'lodash';
import _get from 'lodash.get';
import { createAction } from 'redux-actions';
import { put, call, takeLatest } from 'redux-saga/effects';
import { RESET_STORES } from './session';
import { handleError } from './common';
import PreviewService from '@/modules/services/preview-service';

import {
	actions as sharesActions, fetchShareById
} from './shares';

export const STORE_NAME = 'previewsStore';

export function *fetchPreviews({ payload: { assetId, shareId, type } }) {
	let params = { asset: assetId };
	if(type) {
		params.type = type;
	}
	try {
		yield put(internalActions.getAssetPreviewsRequest(assetId));
		let previews = yield PreviewService.getAllPreviews(params);

		// If shareId is provided, then we need to filter out any previews
		// that don't belong to that share depending on share's languages
		if(shareId) {
			let share = yield call(fetchShareById, sharesActions.getShareById(shareId));
			const shareLanguages = _get(share, 'languages', []);

			previews = _.filter(previews, (preview) => {
				return _.contains(shareLanguages, preview.language);
			});
		}

		yield put(internalActions.getAssetPreviewsSuccess({ assetId, previews }));
		return previews;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getAssetPreviewsFailure({ id: assetId, error: err }));
		return err;
	}
}

export const FETCH_PREVIEWS = 'previews.fetch';
export const FETCH_PREVIEWS_REQUEST = 'previews.fetch.request';
export const FETCH_PREVIEWS_SUCCESS = 'previews.fetch.success';
export const FETCH_PREVIEWS_FAILURE = 'previews.fetch.failure';

export const INITIAL_STATE = {
	previews: {},
	previewsByAsset: {}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case RESET_STORES:
			return INITIAL_STATE;

		case FETCH_PREVIEWS_REQUEST: {
			let assetId = action.payload;

			return {
				...state,
				previewsByAsset: {
					...state.previewsByAsset,
					[assetId]: {
						// Place lastLoadedAt: null first so that its overwritten if it already exists
						lastLoadedAt: null,
						...state.previewsByAsset[assetId],
						isLoading: true
					}
				}
			};
		}

		case FETCH_PREVIEWS_SUCCESS: {
			let { assetId, previews } = action.payload;

			let now = new Date();
			let previewIds = _.map(previews, '_id');
			let transformedPreviews = _.reduce(previews, (result, preview) => {
				result[preview._id] = {
					isLoading: false,
					lastLoadedAt: now,
					data: preview
				};

				return result;
			}, {});

			return {
				...state,
				previews: {
					...state.previews,
					...transformedPreviews
				},
				previewsByAsset: {
					...state.previewsByAsset,
					[assetId]: {
						isLoading: false,
						lastLoadedAt: now,
						previews: previewIds
					}
				}
			};
		}

		case FETCH_PREVIEWS_FAILURE: {
			let { assetId } = action.payload;

			return {
				...state,
				previewsByAsset: {
					[assetId]: {
						...state.previewsByAsset[assetId],
						isLoading: false
					}
				}
			};
		}

		default:
			return state;
	}
}

export const actions = {
	getAssetPreviews: createAction(FETCH_PREVIEWS)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	getAssetPreviewsRequest: createAction(FETCH_PREVIEWS_REQUEST),
	getAssetPreviewsSuccess: createAction(FETCH_PREVIEWS_SUCCESS),
	getAssetPreviewsFailure: createAction(FETCH_PREVIEWS_FAILURE)
};

export const selectors = {
	areAssetPreviewsLoading: createSelector(areAssetPreviewsLoading),
	areAssetPreviewsLoaded: createSelector(areAssetPreviewsLoaded),
	getAssetPreviewsLastLoadedAt: createSelector(getAssetPreviewsLastLoadedAt),
	getAssetPreviews: createSelector(getAssetPreviews)
};

function areAssetPreviewsLoading(state, assetId) {
	let assetContainer = state.previewsByAsset[assetId];
	if( !assetContainer ) {
		return false;
	}

	return assetContainer.isLoading;
}

function areAssetPreviewsLoaded(state, assetId) {
	let assetContainer = state.previewsByAsset[assetId];
	if( !assetContainer ) {
		return false;
	}

	return !!assetContainer.lastLoadedAt;
}

function getAssetPreviewsLastLoadedAt(state, assetId) {
	let assetContainer = state.previewsByAsset[assetId];
	if( !assetContainer ) {
		return null;
	}

	return assetContainer.lastLoadedAt;
}

function getAssetPreviews(state, assetId) {
	let assetContainer = state.previewsByAsset[assetId];
	if( !assetContainer ) {
		return [];
	}

	return _.map(assetContainer.previews, (previewId) => state.previews[previewId].data);
}

/**
 * Helper method that grabs our specific part of the global store, then passes it into the given selector.
 * This makes it so that each selector doesn't have to grab the sub-state itself, reducing code duplication.
 */
function createSelector(selector) {
	return function selectorWrapper(state, ...rest) {
		let previewsStore = state[STORE_NAME];
		return selector(previewsStore, ...rest);
	};
}

export function *watchPreviews() {
	yield takeLatest(FETCH_PREVIEWS, fetchPreviews);
}
