import * as Sentry from '@sentry/react';
import ToastService from '../services/toast-service';

const GLOBAL_ERROR_MSG = 'An unexpected error has occurred. '
	+ 'If you notice your application acting oddly, please refresh the page.';

export function handleGlobalError(exception, cause) {

	if(window.env !== 'development') {
		Sentry.captureException(exception);
	}

	ToastService.create('error', GLOBAL_ERROR_MSG, { duration: 10000 });

	// Cause is not always provided. Dynamically attach it if available
	let errorProps = [exception];
	if(cause) {
		errorProps.unshift(cause);
	}

	console.error(...errorProps);
}

export function captureError(e, message) {

	if(window.env !== 'development') {
		Sentry.captureException(e);
	}

	console.error(message, e);

	if(message) {
		ToastService.create('error', message, { toastId: 'capture-error' });
	}
	else if(typeof e === 'string') {
		ToastService.create('error', e, { toastId: 'capture-error' });
	}
	else if(typeof e.message === 'string') {
		ToastService.create('error', e.message, { toastId: 'capture-error' });
	}
}
