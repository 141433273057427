import _ from 'lodash';
import RouteService from './route-service';
import api, { buildURL } from './api-service';
import { captureError } from '../pixwel/exception-handler.factory';

export const GET_SHARE_QUEUE_QUERY = `
query getShareQueue {
	session {
		id
		shareQueue {
			id
			mediaType
		}
	}
}
`;

export const CREATE_SHARE_MUTATION = `
mutation createShare($input: ShareInput) {
	createShare(input: $input) {
		share {
			id
		}
	}
}
`;

export const UPDATE_SHARE_MUTATION = `
mutation updateShare($input: ShareInput) {
	updateShare(input: $input) {
		share {
			id
		}
	}
}
`;

export const UPDATE_SHARE_QUEUE_MUTATION = `
mutation updateShareQueue($input: UserInput) {
	updateSession(input: $input) {
		session {
			id
			emailNormal
			shareQueue {
				id
				name
				thumbnail
				creative
				project {
					slug
					name
				}
				assetType {
					category
				}
			}
		}
	}
}
`;

const ShareService = {

	getShareQueue(id) {
		return api.graphql.request(GET_SHARE_QUEUE_QUERY, { id });
	},

	initializeShare(data) {
		const { id, shareQueue: assets } = data.session;

		return {
			assets,
			assetCategory: assets[0]?.assetType.category,
			from: id,
			to: [],
			groups: [],
			notes: '',
			feedback: {
				enabled: assets?.every((asset) => asset.creative === true)
			},
			tags: [],
			advancedTags: null
		};
	},

	createShare(share) {
		const assetUrls = share.assets.reduce((result, asset) => {
			result[asset.id] = RouteService.stateHref(
				'asset',
				{ projectId: asset.project.id, assetId: asset.id },
				{ absolute: true }
			);
			return result;
		}, {});

		// add 'txtd' to online tag
		const tags = share.tags.map((tag) => {
			if( tag[0] === 'online' ) {
				tag.push('txtd');
			}
			return tag;
		});

		const urls = {
			...assetUrls,
			playlist: `${window.location.origin}/shares/:share?index=0`,
			invite: `${window.location.origin}/register/:code`
		};

		const params = {
			input: {
				...share,
				to: share.to.map((to) => typeof to === 'string' ? to : to.id),
				groups: share.groups.map((group) => group.id),
				assets: share.assets.map((asset) => asset.id),
				tags,
				urls: _.transform(urls, (result, url, key) => {
					result[key] = decodeURIComponent(url);
				}),
				languages: share?.languages?.map((language) => language.value) ?? []
			}
		};
		return api.graphql.request(CREATE_SHARE_MUTATION, params);
	},

	updateShare(share) {
		// add 'txtd' to online tag (if missing)
		const tags = share.tags.map((tag) => {
			if( tag[0] === 'online' && !tag.includes('txtd') ) {
				tag.push('txtd');
			}
			return tag;
		});

		const params = {
			input: {
				id: share.id,
				to: share.to.map((to) => typeof to === 'string' ? to : to.id),
				groups: share.groups.map((group) => group.id),
				assets: share.assets.map((asset) => asset.id),
				notes: share.notes,
				feedback: share.feedback,
				tags
			}
		};
		return api.graphql.request(UPDATE_SHARE_MUTATION, params);
	},

	updateShareQueue(id, assets) {
		const shareQueue = assets.map((asset) => asset.id);

		return api.graphql.request(UPDATE_SHARE_QUEUE_MUTATION, {
			input: { id, shareQueue }
		});
	},

	mapRecipients(recipients) {
		const groupedRecipients = _.groupBy(recipients, (contact) => {
			if( typeof contact === 'string' ) {
				return 'to';
			}
			return 'emailNormal' in contact ? 'to' : 'groups';
		});

		return {
			to: groupedRecipients?.to ?? [],
			groups: groupedRecipients?.groups ?? []
		};
	},

	validateShare(share, isDownloadEnabled, isAdmin) {
		// validate recipients
		if( !share.to.length && !share.groups.length ) {
			return 'Select recipients';
		}

		if( !share.languages && !share.id && !isAdmin ) {
			return 'Select languages';
		}

		// validate download access
		if( isDownloadEnabled ) {
			let downloadInvalid;

			if( share.tags.length ) {
				downloadInvalid = share.tags.some(
					(arr) => arr.length === 1 && arr[0] !== 'online'
				);
			}
			else {
				downloadInvalid = true;
			}

			if( downloadInvalid ) {
				return 'Download access invalid';
			}
		}

		// validate voting
		if( share.feedback.enabled && !share.feedback.expires ) {
			return 'Select voting expiration';
		}

		return null;
	},

	checkTypesMatch(assets, queue) {
		const typesMatch = assets.every(
			(asset) => asset.mediaType === assets[0].mediaType
		);
		const typesAllowed =
			queue.length > 0
				? queue[0].mediaType === assets[0].mediaType
				: true;

		return typesMatch && typesAllowed;
	},

	isFeedbackEnabled(share) {
		return share.feedback && share.feedback.enabled;
	},

	isVotingClosed(share) {
		if( !share.feedback || !share.feedback.enabled ) {
			return;
		}

		return share.feedback.expires <= Math.floor(Date.now() / 1000);
	},

	isShareExpired(share) {
		return !!share.expired;
	},

	isVoteSubmitted(share) {
		return share.assets.every((asset) => {
			if( !asset.$feedback.vote ) {
				return false;
			}

			return asset.$feedback.vote.submitted;
		});
	},

	expireVoting(share) {
		if(!share.feedback || !share.feedback.enabled) {
			throw new Error('only shares with feedback enabled can have an expiration value');
		}
		share.feedback.expires = Math.floor(Date.now() / 1000);

		return api.client.patch(`Shares/${share._id}`, {
			feedback: share.feedback
		}).then((res) => res.data);
	},

	expireShare(share) {
		return api.client.patch(`/Shares/${share._id}`, {
			expired: true
		}).then((res) => res.data);
	},

	getFeedback(assets) {
		return _(assets)
			.map((asset) => {
				return {
					asset: asset._id,
					...asset.$feedback.vote,
					$links: {
						self: asset.$links.assetFeedback
					}
				};
			})
			.indexBy('asset')
			.value();
	},

	getShareById(shareId) {
		return api.client.get(buildURL('/shares', {
			id: shareId
		}))
			.then((res) => {
				return res.data[0] || res.data;
			});
	},

	getSharesByQueryAndPage({ query, start, end }) {
		let params = {
			user: query.user,
			order: {
				_id: query.order._id
			},
			created: {
				from: query.created?.from
			}
		};
		if(query.from) {
			params.from = query.from;
		}
		else {
			params.to = true;
		}

		return api.client.get(
			buildURL('/shares', params),
			{ headers: { 'X-Range': `resources=${start}-${end}` } }
		);
	},

	getSharesByQuery(query) {
		return api.client.get(buildURL('/shares', query))
			.then((res) => res.data);
	},

	submitFeedback(allFeedback) {
		const unvotedFeedback = Object.values(allFeedback);

		return unvotedFeedback.reduce((chain, feedback) => {
			return chain.then(() => {
				const { comment, vote, wouldOrder } = feedback;
				return api.client.patch(`/Assetfeedback/${feedback._id}`, {
					comment,
					vote,
					wouldOrder,
					submitted: true
				});
			}).catch((err) => {
				let errorMessage = 'An error occurred saving feedback';
				if( err.data?.message?.includes('Forbidden') ) {
					errorMessage = 'You do not have permission to change your vote. Voting is closed or your permission has been removed.';
				}
				captureError(err, errorMessage);
			});
		}, Promise.resolve());
	}
};

export default ShareService;
