import _ from 'lodash';

const ErrorService = {
	getErrorMessage: (errors) => {
		const error = errors[0];

		if( error.extensions?.code === 'BAD_USER_INPUT' ) {
			const { invalidFields } = error.extensions;
			const messages = Object.keys(invalidFields).map((field) => invalidFields[field]);
			if( messages.length ) {
				return _.isArray(messages[0]) ? messages[0][0] : messages[0];
			}
		}
		if( error.message !== '' ) {
			return error.message;
		}
		return error.extensions?.code;
	}
};

export default ErrorService;
