import _ from 'lodash';
import isError from 'lodash.iserror';
import { createAction } from 'redux-actions';
import { call, put, fork, join, takeLatest } from 'redux-saga/effects';
import { createSelector, handleError } from './common';

import {
	actions as projectsActions, fetchProjectBySlug,
	selectors as projectsSelectors
} from './projects';
import {
	actions as assetsActions, fetchProjectAssets,
	selectors as assetsSelectors
} from './assets';
import {
	actions as assetTypesActions, fetchAssetTypes,
	selectors as assetTypesSelectors
} from './asset-types';
import {
	actions as sessionActions, fetchSession,
	selectors as sessionSelectors
} from './session';

export const STORE_NAME = 'projectPageStore';

function *loadPageData({ payload: projectSlug }) {
	try {
		yield put(internalActions.loadPageRequest());

		// Fork to run separately (non-blocking)
		let assetTypesTask = yield fork(fetchAssetTypes, assetTypesActions.getAssetTypes());
		let sessionTask = yield fork(fetchSession, sessionActions.getSession());

		// Run sequentially
		let project = yield call(fetchProjectBySlug, projectsActions.getProjectBySlug(projectSlug));
		if( isError(project) || project === null ) {
			throw new Error('Failed to load project');
		}

		let assets = yield call(fetchProjectAssets, assetsActions.getProjectAssets({ projectId: project._id }));
		if( isError(assets) ) {
			throw new Error('Failed to load project\'s assets');
		}

		// Join forked tasks back in
		let assetTypes = yield join(assetTypesTask);
		if( isError(assetTypes) ) {
			throw new Error('Failed to load page resources');
		}

		let session = yield join(sessionTask);
		if( isError(session) ) {
			throw new Error('Failed to load page resources');
		}

		yield put(internalActions.loadPageSuccess());
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadPageFailure(err));
		return err;
	}
}

export const LOAD = 'project-page.load';
export const LOAD_REQUEST = 'project-page.load.request';
export const LOAD_SUCCESS = 'project-page.load.success';
export const LOAD_FAILURE = 'project-page.load.failure';

export const UPDATE_SAVED_FILTERS = 'project-page.saved-filters.update';
export const UPDATE_SAVED_PAGE_PREFERENCES = 'project-page.saved-page-preferences.update';

export const INITIAL_STATE = {
	isLoading: false,
	loadFailed: false,
	savedFiltersByProject: {},
	savedPagePreferences: null
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case LOAD_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case LOAD_SUCCESS:
			return {
				...state,
				isLoading: false,
				loadFailed: false
			};

		case LOAD_FAILURE:
			return {
				...state,
				isLoading: false,
				loadFailed: true
			};

		case UPDATE_SAVED_FILTERS: {
			let { projectId, filters } = action.payload;

			// Clone the object so that we don't get reference side effects
			let savedFilters = _.cloneDeep(filters);

			return {
				...state,
				savedFiltersByProject: {
					...state.savedFiltersByProject,
					[projectId]: savedFilters
				}
			};
		}

		case UPDATE_SAVED_PAGE_PREFERENCES: {
			// Clone the object so that we don't get reference side effects
			let savedPagePreferences = _.cloneDeep(action.payload);

			return {
				...state,
				savedPagePreferences
			};
		}

		default:
			return state;
	}
}

export const actions = {
	loadPageData: createAction(LOAD),
	updateSavedFilters: createAction(UPDATE_SAVED_FILTERS),
	updateSavedPagePreferences: createAction(UPDATE_SAVED_PAGE_PREFERENCES)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	loadPageRequest: createAction(LOAD_REQUEST),
	loadPageSuccess: createAction(LOAD_SUCCESS),
	loadPageFailure: createAction(LOAD_FAILURE)
};

export const selectors = {
	isLoading,
	isReady,
	needsRefresh,
	isFailedLoad,
	getSavedFilters: createSelector(STORE_NAME, getSavedFilters),
	getSavedPagePreferences: createSelector(STORE_NAME, getSavedPagePreferences)
};

function isLoading(state) {
	let { projectPageStore } = state;

	return projectPageStore.isLoading;
}

function isFailedLoad(state) {
	let { projectPageStore } = state;

	return projectPageStore.loadFailed;
}

function isReady(state, projectId) {
	let isProjectLoaded = projectsSelectors.isLoaded(state, projectId);
	let areAssetsLoaded = assetsSelectors.areProjectAssetsLoaded(state, projectId);
	let areAssetTypesLoaded = assetTypesSelectors.isLoaded(state);
	let isSessionLoaded = sessionSelectors.isLoaded(state);

	if( !isProjectLoaded || !areAssetsLoaded || !areAssetTypesLoaded || !isSessionLoaded ) {
		return false;
	}

	let projectDataLastLoadedAt = projectsSelectors.getLastLoadedAt(state, projectId);
	let assetsDataLastLoadedAt = assetsSelectors.getProjectAssetsLastLoadedAt(state, projectId);
	let assetTypesDataLastLoadedAt = assetTypesSelectors.getLastLoadedAt(state);
	let sessionDataLastLoadedAt = sessionSelectors.getLastLoadedAt(state);

	let now = new Date();
	let isProjectDataStale = now - projectDataLastLoadedAt > 7200000;
	let isAssetsDataStale = now - assetsDataLastLoadedAt > 7200000;
	let isAssetTypesDataStale = now - assetTypesDataLastLoadedAt > 7200000;
	let isSessionDataStale = now - sessionDataLastLoadedAt > 7200000;

	// If the data has been loaded within the last past two hours, its ready
	return !isProjectDataStale && !isAssetsDataStale && !isAssetTypesDataStale && !isSessionDataStale;
}

function needsRefresh(state, projectId) {
	let isProjectLoaded = projectsSelectors.isLoaded(state, projectId);
	let areAssetsLoaded = assetsSelectors.areProjectAssetsLoaded(state, projectId);
	let areAssetTypesLoaded = assetTypesSelectors.isLoaded(state);
	let isSessionLoaded = sessionSelectors.isLoaded(state);

	if( !isProjectLoaded || !areAssetsLoaded || !areAssetTypesLoaded || isSessionLoaded) {
		return true;
	}

	let projectDataLastLoadedAt = projectsSelectors.getLastLoadedAt(state, projectId);
	let assetsDataLastLoadedAt = assetsSelectors.getProjectAssetsLastLoadedAt(state, projectId);
	let assetTypesDataLastLoadedAt = assetTypesSelectors.getLastLoadedAt(state);
	let sessionDataLastLoadedAt = sessionSelectors.getLastLoadedAt(state);

	let now = new Date();
	let isProjectDataStale = now - projectDataLastLoadedAt > 1800000;
	let isAssetsDataStale = now - assetsDataLastLoadedAt > 1800000;
	let isAssetTypesDataStale = now - assetTypesDataLastLoadedAt > 1800000;
	let isSessionDataStale = now - sessionDataLastLoadedAt > 1800000;

	// If the data has been loaded within the last past two hours, its ready
	return isProjectDataStale || isAssetsDataStale || isAssetTypesDataStale || isSessionDataStale;
}

function getSavedFilters(state, projectId) {
	let savedFilters = state.savedFiltersByProject[projectId];
	if( savedFilters ) {
		// Return a copy of the filters to avoid state side effects
		savedFilters = _.cloneDeep(savedFilters);
	}

	return savedFilters;
}

function getSavedPagePreferences(state) {
	// Return a copy of the filters to avoid state side effects
	return _.cloneDeep(state.savedPagePreferences);
}

export function *watchProjectPage() {
	yield takeLatest(LOAD, loadPageData);
}
