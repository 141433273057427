import _ from 'lodash';
import { createAction } from 'redux-actions';
import { put, takeLatest, takeEvery } from 'redux-saga/effects';
import { RESET_STORES } from './session';
import { handleError } from './common';
import ProjectService from '@/modules/services/project-service';

export const STORE_NAME = 'projectsStore';

export function *fetchProjects({ payload }) {
	try {
		yield put(internalActions.getProjectsRequest());
		let projects = yield ProjectService.getAllProjects(payload);
		yield put(internalActions.getProjectsSuccess(projects));
		return projects;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getProjectsFailure(err));
		return err;
	}
}

export function *fetchProjectBySlug({ payload: slug }) {
	try {
		yield put(internalActions.getProjectBySlugRequest(slug));

		let project = yield ProjectService.getProjectsBySlug({ slug });
		if( project === null ) {
			throw new Error('Failed to load project');
		}

		yield put(internalActions.getProjectBySlugSuccess(project));
		return project;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getProjectBySlugFailure({ slug, err }));
		return err;
	}
}

export function *fetchProjectById({ payload: id }) {
	try {
		yield put(internalActions.getProjectByIdRequest(id));

		let project = yield ProjectService.getProjectById({ id });
		if( project === null ) {
			throw new Error('Failed to load project');
		}

		yield put(internalActions.getProjectByIdSuccess(project));
		return project;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getProjectByIdFailure({ id, err }));
		return err;
	}
}

export function *fetchProjectsById({ payload: projectIds }) {
	try {
		yield put(internalActions.fetchProjectsByIdRequest(projectIds));

		let projectPromises = _.map(projectIds, (projectId) => ProjectService.getProjectById({ id: projectId }));
		const projects = yield Promise.all(projectPromises);

		yield put(internalActions.fetchProjectsByIdSuccess(projects));
		return projects;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.fetchProjectsByIdFailure({ projectIds, err }));
		return err;
	}
}

export const FETCH_PROJECTS = 'projects.fetch';
export const FETCH_PROJECTS_REQUEST = 'projects.fetch.request';
export const FETCH_PROJECTS_SUCCESS = 'projects.fetch.success';
export const FETCH_PROJECTS_FAILURE = 'projects.fetch.failure';

export const FETCH_PROJECT_BY_SLUG = 'project-by-slug.fetch';
export const FETCH_PROJECT_BY_SLUG_REQUEST = 'project-by-slug.fetch.request';
export const FETCH_PROJECT_BY_SLUG_SUCCESS = 'project-by-slug.fetch.success';
export const FETCH_PROJECT_BY_SLUG_FAILURE = 'project-by-slug.fetch.failure';

export const FETCH_PROJECT_BY_ID = 'project-by-id.fetch';
export const FETCH_PROJECT_BY_ID_REQUEST = 'project-by-id.fetch.request';
export const FETCH_PROJECT_BY_ID_SUCCESS = 'project-by-id.fetch.success';
export const FETCH_PROJECT_BY_ID_FAILURE = 'project-by-id.fetch.failure';

export const FETCH_PROJECTS_BY_ID = 'projects-by-id.fetch';
export const FETCH_PROJECTS_BY_ID_REQUEST = 'projects-by-id.fetch.request';
export const FETCH_PROJECTS_BY_ID_SUCCESS = 'projects-by-id.fetch.success';
export const FETCH_PROJECTS_BY_ID_FAILURE = 'projects-by-id.fetch.failure';

export const UPDATE_PROJECT = 'projects.update.manual';

export const INITIAL_STATE = {
	isLoading: false,
	lastLoadedAt: null,
	projects: {},
	slugToId: {},
	loadingSlugs: []
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case RESET_STORES:
			return INITIAL_STATE;

		case FETCH_PROJECTS_REQUEST:
			return {
				lastLoadedAt: null,
				...state,
				isLoading: true
			};

		case FETCH_PROJECTS_SUCCESS: {
			let now = new Date();
			let { projects, slugToId } = _.reduce(action.payload, (result, project) => {
				result.projects[project._id] = {
					isLoading: false,
					lastLoadedAt: now,
					data: project
				};

				result.slugToId[project.slug] = project._id;

				return result;
			}, { projects: {}, slugToId: {} });

			return {
				...state,
				isLoading: false,
				lastLoadedAt: now,
				projects: {
					...projects
				},
				slugToId: {
					...slugToId
				}
			};
		}

		case FETCH_PROJECTS_FAILURE:
			// TODO: Store error?
			return {
				...state,
				isLoading: false
			};

		case FETCH_PROJECT_BY_SLUG_REQUEST: {
			let projectSlug = action.payload;
			let project = getProjectBySlug(state, projectSlug);
			let loadingSlugs = [...state.loadingSlugs];
			let projects = {
				...state.projects
			};

			// If we can't find the project by slug, we don't know the id. Place the slug in the loadingSlugs array
			if( !project && loadingSlugs.indexOf(projectSlug) === -1 ) {
				loadingSlugs.push(projectSlug);
			}

			// If we know the project and thus project id for the given slug, just update the project by id
			if( project ) {
				projects[project._id].isLoading = true;
			}

			return {
				...state,
				loadingSlugs,
				projects
			};
		}

		case FETCH_PROJECT_BY_SLUG_SUCCESS: {
			let project = action.payload;
			let loadingSlugs = _.reject(state.loadingSlugs, project.slug);

			return {
				...state,
				isLoading: false,
				loadingSlugs,
				projects: {
					...state.projects,
					[project._id]: {
						isLoading: false,
						lastLoadedAt: new Date(),
						data: project
					}
				},
				slugToId: {
					...state.slugToId,
					[project.slug]: project._id
				}
			};
		}

		case FETCH_PROJECT_BY_SLUG_FAILURE: {
			let { slug } = action.payload;
			let loadingSlugs = _.reject(state.loadingSlugs, slug);

			return {
				...state,
				loadingSlugs,
				isLoading: false
			};
		}

		case FETCH_PROJECT_BY_ID_REQUEST: {
			let projectId = action.payload;

			return {
				...state,
				projects: {
					...state.projects,
					[projectId]: {
						lastLoadedAt: null,
						...state.projects[projectId],
						isLoading: true
					}
				}
			};
		}

		case FETCH_PROJECT_BY_ID_SUCCESS: {
			let project = action.payload;

			return {
				...state,
				projects: {
					...state.projects,
					[project._id]: {
						isLoading: false,
						lastLoadedAt: new Date(),
						data: project
					}
				},
				slugToId: {
					...state.slugToId,
					[project.slug]: project._id
				}
			};
		}

		case FETCH_PROJECT_BY_ID_FAILURE: {
			let { id } = action.payload;

			return {
				...state,
				projects: {
					...state.projects,
					[id]: {
						...state.projects[id],
						isLoading: false
					}
				}
			};
		}

		case FETCH_PROJECTS_BY_ID_REQUEST: {
			let projectIDs = action.payload;
			let updatedProjects = _.reduce(projectIDs, (result, projectID) => {
				result[projectID] = {
					lastLoadedAt: null,
					...state.projects[projectID],
					isLoading: true
				};

				return result;
			}, {});

			return {
				...state,
				projects: {
					...state.projects,
					...updatedProjects
				}
			};
		}

		case FETCH_PROJECTS_BY_ID_SUCCESS: {
			let projects = action.payload;
			let updatedProjects = _.reduce(projects, (result, project) => {
				result.projects[project._id] = {
					isLoading: false,
					lastLoadedAt: new Date(),
					data: project
				};

				result.slugToId[project._id] = project.slug;

				return result;
			}, { projects: {}, slugToId: {} });

			return {
				...state,
				projects: {
					...state.projects,
					...updatedProjects.projects
				},
				slugToId: {
					...state.slugToId,
					...updatedProjects.slugToId
				}
			};
		}

		case FETCH_PROJECTS_BY_ID_FAILURE: {
			let { projectIds } = action.payload;
			let updatedProjects = _.reduce(projectIds, (result, projectId) => {
				result[projectId] = {
					isLoading: false
				};

				return result;
			}, {});

			return {
				...state,
				projects: {
					...state.projects,
					...updatedProjects
				}
			};
		}

		case UPDATE_PROJECT: {
			let project = action.payload;

			return {
				...state,
				projects: {
					...state.projects,
					[project._id]: {
						...state.projects[project._id],
						data: project
					}
				},
				slugToId: {
					...state.slugToId,
					[project.slug]: project._id
				}
			};
		}

		default:
			return state;
	}
}

export const actions = {
	getProjects: createAction(FETCH_PROJECTS),
	getProjectBySlug: createAction(FETCH_PROJECT_BY_SLUG),
	getProjectById: createAction(FETCH_PROJECT_BY_ID),
	fetchProjectsById: createAction(FETCH_PROJECTS_BY_ID),
	updateProject: createAction(UPDATE_PROJECT)
};

/**
 * Actions that should only be invoked internally
 */
export const internalActions = {
	getProjectsRequest: createAction(FETCH_PROJECTS_REQUEST),
	getProjectsSuccess: createAction(FETCH_PROJECTS_SUCCESS),
	getProjectsFailure: createAction(FETCH_PROJECTS_FAILURE),
	getProjectBySlugRequest: createAction(FETCH_PROJECT_BY_SLUG_REQUEST),
	getProjectBySlugSuccess: createAction(FETCH_PROJECT_BY_SLUG_SUCCESS),
	getProjectBySlugFailure: createAction(FETCH_PROJECT_BY_SLUG_FAILURE),
	getProjectByIdRequest: createAction(FETCH_PROJECT_BY_ID_REQUEST),
	getProjectByIdSuccess: createAction(FETCH_PROJECT_BY_ID_SUCCESS),
	getProjectByIdFailure: createAction(FETCH_PROJECT_BY_ID_FAILURE),
	fetchProjectsByIdRequest: createAction(FETCH_PROJECTS_BY_ID_REQUEST),
	fetchProjectsByIdSuccess: createAction(FETCH_PROJECTS_BY_ID_SUCCESS),
	fetchProjectsByIdFailure: createAction(FETCH_PROJECTS_BY_ID_FAILURE)
};

export const selectors = {
	isLoading: createSelector(isLoading),
	isLoadingBySlug: createSelector(isLoadingBySlug),
	isLoaded: createSelector(isLoaded),
	isLoadedBySlug: createSelector(isLoadedBySlug),
	getLastLoadedAt: createSelector(getLastLoadedAt),
	getLastLoadedAtBySlug: createSelector(getLastLoadedAtBySlug),
	getProjects: createSelector(getProjects),
	getProjectById: createSelector(getProjectById),
	getProjectBySlug: createSelector(getProjectBySlug)
};

function isLoading(state, id=null) {
	// If we're loading the whole store, any individual project is considered loading as well
	if( state.isLoading ) {
		return true;
	}

	// If no id was provided, we already checked if all projects were loading and they aren't so return false;
	if( !id ) {
		return false;
	}

	const projectContainer = getProjectContainer(state, id);
	if( !projectContainer ) {
		return false;
	}

	return projectContainer.isLoading;
}

function isLoadingBySlug(state, slug) {
	let projectId = state.slugToId[slug];
	if( !projectId ) {
		return state.loadingSlugs.indexOf(slug) !== -1;
	}

	return isLoading(state, projectId);
}

function isLoaded(state, id=null) {
	if( !id ) {
		return !!state.lastLoadedAt;
	}

	const projectContainer = getProjectContainer(state, id);
	if( !projectContainer ) {
		return false;
	}

	return !!projectContainer.lastLoadedAt;
}

function isLoadedBySlug(state, slug) {
	let projectId = state.slugToId[slug];
	if( !projectId ) {
		return false;
	}

	return isLoaded(state, projectId);
}

function getProjects(state) {
	return _.values(state.projects)
		.filter((wrapper) => wrapper.data)
		.map((wrapper) => wrapper.data);
}

function getProjectById(state, id) {
	let projectContainer = getProjectContainer(state, id);
	return projectContainer ? projectContainer.data : null;
}

function getProjectBySlug(state, slug) {
	let projectId = state.slugToId[slug];
	if( !projectId ) {
		return null;
	}

	return getProjectById(state, projectId);
}

function getLastLoadedAt(state, id) {
	if( !id ) {
		return state.lastLoadedAt;
	}

	let projectContainer = getProjectContainer(state, id);

	return projectContainer ? projectContainer.lastLoadedAt : null;
}

function getLastLoadedAtBySlug(state, slug) {
	let projectId = state.slugToId[slug];
	if( !projectId ) {
		return null;
	}

	return getLastLoadedAt(state, projectId);
}

function getProjectContainer(state, id) {
	return state.projects[id];
}

/**
 * Helper method that grabs our specific part of the global store, then passes it into the given selector.
 * This makes it so that each selector doesn't have to grab the sub-state itself, reducing code duplication.
 */
function createSelector(selector) {
	return function selectorWrapper(state, ...rest) {
		let projectsStore = state[STORE_NAME];
		return selector(projectsStore, ...rest);
	};
}

export function *watchProjects() {
	yield takeLatest(FETCH_PROJECTS, fetchProjects);
	yield takeEvery(FETCH_PROJECT_BY_SLUG, fetchProjectBySlug);
	yield takeEvery(FETCH_PROJECT_BY_ID, fetchProjectById);
	yield takeEvery(FETCH_PROJECTS_BY_ID, fetchProjectsById);
}
