"use client";

import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import LoadError from "@/modules/components/global/load-error";

export default function Error({ error }) {
	useEffect(() => {
		Sentry.captureException(error);
	}, [error]);

	return (
		<div className="flex flex-col h-screen justify-center items-center">
			<LoadError />
		</div>
	);
}
