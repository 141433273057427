import { createAction } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import RouteService from '../modules/services/route-service';
import { createSelector, handleError } from './common';
import LocaleService from '@/modules/services/locale-service';

export const STORE_NAME = 'localeStore';

function *changeLanguage({ payload: language }) {
	try {
		yield put(internalActions.changeLanguageRequest(language));

		let lang = yield LocaleService.loadLocale(language);
		yield put(internalActions.changeLanguageSuccess(lang));

		// We reload the state to make sure all our Locale components get re-rendered
		// Once we can hook up nested React components directly to Redux, this can be removed
		RouteService.reload();
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.changeLanguageFailure(err));
		return err;
	}
}

export const CHANGE_LANGUAGE = 'locale.change';
export const CHANGE_LANGUAGE_REQUEST = 'locale.change.request';
export const CHANGE_LANGUAGE_SUCCESS = 'locale.change.success';
export const CHANGE_LANGUAGE_FAILURE = 'locale.change.failure';

export const INITIAL_STATE = {
	isLoadingLanguage: false,
	failedLanguageLoad: false
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case CHANGE_LANGUAGE_REQUEST:
			return {
				...state,
				isLoadingLanguage: true,
				failedLanguageLoad: false
			};

		case CHANGE_LANGUAGE_SUCCESS:
			return {
				...state,
				isLoadingLanguage: false
			};

		case CHANGE_LANGUAGE_FAILURE:
			return {
				...state,
				isLoadingLanguage: false,
				failedLanguageLoad: true
			};

		default:
			return state;
	}
}

export const actions = {
	changeLanguage: createAction(CHANGE_LANGUAGE)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	changeLanguageRequest: createAction(CHANGE_LANGUAGE_REQUEST),
	changeLanguageSuccess: createAction(CHANGE_LANGUAGE_SUCCESS),
	changeLanguageFailure: createAction(CHANGE_LANGUAGE_FAILURE)
};

export const selectors = {
	isLanguageLoading: createSelector(STORE_NAME, isLanguageLoading),
	isLanguageLoadFailed: createSelector(STORE_NAME, isLanguageLoadFailed)
};

function isLanguageLoading(state) {
	return state.isLoadingLanguage;
}

function isLanguageLoadFailed(state) {
	return state.failedLanguageLoad;
}

export function *watchLocale() {
	yield takeLatest(CHANGE_LANGUAGE, changeLanguage);
}
