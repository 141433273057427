import _, { isArray } from 'lodash';
import _get from 'lodash.get';
import { createAction } from 'redux-actions';
import {
	put, race, takeLatest, takeEvery, call, cancelled, delay, take
} from 'redux-saga/effects';
import { RESET_STORES } from './session';
import { handleError } from './common';
import AssetService from '../modules/services/asset-service';

export const STORE_NAME = 'assetsStore';
const EXTENDED_KEYS = ['sources', 'canAutosub'];

export function *fetchProjectAssets({ payload }) {
	let { projectId } = payload;
	try {
		yield put(internalActions.getProjectAssetsRequest({ projectId }));
		const assets = yield AssetService.getProjectAssets(projectId);
		yield put(internalActions.getProjectAssetsSuccess({ projectId, assets }));
		return assets;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getProjectAssetsFailure({ projectId, err }));
		return err;
	}
}

export function *fetchSimilarAssets({ payload }) {
	let { type, project } = payload;
	try {
		yield put(internalActions.getSimilarAssetsRequest({ type }));
		const assets = yield AssetService.getSimilarAssets({ type, project });
		yield put(internalActions.getSimilarAssetsSuccess({ type, assets }));
		return assets;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getSimilarAssetsFailure({ type, err }));
		return err;
	}
}

export function *fetchAsset({ payload }) {
	let { assetId, options } = payload;
	try {
		yield put(internalActions.getAssetByIdRequest({ assetId, options }));
		const asset = yield AssetService.getAssetById(assetId, options);

		if(!asset || _.isEmpty(asset) || !asset.name || !asset.mediaType) {
			throw new Error('Oops... This asset does not exist anymore');
		}

		yield put(internalActions.getAssetByIdSuccess({ asset, options }));
		return asset;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getAssetByIdFailure({ assetId, err }));
		return err;
	}
}

export function *fetchAssets({ payload }) {
	let { assetIds, options } = payload;

	try {
		yield put(internalActions.getAssetsByIdRequest({ assetIds, options }));
		let assetPromises = _.map(assetIds, (assetId) => AssetService.getAssetById(assetId, options));
		const assets = yield Promise.all(assetPromises);

		yield put(internalActions.getAssetsByIdSuccess({ assets, options }));
		return assets;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getAssetsByIdFailure({ assetIds, err }));
		return err;
	}
}

export function *fetchRelatedAssets({ payload }) {
	let { assetId } = payload;

	try {
		yield put(internalActions.getRelatedAssetsRequest({ assetId }));
		const relatedAssets = yield AssetService.getRelatedAssets(assetId);
		yield put(internalActions.getRelatedAssetsSuccess({ assetId, assets: relatedAssets }));
		return relatedAssets;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getRelatedAssetsFailure({ assetId, err }));
		return err;
	}
}

function *backgroundDeleteAsset(assetId) {
	// If we receive an uniq asset, convert it to array (e.g: when we delete an asset from the asset page)
	if(!isArray(assetId)) {
		assetId = [assetId];
	}

	// First, we put assets in queue to be able to cancel it later if needed
	// Otherwise, assets which haven't started can't have an updated state in store and being correctly displayed
	for(let i in assetId) {
		yield put(internalActions.removeAssetQueue({ assetId: assetId[i] }));
	}

	try {
		for(let i in assetId) {
			yield put(internalActions.removeAssetRequest({ assetId: assetId[i] }));
			yield call(AssetService.deleteAsset, assetId[i]);
			yield put(internalActions.removeAssetSuccess({ assetId: assetId[i] }));
			yield delay(1000);
		}
		return;
	}
	catch(err) {
		if(yield cancelled()) {
			// If we cancel the operation, we need to end with last started operation
			yield put(internalActions.cancelAssetRemoval());
		}
		else {
			yield put(internalActions.removeAssetFailure({ assetId, err }));
		}
	}
}

export function *removeAsset({ payload }) {
	let { assetId } = payload;

	yield race({
		response: call(backgroundDeleteAsset, assetId),
		cancel: take(CANCEL_ASSET_REMOVAL)
	});
}

export const FETCH_PROJECT_ASSETS = 'project-assets.fetch';
export const FETCH_PROJECT_ASSETS_REQUEST = 'project-assets.fetch.request';
export const FETCH_PROJECT_ASSETS_SUCCESS = 'project-assets.fetch.success';
export const FETCH_PROJECT_ASSETS_FAILURE = 'project-assets.fetch.failure';

export const FETCH_SIMILAR_ASSETS = 'similar-assets.fetch';
export const FETCH_SIMILAR_ASSETS_REQUEST = 'similar-assets.fetch.request';
export const FETCH_SIMILAR_ASSETS_SUCCESS = 'similar-assets.fetch.success';
export const FETCH_SIMILAR_ASSETS_FAILURE = 'similar-assets.fetch.failure';

export const FETCH_ASSET = 'asset.fetch';
export const FETCH_ASSET_REQUEST = 'asset.fetch.request';
export const FETCH_ASSET_SUCCESS = 'asset.fetch.success';
export const FETCH_ASSET_FAILURE = 'asset.fetch.failure';

export const FETCH_ASSETS = 'assets.fetch';
export const FETCH_ASSETS_REQUEST = 'assets.fetch.request';
export const FETCH_ASSETS_SUCCESS = 'assets.fetch.success';
export const FETCH_ASSETS_FAILURE = 'assets.fetch.failure';

export const FETCH_RELATED_ASSETS = 'related-assets.fetch';
export const FETCH_RELATED_ASSETS_REQUEST = 'related-assets.fetch.request';
export const FETCH_RELATED_ASSETS_SUCCESS = 'related-assets.fetch.success';
export const FETCH_RELATED_ASSETS_FAILURE = 'related-assets.fetch.failure';

export const UPDATE_ASSET = 'asset.update.manual';

export const REMOVE_ASSET = 'asset.remove';
export const REMOVE_ASSET_QUEUE = 'asset.remove.queue';
export const REMOVE_ASSET_REQUEST = 'asset.remove.request';
export const REMOVE_ASSET_SUCCESS = 'asset.remove.success';
export const REMOVE_ASSET_FAILURE = 'asset.remove.failure';
export const CANCEL_ASSET_REMOVAL = 'asset.remove.cancel';

export const INITIAL_STATE = {
	assets: {},
	assetsByProject: {},
	similarAssets: {},
	error: undefined
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case RESET_STORES:
			return INITIAL_STATE;

		case FETCH_PROJECT_ASSETS_REQUEST: {
			let { projectId } = action.payload;

			return {
				...state,
				assetsByProject: {
					...state.assetsByProject,
					[projectId]: {
						// Place lastLoadedAt: null first so that its overwritten if it already exists
						lastLoadedAt: null,
						assets: [],
						...state.assetsByProject[projectId],
						isLoading: true
					}
				}
			};
		}

		case FETCH_PROJECT_ASSETS_SUCCESS: {
			let { projectId, assets } = action.payload;

			let now = new Date();
			let assetIds = _.map(assets, '_id');
			let transformedAssets = _.reduce(assets, (result, asset) => {
				let existingPermissions = _get(state, ['assets', asset._id, 'data', '$permissions'], {});

				// Merge existing data so that we don't lose extended permission data on a simple refresh
				_.merge(asset?.$permissions, _.pick(existingPermissions, EXTENDED_KEYS));

				result[asset._id] = {
					isLoading: false,
					lastLoadedAt: now,
					data: asset
				};

				return result;
			}, {});

			return {
				...state,
				assets: {
					...state.assets,
					...transformedAssets
				},
				assetsByProject: {
					...state.assetsByProject,
					[projectId]: {
						isLoading: false,
						lastLoadedAt: now,
						assets: assetIds
					}
				}
			};
		}

		case FETCH_PROJECT_ASSETS_FAILURE: {
			let { projectId } = action.payload;

			return {
				...state,
				assetsByProject: {
					[projectId]: {
						...state.assetsByProject[projectId],
						isLoading: false
					}
				}
			};
		}

		case FETCH_SIMILAR_ASSETS_REQUEST: {
			let { type } = action.payload;

			return {
				...state,
				similarAssets: {
					...state.similarAssets,
					[type]: {
						// Place lastLoadedAt: null first so that its overwritten if it already exists
						lastLoadedAt: null,
						assets: [],
						...state.similarAssets[type],
						isLoading: true
					}
				}
			};
		}

		case FETCH_SIMILAR_ASSETS_SUCCESS: {
			let { type, assets } = action.payload;

			let now = new Date();
			let assetIds = _.map(assets, '_id');
			let transformedAssets = _.reduce(assets, (result, asset) => {
				let existingPermissions = _get(state, ['assets', asset._id, 'data', '$permissions'], {});

				// Merge existing data so that we don't lose extended permission data on a simple refresh
				_.merge(asset?.$permissions, _.pick(existingPermissions, EXTENDED_KEYS));

				result[asset._id] = {
					isLoading: false,
					lastLoadedAt: now,
					data: asset
				};

				return result;
			}, {});

			return {
				...state,
				assets: {
					...state.assets,
					...transformedAssets
				},
				similarAssets: {
					...state.similarAssets,
					[type]: {
						isLoading: false,
						lastLoadedAt: now,
						assets: assetIds
					}
				}
			};
		}

		case FETCH_SIMILAR_ASSETS_FAILURE: {
			let { type } = action.payload;

			return {
				...state,
				similarAssets: {
					[type]: {
						...state.similarAssets[type],
						isLoading: false
					}
				}
			};
		}

		case FETCH_ASSET_REQUEST: {
			let { assetId } = action.payload;
			let existingContainer = state.assets[assetId] || {};

			return {
				...state,
				assets: {
					...state.assets,
					[assetId]: {
						lastLoadedAt: null,
						lastLoadedExtendedAt: null,
						data: null,
						...existingContainer,
						isLoading: true
					}
				}
			};
		}

		case FETCH_ASSET_SUCCESS: {
			let { asset, options } = action.payload;
			let now = new Date();
			let withExtended = _get(options, 'withExtended', false);
			let existingContainer = state.assets[asset._id];
			let existingPermissions = _get(existingContainer, 'data.$permissions', {});

			// If the current query does not include extended permissions, merge possible existing ones
			if( !withExtended ) {
				_.merge(asset?.$permissions, _.pick(existingPermissions, EXTENDED_KEYS));
			}

			return {
				...state,
				error: undefined,
				assets: {
					...state.assets,
					[asset._id]: {
						...state.assets[asset._id],
						isLoading: false,
						lastLoadedAt: now,
						lastLoadedExtendedAt: withExtended ? now : existingContainer?.lastLoadedExtendedAt,
						data: asset
					}
				}
			};
		}

		case FETCH_ASSET_FAILURE: {
			let { assetId, err } = action.payload;

			return {
				...state,
				error: err,
				assets: {
					...state.assets,
					[assetId]: {
						...state.assets[assetId],
						isLoading: false
					}
				}
			};
		}

		case FETCH_ASSETS_REQUEST: {
			let { assetIds } = action.payload;
			let assetContainers = _.reduce(assetIds, (result, assetId) => {
				result[assetId] = {
					lastLoadedAt: null,
					lastLoadedExtendedAt: null,
					...state.assets[assetId],
					isLoading: true
				};
				return result;
			}, {});

			return {
				...state,
				assets: {
					...state.assets,
					...assetContainers
				}
			};
		}

		case FETCH_ASSETS_SUCCESS: {
			let { assets, options } = action.payload;
			let extendAssets = {};
			let now = new Date();
			let withExtended = _get(options, 'withExtended', false);

			assets.forEach((asset) => {
				let existingContainer = state.assets[asset._id];
				let existingPermissions = _get(existingContainer, 'data.$permissions', {});

				// If the current query does not include extended permissions, merge possible existing ones
				if( !withExtended ) {
					_.merge(asset?.$permissions, _.pick(existingPermissions, EXTENDED_KEYS));
				}

				extendAssets[asset._id] = {
					isLoading: false,
					lastLoadedAt: now,
					lastLoadedExtendedAt: withExtended ? now : existingContainer?.lastLoadedExtendedAt,
					data: asset
				};
			});

			return {
				...state,
				assets: {
					...state.assets,
					...extendAssets
				}
			};
		}

		case FETCH_ASSETS_FAILURE: {
			let { assetIds } = action.payload;
			let extendAssets = {};

			assetIds.forEach((assetId) => {
				extendAssets[assetId] = {
					...state.assets[assetId],
					isLoading: false
				};
			});

			return {
				...state,
				assets: {
					...state.assets,
					...extendAssets
				}
			};
		}

		case FETCH_RELATED_ASSETS_REQUEST: {
			let { assetId } = action.payload;

			return {
				...state,
				assets: {
					...state.assets,
					[assetId]: {
						...state.assets[assetId],
						related: {
							assets: state.assets[assetId]?.related?.assets,
							isLoading: true,
							isLoaded: false,
							isFailed: false,
							error: null
						}
					}
				}
			};
		}

		case FETCH_RELATED_ASSETS_SUCCESS: {
			let { assetId, assets } = action.payload;

			return {
				...state,
				assets: {
					...state.assets,
					[assetId]: {
						...state.assets[assetId],
						related: {
							assets,
							isLoading: false,
							isLoaded: true,
							isFailed: false,
							error: null
						}
					}
				}
			};
		}

		case FETCH_RELATED_ASSETS_FAILURE: {
			let { assetId, err } = action.payload;

			return {
				...state,
				assets: {
					...state.assets,
					[assetId]: {
						...state.assets[assetId],
						related: {
							...state.assets[assetId]?.related,
							isLoading: false,
							isLoaded: false,
							isFailed: true,
							error: err
						}
					}
				}
			};
		}

		case UPDATE_ASSET: {
			let asset = action.payload;
			let now = new Date();

			return {
				...state,
				assets: {
					...state.assets,
					[asset._id]: {
						isLoading: false,
						lastLoadedAt: now,
						data: asset
					}
				}
			};
		}

		case REMOVE_ASSET_QUEUE: {
			let { assetId } = action.payload;

			// If assetId already in queue, do nothing
			if( _.some(state.assets, (asset) => asset.id === assetId) ) {
				return state;
			}

			return {
				...state,
				assets: {
					...state.assets,
					[assetId]: {
						...state.assets[assetId],
						isRemoving: false,
						isRemoved: false,
						removalError: null
					}
				}
			};
		}

		case REMOVE_ASSET_REQUEST: {
			let { assetId } = action.payload;

			return {
				...state,
				assets: {
					...state.assets,
					[assetId]: {
						...state.assets[assetId],
						isRemoving: true,
						isRemoved: false,
						removalError: null
					}
				}
			};
		}

		case REMOVE_ASSET_SUCCESS: {
			let { assetId } = action.payload;

			return {
				...state,
				assets: {
					...state.assets,
					[assetId]: {
						...state.assets[assetId],
						isRemoving: false,
						isRemoved: true,
						removalError: null
					}
				}
			};
		}

		case CANCEL_ASSET_REMOVAL: {
			let updated = _.cloneDeep(state.assets);

			// Cancel removal only for assets haven't started been removed yet
			// Because, if the request has been sent, we can't cancel it
			_.keys(updated).forEach((assetId) => {
				if(
					!updated[assetId].isRemoved
					&& !updated[assetId].isRemoving
				) {
					updated[assetId] = {
						...updated[assetId],
						removalError: 'cancelled'
					};
				}

				if(
					updated[assetId].isRemoving
				) {
					updated[assetId] = {
						...updated[assetId],
						removalError: null,
						isRemoved: true,
						isRemoving: false
					};
				}
			});

			return {
				...state,
				assets: updated
			};
		}

		case REMOVE_ASSET_FAILURE: {
			let { assetId } = action.payload;

			return {
				...state,
				assets: {
					...state.assets,
					[assetId]: {
						...state.assets[assetId],
						isRemoving: false,
						isRemoved: false,
						removalError: 'cancelled'
					}
				}
			};
		}

		default:
			return state;
	}
}

export const actions = {
	getProjectAssets: createAction(FETCH_PROJECT_ASSETS),
	getSimilarAssets: createAction(FETCH_SIMILAR_ASSETS),
	getAssetById: createAction(FETCH_ASSET),
	getAssetsById: createAction(FETCH_ASSETS),
	getRelatedAssets: createAction(FETCH_RELATED_ASSETS),
	updateAsset: createAction(UPDATE_ASSET),
	removeAsset: createAction(REMOVE_ASSET),
	cancelAssetRemoval: createAction(CANCEL_ASSET_REMOVAL)
};

/**
 * Actions that should only be invoked internally
 */
export const internalActions = {
	getProjectAssetsRequest: createAction(FETCH_PROJECT_ASSETS_REQUEST),
	getProjectAssetsSuccess: createAction(FETCH_PROJECT_ASSETS_SUCCESS),
	getProjectAssetsFailure: createAction(FETCH_PROJECT_ASSETS_FAILURE),
	getSimilarAssetsRequest: createAction(FETCH_SIMILAR_ASSETS_REQUEST),
	getSimilarAssetsSuccess: createAction(FETCH_SIMILAR_ASSETS_SUCCESS),
	getSimilarAssetsFailure: createAction(FETCH_SIMILAR_ASSETS_FAILURE),
	getAssetByIdRequest: createAction(FETCH_ASSET_REQUEST),
	getAssetByIdSuccess: createAction(FETCH_ASSET_SUCCESS),
	getAssetByIdFailure: createAction(FETCH_ASSET_FAILURE),
	getAssetsByIdRequest: createAction(FETCH_ASSETS_REQUEST),
	getAssetsByIdSuccess: createAction(FETCH_ASSETS_SUCCESS),
	getAssetsByIdFailure: createAction(FETCH_ASSETS_FAILURE),
	getRelatedAssetsRequest: createAction(FETCH_RELATED_ASSETS_REQUEST),
	getRelatedAssetsSuccess: createAction(FETCH_RELATED_ASSETS_SUCCESS),
	getRelatedAssetsFailure: createAction(FETCH_RELATED_ASSETS_FAILURE),
	removeAssetQueue: createAction(REMOVE_ASSET_QUEUE),
	removeAssetRequest: createAction(REMOVE_ASSET_REQUEST),
	removeAssetSuccess: createAction(REMOVE_ASSET_SUCCESS),
	removeAssetFailure: createAction(REMOVE_ASSET_FAILURE),
	cancelAssetRemoval: createAction(CANCEL_ASSET_REMOVAL)
};

export const selectors = {
	getPageError: createSelector(getPageError),
	areProjectAssetsLoading: createSelector(areProjectAssetsLoading),
	areProjectAssetsLoaded: createSelector(areProjectAssetsLoaded),
	getProjectAssetsLastLoadedAt: createSelector(getProjectAssetsLastLoadedAt),
	getProjectAssets: createSelector(getProjectAssets),
	getSimilarAssets: createSelector(getSimilarAssets),
	isSimilarAssetsLoading: createSelector(isSimilarAssetsLoading),
	isSimilarAssetsLoaded: createSelector(isSimilarAssetsLoaded),
	getSimilarAssetsError: createSelector(getSimilarAssetsError),
	isAssetLoading: createSelector(isAssetLoading),
	isAssetLoaded: createSelector(isAssetLoaded),
	isExtendedAssetLoaded: createSelector(isExtendedAssetLoaded),
	getAssetLastLoadedAt: createSelector(getAssetLastLoadedAt),
	getAssetById: createSelector(getAssetById),
	getAssetsById: createSelector(getAssetsById),
	getRelatedAssets: createSelector(getRelatedAssets),
	isRelatedAssetsLoading: createSelector(isRelatedAssetsLoading),
	isRelatedAssetsLoaded: createSelector(isRelatedAssetsLoaded),
	isRelatedAssetsFailed: createSelector(isRelatedAssetsFailed),
	getRelatedAssetsError: createSelector(getRelatedAssetsError),
	getAssetsRemovingStatus: createSelector(getAssetsRemovingStatus)
};

function areProjectAssetsLoading(state, projectId) {
	let projectContainer = state.assetsByProject[projectId];
	if( !projectContainer ) {
		return false;
	}

	return projectContainer.isLoading;
}

function areProjectAssetsLoaded(state, projectId) {
	let projectContainer = state.assetsByProject[projectId];
	if( !projectContainer ) {
		return false;
	}

	return !!projectContainer.lastLoadedAt;
}

function getProjectAssetsLastLoadedAt(state, projectId) {
	let projectContainer = state.assetsByProject[projectId];
	if( !projectContainer ) {
		return null;
	}

	return projectContainer.lastLoadedAt;
}

function getProjectAssets(state, projectId) {
	let projectContainer = state.assetsByProject[projectId];
	if( !projectContainer ) {
		return [];
	}

	return _.map(projectContainer.assets, (assetId) => state.assets[assetId].data);
}

function getSimilarAssets(state, type) {
	let typeContainer = state.similarAssets[type];
	if( !typeContainer ) {
		return [];
	}

	return _.map(typeContainer.assets, (assetId) => state.assets[assetId]?.data);
}

function isSimilarAssetsLoading(state, type) {
	let typeContainer = state.similarAssets[type];
	if( !typeContainer ) {
		return false;
	}

	return typeContainer.isLoading;
}
function isSimilarAssetsLoaded(state, type) {
	let typeContainer = state.similarAssets[type];
	if( !typeContainer ) {
		return false;
	}
	return !!typeContainer.lastLoadedAt;
}

function getSimilarAssetsError(state, type) {

	let typeContainer = state.similarAssets[type];
	if( !typeContainer ) {
		return {};
	}

	return false;
}

function isAssetLoading(state, assetId) {
	let assetContainer = state.assets[assetId];
	if( !assetContainer ) {
		return false;
	}

	return assetContainer.isLoading;
}

function isAssetLoaded(state, assetId) {
	let assetContainer = state.assets[assetId];
	if( !assetContainer ) {
		return false;
	}

	return !!assetContainer.lastLoadedAt;
}

function isExtendedAssetLoaded(state, assetId) {
	return isAssetLoaded(state, assetId) && !!state.assets[assetId].lastLoadedExtendedAt;
}

function getAssetLastLoadedAt(state, assetId) {
	let assetContainer = state.assets[assetId];
	if( !assetContainer ) {
		return null;
	}

	return assetContainer.lastLoadedAt;
}

function getAssetById(state, assetId) {
	let assetContainer = state.assets[assetId];
	if( !assetContainer ) {
		return null;
	}

	return assetContainer.data;
}

function getRelatedAssets(state, assetId) {
	let assetContainer = state.assets[assetId];

	if( !assetContainer ) {
		return [];
	}
	return assetContainer.related?.assets || [];
}

function isRelatedAssetsLoaded(state, assetId) {
	let assetContainer = state.assets[assetId];
	if( !assetContainer ) {
		return false;
	}
	return assetContainer.related?.isLoaded;
}

function isRelatedAssetsLoading(state, assetId) {
	let assetContainer = state.assets[assetId];
	if( !assetContainer ) {
		return false;
	}
	return assetContainer.related?.isLoading;
}

function isRelatedAssetsFailed(state, assetId) {
	let assetContainer = state.assets[assetId];
	if( !assetContainer ) {
		return false;
	}
	return assetContainer.related?.isFailed;
}

function getRelatedAssetsError(state, assetId) {
	let assetContainer = state.assets[assetId];
	if( !assetContainer ) {
		return null;
	}
	return assetContainer.related?.error;
}

function getAssetsById(state, assetIds) {
	return assetIds && _.map(assetIds, (id) => getAssetById(state, id));
}

function getPageError(state) {
	return state.error;
}

function getAssetsRemovingStatus(state, assets) {
	if( !assets.length ) {
		return false;
	}

	assets = assets.map((asset) => {
		let assetContainer = state.assets[asset.id || asset._id];

		return {
			...asset,
			id: asset.id,
			isRemoving: assetContainer ? assetContainer.isRemoving : false,
			isRemoved: assetContainer ? assetContainer.isRemoved : false,
			removalError: assetContainer ? assetContainer.removalError : null,
			name: asset.name
		};
	});

	return assets;
}

/**
 * Helper method that grabs our specific part of the global store, then passes it into the given selector.
 * This makes it so that each selector doesn't have to grab the sub-state itself, reducing code duplication.
 */
function createSelector(selector) {
	return function selectorWrapper(state, ...rest) {
		let assetsStore = state[STORE_NAME];
		return selector(assetsStore, ...rest);
	};
}

export function *watchAssets() {
	yield takeLatest(FETCH_PROJECT_ASSETS, fetchProjectAssets);
	yield takeLatest(FETCH_SIMILAR_ASSETS, fetchSimilarAssets);
	yield takeEvery(FETCH_ASSET, fetchAsset);
	yield takeEvery(FETCH_ASSETS, fetchAssets);
	yield takeEvery(FETCH_RELATED_ASSETS, fetchRelatedAssets);
	yield takeEvery(REMOVE_ASSET, removeAsset);
}
