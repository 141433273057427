"use client";

import Spinner from "@/modules/components/global/spinner";

export default function Loading() {
	return (
		<div className="flex items-center justify-center h-screen w-screen">
			<Spinner />
		</div>
	);
}
