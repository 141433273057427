import _ from 'lodash';
import isError from 'lodash.iserror';
import { createAction } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleError } from './common';

import {
	actions as sharesActions, fetchShareById,
	selectors as sharesSelectors
} from './shares';
import {
	actions as projectsActions, fetchProjects,
	selectors as projectsSelectors
} from './projects';
import {
	actions as previewsActions, fetchPreviews,
	selectors as previewsSelectors
} from './previews';

export const STORE_NAME = 'shareViewPageStore';

function *loadPageData({ payload: shareId }) {
	try {
		yield put(internalActions.loadPageDataRequest());

		let projects = yield call(fetchProjects, projectsActions.getProjects());
		if( isError(projects) ) {
			throw new Error('Failed to load projects');
		}

		let share = yield call(fetchShareById, sharesActions.getShareById(shareId));
		if( isError(share) ) {
			throw new Error('Failed to load share');
		}

		yield put(internalActions.loadPageDataSuccess());
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadPageDataFailure(err));
		return err;
	}
}

function *loadAssetPreviews({ payload: { assetId, shareId } }) {
	try {
		yield put(internalActions.loadAssetPreviewsRequest());

		let previews = yield call(fetchPreviews, previewsActions.getAssetPreviews({ assetId, shareId }));
		if( isError(previews) ) {
			throw new Error('Failed to load previews');
		}

		yield put(internalActions.loadAssetPreviewsSuccess());
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadAssetPreviewsFailure(err));
		return err;
	}
}

export const LOAD = 'share-view.load';
export const LOAD_REQUEST = 'share-view.load.request';
export const LOAD_SUCCESS = 'share-view.load.success';
export const LOAD_FAILURE = 'share-view.load.failure';

export const LOAD_PREVIEWS = 'previews.load';
export const LOAD_PREVIEWS_REQUEST = 'previews.load.request';
export const LOAD_PREVIEWS_SUCCESS = 'previews.load.success';
export const LOAD_PREVIEWS_FAILURE = 'previews.load.failure';

export const SAVE_FEEDBACK_DATA = 'share-view.feedback.save';

export const INITIAL_STATE = {
	isLoading: false,
	loadFailed: false,
	arePreviewsLoading: false,
	didPreviewsLoadFail: false,
	feedback: {}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case LOAD_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}

		case LOAD_SUCCESS: {
			return {
				...state,
				isLoading: false,
				loadFailed: false
			};
		}

		case LOAD_FAILURE: {
			return {
				...state,
				isLoading: false,
				loadFailed: true
			};
		}

		case LOAD_PREVIEWS_REQUEST: {
			return {
				...state,
				arePreviewsLoading: true
			};
		}

		case LOAD_PREVIEWS_SUCCESS: {
			return {
				...state,
				arePreviewsLoading: false,
				didPreviewsLoadFail: false
			};
		}

		case LOAD_PREVIEWS_FAILURE: {
			return {
				...state,
				arePreviewsLoading: false,
				didPreviewsLoadFail: true
			};
		}

		case SAVE_FEEDBACK_DATA: {
			return {
				...state,
				feedback: _.merge(state.feedback, action.payload)
			};
		}

		default:
			return state;
	}
}

export const actions = {
	loadPageData: createAction(LOAD),
	loadAssetPreviews: createAction(LOAD_PREVIEWS),
	saveFeedbackData: createAction(SAVE_FEEDBACK_DATA)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	loadPageDataRequest: createAction(LOAD_REQUEST),
	loadPageDataSuccess: createAction(LOAD_SUCCESS),
	loadPageDataFailure: createAction(LOAD_FAILURE),
	loadAssetPreviewsRequest: createAction(LOAD_PREVIEWS_REQUEST),
	loadAssetPreviewsSuccess: createAction(LOAD_PREVIEWS_SUCCESS),
	loadAssetPreviewsFailure: createAction(LOAD_PREVIEWS_FAILURE)
};

export const selectors = {
	isPageLoading,
	isPageReady,
	isPageLoadError,
	arePreviewsLoading,
	arePreviewsReady,
	didPreviewsLoadFail,
	getFeedbackData
};

function isPageLoading(state) {
	let { shareViewPageStore } = state;

	return shareViewPageStore.isLoading;
}

function isPageReady(state, id) {
	let isShareDataLoaded = sharesSelectors.isLoaded(state, id);
	let isProjectsDataLoaded = projectsSelectors.isLoaded(state);

	return isShareDataLoaded && isProjectsDataLoaded;
}

function isPageLoadError(state) {
	let { shareViewPageStore } = state;
	return shareViewPageStore.loadFailed;
}

function arePreviewsLoading(state) {
	let { shareViewPageStore } = state;

	return shareViewPageStore.arePreviewsLoading;
}

function arePreviewsReady(state, assetId) {
	if( !assetId ) {
		return false;
	}

	let arePreviewsLoaded = previewsSelectors.areAssetPreviewsLoaded(state, assetId);

	return arePreviewsLoaded;
}

function didPreviewsLoadFail(state) {
	let { shareViewPageStore } = state;
	return shareViewPageStore.didPreviewsLoadFail;
}

function getFeedbackData(state) {
	let shareViewPageStore = state.shareViewPageStore;
	return shareViewPageStore.feedback;
}

export function *watchShareViewPage() {
	yield takeLatest(LOAD, loadPageData);
	yield takeLatest(LOAD_PREVIEWS, loadAssetPreviews);
}
