import _ from 'lodash';
import _get from 'lodash.get';
import { createAction } from 'redux-actions';
import { put, takeEvery } from 'redux-saga/effects';
import { RESET_STORES } from './session';
import { createSelector, handleError } from './common';
import CommentService from '@/modules/services/comment-service';

// Comments come in denormalized (replies attached directly)
// which can make some of the operations tricky.
// TODO: If performance or bugs start to appear, we may need to restructure to something simpler
export const STORE_NAME = 'commentsStore';

function *fetchWorkRequestComments({ payload }) {
	let { workRequestId } = payload;

	try {
		yield put(internalActions.getWorkRequestCommentsRequest({ workRequestId }));
		const comments = yield CommentService.getCommentsForWorkRequest(workRequestId);
		yield put(internalActions.getWorkRequestCommentsSuccess({ workRequestId, comments }));
		return comments;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getWorkRequestCommentsFailure({ workRequestId, err }));
		return err;
	}
}

function *fetchOfflineComments({ payload }) {
	let { offlineId } = payload;

	try {
		yield put(internalActions.getOfflineCommentsRequest({ offlineId }));
		const comments = yield CommentService.getCommentsForOffline(offlineId);
		yield put(internalActions.getOfflineCommentsSuccess({ offlineId, comments }));
		return comments;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getOfflineCommentsFailure({ offlineId, err }));
		return err;
	}
}

export const FETCH_OFFLINE_COMMENTS = 'comments.fetch-by-offline';
export const FETCH_OFFLINE_COMMENTS_REQUEST = 'comments.fetch-by-offline.request';
export const FETCH_OFFLINE_COMMENTS_SUCCESS = 'comments.fetch-by-offline.success';
export const FETCH_OFFLINE_COMMENTS_FAILURE = 'comments.fetch-by-offline.failure';

export const FETCH_WORK_REQUEST_COMMENTS = 'comments.fetch-by-work-request';
export const FETCH_WORK_REQUEST_COMMENTS_REQUEST = 'comments.fetch-by-work-request.request';
export const FETCH_WORK_REQUEST_COMMENTS_SUCCESS = 'comments.fetch-by-work-request.success';
export const FETCH_WORK_REQUEST_COMMENTS_FAILURE = 'comments.fetch-by-work-request.failure';

export const MANUAL_INSERT = 'comments.insert.manual';
export const MANUAL_REMOVE = 'comments.remove.manual';

export const INITIAL_STATE = {
	comments: {},
	commentsByOffline: {},
	commentsByWorkRequest: {}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case RESET_STORES:
			return INITIAL_STATE;

		case FETCH_WORK_REQUEST_COMMENTS_REQUEST: {
			let { workRequestId } = action.payload;

			let existingContainer = _get(state.commentsByWorkRequest, workRequestId, {});

			return {
				...state,
				commentsByWorkRequest: {
					...state.commentsByWorkRequest,
					[workRequestId]: {
						lastLoadedAt: null,
						data: [],
						...existingContainer,
						isLoading: true
					}
				}
			};
		}

		case FETCH_WORK_REQUEST_COMMENTS_SUCCESS: {
			let { workRequestId, comments } = action.payload;
			let now = new Date();

			let updatedComments = {
				...state.comments
			};

			_.forEach(comments, (comment) => {
				updatedComments[comment._id] = {
					isLoading: false,
					lastLoadedAt: now,
					data: comment
				};
			});

			return {
				...state,
				comments: updatedComments,
				commentsByWorkRequest: {
					...state.commentsByWorkRequest,
					[workRequestId]: {
						lastUpdatedAt: now,
						isLoading: false,
						data: _.map(comments, '_id')
					}
				}
			};
		}

		case FETCH_WORK_REQUEST_COMMENTS_FAILURE: {
			let { workRequestId, err } = action.payload;

			return {
				...state,
				commentsByOffline: {
					...state.commentsByWorkRequest,
					[workRequestId]: {
						...state.commentsByWorkRequest[workRequestId],
						isLoading: false,
						loadError: err
					}
				}
			};
		}

		case FETCH_OFFLINE_COMMENTS_REQUEST: {
			let { offlineId } = action.payload;

			let existingContainer = _get(state.commentsByOffline, offlineId, {});

			return {
				...state,
				commentsByOffline: {
					...state.commentsByOffline,
					[offlineId]: {
						lastLoadedAt: null,
						data: [],
						...existingContainer,
						isLoading: true
					}
				}
			};
		}

		case FETCH_OFFLINE_COMMENTS_SUCCESS: {
			let { offlineId, comments } = action.payload;
			let now = new Date();

			let updatedComments = {
				...state.comments
			};

			_.forEach(comments, (comment) => {
				updatedComments[comment._id] = {
					isLoading: false,
					lastLoadedAt: now,
					data: comment
				};
			});

			return {
				...state,
				comments: updatedComments,
				commentsByOffline: {
					...state.commentsByOffline,
					[offlineId]: {
						lastUpdatedAt: now,
						isLoading: false,
						data: _.map(comments, '_id')
					}
				}
			};
		}

		case FETCH_OFFLINE_COMMENTS_FAILURE: {
			let { offlineId, err } = action.payload;

			return {
				...state,
				commentsByOffline: {
					...state.commentsByOffline,
					[offlineId]: {
						...state.commentsByOffline[offlineId],
						isLoading: false,
						loadError: err
					}
				}
			};
		}

		case MANUAL_INSERT: {
			let { comment } = action.payload;
			let now = new Date();

			// If its a reply
			if( comment.replyTo ) {
				let container = state.comments[comment.replyTo];
				let oldComment = container.data;
				let updatedComment = {
					...oldComment,
					replies: [
						...oldComment.replies,
						comment
					]
				};

				return {
					...state,
					comments: {
						...state.comments,
						[comment.replyTo]: {
							...container,
							lastUpdatedAt: now,
							data: updatedComment
						}
					}
				};
			}

			let commentsByOffline = state.commentsByOffline;
			let commentsByWorkRequest = state.commentsByWorkRequest;
			if( comment.offline ) {
				let offlineContainer = _get(state.commentsByOffline, comment.offline, {
					lastLoadedAt: null,
					isLoading: false,
					data: []
				});

				offlineContainer.data.push(comment._id);
				commentsByOffline = {
					...state.commentsByOffline,
					[comment.offline]: offlineContainer
				};
			}
			else if( comment.workRequest ) {
				let workRequestContainer = _get(state.commentsByWorkRequest, comment.workRequest, {
					lastLoadedAt: null,
					isLoading: false,
					data: []
				});

				workRequestContainer.data.push(comment._id);
				commentsByWorkRequest = {
					...state.commentsByWorkRequest,
					[comment.workRequest]: workRequestContainer
				};
			}

			return {
				...state,
				comments: {
					...state.comments,
					[comment._id]: {
						isLoading: false,
						lastLoadedAt: now,
						data: comment
					}
				},
				commentsByOffline,
				commentsByWorkRequest
			};
		}

		case MANUAL_REMOVE: {
			let {
				workRequestId,
				offlineId,
				commentId,
				replyTo
			} = action.payload;

			// If its a reply
			if( replyTo ) {
				let container = state.comments[replyTo];
				let comment = container.data;
				let updatedComment = {
					...comment,
					replies: _.reject(comment.replies, { _id: commentId })
				};

				return {
					...state,
					comments: {
						...state.comments,
						[comment._id]: {
							...container,
							data: updatedComment
						}
					}
				};
			}

			let comments = _.omit(state.comments, commentId);
			let commentsByOffline = state.commentsByOffline;
			let commentsByWorkRequest = state.commentsByWorkRequest;
			if( offlineId ) {
				let container = state.commentsByOffline[offlineId];
				commentsByOffline = {
					...state.commentsByOffline,
					[offlineId]: {
						...container,
						data: _.without(container.data, commentId)
					}
				};
			}
			else if( workRequestId ) {
				let container = state.commentsByWorkRequest[workRequestId];
				commentsByWorkRequest = {
					...state.commentsByWorkRequest,
					[workRequestId]: {
						...container,
						data: _.without(container.data, commentId)
					}
				};
			}

			return {
				...state,
				comments,
				commentsByOffline,
				commentsByWorkRequest
			};
		}

		default:
			return state;
	}
}

export const actions = {
	getWorkRequestComments: createAction(FETCH_WORK_REQUEST_COMMENTS),
	getOfflineComments: createAction(FETCH_OFFLINE_COMMENTS),
	insertComment: createAction(MANUAL_INSERT),
	removeComment: createAction(MANUAL_REMOVE)
};

/**
 * Actions that should only be invoked internally
 */
export const internalActions = {
	getWorkRequestCommentsRequest: createAction(FETCH_WORK_REQUEST_COMMENTS_REQUEST),
	getWorkRequestCommentsSuccess: createAction(FETCH_WORK_REQUEST_COMMENTS_SUCCESS),
	getWorkRequestCommentsFailure: createAction(FETCH_WORK_REQUEST_COMMENTS_FAILURE),
	getOfflineCommentsRequest: createAction(FETCH_OFFLINE_COMMENTS_REQUEST),
	getOfflineCommentsSuccess: createAction(FETCH_OFFLINE_COMMENTS_SUCCESS),
	getOfflineCommentsFailure: createAction(FETCH_OFFLINE_COMMENTS_FAILURE)
};

export const selectors = {
	areWorkRequestCommentsLoading: createSelector(STORE_NAME, areWorkRequestCommentsLoading),
	areWorkRequestCommentsLoaded: createSelector(STORE_NAME, areWorkRequestCommentsLoaded),
	didWorkRequestCommentsLoadFail: createSelector(STORE_NAME, didWorkRequestCommentsLoadFail),
	getWorkRequestComments: createSelector(STORE_NAME, getWorkRequestComments),
	areOfflineCommentsLoading: createSelector(STORE_NAME, areOfflineCommentsLoading),
	areOfflineCommentsLoaded: createSelector(STORE_NAME, areOfflineCommentsLoaded),
	didOfflineCommentsLoadFail: createSelector(STORE_NAME, didOfflineCommentsLoadFail),
	getOfflineComments: createSelector(STORE_NAME, getOfflineComments)
};

function areWorkRequestCommentsLoading(state, workRequestId) {
	return !!_get(state.commentsByWorkRequest, [workRequestId, 'isLoading'], false);
}

function areWorkRequestCommentsLoaded(state, workRequestId) {
	return !_get(state.commentsByWorkRequest, [workRequestId, 'lastLoadedAt'], false);
}

function didWorkRequestCommentsLoadFail(state, workRequestId) {
	return !!_get(state.commentsByWorkRequest, [workRequestId, 'loadError'], false);
}

function getWorkRequestComments(state, workRequestId) {
	let commentIds = _get(state.commentsByWorkRequest, [workRequestId, 'data'], []);
	return _.map(commentIds, (id) => state.comments[id].data);
}

function areOfflineCommentsLoading(state, offlineId) {
	return !!_get(state.commentsByOffline, [offlineId, 'isLoading'], false);
}

function areOfflineCommentsLoaded(state, offlineId) {
	return !_get(state.commentsByOffline, [offlineId, 'lastLoadedAt'], false);
}

function didOfflineCommentsLoadFail(state, offlineId) {
	return !!_get(state.commentsByOffline, [offlineId, 'loadError'], false);
}

function getOfflineComments(state, offlineId) {
	let commentIds = _get(state.commentsByOffline, [offlineId, 'data'], []);
	return _.map(commentIds, (id) => state.comments[id].data);
}

export function *watchComments() {
	yield takeEvery(FETCH_WORK_REQUEST_COMMENTS, fetchWorkRequestComments);
	yield takeEvery(FETCH_OFFLINE_COMMENTS, fetchOfflineComments);
}
