import { createAction } from 'redux-actions';
import { put, select, takeLatest } from 'redux-saga/effects';
import { createSelector, handleError } from './common';
import SessionService from '@/modules/services/session-service';
import AuthService from '@/modules/services/auth-service';
import RouteService from '@/modules/services/route-service';

export const STORE_NAME = 'sessionStore';

/**
	 * This saga should be triggered when a user's session expires
	 */
/* eslint-disable-next-line require-yield */
function *clearSession() {
	AuthService.removeToken();
}

/**
	 * This saga is triggered when a user logs or relogs.
	 * The main edge case to beware of here is if a different user
	 * relogs, we need to dispatch a reset store action to clear all caches.
	 */
function *login({ payload: credentials }) {
	try {
		let lastUserEmail = yield select((state) => state[STORE_NAME].lastUserEmail);
		yield put(internalActions.loginRequest());

		let session = yield AuthService.login(credentials);

		// If the last session's email is different then this session's email
		// Dispatch a reset stores action to clear our cache, and reload
		// the page to make sure data isn't partially loaded and permissions
		// are fully rechecked. (Yes we can technically skip the reset stores because of the hard refresh)
		if( lastUserEmail && lastUserEmail !== session.emailNormal ) {
			yield put(internalActions.resetStores());
			window.location.reload();
			return;
		}

		yield put(internalActions.loginSuccess(session));
		if(credentials.isTwoFactor) {
			RouteService.navigateTo('projects');
		}
		return session;
	}
	catch(e) {
		const err = handleError({ message: e==='access-denied'?'Invalid username or password':e });
		yield put(internalActions.loginFailure(err));
		return err;
	}
}

/**
	 * This saga should be triggered when a user explicitly logs out
	 */
function *logout() {
	try {
		yield put(internalActions.logoutRequest());
		yield AuthService.logout();
		yield put(internalActions.logoutSuccess());
		yield put(actions.clearSession());

		window.location.replace('/');
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.logoutFailure(err));

		// Even if we fail, we can clear the cookies and reload the site
		// The session will be cleared from the computer and eventually timeout on the server side
		AuthService.removeToken();
		window.location.replace('/');

		return err;
	}
}

function *resetPassword({ payload: { email } }) {
	try {
		yield put(internalActions.resetPasswordRequest());

		let url = `${window.location.origin}/reset/:token`;
		yield SessionService.resetPassword({ email, url });

		yield put(internalActions.resetPasswordSuccess());
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.resetPasswordFailure(err));

		return err;
	}
}

function *twoFactorLogin({ payload: { token } }) {
	try {
		yield put(internalActions.twoFactorLoginRequest());

		let session = SessionService.twoFactorLogin({ token });

		yield login({ token: session?.token, username: session?.emailNormal });

		yield put(internalActions.twoFactorLoginSuccess());
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.twoFactorLoginFailure(err));

		return err;
	}
}

export function *fetchSession() {
	try {
		yield put(internalActions.getSessionRequest());
		const session = yield SessionService.getSession();
		yield put(internalActions.getSessionSuccess(session));
		return session;
	}
	catch(e) {
		AuthService.removeToken();
		const err = handleError(e);
		yield put(internalActions.getSessionFailure(err));
		return err;
	}
}

export const LOGIN = 'session.login';
export const LOGIN_REQUEST = 'session.login.request';
export const LOGIN_SUCCESS = 'session.login.success';
export const LOGIN_FAILURE = 'session.login.failure';

export const TWO_FACTOR_LOGIN = 'session.two-factor-login';
export const TWO_FACTOR_LOGIN_REQUEST = 'session.two-factor-login.request';
export const TWO_FACTOR_LOGIN_SUCCESS = 'session.two-factor-login.success';
export const TWO_FACTOR_LOGIN_FAILURE = 'session.two-factor-login.failure';

export const LOGOUT = 'session.logout';
export const LOGOUT_REQUEST = 'session.logout.request';
export const LOGOUT_SUCCESS = 'session.logout.success';
export const LOGOUT_FAILURE = 'session.logout.failure';

export const RESET_PASSWORD = 'session.reset-password';
export const RESET_PASSWORD_REQUEST = 'session.reset-password.request';
export const RESET_PASSWORD_SUCCESS = 'session.reset-password.success';
export const RESET_PASSWORD_FAILURE = 'session.reset-password.failure';
export const RESET_PASSWORD_CLEAR_FEEDBACK = 'session.reset-password.clear-feedback';

export const FETCH_SESSION = 'session.fetch';
export const FETCH_SESSION_REQUEST = 'session.fetch.request';
export const FETCH_SESSION_SUCCESS = 'session.fetch.success';
export const FETCH_SESSION_FAILURE = 'session.fetch.failure';

export const RESUME_SESSION = 'session.resume';
export const CLEAR_SESSION = 'session.clear';

export const UPDATE_LAST_ATTEMPT = 'session.update.last-attempt';
export const RESET_STORES = 'session.reset-stores';

export const MANUAL_UPDATE_SESSION = 'session.manual-update';

const INITIAL_STATE = {
	isLoggingIn: false,
	isLoggingOut: false,
	isResetingPassword: false,
	isResetPasswordSuccess: false,
	session: null,
	lastAttemptedAt: null,
	lastUserEmail: null,
	loginError: null,
	resetPasswordError: null,
	isLoading: false,
	lastLoadedAt: null
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case LOGIN_REQUEST: {
			return {
				...state,
				isLoggingIn: true,
				loginError: null
			};
		}

		case LOGIN_SUCCESS: {
			let session = action.payload;

			return {
				...state,
				isLoggingIn: false,
				session: session,
				lastAttemptedAt: new Date(),
				lastUserEmail: session.emailNormal,
				loginError: null
			};
		}

		case LOGIN_FAILURE: {
			return {
				...state,
				isLoggingIn: false,
				lastAttemptedAt: new Date(),
				loginError: action.payload
			};
		}

		case TWO_FACTOR_LOGIN_REQUEST: {
			return {
				...state,
				isLoggingIn: true,
				loginError: null
			};
		}

		case TWO_FACTOR_LOGIN_SUCCESS: {
			let session = action.payload;

			return {
				...state,
				isLoggingIn: false,
				session: session,
				twofactor: { enabled: true },
				lastAttemptedAt: new Date(),
				lastUserEmail: session.emailNormal,
				loginError: null
			};
		}

		case TWO_FACTOR_LOGIN_FAILURE: {
			return {
				...state,
				isLoggingIn: false,
				twofactor: { enabled: true },
				lastAttemptedAt: new Date(),
				loginError: action.payload
			};
		}

		case LOGOUT_REQUEST: {
			return {
				...state,
				isLoggingOut: true
			};
		}

		case LOGOUT_SUCCESS: {
			return {
				...state,
				session: null,
				isLoggingOut: false,
				lastUserEmail: null
			};
		}

		case LOGOUT_FAILURE: {
			return {
				...state,
				isLoggingOut: false
			};
		}

		case RESET_PASSWORD_REQUEST: {
			return {
				...state,
				isResetingPassword: true,
				resetPasswordError: null,
				resetPasswordSuccess: false
			};
		}

		case RESET_PASSWORD_SUCCESS: {
			return {
				...state,
				isResetingPassword: false,
				resetPasswordSuccess: true
			};
		}

		case RESET_PASSWORD_FAILURE: {
			return {
				...state,
				isResetingPassword: false,
				resetPasswordError: action.payload
			};
		}

		case RESET_PASSWORD_CLEAR_FEEDBACK: {
			return {
				...state,
				resetPasswordError: null,
				resetPasswordSuccess: false
			};
		}

		case FETCH_SESSION_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}

		case FETCH_SESSION_SUCCESS: {
			const session = action.payload;

			return {
				...state,
				session: session,
				isLoading: false,
				lastLoadedAt: new Date()
			};
		}

		case FETCH_SESSION_FAILURE: {
			return {
				...state,
				isLoading: false
			};
		}

		case RESUME_SESSION: {
			let session = action.payload;

			return {
				...state,
				session: session,
				lastAttemptedAt: new Date(),
				lastUserEmail: session.emailNormal
			};
		}

		case UPDATE_LAST_ATTEMPT: {
			return {
				...state,
				lastAttemptedAt: new Date()
			};
		}

		case CLEAR_SESSION: {
			return {
				...state,
				session: null,
				loginError: null
			};
		}

		case MANUAL_UPDATE_SESSION: {
			let session = action.payload;

			return {
				...state,
				session
			};
		}

		default:
			return state;
	}
}

export const actions = {
	login: createAction(LOGIN),
	logout: createAction(LOGOUT),
	resetPassword: createAction(RESET_PASSWORD),
	twoFactorLogin: createAction(TWO_FACTOR_LOGIN),
	clearResetPasswordFeedback: createAction(RESET_PASSWORD_CLEAR_FEEDBACK),
	getSession: createAction(FETCH_SESSION),
	resumeSession: createAction(RESUME_SESSION),
	updateLastAttempt: createAction(UPDATE_LAST_ATTEMPT),
	clearSession: createAction(CLEAR_SESSION),
	manualUpdateSession: createAction(MANUAL_UPDATE_SESSION)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	loginRequest: createAction(LOGIN_REQUEST),
	loginSuccess: createAction(LOGIN_SUCCESS),
	loginFailure: createAction(LOGIN_FAILURE),
	twoFactorLoginRequest: createAction(TWO_FACTOR_LOGIN_REQUEST),
	twoFactorLoginSuccess: createAction(TWO_FACTOR_LOGIN_SUCCESS),
	twoFactorLoginFailure: createAction(TWO_FACTOR_LOGIN_FAILURE),
	logoutRequest: createAction(LOGOUT_REQUEST),
	logoutSuccess: createAction(LOGOUT_SUCCESS),
	logoutFailure: createAction(LOGOUT_FAILURE),
	resetPasswordRequest: createAction(RESET_PASSWORD_REQUEST),
	resetPasswordSuccess: createAction(RESET_PASSWORD_SUCCESS),
	resetPasswordFailure: createAction(RESET_PASSWORD_FAILURE),
	getSessionRequest: createAction(FETCH_SESSION_REQUEST),
	getSessionSuccess: createAction(FETCH_SESSION_SUCCESS),
	getSessionFailure: createAction(FETCH_SESSION_FAILURE),
	resetStores: createAction(RESET_STORES)
};

export const selectors = {
	getSession: createSelector(STORE_NAME, getSession),
	getStudioLogo: createSelector(STORE_NAME, getStudioLogo),
	isLoggedIn: createSelector(STORE_NAME, isLoggedIn),
	isLoggingIn: createSelector(STORE_NAME, isLoggingIn),
	isLoggingOut: createSelector(STORE_NAME, isLoggingOut),
	isResetingPassword: createSelector(STORE_NAME, isResetingPassword),
	isResetPasswordSuccess: createSelector(STORE_NAME, isResetPasswordSuccess),
	getLastAttemptedAt: createSelector(STORE_NAME, getLastAttemptedAt),
	getLoginError: createSelector(STORE_NAME, getLoginError),
	getTwoFactorLogin: createSelector(STORE_NAME, getTwoFactorLogin),
	getResetPasswordError: createSelector(STORE_NAME, getResetPasswordError),
	isLoading: createSelector(STORE_NAME, isLoading),
	isLoaded: createSelector(STORE_NAME, isLoaded),
	getLastLoadedAt: createSelector(STORE_NAME, getLastLoadedAt)
};

function getSession(state) {
	return state.session;
}

function getStudioLogo(state) {
	if(state.session?.studios?.length === 1) {
		return state.session?.studios[0].$links.logo;
	}

	return '/img/pixwel.logo.png';
}

function isLoggedIn(state) {

	return !!state.session && !state.session?.twofactor;
}

function isLoggingIn(state) {
	return state.isLoggingIn;
}

function isResetingPassword(state) {
	return state.isResetingPassword;
}

function isResetPasswordSuccess(state) {
	return state.resetPasswordSuccess;
}

function isLoggingOut(state) {
	return state.isLoggingOut;
}

function getLastAttemptedAt(state) {
	return state.lastAttemptedAt;
}

function getLoginError(state) {
	return state.loginError;
}
function getTwoFactorLogin(state) {
	return state.session?.twofactor;
}

function getResetPasswordError(state) {
	return state.resetPasswordError;
}

function isLoading(state) {
	return state.isLoading;
}

function isLoaded(state) {
	return !!state.lastLoadedAt;
}

function getLastLoadedAt(state) {
	return state.lastLoadedAt;
}

export function *watchSession() {
	yield takeLatest(LOGIN, login);
	yield takeLatest(TWO_FACTOR_LOGIN, twoFactorLogin);
	yield takeLatest(LOGOUT, logout);
	yield takeLatest(CLEAR_SESSION, clearSession);
	yield takeLatest(RESET_PASSWORD, resetPassword);
	yield takeLatest(FETCH_SESSION, fetchSession);
}
