import { POSTHOG_CONFIG, POSTHOG_TOKEN } from "@/config/config.3x";

import posthog from "posthog-js";

const FlagService = {
	init: () => {
		// if(window.Cypress) {
		// 	return;
		// }
		if (typeof window !== "undefined") {
			return posthog.init(POSTHOG_TOKEN, POSTHOG_CONFIG);
		}
	},
	identify: (session) => {
		// if(window.Cypress) {
		// 	return;
		// }
		posthog.identify(session._id, {
			name: session.name,
			email: session.emailNormal,
		});
	},
	isFeatureEnabled: (featureName) => {
		// if(window.Cypress) {
		// 	return true;
		// }
		return posthog.isFeatureEnabled(featureName);
	},
};

export default FlagService;
