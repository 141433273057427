import _ from 'lodash';
import _get from 'lodash.get';
import api, { buildURL } from './api-service';
import { cdn } from '@/modules/services/image-service';
import ValidationService from './validation-service';

const CREATE_ASSET_MUTATION = `
mutation createAsset($input: AssetInput) {
	createAsset(input: $input) {
		asset {
			id
			name
			embargo
		}
	}
}
`;

// eslint-disable-next-line no-unused-vars

export const ASSET_CONSTRAINTS = {
	name: {
		presence: {
			allowEmpty: false
		}
	},
	slug: {
		presence: {
			allowEmpty: false
		},
		format: {
			pattern: /^[a-zA-Z0-9-]+$/
		}
	},
	usage: {
		presence: {
			allowEmpty: false
		}
	},
	type: {
		presence: {
			allowEmpty: false
		}
	}
};
export const ASSET_CONSTRAINTS_SHORT = {
	name: {
		presence: {
			allowEmpty: false
		}
	},
	usage: {
		presence: {
			allowEmpty: false
		}
	},
	type: {
		presence: {
			allowEmpty: false
		}
	}
};

const PROJECT_ASSETS_WITH_PREVIEW_QUERY = `
	query ProjectAssetsWithPreviews($id: ID!) {
		assets(project: $id, limit: 999) {
			nodes {
				id
				name
				document
				type
				unorderable
				mediaType
				creative
				private
				thumbnail
				permissions
				created
				length
				flags
				autosubIngest
				vote {
					vote
				}
				share
				transcription
			}
		}
	}
`;

const AssetService = {

	createAsset(asset) {
		const params = {
			input: {
				...asset,
				...asset.length ? { length: parseInt(asset.length, 10) } : {}
			}
		};
		return api.graphql.request(CREATE_ASSET_MUTATION, params);
	},

	validateAsset(asset) {
		return ValidationService.validate(asset, ASSET_CONSTRAINTS);
	},

	validateAssetShort(asset) {
		return ValidationService.validate(asset, ASSET_CONSTRAINTS_SHORT);
	},

	addAssetAudio(data) {
		return api.client.post('/asset-audios', data)
			.then((res) => res.data);
	},

	deleteAssetAudio(id) {
		return api.client.delete(`/asset-audios/${id}`)
			.then((res) => res.data);
	},

	getAssetAudiosList(id) {
		return api.client.get(`/asset-audios?asset=${id}`)
			.then((res) => res.data);
	},

	// TODO fix autosubs variable - have a single value from the backend
	canAutosub(asset) {
		return ( _get(asset, '$permissions.canAutosub', false)
			&& _get(asset, '$permissions.access', []).includes('autosubs') )
				|| _get(asset, 'permissions.access', []).includes('autosubs');
	},

	canOrder(asset) {
		// @todo remove $permissions
		return !asset.unorderable && !asset.creative && (asset?.$permissions?.access.includes('requests') || asset?.permissions?.access.includes('requests'));
	},

	canShare(asset) {
		// @todo remove $permissions
		return asset?.$permissions?.access.includes('sharing') || asset?.permissions?.access.includes('sharing');
	},

	canOrderLanguage(asset, language, withAutosubs = false) {
		let key = withAutosubs ? 'autosubs' : 'requests';
		let access = _get(asset, '$permissions.access', []);
		let canAccess = access.indexOf(key) !== -1;

		if( !canAccess ) {
			return false;
		}

		let canOrderLanguages = _get(asset, `$permissions.sources.${key}.languages`);
		if( !_.isArray(canOrderLanguages) ) {
			return false;
		}

		let canOrder = !canOrderLanguages.length
			|| canOrderLanguages.indexOf(language.code) !== -1;

		return canOrder;
	},

	canOrderTerritory(asset, territory, withAutosubs = false) {
		let key = withAutosubs ? 'autosubs' : 'requests';
		let access = _get(asset, '$permissions.access', []);
		let canAccess = access.indexOf(key) !== -1;

		if( !canAccess ) {
			return false;
		}

		let canOrderTerritories = _get(asset, `$permissions.sources.${key}.countries`);
		if( !_.isArray(canOrderTerritories) ) {
			return false;
		}

		let canOrder = !canOrderTerritories.length
			|| canOrderTerritories.indexOf(territory.code) !== -1;

		return canOrder;
	},

	getProjectAssets(projectId) {
		return api.client.get(`/assets?project=${projectId}`, { headers: { 'X-Range': 'resources=0-200' } })
			.then((res) => res.data);
	},

	getProjectAssetsQuery(projectId) {
		return api.graphql.request(PROJECT_ASSETS_WITH_PREVIEW_QUERY, { id: projectId }).then((data) => {
			return data?.assets?.nodes;
		});
	},

	getSimilarAssets(params) {
		return api.client.get(buildURL(`/assets`, params), { headers: { 'X-Range': 'resources=0-200' } })
			.then((res) => res.data);
	},

	getAssetById(id, options = {}) {
		let url = `/assets/${id}`;

		// Check if an extended response is being requested
		// @TODO: Remove this when the API is updated to return extended data by default
		if( options.withExtended ) {
			url = `/assets?id=${id}&$permissions=extended`;
		}

		return api.client.get(url)
			.then((res) => res.data[0] || res.data);
	},

	getRelatedAssets(assetId) {
		return api.client.get(`/assets/related/${assetId}`)
			.then((res) => res.data);
	},

	getResizedThumbnail(asset) {
		// @todo remove $links when GraphQL is used everywhere
		let thumbnailUrl = asset?.$links?.thumbnail ?? asset?.thumbnail;

		if( !thumbnailUrl ) {
			return '//via.placeholder.com/160x67?text=No%20Thumbnail';
		}

		return cdn(thumbnailUrl, { width: 160 });
	},

	getLength(asset) {
		const length = asset.length;

		if( !length ) {
			return null;
		}

		const minutes = ~~(length / 60);
		const seconds = length - (minutes * 60);

		const minutesStr = ('' + minutes).padStart(2, '0');
		const secondsStr = ('' + seconds).padStart(2, '0');

		return `${minutesStr}:${secondsStr}`;
	},

	isNew(asset) {
		return asset?.flags?.includes('new') || asset?.$flags?.includes('new');
	},

	isRecentlyUpdated(asset) {
		return asset?.flags?.includes('updated') || asset?.$flags?.includes('updated');
	},

	isPrintAsset(asset) {
		return asset.mediaType === 'image';
	},

	isRoadshowAsset(asset) {
		return asset.type === 'roadshow';
	},

	isTrailerAsset(asset) {
		return asset.type.indexOf('trailer') > -1;
	},

	updateAsset(id, data) {
		return api.client.patch(`/assets/${id}`, data)
			.then((res) => res.data);
	},

	searchAssets(query, limit=20) {
		return api.client.get(buildURL('/assets', { q: query, limit: limit }))
			.then((res) => res.data);
	},

	triggerGraphicsAnalysis(id) {
		return api.client.get(`/assets/analyze?id=${id}`).then((res) => res.data && res.status === 200);
	},

	deleteAsset(id) {
		return api.client.delete(`/assets/${id}`).then((res) => res.data);
	}
};

export default AssetService;
