import api, { buildURL } from './api-service';

const DownloadsPageService = {

	getAllDownloads({ userId, query, start, end }) {
		const params = {
			user: userId,
			order: {
				downloaded: 'desc'
			}
		};
		if(query) {
			params.q = query;
		}

		return api.client.get(
			buildURL('/downloads', params),
			{ headers: { 'X-Range': `resources=${start}-${end}` } }
		);
	},

	fetchNewDownloads({ userId, mostRecentLoadAt }) {
		const params = {
			user: userId,
			created: {
				from: mostRecentLoadAt
			},
			order: {
				downloaded: 'desc'
			}
		};

		return api.client.get(buildURL('/downloads', params));
	}
};

export default DownloadsPageService;
