export function cdn(url) {
	if (!url) {
		return;
	}
	if (url.includes("apple-touch-icon")) {
		return url;
	}
	let uri = new URL(`https:${url}`);
	switch (process.env.NEXT_PUBLIC_ENV) {
		case "development":
			return `http://${url}`;
		case "staging":
			return `https://icdn-staging.pixwel.com/${uri.pathname}`;
		case "production":
			return `https://icdn.pixwel.com/${uri.pathname}`;
		default:
			return `https://icdn-staging.pixwel.com/${uri.pathname}`;
	}
}
