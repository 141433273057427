import _ from 'lodash';
import _get from 'lodash.get';
import _capitalize from 'lodash.capitalize';
import moment from 'moment';
import jstz from 'jstz';
import AssetService from './asset-service';
import ValidationService from './validation-service';
import TranslationService from './translation-service';
import UploadService from './upload-service';
import RouteService from './route-service';
import IdService from './id-service';
import api from './api-service';
import { captureError } from '../pixwel/exception-handler.factory';

const ROADSHOW_REQUIRED = {
	if: {
		condition: (value, { meta }) => {
			return AssetService.isRoadshowAsset(meta.asset);
		},
		validations: {
			presence: {
				allowEmpty: false
			}
		}
	}
};
const USAGE_REQUIRED = {
	custom: {
		fn: (value, { fullObject }) => {
			// We can check all usage sections instead of the asset specific ones
			// as long as no usage options are defaulted to on.
			let valid = _.reduce(fullObject.usage, (result, usageOptions) => {
				return result || _.reduce(usageOptions, (result, value, key) => {
					if( ['sendTo', 'shipping', 'deliveryDeadline'].indexOf(key) >= 0 ) {
						return result;
					}

					return result || value;
				}, result);
			}, false);

			return valid ? undefined : 'Must select at least one usage';
		}
	}
};
export const ORDER_CONSTRAINTS = {
	due: {
		dateTime: {
			if: {
				condition: (value, { meta }) => {
					const { order } = meta;
					return !(order.localization.autosubs || order.localization.auto);
				},
				validations: {
					presence: true,
					datetime: {
						earliest: moment.utc().add(1, 'day'),
						tooEarly: 'must be at least 24 hours from now'
					}
				}
			}
		}
	},
	localization: {
		language: {
			presence: {
				allowEmpty: false
			}
		},
		territory: {
			presence: {
				allowEmpty: false
			}
		},
		audioFile: {
			if: {
				condition: (value, { meta }) => {
					const { order } = meta;
					return order.localization.auto === 'audio';
				},
				validations: {
					presence: {
						allowEmpty: false
					}
				}
			}
		},
		translationProviderType: {
			presence: {
				allowEmpty: false
			}
		},
		translationProvider: {
			if: {
				condition: (value, { meta }) => {
					const { order } = meta;
					let needsTranslation = [
						order.localization.dialogue,
						order.localization.narration,
						order.localization.graphics
					].indexOf('Subtitled') > -1;

					if( !needsTranslation ) {
						return false;
					}

					return order.localization.translationProviderType === 'email';
				},
				validations: {
					presence: {
						allowEmpty: false
					}
				}
			}
		},
		translationFile: {
			if: {
				condition: (value, { meta }) => {
					const { order } = meta;
					let needsTranslation = [
						order.localization.dialogue,
						order.localization.narration,
						order.localization.graphics
					].indexOf('Subtitled') > -1;

					if( !needsTranslation ) {
						return false;
					}

					return order.localization.translationProviderType === 'upload';
				},
				validations: {
					presence: {
						allowEmpty: false
					}
				}
			}
		}
	},
	tags: {
		tags: {
			if: {
				condition: (value, { meta }) => {
					let { order, asset } = meta;
					return !(order.localization.autosubs || order.localization.auto)
						&& !AssetService.isPrintAsset(asset)
						&& !AssetService.isRoadshowAsset(asset);
				},
				validations: {
					presence: {
						allowEmpty: false,
						message: 'must have at least 1 tag'
					},
					arrayOf: {
						tag: {
							presence: {
								allowEmpty: false
							},
							unique: {
								getAllValues: (value, { fullObject }) => {
									return _.map(fullObject.tags, 'tag');
								},
								compareFn: (v1, v2) => {
									return v1 === v2;
								},
								findDuplicates: (tags) => {
									return (new Set(tags.map( (e) => e.tag.toLowerCase()))).size !== tags.length;
								}
							}
						}
					}
				}
			}
		}
	},
	usage: {
		broadcast: USAGE_REQUIRED,
		online: USAGE_REQUIRED,
		theatrical: USAGE_REQUIRED,
		print: USAGE_REQUIRED,
		digital: USAGE_REQUIRED,
		roadshow: USAGE_REQUIRED,
		streaming: USAGE_REQUIRED
	},
	questions: {
		eventName: ROADSHOW_REQUIRED,
		territory: ROADSHOW_REQUIRED,
		venueName: ROADSHOW_REQUIRED,
		venueAddress: ROADSHOW_REQUIRED,
		rehearsalDate: ROADSHOW_REQUIRED,
		eventDate: ROADSHOW_REQUIRED,
		attendeeDetails: ROADSHOW_REQUIRED
	},
	additionalInfo: {}
};

const WorkRequestOrderService = {

	createBlankOrder: () => {
		let future = new Date();
		future.setTime(future.getTime() + (48 * 60 * 60 * 1000));

		return {
			due: {
				dateTime: future
			},
			localization: {
				language: '',
				territory: '',
				autosubs: false,
				auto: false,
				dialogue: 'OV',
				narration: 'OV',
				graphics: 'OV',
				printTranslation: 'ov',
				roadshowTranslation: 'OV',
				translationProviderType: 'me',
				translationProvider: null,
				translationFile: null,
				needsApproval: false,
				dubbed: false,
				audioFile: null
			},
			tags: {
				tags: [
					createTag()
				]
			},
			usage: {
				broadcast: {
					sendTo: [],
					'SD 4:3': false,
					'SD 16:9': false,
					'HD': false
				},
				online: {
					sendTo: [],
					'Orban': false,
					'1920p': false,
					'1350p': false,
					'1080p': false,
					'720p': false,
					'480p': false,
					'360p': false
				},
				theatrical: {
					sendTo: [],
					deliveryDeadline: {
						dateTime: future
					},
					shipping: {
						qty: 0,
						addresses: []
					},
					'2D Flat': false,
					'2D Scope': false,
					'2D Combo': false,
					'3D Flat': false,
					'3D Scope': false,
					'3D Combo': false,
					'2D Flat (4K)': false,
					'2D Scope (4K)': false,
					'2D Combo (4K)': false,
					'3D Flat (4K)': false,
					'3D Scope (4K)': false,
					'3D Combo (4K)': false,
					'IMAX Laser': false,
					'IMAX Xenon': false,
					'ATMOS': false
				},
				print: {
					sendTo: [],
					cmyk: false
				},
				digital: {
					sendTo: [],
					rgb: false
				},
				roadshow: {
					sendTo: [],
					physical: false,
					aspera: false
				},
				streaming: {
					sendTo: [],
					streamingfile: false
				}
			},
			questions: {
				eventName: '',
				territory: '',
				venueName: '',
				venueAddress: '',
				rehearsalDate: null,
				eventDate: null,
				attendeeDetails: '',
				securityDetails: '',
				notes: ''
			},
			additionalInfo: {
				specialInstructions: '',
				files: []
			}
		};
	},

	validateOrder: (order, asset) => {
		let options = {
			meta: {
				order,
				asset
			}
		};

		return ValidationService.validateNested(order, ORDER_CONSTRAINTS, options);
	},

	wrapOrderWithValidations: (order, asset) => {
		let validations = WorkRequestOrderService.validateOrder(order, asset);

		let wrappedOrder = {};
		_.forEach(order, (orderSection, section) => {
			// If the section is simple data type (i.e. not an object)
			// then we skip validation. This is probably meta information like _id
			if( !_.isObject(orderSection) ) {
				return;
			}

			wrappedOrder[section] = wrapFields(orderSection, validations[section], BLANK_ORDER[section]);
		});

		return wrappedOrder;

		function wrapFields(section, sectionValidation, BLANKS) {
			let fields = {};

			_.forEach(section, (fieldValue, field) => {
				let fieldValidation = _get(sectionValidation, field);
				let isArray = _.isArray(fieldValue);

				let wrappedField = isArray ? [] : {};
				if(field === 'theatrical') {
					wrappedField.isPristine = _.isEqual(
						{ ...fieldValue, deliveryDeadline: '' },
						{ ...BLANKS[field], deliveryDeadline: '' }
					);
				}
				else {
					wrappedField.isPristine = _.isEqual(fieldValue, BLANKS[field]);
				}
				wrappedField.isInvalid = !_.isEmpty(fieldValidation);
				wrappedField.value = fieldValue;
				wrappedField.validations = fieldValidation;

				if( isArray ) {
					let arrayOfValidations = _get(fieldValidation, 'arrayOf');

					_.forEach(fieldValue, (element, i) => {
						let elementValidation = _get(arrayOfValidations, i);
						let wrappedElement = elementValidation
							? wrapFields(element, elementValidation, BLANK_PARTIALS[field])
							: undefined;

						wrappedField.push(wrappedElement);
					});
				}

				fields[field] = wrappedField;
			});

			return fields;
		}
	},

	getCostEstimate: (order, asset, assetType) => {
		let workRequest = WorkRequestOrderService.toWorkRequest(order, asset, assetType);

		return api.client.post('/workrequests/estimate', workRequest)
			.then((estimate) => _.pick(estimate.data, ['applied', 'base', 'errors']));
	},

	doesOrderNeedTranslation: (order) => {
		const {
			dialogue,
			narration,
			graphics,
			printTranslation,
			roadshowTranslation
		} = order.localization;

		const isPrintTranslation = ['date', 'full']
			.includes(printTranslation);

		const isAvTranslation = [dialogue, narration, graphics, roadshowTranslation]
			.includes('Subtitled') || [graphics].includes('Dedicated / Localized');

		return isPrintTranslation || isAvTranslation;
	},

	doesGfxOrderNeedTranslation: (order, asset) => {
		return ['Dedicated / Localized', 'Subtitled'].includes(order.localization?.graphics)
			&& order.localization?.dialogue !== 'Subtitled'
			&& order.localization?.narration !== 'Subtitled'
			&& !!asset.transcription?.graphics;
	},

	toWorkRequest: (order, asset, assetType) => {
		let isPrintAsset = AssetService.isPrintAsset(asset);
		let isRoadshowAsset = AssetService.isRoadshowAsset(asset);

		if( order.localization.autosubs || order.localization.auto ) {
			order.usage.theatrical = BLANK_ORDER.usage.theatrical;
		}

		let transformedOrder = {
			asset: asset._id,
			assetType: asset?.type,
			assetCategory: assetType?.category,
			due: {
				date: order.due.dateTime,
				tz: jstz.determine().name()
			},
			deliveryDeadline: {
				date: order.usage['theatrical']?.deliveryDeadline?.dateTime,
				tz: jstz.determine().name()
			},
			auto: !!order.localization.auto,
			autoMode: order.localization.auto,
			autosubs: order.localization.autosubs,
			language: order.localization.language,
			territory: order.localization.territory,
			dng: {
				dialogue: [order.localization.dialogue],
				graphics: [order.localization.graphics],
				narration: [order.localization.narration]
			},
			audioFile: order.localization.dubbed ? order.localization.audioFile : null,
			instructions: order.additionalInfo.specialInstructions,
			urls: _.mapValues({
				view: RouteService.stateHref('work-request', { id: ':workRequest' }, { absolute: true }),
				manage: RouteService.stateHref('work-request', { id: ':workRequest' }, { absolute: true }),
				invite: RouteService.stateHref('register', { code: ':code' }, { absolute: true }),
				translate: RouteService.stateHref('subtitler', { id: ':workRequest' }, { absolute: true })
			}, decodeURIComponent)
		};

		// If this order has an id, assign it to work request
		if( order._id ) {
			transformedOrder._id = order._id;
		}

		if(order.localization?.translationProvider?.id) {
			transformedOrder.translator = order.localization?.translationProvider?.id;
		}

		if( isPrintAsset ) {
			transformedOrder.dng = 'OV';
			transformedOrder.printTranslation = order.localization.printTranslation;
			transformedOrder.tags = [];

			let { uses, recipients, shipping } = transformUsage(_.pick(order.usage, ['print', 'digital']));
			transformedOrder.uses = uses;
			transformedOrder.recipients = recipients;
			transformedOrder.shipping = shipping;
		}
		else if( isRoadshowAsset ) {
			transformedOrder.dng = order.localization.roadshowTranslation;
			transformedOrder.questions = order.questions;

			let { uses, recipients } = transformUsage(_.pick(order.usage, ['roadshow', 'streaming']));
			transformedOrder.uses = uses;
			transformedOrder.recipients = recipients;
		}
		else {

			if( !(order.localization.autosubs || order.localization.auto)) {
				transformedOrder.tags = _.map(order.tags.tags, (tag) => ({
					name: tag.tag,
					description: tag.instructions,
					files: _.map(tag.files, () => ({}))
				}));
			}

			transformedOrder.files = [{
				name: 'attachments',
				files: []
			}];

			let { uses, dcp, recipients, shipping } = transformUsage(_.pick(order.usage, ['broadcast', 'online', 'theatrical']));
			transformedOrder.uses = uses;
			transformedOrder.dcp = dcp;
			transformedOrder.recipients = recipients;
			transformedOrder.shippingAddresses = shipping;
		}

		return transformedOrder;
	},

	fromWorkRequest: (workRequest) => {
		let isPrintAsset = workRequest.assetCategory === 'print';
		let isRoadshowAsset = workRequest.assetType === 'roadshow';

		let transformedWorkRequest = {
			_id: workRequest._id,
			due: {
				dateTime: moment.unix(workRequest.due.date).toDate()
			},
			localization: {
				language: workRequest.language,
				territory: workRequest.territory,
				autosubs: workRequest.autosubs,
				auto: workRequest.auto,
				autoMode: workRequest.autoMode,
				dialogue: 'OV',
				narration: 'OV',
				graphics: 'OV',
				printTranslation: 'ov',
				roadshowTranslation: 'OV',
				translationProviderType: 'me',
				translationProvider: null,
				translationFile: null,
				needsApproval: false,
				dubbed: false,
				audioFile: null
			},
			usage: {
				// TODO - leave this as is, loop through and switch properties to true below
				broadcast: {
					sendTo: [],
					'SD 4:3': false,
					'SD 16:9': false,
					HD: false
				},
				online: {
					sendTo: [],
					Orban: false,
					'1920p': false,
					'1350p': false,
					'1080p': false,
					'720p': false,
					'480p': false,
					'360p': false
				},
				theatrical: {
					sendTo: [],
					shipping: {
						qty: 0,
						addresses: []
					},
					'2D Flat': false,
					'2D Scope': false,
					'2D Combo': false,
					'3D Flat': false,
					'3D Scope': false,
					'3D Combo': false,
					'2D Flat (4K)': false,
					'2D Scope (4K)': false,
					'2D Combo (4K)': false,
					'3D Flat (4K)': false,
					'3D Scope (4K)': false,
					'3D Combo (4K)': false,
					'IMAX Laser': false,
					'IMAX Xenon': false,
					ATMOS: false
				},
				print: {
					sendTo: [],
					cmyk: false
				},
				digital: {
					sendTo: [],
					rgb: false
				},
				roadshow: {
					sendTo: [],
					physical: false,
					aspera: false
				},
				streaming: {
					sendTo: [],
					streamingfile: false
				}
			},
			questions: {
				eventName: '',
				territory: '',
				venueName: '',
				venueAddress: '',
				rehearsalDate: null,
				eventDate: null,
				attendeeDetails: '',
				securityDetails: '',
				notes: ''
			},
			additionalInfo: {
				specialInstructions: workRequest.instructions || '',
				files: workRequest.files
			}
		};

		if(workRequest.deliveryDeadline) {
			transformedWorkRequest.usage.theatrical.deliveryDeadline = {
				dateTime: moment(workRequest.deliveryDeadline.date).toDate()
			};
		}

		if( isPrintAsset ) {
			// localization
			transformedWorkRequest.localization.printTranslation = workRequest.printTranslation;

			// tags
			transformedWorkRequest.tags = [];

			// usage
			transformedWorkRequest.usage.print.cmyk = !!workRequest.uses.details.Cmyk;
			transformedWorkRequest.usage.digital.rgb = !!workRequest.uses.details.Rgb;

			// print recipients
			if( workRequest.recipients.Cmyk.length > 0 ) {
				transformedWorkRequest.usage.print.sendTo =
					IdService.convertToIds(workRequest.recipients.Cmyk);
			}

			// digital recipients
			if( workRequest.recipients.Rgb.length > 0 ) {
				transformedWorkRequest.usage.digital.sendTo =
					IdService.convertToIds(workRequest.recipients.Rgb);
			}
		}
		else if( isRoadshowAsset ) {
			// localization
			transformedWorkRequest.localization.roadshowTranslation = workRequest.dng;

			// translation
			if( workRequest.translation ) {
				transformedWorkRequest.localization = {
					...transformedWorkRequest.localization,
					translationProviderType: getTranslationProviderType(workRequest),
					translationProvider: IdService.convertToId(workRequest.translation.translator),
					translationFile: workRequest.translation.file || null,
					needsApproval: workRequest.translation?.approval
						|| workRequest.graphicsTranslation?.approval
						|| false
				};
			}

			// usage
			if( workRequest.uses.details.Dcp ) {
				workRequest.uses.details.Dcp.forEach((use) => {
					transformedWorkRequest.usage.roadshow[use.value] = true;
				});
			}

			if( workRequest.uses.details.Streamingfile ) {
				workRequest.uses.details.Streamingfile.forEach((use) => {
					transformedWorkRequest.usage.streaming[use.value] = true;
				});
			}

			// recipients
			if( workRequest.recipients.Dcp.length > 0 ) {
				transformedWorkRequest.usage.roadshow.sendTo =
					IdService.convertToIds(workRequest.recipients.Dcp);
			}
			if( workRequest.recipients.Streamingfile.length > 0 ) {
				transformedWorkRequest.usage.streaming.sendTo =
					IdService.convertToIds(workRequest.recipients.Streamingfile);
			}

			// questions
			transformedWorkRequest.questions = workRequest.questions;
		}
		else {
			// localization
			transformedWorkRequest.localization = {
				...transformedWorkRequest.localization,
				dialogue: workRequest.dng.dialogue[0],
				narration: workRequest.dng.narration[0],
				graphics: workRequest.dng.graphics[0]
			};

			// tags
			if( !(workRequest.autosubs || workRequest.auto) ) {
				transformedWorkRequest.tags = {
					tags: workRequest.tags.map((tag) => ({
						tag: tag.name,
						instructions: tag.description || '',
						files: tag.files || []
					}))
				};
			}

			// translation
			if( workRequest.translation ) {
				transformedWorkRequest.localization = {
					...transformedWorkRequest.localization,
					translationProviderType: getTranslationProviderType(workRequest),
					translationProvider: IdService.convertToId(workRequest.translation.translator),
					translationFile: workRequest.translation.file || null,
					needsApproval: workRequest.translation?.approval
						|| workRequest.graphicsTranslation?.approval
						|| false
				};
			}

			// broadcast/online usage
			_.forEach(workRequest.uses.details, (usesArr, category) => {
				// 'Broadcast' -> 'broadcast'
				let useCategory = category.toLowerCase();
				let useKey;

				usesArr.forEach((use) => {
					// 'Sd 4:3' -> 'SD 4:3'
					useKey = useCategory === 'broadcast' ? use.value.toUpperCase() : use.value;
					transformedWorkRequest.usage[useCategory][useKey] = true;
				});
			});

			// dcp usage
			Object.keys(workRequest.dcp.details).forEach((key) => {
				let details = workRequest.dcp.details[key];
				let useCategory = 'theatrical';
				let useKey = key;
				transformedWorkRequest.usage[useCategory][useKey] = true;
				transformedWorkRequest.usage[useCategory].shipping.qty += details.quantity;
			});
			if( transformedWorkRequest.usage['theatrical'].shipping.qty ) {
				transformedWorkRequest.usage['theatrical'].shipping.qty /= Object.keys(workRequest.dcp.details).length;
			}

			// recipients
			_.forEach(workRequest.recipients, (recipients, category) => {
				if( recipients.length > 0 ) {
					let useCategory = category;
					if( useCategory in REVERSE_USAGE_KEY_MAP ) {
						useCategory = REVERSE_USAGE_KEY_MAP[useCategory];
					}
					transformedWorkRequest.usage[useCategory].sendTo = IdService.convertToIds(recipients);
				}
			});

			transformedWorkRequest.usage.theatrical.shipping.addresses = workRequest.shippingAddresses;
			transformedWorkRequest.usage.theatrical.shipping.addresses = setBlankAddresses(
				transformedWorkRequest.usage.theatrical
			);
		}

		return transformedWorkRequest;
	},

	toWorkRequestPatch: async(section, order, workRequest, asset, newDueDate) => {
		let isPrintAsset = AssetService.isPrintAsset(asset);
		let isRoadshowAsset = AssetService.isRoadshowAsset(asset);
		let orderDueDateUnix = moment(order.due.dateTime).unix();
		let patchData = {};
		let translationPatchData = {};
		let graphicsTranslationPatchData = {};
		let uploadRequest;
		let uploadedFile;

		// Localization updated
		if( section === 'localization' ) {
			// due date
			if( orderDueDateUnix !== workRequest.due.date ) {
				patchData.due = {
					...workRequest.due,
					date: order.due.dateTime
				};
			}

			// language & territory
			if( order.localization.language !== workRequest.language ) {
				patchData.language = order.localization.language;
			}
			if( order.localization.territory !== workRequest.territory ) {
				patchData.territory = order.localization.territory;
			}

			const needsTranslation = WorkRequestOrderService.doesOrderNeedTranslation(order);
			const needsGfxTranslation = WorkRequestOrderService.doesGfxOrderNeedTranslation(order, asset);

			// edit existing translation
			if( workRequest.translation ) {
				// language
				if( patchData.language ) {
					translationPatchData.language = patchData.language;
					graphicsTranslationPatchData.language = patchData.language;
				}

				// reset all translation properties if order no longer needs translation
				if( !needsTranslation && !needsGfxTranslation ) {
					patchData.translation = null;
					patchData.status = 'submitted';

					order.localization = {
						...order.localization,
						translationProvider: 'me',
						translationProviderType: null,
						translationFile: null,
						needsApproval: false,
						dubbed: false,
						audioFile: null
					};
				}

				// translator
				if( !order.localization.translationProvider ) {
					order.localization.translationProvider = workRequest.from;
				}

				const translatorId = order.localization.translationProvider._id
					|| order.localization.translationProvider.id;

				if( translatorId !== workRequest.translation.translator._id ) {
					translationPatchData.translator = translatorId;
				}
				if( translatorId !== workRequest.graphicsTranslation?.translator?._id ) {
					graphicsTranslationPatchData.translator = translatorId;
				}

				// translation file
				if( order.localization.translationProviderType === 'upload' && (order.localization.translationFile instanceof File)) {
					uploadRequest = {
						translationId: workRequest.translation._id,
						file: order.localization.translationFile
					};

					try {
						uploadedFile = await UploadService.uploadTranslation(
							order.localization.translationFile,
							uploadRequest,
							_.noop
						);
					}
					catch(err) {
						captureError(err, `Failed to upload translation file`);
					}
					finally {
						translationPatchData.file = uploadedFile;
						translationPatchData.status = 'submitted';
					}
				}
				else {
					translationPatchData.file = null;
				}

				// translation approval
				translationPatchData.approval = order.localization.needsApproval;
				graphicsTranslationPatchData.approval = order.localization.needsApproval;

				await TranslationService.updateTranslation(workRequest.translation._id, translationPatchData);
				if(workRequest.graphicsTranslation) {
					await TranslationService.updateTranslation(
						workRequest.graphicsTranslation._id,
						graphicsTranslationPatchData
					);
				}
			}
			// creating new translation
			else {
				if( (needsTranslation || needsGfxTranslation) && workRequest.status === 'submitted' ) {
					patchData.status = 'incomplete';
				}
				if( order.localization.translationProviderType === 'email' ) {
					patchData.translator = order.localization.translationProvider.id;
				}
			}

			// if new translation is type 'upload', handle uploading file after updating
			// work request, since we'll need the translation id created by the API

			if( isPrintAsset ) {
				patchData.dng = 'OV';
				patchData.printTranslation = order.localization.printTranslation;
			}
			else if( isRoadshowAsset ) {
				patchData.dng = order.localization.roadshowTranslation;
			}
			else {
				// AV asset
				patchData.dng = {
					dialogue: [order.localization.dialogue],
					graphics: [order.localization.graphics],
					narration: [order.localization.narration]
				};
			}
		}

		// Tags updated
		if( section === 'tags' ) {
			if( !(order.localization.autosubs || order.localization.auto) ) {
				patchData.tags = order.tags.tags.map((tag) => ({
					name: tag.tag,
					description: tag.instructions,
					files: tag.files.filter((file) => typeof file === 'string') // keep any existing files (URLs)
				}));
			}
		}

		// Usage updated
		if( section === 'usage' ) {
			let keys = [];

			if( isPrintAsset ) {
				keys.push('print', 'digital');
			}
			else if( isRoadshowAsset ) {
				keys.push('roadshow', 'streaming');
			}
			else {
				// AV asset
				keys.push('broadcast', 'online', 'theatrical');
			}

			let { uses, dcp, recipients, shipping } = transformUsage(_.pick(order.usage, keys));
			patchData.uses = uses;
			patchData.dcp = dcp;
			patchData.recipients = recipients;
			patchData.shippingAddresses = shipping;
		}

		// Due date pushed 24 hrs
		if( newDueDate ) {
			patchData.due = {
				...workRequest.due,
				date: newDueDate
			};
		}

		return patchData;
	}
};

// Used for checking if an order's field is pristine
const BLANK_ORDER = WorkRequestOrderService.createBlankOrder();

const BLANK_PARTIALS = {
	tags: createTag()
};

const USAGE_KEY_MAP = {
	Roadshow: 'Dcp',
	Theatrical: 'Dcp',
	Streaming: 'Streamingfile',
	Digital: 'Rgb',
	Print: 'Cmyk'
};

const REVERSE_USAGE_KEY_MAP = {
	'2D Flat': 'theatrical',
	'2D Scope': 'theatrical',
	'2D Combo': 'theatrical',
	'3D Flat': 'theatrical',
	'3D Scope': 'theatrical',
	Broadcast: 'broadcast',
	Online: 'online',
	dcp: 'theatrical',
	streamingfile: 'streaming',
	rgb: 'digital',
	cmyk: 'print'
};

function createTag() {
	return {
		tag: '',
		instructions: '',
		files: []
	};
}

function transformUsage(usage) {
	let uses = {
		items: [],
		details: {}
	};
	let dcp = {
		items: [],
		details: {}
	};
	let recipients = {};
	let shipping = {};

	_.forEach(usage, (use, category) => {
		let useKey = _capitalize(category);

		if( useKey in USAGE_KEY_MAP ) {
			useKey = USAGE_KEY_MAP[useKey];
		}

		// Get all selected usage
		let values = _.filter(_.keys(use), (usage) => {
			return ['sendTo', 'shipping', 'deliveryDeadline'].indexOf(usage) < 0 && use[usage];
		});

		// If values have been selected, add them to the work order
		if( values.length ) {
			if( useKey === 'Dcp' && category !== 'roadshow' ) {
				values.forEach((dcpItem) => {
					let qty = parseInt(use.shipping.qty);

					dcp.items.push(dcpItem);
					dcp.details[dcpItem] = {
						link: true,
						ship: qty > 0,
						quantity: qty
					};
					recipients[dcpItem] = IdService.convertFromIds(use.sendTo);
					if( use.shipping.qty ) {
						shipping = use.shipping.addresses;
					}
				});
			}
			else {
				uses.items.push(useKey);
				uses.details[useKey] = _.map(values, (value) => ({
					value: value,
					// TODO: Shipping
					ship: false
				}));
				if( use.sendTo.length ) {
					recipients[useKey] = IdService.convertFromIds(use.sendTo);
				}
			}
		}
	});

	return {
		uses,
		dcp,
		recipients,
		shipping
	};
}

function getTranslationProviderType(workRequest) {
	let { translation, from } = workRequest;
	let isUserTranslator = translation.translator._id === from._id;

	if( !isUserTranslator ) {
		return 'email';
	}

	if( translation.file ) {
		return 'upload';
	}

	return 'me';
}

function setBlankAddresses(field) {
	field.sendTo.forEach((user) => {
		if( !field.shipping.addresses.hasOwnProperty(user.name) ) {
			field.shipping.addresses[user.name] = {
				name: '$links' in user ? user.name : '',
				address: '',
				city: '',
				state: '',
				postalCode: '',
				country: ''
			};
		}
	});

	return field.shipping.addresses;
}

export default WorkRequestOrderService;
