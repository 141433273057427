import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension'; // redux-devtools-extension/developmentOnly

import rootSaga from '@/store/root-saga';
import rootReducer from '@/store/root-reducer';
import LogRocket from 'logrocket';
import { createLogger } from 'redux-logger';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [];

// eslint-disable-next-line no-undef
// if(process.env.NODE_ENV !== `production`) {
// 	const logger = createLogger({
// 		collapsed: true
// 	});
// 	middlewares.push(logger);
// }

const store = createStore(rootReducer, composeWithDevTools(
	applyMiddleware(sagaMiddleware, LogRocket.reduxMiddleware(), ...middlewares)
));

sagaMiddleware.run(rootSaga);

export default store;
