import _ from 'lodash';
import keyBy from 'lodash.keyby';
import { createAction } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { handleError } from './common';
import TerritoryService from '@/modules/services/territory-service';

export const STORE_NAME = 'territoriesStore';

export function *fetchTerritories({ payload }) {
	try {
		yield put(internalActions.getTerritoriesRequest(payload));
		const territories = yield TerritoryService.getAllTerritories();
		yield put(internalActions.getTerritoriesSuccess(territories));
		return territories;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getTerritoriesFailure(err));
		return err;
	}
}

export const FETCH_TERRITORIES = 'territories.fetch';
export const FETCH_TERRITORIES_REQUEST = 'territories.fetch.request';
export const FETCH_TERRITORIES_SUCCESS = 'territories.fetch.success';
export const FETCH_TERRITORIES_FAILURE = 'territories.fetch.failure';

export const INITIAL_STATE = {
	isLoading: false,
	lastLoadedAt: null,
	territories: {}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case FETCH_TERRITORIES_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case FETCH_TERRITORIES_SUCCESS: {
			let now = new Date();
			let territories = keyBy(action.payload, 'id');

			return {
				...state,
				territories,
				isLoading: false,
				lastLoadedAt: now
			};
		}

		case FETCH_TERRITORIES_FAILURE:
			return {
				...state,
				isLoading: false
			};

		default:
			return state;
	}
}

export const actions = {
	getTerritories: createAction(FETCH_TERRITORIES)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	getTerritoriesRequest: createAction(FETCH_TERRITORIES_REQUEST),
	getTerritoriesSuccess: createAction(FETCH_TERRITORIES_SUCCESS),
	getTerritoriesFailure: createAction(FETCH_TERRITORIES_FAILURE)
};

export const selectors = {
	getTerritories,
	isLoading,
	isLoaded,
	getLastLoadedAt
};

function getTerritories(state) {
	let territoriesStore = state[STORE_NAME];

	return _.values(territoriesStore.territories);
}

function isLoading(state) {
	let territoriesStore = state[STORE_NAME];

	return territoriesStore.isLoading;
}

function isLoaded(state) {
	let territoriesStore = state[STORE_NAME];

	return !!territoriesStore.lastLoadedAt;
}

function getLastLoadedAt(state) {
	let territoriesStore = state[STORE_NAME];

	return territoriesStore.lastLoadedAt;
}

export function *watchTerritories() {
	yield takeLatest(FETCH_TERRITORIES, fetchTerritories);
}
