import Aspera from './aspera';

class Transfer {
	constructor(data) {
		this.id = data.uuid;
		this.fileName = data.title;
		this.percentage = (data.percentage * 100).toFixed(2);
		this.rate = data.calculated_rate_kbps;
		this.status = data.status;
		this.addTime = data.add_time;

		this.direction = data.transfer_spec?.direction;

		this.bytes = {
			total: data.bytes_expected,
			received: data.bytes_written
		};

		this.error = !data.error_code
			? null
			: {
				code: data.error_code,
				desc: data.error_desc
			};

		// These are currently untrustworthy because TransferManager creates a new Transfer record on each update,
		// rather then updating existing fields.
		this.isPausing = false;
		this.isResuming = false;
		this.isCancelling = false;
	}

	get isPaused() {
		return this.status === Aspera.TRANSFER_STATUS.CANCELLED;
	}

	get isDownloading() {
		return this.status === Aspera.TRANSFER_STATUS.INITIATING
			|| this.status === Aspera.TRANSFER_STATUS.RUNNING
			|| this.status === Aspera.TRANSFER_STATUS.WILLRETRY
			|| this.status === Aspera.TRANSFER_STATUS.QUEUED;
	}

	// Cancel means removed, not paused. Aspera treats paused as cancelled.
	get isCancelled() {
		return this.status === Aspera.TRANSFER_STATUS.REMOVED;
	}

	get isFailed() {
		return this.status === Aspera.TRANSFER_STATUS.FAILED;
	}

	get isCompleted() {
		return this.status === Aspera.TRANSFER_STATUS.COMPLETED;
	}

	pause = () => {
		this.isPausing = true;

		return new Promise((resolve, reject) => Aspera.Connect.stopTransfer(this.id, {
			success: (result) => {
				this.status = Aspera.TRANSFER_STATUS.CANCELLED;
				this.isPausing = false;
				resolve(result);
			},
			error: (e) => {
				this.isPausing = false;
				reject(e);
			}
		}));
	}

	resume = () => {
		this.isResuming = true;

		return new Promise((resolve, reject) => Aspera.Connect.resumeTransfer(this.id, {}, {
			success: (result) => {
				this.status = Aspera.TRANSFER_STATUS.RUNNING;
				this.isResuming = false;
				resolve(result);
			},
			error: (e) => {
				this.isResuming = false;
				reject(e);
			}
		}));
	}

	cancel = () => {
		this.isCancelling = true;

		return new Promise((resolve, reject) => Aspera.Connect.removeTransfer(this.id, {
			success: (result) => {
				this.status = Aspera.TRANSFER_STATUS.REMOVED;
				this.isCancelling = false;
				resolve(result);
			},
			error: (e) => {
				this.isCancelling = false;
				reject(e);
			}
		}));
	}
}

export default Transfer;
