import api from './api-service';

const STUDIOS_QUERY = `query getStudios ($offset: Int, $limit: Int, $search: String) {
	studios(offset: $offset, limit: $limit, search: $search){
		totalCount
		nodes {
			id
			name
			slug
			logo
			coordinator{
				id: _id
				name
			}
			previewWatermarkFormat
		}
	}
}`;

const STUDIOS_SHORT_QUERY = `query getStudios {
	studios(limit: 100) {
		totalCount
	  	nodes {
			value: id
			label : name
	  }
	}
}
`;
const STUDIOS_SHORT_QUERY_ORIGINAL = `query getStudios {
	studios(limit: 100) {
		totalCount
	  	nodes {
			id
			name
	  }
	}
 }
`;

export const ERROR_STUDIO_UNKNOWN = 'error.studio.unknown';

const StudioService = {
	getAllStudiosShort(original) {
		if(original) {
			return api.graphql.request(STUDIOS_SHORT_QUERY_ORIGINAL).then((data) => data.studios.nodes);

		}
		return api.graphql.request(STUDIOS_SHORT_QUERY).then((data) => data.studios.nodes);
	},
	getAllStudios(params) {
		if(params) {
			return api.graphql.request(STUDIOS_QUERY, params).then((data) => data.studios);
		}
		// migrate later
		// return api.graphql.request(STUDIOS_QUERY).then((data) => data.studios.nodes);
		return api.client.get('/studios', { headers: { 'X-Range': 'resources=0-200' } })
			.then((res) => res.data);
	},

	postStudio(data) {
		return new Promise((resolve, reject) => {
			api.client.post(`/studios`, data)
				.then(resolve)
				.catch((err) => {
					reject({
						code: ERROR_STUDIO_UNKNOWN,
						message: err?.response?.data?.error?.message || 'Failed to create a new studio',
						innerError: err?.response?.data || err
					});
				});
		});
	},

	patchStudio(data) {
		return new Promise((resolve, reject) => {
			api.client.patch(`/studios/${data.slug}`, data)
				.then(resolve)
				.catch((err) => {
					reject({
						code: ERROR_STUDIO_UNKNOWN,
						message: err?.response?.data?.error?.message || 'Failed to update the studio',
						innerError: err?.response?.data || err
					});
				});
		});
	},

	deleteStudio({ slug }) {
		return api.client.delete(`/studios/${slug}`);
	}
};
export default StudioService;
