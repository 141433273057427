import React from 'react';
import { toast } from 'react-toastify';
import Button from '@/modules/components/global/button';

const DEFAULT_TOAST_OPTIONS = {
	theme: 'dark', pauseOnHover: true, hideProgressBar: false, closeOnClick: true
};
const ToastService = {
	create(type, message, options) {
		const allOptions = { ...DEFAULT_TOAST_OPTIONS, ...options };
		// TODO: Verify all type's match
		// TODO: Find all calls and modify options
		return toast[type](message, allOptions);
	},

	createWithAction(type, message, action, actionCb, options) {
		const allOptions = { ...DEFAULT_TOAST_OPTIONS, ...options };
		let content = <div>
			{message}
			<br/>
			<Button variant="primary" onClick={actionCb} style={{ float: 'inline-end', marginTop: 8 }}>{action}</Button>
		</div>;
		return toast[type](content, allOptions);
	},

	dismiss(toastId) {
		toast.dismiss(toastId);
	}
};

export default ToastService;
