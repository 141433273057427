import api from './api-service';
import _ from 'lodash';

const SessionService = {
	getSession() {
		return api.client.get('/session')
			.then((res) => {
				return {
					...res.data,
					studioLogo: () => {
						if (res.data.studios?.length === 1) {
							return res.data.studios[0].$links.logo;
						}

						return '/img/pixwel.logo.png';
					},
					isAdmin: () => {
						return _.chain(res.data.groups)
							.pluck('type')
							.contains('a')
							.value();
					}
				};
			});
	},

	getStudioLogo(session) {
		if (session?.studios?.length === 1) {
			return state.session?.studios[0].$links.logo;
		}

		return '/img/pixwel.logo.png';
	},

	resetPassword({ email, url }) {
		return api.client.post('/password-reset', {
			email: email,
			url: decodeURIComponent(url)
		});
	},

	twoFactorLogin({ token }) {
		return api.client.post('/session', {
			token: token
		});
	}
};

export default SessionService;
