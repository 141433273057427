import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button';

import {
	Container,
	Graphic,
	Text
} from './load-error.css';

function LoadError(props) {
	const {
		variant,
		graphic = "popcorn",
		message = "Oops... Looks like we messed up.",
		onButtonClick,
		buttonText = "Retry",
	} = props;

	return (
		<Container
			className={variant === 'small' ? 'small' : null}
			data-cy="load-error"
			data-testid="load-error"
		>
			<Graphic use={graphic} data-testid="load-error-graphic" />

			<Text data-testid="load-error-msg">
				{ message }
			</Text>

			{onButtonClick && (
				<Button
					size={variant === 'small' ? 'sm' : 'lg'}
					onClick={onButtonClick}
					data-cy="retry-btn"
				>
					{buttonText}
				</Button>
			)}
		</Container>
	);
}

LoadError.propTypes = {
	variant: PropTypes.string,
	graphic: PropTypes.string,
	message: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node
	]),
	onButtonClick: PropTypes.func,
	buttonText: PropTypes.string
};

export default LoadError;
