import { all } from 'redux-saga/effects';
import { watchAssetPage } from '@/store/asset-page';
import { watchAssetTypes } from '@/store/asset-types';
import { watchAssets } from '@/store/assets';
import { watchComments } from '@/store/comments';
import { watchDownloadsPage } from '@/store/downloads-page';
import { watchFiles } from '@/store/files';
import { watchGroups } from '@/store/groups';
import { watchIngestUpload } from '@/store/ingest-upload';
import { watchIngests } from '@/store/ingests';
import { watchLanguages } from '@/store/languages';
import { watchNotifications } from '@/store/notifications';
import { watchStudios } from '@/store/studios';
import { watchProjectCrud } from '@/store/project-crud';
import { watchPreviews } from '@/store/previews';
import { watchProjectSidebar } from '@/store/project-sidebar';
import { watchProjectPage } from '@/store/project-page';
import { watchProjects } from '@/store/projects';
import { watchProjectsIndex } from '@/store/projects-index';
import { watchRegisterPage } from '@/store/register-page';
import { watchUsers } from '@/store/users';
import { watchTerritories } from '@/store/territories';
import { watchSession } from '@/store/session';
import { watchShareEditPage } from '@/store/share-edit-page';
import { watchShares } from '@/store/shares';
import { watchFeedbackPage } from '@/store/share-feedback-page';
import { watchShareViewPage } from '@/store/share-view-page';
import { watchSharesPage } from '@/store/shares-page';
import { watchSubtitlerPage } from '@/store/subtitler-page';
import { watchTranslations } from '@/store/translations';
import { watchWorkRequestPage } from '@/store/work-request-page';
import { watchWorkRequestQueue } from '@/store/work-request-queue';
import { watchWorkRequests } from '@/store/work-requests';
import { watchLocale } from '@/store/locale';
import { watchWorkRequestsPage } from '@/store/work-requests-page';

export function *rootSaga() {
	yield all([
		watchAssetPage(),
		watchAssetTypes(),
		watchAssets(),
		watchComments(),
		watchDownloadsPage(),
		watchDownloadsPage(),
		watchFiles(),
		watchGroups(),
		watchIngestUpload(),
		watchIngests(),
		watchLanguages(),
		watchLocale(),
		watchNotifications(),
		watchProjectCrud(),
		watchPreviews(),
		watchProjectSidebar(),
		watchProjectPage(),
		watchProjects(),
		watchProjectsIndex(),
		watchRegisterPage(),
		watchSession(),
		watchShareEditPage(),
		watchFeedbackPage(),
		watchShareViewPage(),
		watchShares(),
		watchSharesPage(),
		watchStudios(),
		watchSubtitlerPage(),
		watchTerritories(),
		watchTranslations(),
		watchUsers(),
		watchWorkRequestPage(),
		watchWorkRequestQueue(),
		watchWorkRequests(),
		watchWorkRequestsPage()
	]);
}
