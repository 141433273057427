import api from './api-service';
import _ from 'lodash';
import _get from 'lodash.get';

const LANGUAGES_QUERY = `query getLanguages ($offset: Int, $limit: Int, $search: String) {
	languages (offset: $offset, limit: $limit, search: $search) {
		totalCount
		nodes {
			id
			name
			translationFonts
			isoCode
			dcpCode
			code
		}
	}
}`;

const LANGUAGES_QUERY_SHORT = `query getLanguages ($limit: Int, $search: String) {
	languages (limit: $limit, search: $search) {
		totalCount
		nodes {
			id
			value: code
			label: name
		}
	}
}`;

const LANGUAGES_QUERY_SHORT_ORIGINAL = `query getLanguages ($limit: Int, $search: String) {
	languages (limit: $limit, search: $search) {
		totalCount
		nodes {
			code
			name
		}
	}
}`;

export const ERROR_LANGUAGES_UNKNOWN = 'error.language.unknown';

const LanguageService = {

	getAllLanguages(params) {
		if(params) {
			return api.graphql.request(LANGUAGES_QUERY, params).then((data) => data.languages);
		}
		return api.client
			.get('/languages', { headers: { 'X-Range': 'resources=0-200' } })
			.then((res) => {
				return res.data.map((lang) => {
					return {
						...lang,
						font: this.font
					};
				});
			});
	},

	getAllLanguagesShort(params) {
		if(params?.original) {
			return api.graphql.request(LANGUAGES_QUERY_SHORT_ORIGINAL, params).then((data) => data.languages.nodes);
		}
		return api.graphql.request(LANGUAGES_QUERY_SHORT, params).then((data) => data.languages);
	},

	postLanguage(data) {
		return new Promise((resolve, reject) => {
			api.client.post(`/languages`, data)
				.then(resolve)
				.catch((err) => {
					reject({
						code: ERROR_LANGUAGES_UNKNOWN,
						message: err?.response?.data?.error?.message || 'Failed to create a new language',
						innerError: err?.response?.data || err
					});
				});
		});
	},

	patchLanguage(data) {
		return new Promise((resolve, reject) => {
			api.client.patch(`/languages/${data.id}`, data)
				.then(resolve)
				.catch((err) => {
					reject({
						code: ERROR_LANGUAGES_UNKNOWN,
						message: err?.response?.data?.error?.message || 'Failed to update this language: This language has already been used, a migration script is needed to perform such changes',
						innerError: err?.response?.data || err
					});
				});
		});
	},

	deleteLanguage({ id }) {
		return new Promise((resolve, reject) => {
			api.client.delete(`/languages/${id}`)
				.then(resolve)
				.catch((err) => {
					reject({
						code: ERROR_LANGUAGES_UNKNOWN,
						message: _get(err, 'response.data.message', 'An error has occurred while trying to delete this language, Please try again later'),
						innerError: err?.response?.data || err
					});
				});
		});
	},

	font(studioId) {
		let defaultFont = {
			face: 'Arial',
			size: 70
		};

		let font = _.find(this.translationFonts, { studio: studioId })
			|| _.find(this.translationFonts, (studio) => {
				return !!studio;
			})
			|| defaultFont;

		font = _.cloneDeep(font);
		delete font.studio;

		return font;
	},

	isGfxAvailable(languageCode) {
		let unavailableLanguages = [
			'ARA-PFR',
			'EST-RUS',
			'PFR-FLE',
			'GER-PFR',
			'LAT-RUS'
		];

		return !unavailableLanguages.includes(languageCode);
	}
};

export default LanguageService;
