import _ from 'lodash';
import api, { buildURL } from './api-service';
import EventService from './event-service';

const DEFAULT_PAGE_SIZE = 30;
const RESOURCES_HEADER = /^resources \d+-\d+\/(\d+|\*)$/;

const NotificationService = {

	getSummary: async() => {
		return api.client.get(buildURL('/notifications', {
			summary: true
		}))
			.then((res) => res.data);
	},

	markRead: async(_id) => {
		return api.client.patch(`/notifications/${_id}`, {
			read: true,
			viewed: true
		});
	},

	markAllRead: async(type) => {
		return api.client.patch(`/notifications?type=${type}&read=0`, {
			read: true
		});
	},

	markUnread: async(_id) => {
		return api.client.patch(`/notifications/${_id}`, {
			read: false,
			viewed: true
		});
	},

	getPagedNotifications: async(query, criteria) => {
		let {
			page = 0,
			limit = DEFAULT_PAGE_SIZE
		} = criteria;

		let first = page * limit;
		let last = (page + 1) * limit - 1;

		const promise = api.client.get(
			buildURL('/notifications', query),
			{ headers: { 'X-Range': `resources=${first}-${last}` } }
		);

		return promise
			.then((results) => {
				let totalItems = results.length;
				let totalPages = 1;
				let matches = results.headers['x-content-range']?.match(RESOURCES_HEADER) || null;

				if( matches !== null && !_.isUndefined(matches[1]) && matches[1] !== '*') {
					totalItems = matches[1];
					totalPages = Math.ceil(totalItems / limit);
				}

				let pagination = {
					page,
					limit,
					totalPages
				};

				return {
					results: results.data,
					pagination
				};
			});
	},

	onRouteChange: (fn) => {
		EventService.on('$stateChangeStart', () => fn());
	}
};

export default NotificationService;
