// import _ from 'lodash';
// import { pushStateLocationPlugin, servicesPlugin, UIRouterReact } from '@uirouter/react';

// const BOOLEAN_TYPE = {
// 	name: 'boolean',
// 	encode: (val) => val ? 'true' : 'false',
// 	decode: (val) => val === 'true',
// 	is: (val) => _.isBoolean(val)
// };

// const LIST_TYPE = {
// 	name: 'list',
// 	raw: true,
// 	encode: (items) => {
// 		if( !_.isArray(items) ) {
// 			return '';
// 		}

// 		return items.join(',');
// 	},
// 	decode: (item) => {
// 		if( !_.isString(item ) ) {
// 			return [];
// 		}

// 		return item.split(',');
// 	},
// 	is: (val) => {
// 		return _.isString(val) || _.isArray(val);
// 	},
// 	equals: (a, b) => {
// 		return _.isEqual(a, b);
// 	}
// };

// const uiRouter = new UIRouterReact();
// uiRouter.plugin(servicesPlugin); // services plugins is necessary for the router to function
// uiRouter.plugin(pushStateLocationPlugin);
// uiRouter.urlService.config.type('boolean', BOOLEAN_TYPE);
// uiRouter.urlService.config.type('list', LIST_TYPE);
// // to track transitions
// // uiRouter.trace.enable('TRANSITION');
// // uiRouter.urlService.rules.initial({ state: 'projects' });
// uiRouter.urlService.rules.otherwise('/');

// const RouteService = {
// 	_lastState: null,
// 	_isStarted: false,

// 	get lastState() {
// 		return RouteService._lastState;
// 	},

// 	get currentState() {
// 		return uiRouter.stateService.current;
// 	},

// 	registerStates(states) {
// 		states.forEach((state) => {
// 			try {
// 				uiRouter.stateRegistry.register(state);
// 			}
// 			catch(e) {
// 				console.error(e);
// 			}
// 		});

// 		return uiRouter;
// 	},

// 	getRouteInfo() {
// 		return {
// 			name: uiRouter.stateService.current.name,
// 			params: uiRouter.stateService.params
// 		};
// 	},

// 	getRouteParam(param) {
// 		return uiRouter.stateService.params[param];
// 	},

// 	getRouteParams() {
// 		return uiRouter.stateService.params;
// 	},

// 	setRouteParams(params) {
// 		setTimeout(() => uiRouter.stateService.go(uiRouter.globals.$current.name, params, { location: 'replace', inherit: false }));
// 	},

// 	updateRouteParams(params) {
// 		setTimeout(() => uiRouter.stateService.go(uiRouter.globals.$current.name, params, { location: 'replace', inherit: true }));
// 	},

// 	goBack() {
// 		window.history.back();
// 	},

// 	reload() {
// 		return uiRouter.stateService.reload();
// 	},

// 	navigateTo(state, params) {
// 		return new Promise((resolve, reject) => {
// 			setTimeout(() => {
// 				uiRouter.stateService.transitionTo(state, params, { reload: false, inherit: false })
// 					.then(resolve)
// 					.catch(reject);
// 			});
// 		});
// 	},

// 	hardNavigateTo(state, ...params) {
// 		let url = uiRouter.stateService.href(state, ...params);
// 		window.location = url;
// 	},

// 	navigateTo2x(fragment) {
// 		let baseUrl = (window.env === 'production')
// 			? 'https://platform.pixwel.com'
// 			: 'https://staging.pixwel.com';
// 		window.open(`${baseUrl}/${fragment}`);
// 	},

// 	hardNavigateTo2x(url) {
// 		window.open(url);
// 	},

// 	registerRouteInterceptor(interceptor) {
// 		return uiRouter.transitionService.onStart({}, interceptor );
// 	},

// 	stateHref(state, ...params) {
// 		return uiRouter.stateService.href(state, ...params);
// 	}
// };

let RouteService = {
	stateHref: () => {},
	navigateTo: () => {},
	getRouteParams: () => {},
	getRouteParam: () => {},
	updateRouteParams: () => {},
	setRouteParams: () => {},
	getRouteInfo: () => {},
	registerRouteInterceptor: () => {},
	reload: () => {},
}

export default RouteService;
