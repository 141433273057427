import api from './api-service';
import Transfer from './transfer';
import _ from 'lodash';
import { ASPERA_DOWNLOAD_ROOT } from '@/config/config.3x';
import Aspera from './aspera';

const TransferManager = {
	credentials: {},
	transfers: [],
	errors: [],

	setCredentials: (session, token) => {
		TransferManager.credentials = { userId: session._id, token };
	},

	logTransfer: async(files) => {
		try {
			const { data } = await api.client.post('/events', {
				from: TransferManager.credentials.userId,
				files: files.map((file) => file._id),
				type: 'fd'
			});
			return data;
		}
		catch(e) {
			const errors = files.map((file) => {
				return {
					path: file.path,
					code: 'px01',
					desc: 'failed to log event'
				};
			});
			throw errors;
		}
	},

	httpDownload: (transferSpec) => {
		const event = window.document.createEvent('MouseEvents');
		let link = window.document.createElement('a');

		link.href = transferSpec.presignedUrl;
		link.download = transferSpec.presignedUrl?.split('?')[0].split('/').pop();

		event.initEvent('click', true, true);
		link.dispatchEvent(event);
	},

	startDownload: (file) => {
		return new Promise((resolve, reject) => api.client.post('/aspera/downloadSpec', { id: file._id })
			.then((res) => {
				let transferSpec = res.data;
				let connectSpec = { allow_dialogs: false };
				if(Aspera.Connect.getStatus() !== 'RUNNING' || Aspera.isDisabled) {
					TransferManager.httpDownload(transferSpec);
					resolve({});
				}
				const result = Aspera.Connect.startTransfer(transferSpec, connectSpec, {
					success: (result) => {
						result = result.transfer_specs[0];
						let transfer = new Transfer({
							uuid: result.uuid,
							title: file.name,
							percentage: 0,
							rate: 0,
							status: Aspera.TRANSFER_STATUS.INITIATING,
							add_time: new Date(),
							// We just make up a number greater then 0, percentage complete will be zero
							bytes_expected: 1,
							received: 0
						});
						TransferManager.transfers.push(transfer);

						resolve(transfer);
					},
					failure: (e) => {
						result.error = e;
						result.error.path = file.path;

						// We resolve failures so that we can handle failures individually later
						resolve(transferSpec);
					}
				});

			})
			.catch((e) => {
				// Wrap $http failure as an object with error field, since that what parseErrors expects.
				reject({
					error: {
						path: file.path,
						code: e.status,
						user_message: e.statusText
					}
				});
			}));
	},
	parseErrors: (results) => {
		return _(results)
			.pluck('error')
			.compact()
			.map((error) => {
				return {
					path: error.path,
					code: error.code,
					desc: error.user_message
				};
			})
			.value();
	},

	add: async(files) => {
		TransferManager.errors = [];
		try {
			await TransferManager.logTransfer(files);
			const results = files.map(TransferManager.startDownload);
			return Promise.all(results).then((res) => {
				let errors = TransferManager.parseErrors(res);

				if(errors.length) {
					throw new Error(errors);
				}

				return files;
			});
		}
		catch(errors) {
			TransferManager.errors = errors;
		}
	},
	upsert: (transfer) => {
		if(Array.isArray(transfer)) {
			return transfer.forEach(TransferManager.upsert);
		}

		const index = _.findIndex(TransferManager.transfers, _.pick(transfer, 'id'));
		if(index < 0) {
			return TransferManager.transfers.push(transfer);
		}

		TransferManager.transfers[index] = transfer;
	},

	remove: (transfer) => {
		if(Array.isArray(transfer)) {
			return transfer.forEach(TransferManager.remove);
		}

		TransferManager.transfers = _.reject(TransferManager.transfers, _.pick(transfer, 'id'));
	},

	pluginStatus: () => Aspera.Connect.getStatus(),

	eventHandler: (eventType, data) => {
		if(!data.transfers.length) {
			return;
		}

		data.transfers.forEach((t) => {
			if(t.transfer_spec && t.transfer_spec.remote_host === ASPERA_DOWNLOAD_ROOT) {
				const transfer = new Transfer(t);
				switch(transfer.status) {
					case Aspera.TRANSFER_STATUS.REMOVED:
						TransferManager.remove(transfer);
						break;
					case Aspera.TRANSFER_STATUS.COMPLETED:
					case Aspera.TRANSFER_STATUS.RUNNING:
					case Aspera.TRANSFER_STATUS.CANCELLED:
						TransferManager.upsert(transfer);
						break;
				}
			}
		});

	}

};
export default TransferManager;
