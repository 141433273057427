import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function Spinner(props) {
	const {
		small = false,
		medium = false,
		className
	} = props;

	const classes = cx('px-spinner', className, {
		small,
		medium
	});

	return (
		<div className={classes} data-testid="spinner">
			<div className="preloader-wrapper active">
				<div className="spinner-layer spinner-secondary">
					<div className="circle-clipper left">
						<div className="circle"></div>
					</div>

					<div className="gap-patch">
						<div className="circle"></div>
					</div>

					<div className="circle-clipper right">
						<div className="circle"></div>
					</div>
				</div>
			</div>
		</div>
	);
}

Spinner.propTypes = {
	small: PropTypes.bool,
	medium: PropTypes.bool,
	className: PropTypes.string
};

export default Spinner;
