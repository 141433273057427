import _ from 'lodash';
import api from './api-service';

export const GET_PREFERENCES_QUERY = `
query getPreferences {
	session {
		id
		emailNormal
		preferences
	}
}
`;

export const UPDATE_PREFERENCES_MUTATION = `
mutation updatePreferences($input: UserInput) {
	updateSession(input: $input) {
		session {
			id
			preferences
		}
	}
}
`;

export const UPDATE_USER_MUTATION = `
mutation updateUser($input: UserInput) {
  updateUser(input: $input) {
    user {
      id
      name
    }
  }
}
`;

export const SEARCH_USERS_QUERY = `
query searchUsers($q: String) {
  users(q: $q, limit: 5) {
    nodes {
      id
      name
      emailNormal
    }
  }
}
`;

export const SEARCH_GROUPS_QUERY = `
query searchGroups($q: String) {
  groups(q: $q, limit: 5) {
    nodes {
      id
      name
    }
  }
}
`;

export const GET_USERS_BY_ID_QUERY = `
query getUsersById($id: [ID]) {
  users(id: $id) {
    nodes {
			id
			name
      emailNormal
    }
  }
}
`;

export const GET_GROUPS_BY_ID_QUERY = `
query getGroupsById($id: [ID]) {
  groups(id: $id) {
    nodes {
			id
			name
    }
  }
}
`;

const UserService = {
	isAdmin(user) {
		return (
			_.chain(user.groups).pluck('type')
				.contains('a')
				.value()
			|| this.isAdminManager(user)
		);
	},

	isAdminManager(user) {
		return _.any(user.permissions, (permission) => {
			return permission.access && permission.access.adminWorkRequests === true;
		});
	},

	isVendor(user) {
		return _.any(user.permissions, (permission) => {
			return permission.access && permission.access.manageWorkRequests === true;
		});
	},

	hasReportAccess(user, report) {
		if( UserService.isAdmin(user) ) {
			return true;
		}

		// checking for access to an individual report
		if( report ) {
			return user.permissions.some((permission) => permission.access?.[report] === true);
		}

		// checking for any report access (to show menu button
		// and allow access to /reports)
		const accessKeys = [
			'workRequestReport',
			'groupSpendingReport',
			'exceptionReport',
			'downloadReport',
			'feedbackReport',
			'encodeReport'
		];

		return accessKeys.some((key) => {
			return user.permissions.some((permission) => permission.access?.[key] === true);
		});
	},

	getPreferences() {
		return api.graphql.request(GET_PREFERENCES_QUERY);
	},

	updatePreferences(id, preferences) {
		return api.graphql.request(UPDATE_PREFERENCES_MUTATION, {
			input: {
				id,
				preferences
			}
		});
	},

	updateUser(id, data) {
		return api.graphql.request(UPDATE_USER_MUTATION, {
			input: {
				...data,
				id
			}
		});
	},

	getUsers(query) {
		return api.graphql.request(SEARCH_USERS_QUERY, { q: query });
	},

	getGroups(query) {
		return api.graphql.request(SEARCH_GROUPS_QUERY, { q: query });
	},

	getUsersByIds(userIds) {
		return api.graphql.request(GET_USERS_BY_ID_QUERY, { id: userIds });
	},

	getGroupsByIds(groupIds) {
		return api.graphql.request(GET_GROUPS_BY_ID_QUERY, { id: groupIds });
	},
	getUserByEmail(email) {
		return api.client.get(`/users?q=${encodeURIComponent(email)}`)
			.then((res) => res.data[0] || res.data);
	}
};

export default UserService;
