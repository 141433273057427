// window.env = '{{env}}';
// window.version = '{{version}}';

const posthogEnabled = process.env.NODE_ENV !== "development";
const logRocketEnabled = process.env.NODE_ENV !== "development";
const intercomEnabled = process.env.NODE_ENV !== "development";

export const OPENREPLAY_KEY = 'U0xxno52HZG7hqKPchbR'
export const OPENREPLAY_URL = 'https://openreplay.pixwel.com/ingest'
export const POSTHOG_TOKEN = 'phc_d96fnGUiRs2vKZMD0rN2gtxlLIOAZGcegMv99S6yZUn';
export const POSTHOG_CONFIG = { api_host: 'https://posthog.pixwel.com' };
export const MESSAGE_ENDPOINT = 'https://notifications.pixwel.com/{{fayeEnv}}/faye';
export const ASPERA_INSTALLER_ROOT = '//d3gcli72yxqn2z.cloudfront.net/connect/';
export const ASPERA_DOWNLOAD_ROOT = 'ats-aws-us-west-2.aspera.io';
export const ICON_USER = 'icon-user';
export const ICON_GROUP = 'icon-globe';
export const JWPLAYER_KEY = 'DdkKJYHp0YIPk/58zT7tGD6gHSWfKbJppglfg7yskmM=';
export const IntercomAPI = 'ef30d123f304de60eb93ebdb931cc694dab2e19c';
export const INTERCOM_ENABLED = intercomEnabled;
export const LOG_ROCKET_ENABLED = logRocketEnabled;
export const LOG_ROCKET_APP_ID = 'bkntis/platform';
export const POSTHOG_ENABLED = posthogEnabled;
export const LANGUAGES = {
	'ar': 'ARA',
	'bg': 'BUL',
	'ca': 'CAT',
	'cs': 'CZE',
	'cy': 'CYM',
	'da': 'DAN',
	'de': 'GER',
	'el': 'GRK',
	'en': 'ENG',
	'es': 'CSP',
	'es-mx': 'LAS',
	'et': 'EST',
	'fi': 'FIN',
	'fr': 'PFR',
	'fr-ca': 'CFR',
	'he': 'HEB',
	'hi': 'HIN',
	'hr': 'CRO',
	'hu': 'HUN',
	'id': 'BAH',
	'is': 'ICE',
	'it': 'ITA',
	'ja': 'JPN',
	'ko': 'KOR',
	'lt': 'LIT',
	'lv': 'LAT',
	'ml': 'MLM',
	'nl': 'DUT',
	'nl-be': 'FLE',
	'no': 'NOR',
	'pl': 'POL',
	'pt': 'EPT',
	'pt-br': 'BPO',
	'ro': 'ROM',
	'ru': 'RUS',
	'sk': 'SLK',
	'sl': 'SLN',
	'sr': 'SER',
	'sv': 'SWE',
	'ta-in': 'TAM',
	'te': 'TEL',
	'th': 'THA',
	'tr': 'TUR',
	'uk': 'UKR',
	'vi': 'VIE',
	'zh-cn': 'PRC',
	'zh-hk': 'CAN',
	'zh-tw': 'MAN'
};

export const NTSC = {
	"pt-br": true,
	"fr-ca": true,
	is: true,
	ja: true,
	ko: true,
	mx: true,
	"zh-tw": true,
	"en-us": true
};

export const LANGUAGE_LABELS = [
	{ lang: "en", label: "English" },
	{ lang: "fr", label: "Français" },
	{ lang: "es", label: "Español" },
	{ lang: "pt-br", label: "Português Brasileiro" },
	{ lang: "ja", label: "日本語" },
	{ lang: "it", label: "Italiano" },
	{ lang: "ru", label: "русский" },
	{ lang: "iw", label: "עברית" },
	{ lang: "zh-CN", label: "中国的" },
	{ lang: "de", label: "Deutsch" }
];

export const PREVIEW_WATERMARK_FORMAT = {
	default: 'FULL_SCREEEN_DIAGONAL',

	'FULL_SCREEEN_DIAGONAL': {
		label: 'Full Screen Diagonal',
		class: 'px-watermark-full-screen-diagonal'
	},
	'SINGLE_CENTER' : {
		label: 'Single Center',
		class: 'px-watermark-single-center'
	},
	'SINGLE_TOP_MIDDLE': {
		label: 'Single Top Middle',
		class: 'px-watermark-single-top-middle'
	},
	'SINGLE_BOTTOM_MIDDLE': {
		label: 'Single Bottom Middle',
		class: 'px-watermark-single-bottom-middle'
	}
};

export const CATALOG_MANIFEST = [
	{
		lang: "en",
		url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-en.json"
	},
	{
		lang: "fr",
		url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-fr.json"
	},
	{
		lang: "es",
		url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-es.json"
	},
	{
		lang: "ja",
		url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-ja.json"
	},
	{
		lang: "pt-br",
		url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-pt-br.json"
	},
	{
		lang: "it",
		url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-it.json"
	},
	{
		lang: "ru",
		url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-ru.json"
	},
	{
		lang: "iw",
		url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-iw.json"
	},
	{
		lang: "zh-CN",
		url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-zh-CN.json"
	},
	{
		lang: "de",
		url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-de.json"
	}
];
