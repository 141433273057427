import _ from 'lodash';
import moment from 'moment';
import validate from 'validate.js';

const DATE_TIME_EXTENSION = {
	format: function formatDateTime(value, options) {
		let format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm';
		return moment.utc(value).format(format);
	},
	parse: function parseDateTime(value) {
		return +moment.utc(value);
	}
};

validate.formatters.structured = formatStructured;
validate.validators.arrayOf = validateArrayOf;
validate.validators.objectOf = validateObjectOf;
validate.validators.unique = validateUnique;
validate.validators.if = validateIf;
validate.validators.custom = validateCustom;
validate.extend(validate.validators.datetime, DATE_TIME_EXTENSION);

function defaultUniqueCompare(v1, v2) {
	return v1 === v2;
}

/**
 * Validates a group of values for uniqueness.
 * Requires that a "getAllValues" method be present on the validation option.
 * Attributes and Global Options will be passed to the "getAllValues" method
 * which is useful when comparing complex object's fields. In which case you
 * should place the "fullObject" in the "globalOptions".
 */
function validateUnique(value, options, attribute, attributes, globalOptions) {
	const { fullObject, parentObject, meta } = globalOptions;

	let compareFn = options.compareFn || defaultUniqueCompare;
	let allValues = options.getAllValues(value, { attributes, fullObject, parentObject, meta, globalOptions });
	let found = _.filter(allValues, (v) => compareFn(value, v));

	// The value is unique if it's only found once (itself)

	let duplicatedTags = false;
	if(_.size(fullObject.tags.tags)>1) {
		duplicatedTags = options.findDuplicates(fullObject.tags.tags);
	}

	if( found.length > 1 || duplicatedTags) {
		return options.message || this.message || 'must be unique';
	}
}

/**
 * Validates each element of an array against the same constraint.
 * Returns an array where each element is the validation of the corresponding input element,
 * or undefined if no errors were found for the given input element.
 */
function validateArrayOf(value, options, attribute, attributes, globalOptions) {
	let validations = _.map(
		value,
		(element) => validate(element, options, {
			...globalOptions,
			parentObject: value
		})
	);
	let hasErrors = !!_.filter(validations).length;

	// validate.js returns undefined for no errors
	if( !hasErrors ) {
		return;
	}

	// validate.js expands error arrays into individual error records which makes tracking indexes
	// a pain. To avoid this we use an object (which is as good as an array in the right circumstances)
	return _.reduce(validations, (result, v, i) => {
		if( v ) {
			result[i] = v;
		}

		return result;
	}, {});
}

/**
 * Validates each key/value of an object against the same constraint.
 * Returns an object where each value is the validation of the corresponding key,
 * or undefined if no errors were found for the given input key.
 */
function validateObjectOf(value, options, attribute, attributes, globalOptions) {
	const validateFn = options.single ? validate.single : validate;
	options = _.omit(options, 'single');
	let validations = _.mapValues(
		value,
		(val) => validateFn(val, options, {
			...globalOptions,
			parentObject: value
		})
	);

	let hasErrors = !!_.filter(validations).length;

	// validate.js returns undefined for no errors
	if( !hasErrors ) {
		return;
	}

	// validate.js expands error arrays into individual error records which makes tracking indexes
	// a pain. To avoid this we use an object (which is as good as an array in the right circumstances)
	return _.reduce(validations, (result, v, i) => {
		if( v ) {
			result[i] = v;
		}

		return result;
	}, {});
}

/**
 * A conditional validator that applies validation rules based on
 * the provided condition method.
 */
function validateIf(value, options, attribute, attributes, globalOptions) {
	const { fullObject, parentObject, meta } = globalOptions;

	// If the condition is not met, do not run validations
	if( !options.condition(value, { attributes, fullObject, parentObject, meta, globalOptions }) ) {
		return;
	}

	// Wrap value so that we can validate a single value WITH structured formatting.
	// validate.single does not support structured formatting
	let result = validate({ [attribute]: value }, { [attribute]: options.validations }, globalOptions);
	return result && result[attribute];
}

/**
 * A simple wrapper for creating a custom validator without
 * needing access to the underlying validate.js
 */
function validateCustom(value, options, attribute, attributes, globalOptions) {
	const { fullObject, parentObject, meta } = globalOptions;

	return options.fn(value, { attributes, fullObject, parentObject, meta, globalOptions });
}

/**
 * Formats validation orders in a structured object
 * {
 *     field1: {
 *         validator1: 'message',
 *         validator2: 'message'
 *     }
 * }
 */
formatStructured.ignoreKeys = ['if', 'objectOf'];
function formatStructured(errors) {
	return _.reduce(errors, (result, { attribute, validator, error }) => {
		if( !(attribute in result) ) {
			result[attribute] = {};
		}

		// Some keys we don't want to include in the structured output, but we want their internal errors
		if( formatStructured.ignoreKeys.indexOf(validator) !== -1 ) {
			result[attribute] = _.merge(result[attribute], error );
			return result;
		}

		result[attribute][validator] = error;
		return result;
	}, {});
}

const ValidationService = {
	validate: (value, constraints, options) => {
		// Set format as structured but allow users to override with options parameter
		options = {
			format: 'structured',
			fullObject: value,
			parentObject: value,
			...options
		};

		return validate(value, constraints, options);
	},

	// The same as validate except for the top layer of key/values are treated as a nested
	// object with its own set of constraints.
	validateNested: (value, constraints, options) => {
		return _.reduce(value, (result, nested, section) => {
			let nestedOptions = {
				...options,
				fullObject: value
			};

			result[section] = ValidationService.validate(nested, constraints[section], nestedOptions);
			return result;
		}, {});
	},

	// The same as validate nested, except for every key is validated against the same constraints
	// instead of validating each nested key against its own nested constraint
	validateAllKeys: (value, constraints, options) => {
		return _.reduce(value, (result, nested, section) => {
			let nestedOptions = {
				...options,
				fullObject: value
			};

			result[section] = ValidationService.validate(nested, constraints, nestedOptions);
			return result;
		}, {});
	}
};

export default ValidationService;
