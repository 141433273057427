import api from './api-service';

const CommentService = {

	createComment(data) {
		return api.client.post('/comments', data)
			.then((res) => res.data);
	},

	getCommentsForWorkRequest(workRequestId) {
		return api.client.get(`/comments?workRequest=${workRequestId}`)
			.then((res) => res.data);
	},

	getCommentsForOffline(offlineId) {
		return api.client.get(`/comments?offline=${offlineId}`)
			.then((res) => res.data);
	},

	deleteComment(id) {
		return api.client.delete(`/comments/${id}`)
			.then((res) => res.data);
	},

	convertToImageAnnotation(comment) {
		let result = {
			_id: comment._id,
			created: comment.created,
			user: comment.user,
			body: comment.body
		};

		if( comment.replies ) {
			// this is a comment
			result.replies = comment.replies.map((reply) => {
				return {
					_id: reply._id,
					replyTo: reply.replyTo,
					created: reply.created,
					user: reply.user,
					body: reply.body
				};
			});
		}

		if( comment.replyTo ) {
			// this is a reply
			result.replyTo = comment.replyTo;
		}

		if( comment.annotation ) {
			// this is an annotation
			result.annotation = comment.annotation;
		}

		return result;
	}
};

export default CommentService;
