import _ from 'lodash';
import { createAction } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { identity, attachTimestamp, handleError } from './common';
import { RESET_STORES } from './session';
import StudioService from '@/modules/services/studio-service';

export const STORE_NAME = 'studiosStore';

export function *fetchStudios({ payload }) {
	try {
		yield put(internalActions.getStudiosRequest(payload));
		const studios = yield StudioService.getAllStudios();
		yield put(internalActions.getStudiosSuccess(studios));
		return studios;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getStudiosFailure(err));
		return err;
	}
}

export const FETCH_STUDIOS = 'studios.fetch';
export const FETCH_STUDIOS_REQUEST = 'studios.fetch.request';
export const FETCH_STUDIOS_SUCCESS = 'studios.fetch.success';
export const FETCH_STUDIOS_FAILURE = 'studios.fetch.failure';

export const INITIAL_STATE = {
	isLoading: false,
	lastLoadedAt: null,
	studios: {}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case RESET_STORES:
			return INITIAL_STATE;

		case FETCH_STUDIOS_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case FETCH_STUDIOS_SUCCESS: {
			let now = new Date();
			let studios = _.reduce(action.payload, (result, studio) => {
				result[studio._id] = {
					isLoading: false,
					lastLoadedAt: now,
					data: studio
				};

				return result;
			}, {});

			return {
				...state,
				isLoading: false,
				lastLoadedAt: now,
				studios: {
					...state.studios,
					...studios
				}
			};
		}

		case FETCH_STUDIOS_FAILURE:
			return {
				...state,
				isLoading: false
			};

		default:
			return state;
	}
}

export const actions = {
	getStudios: createAction(FETCH_STUDIOS)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	getStudiosRequest: createAction(FETCH_STUDIOS_REQUEST),
	getStudiosSuccess: createAction(FETCH_STUDIOS_SUCCESS, identity, attachTimestamp),
	getStudiosFailure: createAction(FETCH_STUDIOS_FAILURE)
};

export const selectors = {
	isLoading: createSelector(isLoading),
	isLoaded: createSelector(isLoaded),
	getStudios: createSelector(getStudios),
	getStudioById: createSelector(getStudioById),
	getLastLoadedAt: createSelector(getLastLoadedAt)
};

function isLoading(state, studioId=null) {
	if( !studioId ) {
		return state.isLoading;
	}

	let studio = getStudioContainer(state, studioId);
	if( !studio ) {
		return false;
	}

	return studio.isLoading;
}

function isLoaded(state, studioId = null) {
	if( !studioId ) {
		return state.lastLoadedAt !== null;
	}

	let studio = getStudioContainer(state, studioId);
	if( !studio ) {
		return false;
	}

	return studio.lastLoadedAt !== null;
}

function getStudios(state) {
	return _.values(state.studios)
		.map((wrapper) => wrapper.data);
}

function getStudioById(state, id) {
	let studio = state[id];

	return studio ? studio.data : studio;
}

function getLastLoadedAt(state, id) {
	if( !id ) {
		return state.lastLoadedAt;
	}

	let studioContainer = getStudioContainer(state, id);

	return studioContainer ? studioContainer.lastLoadedAt : null;
}

function getStudioContainer(state, id) {
	return state.studios[id];
}

function createSelector(selector) {
	return function wrappedSelector(state, ...args) {
		let studiosStore = state[STORE_NAME];
		return selector(studiosStore, ...args);
	};
}

export function *watchStudios() {
	yield takeLatest(FETCH_STUDIOS, fetchStudios);
}
