import _ from 'lodash';
import { createAction } from 'redux-actions';
import { select, put, takeLatest } from 'redux-saga/effects';
import { handleError } from './common';
import DownloadsPageService from '@/modules/services/downloads-page-service';
export const STORE_NAME = 'downloadsPageStore';
export const PAGE_SIZE = 100;

// When the user wants to retrieve the next page of downloads (use this for page 0 as well);
function *fetchNextDownloadsPage({ payload }) {
	try {
		let { userId } = payload;

		let page = yield select((state) => state[STORE_NAME].allDownloads.currentPage);
		let start = page * PAGE_SIZE;
		let end = (page + 1) * PAGE_SIZE - 1;

		let requestedAt = (new Date()).getTime();
		yield put(internalActions.fetchNextDownloadsPageRequest(userId));

		let downloadsPromise = DownloadsPageService.getAllDownloads({ userId, start, end });
		let downloads = yield downloadsPromise;

		let responseHeaders = downloads.headers;
		let total = getPaginationTotal(responseHeaders['x-content-range']);
		let nextPage = page + 1;

		yield put(internalActions.fetchNextDownloadsPageSuccess({ downloads, total, nextPage, requestedAt }));
		return downloads;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.fetchNextDownloadsPageFailure(err));
		return err;
	}
}

// When the user needs to update their downloads store with only new records (uses last updated at)
function *fetchNewDownloads({ payload }) {
	try {
		let { userId } = payload;

		let mostRecentLoadAt = yield select((state) => state[STORE_NAME].allDownloads.mostRecentLoadAt);
		if( !mostRecentLoadAt ) {
			return;
		}

		let requestedAt = (new Date()).getTime();
		yield put(internalActions.fetchNewDownloadsRequest(userId));

		// Convert back to date/time as the API uses it (drop the milliseconds)
		mostRecentLoadAt = ~~(mostRecentLoadAt/1000);
		let downloads = yield DownloadsPageService.fetchNewDownloads({ userId, mostRecentLoadAt });

		yield put(internalActions.fetchNewDownloadsSuccess({ downloads, requestedAt }));
		return downloads;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.fetchNewDownloadsFailure(err));
		return err;
	}
}

// When the user performs a server-side search
function *searchDownloads({ payload }) {
	try {
		let { userId, query } = payload;
		let start = 0;
		let end = PAGE_SIZE - 1;

		yield put(internalActions.searchDownloadsRequest({ userId, query }));

		let downloadsPromise = DownloadsPageService.getAllDownloads({ userId, query, start, end });
		let downloads = yield downloadsPromise;

		let responseHeaders = downloads.headers;
		let total = getPaginationTotal(responseHeaders['x-content-range']);
		let nextPage = 1;

		yield put(internalActions.searchDownloadsSuccess({ downloads, total, nextPage }));
		return downloads;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.searchDownloadsFailure(err));
		return err;
	}
}

function *fetchNextSearchPage({ payload }) {
	try {
		let { userId, query } = payload;

		let page = yield select((state) => state[STORE_NAME].searchDownloads.currentPage);
		let start = page * PAGE_SIZE;
		let end = (page + 1) * PAGE_SIZE - 1;

		let requestedAt = new Date();
		yield put(internalActions.fetchNextSearchRequest({ userId, query }));

		let downloadsPromise = DownloadsPageService.getAllDownloads({ userId, query, start, end });
		let downloads = yield downloadsPromise;

		let responseHeaders = downloads.headers;
		let total = getPaginationTotal(responseHeaders['x-content-range']);
		let nextPage = 1;

		yield put(internalActions.fetchNextSearchSuccess({ downloads, total, nextPage, requestedAt }));
		return downloads;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.fetchNextSearchFailure(err));
		return err;
	}
}

function getPaginationTotal(headerValue = '') {
	const rangePattern = /^resources \d+-\d+\/(\d+|\*)/;
	let matches = headerValue.match(rangePattern);

	if( matches === null || _.isUndefined(matches[1]) || matches[1] === '*' ) {
		return 0;
	}

	return +matches[1];
}

export const FETCH_NEXT_DOWNLOADS_PAGE = 'downloads.fetch-next-page';
export const FETCH_NEXT_DOWNLOADS_PAGE_REQUEST = 'downloads.fetch-next-page.request';
export const FETCH_NEXT_DOWNLOADS_PAGE_SUCCESS = 'downloads.fetch-next-page.success';
export const FETCH_NEXT_DOWNLOADS_PAGE_FAILURE = 'downloads.fetch-next-page.failure';

export const FETCH_NEW_DOWNLOADS = 'downloads.fetch-new';
export const FETCH_NEW_DOWNLOADS_REQUEST = 'downloads.fetch-new.request';
export const FETCH_NEW_DOWNLOADS_SUCCESS = 'downloads.fetch-new.success';
export const FETCH_NEW_DOWNLOADS_FAILURE = 'downloads.fetch-new.failure';

export const SEARCH_DOWNLOADS = 'downloads.search';
export const SEARCH_DOWNLOADS_REQUEST = 'downloads.search.request';
export const SEARCH_DOWNLOADS_SUCCESS = 'downloads.search.success';
export const SEARCH_DOWNLOADS_FAILURE = 'downloads.search.failure';

export const FETCH_NEXT_SEARCH = 'downloads.fetch-next-search';
export const FETCH_NEXT_SEARCH_REQUEST = 'downloads.fetch-next-search.request';
export const FETCH_NEXT_SEARCH_SUCCESS = 'downloads.fetch-next-search.successs';
export const FETCH_NEXT_SEARCH_FAILURE = 'downloads.fetch-next-search.failure';

export const RESET_DOWNLOADS = 'downloads.reset';
export const UPDATE_TRANSFERS = 'transfers.update';

export const INITIAL_STATE = {
	allDownloads: {
		downloads: [],
		currentPage: 0,
		isLoadingNextPage: false,
		mostRecentLoadAt: null,
		loadFailed: false,
		hasMoreDownloads: true
	},
	searchDownloads: {
		downloads: [],
		currentPage: 0,
		isLoading: false,
		isLoadingNext: false,
		loadFailed: false,
		hasMoreDownloads: true
	},
	newDownloads: {
		isLoading: false,
		loadFailed: false
	},
	transfers: []
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {

		case FETCH_NEXT_DOWNLOADS_PAGE_REQUEST: {
			return {
				...state,
				allDownloads: {
					...state.allDownloads,
					isLoadingNextPage: true
				}
			};
		}

		case FETCH_NEXT_DOWNLOADS_PAGE_SUCCESS: {
			let { downloads, total, nextPage, requestedAt } = action.payload;
			let mostRecentLoadAt = state.allDownloads.mostRecentLoadAt;

			if( !mostRecentLoadAt ) {
				mostRecentLoadAt = requestedAt;
			}

			downloads = [].concat(state.allDownloads.downloads, downloads.data);
			downloads = _.uniq(downloads, '_id');
			downloads = _.sortBy(downloads, ['-created', 'name']);

			let hasMoreDownloads = downloads.length < total;

			return {
				...state,
				allDownloads: {
					isLoadingNextPage: false,
					currentPage: nextPage,
					mostRecentLoadAt,
					hasMoreDownloads,
					downloads
				}
			};
		}

		case FETCH_NEXT_DOWNLOADS_PAGE_FAILURE: {
			return {
				...state,
				allDownloads: {
					...state.allDownloads,
					isLoadingNextPage: false,
					loadFailed: true
				}
			};
		}

		case FETCH_NEW_DOWNLOADS_REQUEST: {
			return {
				...state,
				newDownloads: {
					isLoading: true
				}
			};
		}

		case FETCH_NEW_DOWNLOADS_SUCCESS: {
			let { downloads, requestedAt } = action.payload;

			downloads = [].concat(downloads.data, state.allDownloads.downloads);
			downloads = _.uniq(downloads, '_id');
			downloads = _.sortBy(downloads, ['-created', 'name']);

			return {
				...state,
				allDownloads: {
					...state.allDownloads,
					mostRecentLoadAt: requestedAt,
					downloads
				},
				newDownloads: {
					isLoading: false
				}
			};
		}

		case FETCH_NEW_DOWNLOADS_FAILURE: {
			return {
				...state,
				newDownloads: {
					isLoading: false,
					loadFailed: true
				}
			};
		}

		case SEARCH_DOWNLOADS_REQUEST: {
			return {
				...state,
				searchDownloads: {
					isLoading: true,
					isLoadingNext: true,
					downloads: [],
					currentPage: 0,
					hasMoreDownloads: true
				}
			};
		}

		case SEARCH_DOWNLOADS_SUCCESS: {
			let { downloads, total, nextPage } = action.payload;
			let hasMoreDownloads = downloads.length < total;

			return {
				...state,
				searchDownloads: {
					...state.searchDownloads,
					isLoading: false,
					isLoadingNext: false,
					downloads: downloads.data,
					currentPage: nextPage,
					hasMoreDownloads,
					total
				}
			};
		}

		case SEARCH_DOWNLOADS_FAILURE: {
			return {
				...state,
				searchDownloads: {
					...state.searchDownloads,
					isLoading: false,
					isLoadingNext: false,
					loadFailed: true
				}
			};
		}

		case FETCH_NEXT_SEARCH_REQUEST: {
			return {
				...state,
				searchDownloads: {
					...state.searchDownloads,
					isLoadingNext: true
				}
			};
		}

		case FETCH_NEXT_SEARCH_SUCCESS: {
			let { downloads, total, nextPage } = action.payload;

			downloads = [].concat(state.searchDownloads.downloads, downloads.data);
			downloads = _.uniq(downloads, '_id');
			downloads = _.sortBy(downloads, ['-created', 'name']);

			let hasMoreDownloads = downloads.length < total;

			return {
				...state,
				searchDownloads: {
					...state.searchDownloads,
					isLoadingNext: false,
					downloads: downloads,
					currentPage: nextPage,
					hasMoreDownloads,
					total
				}
			};
		}

		case FETCH_NEXT_SEARCH_FAILURE: {
			return {
				...state,
				searchDownloads: {
					...state.searchDownloads,
					isLoadingNext: false,
					loadFailed: true
				}
			};
		}

		case RESET_DOWNLOADS: {
			return {
				...state,
				allDownloads: {
					downloads: [],
					currentPage: 0,
					mostRecentLoadAt: null,
					isLoadingNextPage: false,
					hasMoreDownloads: true
				}
			};
		}

		case UPDATE_TRANSFERS: {
			let transfers = action.payload;

			return {
				...state,
				transfers: [
					...transfers
				]
			};
		}

		default:
			return state;
	}
}

export const actions = {
	fetchNextDownloadsPage: createAction(FETCH_NEXT_DOWNLOADS_PAGE, (userId) => ({ userId })),
	fetchNewDownloads: createAction(FETCH_NEW_DOWNLOADS, (userId) => ({ userId })),
	searchDownloads: createAction(SEARCH_DOWNLOADS, (userId, query) => ({ userId, query })),
	fetchNextSearchDownloads: createAction(FETCH_NEXT_SEARCH, (userId, query) => ({ userId, query }))
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	fetchNextDownloadsPageRequest: createAction(FETCH_NEXT_DOWNLOADS_PAGE_REQUEST),
	fetchNextDownloadsPageSuccess: createAction(FETCH_NEXT_DOWNLOADS_PAGE_SUCCESS),
	fetchNextDownloadsPageFailure: createAction(FETCH_NEXT_DOWNLOADS_PAGE_FAILURE),
	fetchNewDownloadsRequest: createAction(FETCH_NEW_DOWNLOADS_REQUEST),
	fetchNewDownloadsSuccess: createAction(FETCH_NEW_DOWNLOADS_SUCCESS),
	fetchNewDownloadsFailure: createAction(FETCH_NEW_DOWNLOADS_FAILURE),
	searchDownloadsRequest: createAction(SEARCH_DOWNLOADS_REQUEST),
	searchDownloadsSuccess: createAction(SEARCH_DOWNLOADS_SUCCESS),
	searchDownloadsFailure: createAction(SEARCH_DOWNLOADS_FAILURE),
	fetchNextSearchRequest: createAction(FETCH_NEXT_SEARCH_REQUEST),
	fetchNextSearchSuccess: createAction(FETCH_NEXT_SEARCH_SUCCESS),
	fetchNextSearchFailure: createAction(FETCH_NEXT_SEARCH_FAILURE),
	updateTransfers: createAction(UPDATE_TRANSFERS)
};

export const selectors = {
	getDownloads: createSelector(getDownloads),
	isLoadingNextPage: createSelector(isLoadingNextPage),
	isNextDownloadsPageError: createSelector(isNextDownloadsPageError),
	hasMoreDownloads: createSelector(hasMoreDownloads),
	getMostRecentLoadAt: createSelector(getMostRecentLoadAt),
	isLoadingNewDownloads: createSelector(isLoadingNewDownloads),
	isNewDownloadsError: createSelector(isNewDownloadsError),
	getSearchedDownloads: createSelector(getSearchedDownloads),
	isLoadingSearchResults: createSelector(isLoadingSearchResults),
	isLoadingNextSearchResults: createSelector(isLoadingNextSearchResults),
	isSearchError: createSelector(isSearchError),
	hasMoreSearchResults: createSelector(hasMoreSearchResults),
	getTransfers: createSelector(getTransfers)
};

function getDownloads(state) {
	return state.allDownloads.downloads;
}

function isLoadingNextPage(state) {
	return state.allDownloads.isLoadingNextPage;
}

function isNextDownloadsPageError(state) {
	return state.allDownloads.loadFailed;
}

function hasMoreDownloads(state) {
	return state.allDownloads.hasMoreDownloads;
}

function getMostRecentLoadAt(state) {
	return state.allDownloads.mostRecentLoadAt;
}

function isLoadingNewDownloads(state) {
	return state.newDownloads.isLoading;
}

function isNewDownloadsError(state) {
	return state.newDownloads.loadFailed;
}

function getSearchedDownloads(state) {
	return state.searchDownloads.downloads;
}

function isLoadingSearchResults(state) {
	return state.searchDownloads.isLoading;
}

function isLoadingNextSearchResults(state) {
	return state.searchDownloads.isLoadingNext;
}

function isSearchError(state) {
	return state.searchDownloads.loadFailed;
}

function hasMoreSearchResults(state) {
	return state.searchDownloads.hasMoreDownloads;
}

function getTransfers(state) {
	return state.transfers;
}

/**
 * Helper method that grabs our specific part of the global store, then passes it into the given selector.
 * This makes it so that each selector doesn't have to grab the sub-state itself, reducing code duplication.
 */
function createSelector(selector) {
	return function selectorWrapper(state, ...rest) {
		let downloadsStore = state[STORE_NAME];
		return selector(downloadsStore, ...rest);
	};
}

export function *watchDownloadsPage() {
	yield takeLatest(FETCH_NEXT_DOWNLOADS_PAGE, fetchNextDownloadsPage);
	yield takeLatest(FETCH_NEW_DOWNLOADS, fetchNewDownloads);
	yield takeLatest(SEARCH_DOWNLOADS, searchDownloads);
	yield takeLatest(FETCH_NEXT_SEARCH, fetchNextSearchPage);
}
