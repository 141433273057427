import isError from 'lodash.iserror';
import { createAction } from 'redux-actions';
import { put, fork, join, takeLatest } from 'redux-saga/effects';
import { handleError } from './common';

import {
	actions as projectsActions, fetchProjectBySlug,
	selectors as projectsSelectors
} from './projects';
import {
	actions as studiosActions, fetchStudios,
	selectors as studiosSelectors
} from './studios';

function *loadSidebarData({ payload: projectSlug }) {
	try {
		yield put(internalActions.loadPageRequest(projectSlug));

		let projectTask = yield fork(fetchProjectBySlug, projectsActions.getProjectBySlug(projectSlug));
		let studiosTask = yield fork(fetchStudios, studiosActions.getStudios());

		// Join forked task back in
		let project = yield join(projectTask);
		let studios = yield join(studiosTask);

		if( isError(project) || isError(studios) ) {
			throw new Error('Failed to load sidebar resources');
		}

		yield put(internalActions.loadPageSuccess(projectSlug));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadPageFailure({ projectSlug, err }));
		return err;
	}

}

export const LOAD = 'project-sidebar.load';
export const LOAD_REQUEST = 'project-sidebar.load.request';
export const LOAD_SUCCESS = 'project-sidebar.load.success';
export const LOAD_FAILURE = 'project-sidebar.load.failure';

export const INITIAL_STATE = {
	isLoading: false,
	loadFailed: false
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case LOAD_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case LOAD_SUCCESS:
			return {
				...state,
				isLoading: false,
				loadFailed: false
			};

		case LOAD_FAILURE:
			return {
				...state,
				isLoading: false,
				loadFailed: true
			};

		default:
			return state;
	}
}

export const actions = {
	loadSidebarData: createAction(LOAD)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	loadPageRequest: createAction(LOAD_REQUEST),
	loadPageSuccess: createAction(LOAD_SUCCESS),
	loadPageFailure: createAction(LOAD_FAILURE)
};

export const selectors = {
	isLoading,
	isReady,
	hasError,
	needsRefresh
};

function isLoading(state) {
	let { projectSidebarStore } = state;

	return projectSidebarStore.isLoading;
}

function hasError(state) {
	let { projectSidebarStore } = state;

	return !!projectSidebarStore.loadFailed;
}

function isReady(state, projectSlug = null) {
	let isProjectLoaded = projectsSelectors.isLoadedBySlug(state, projectSlug);
	let areStudiosLoaded = studiosSelectors.isLoaded(state);

	if( !isProjectLoaded || !areStudiosLoaded ) {
		return false;
	}

	let projectDataLastLoadedAt = projectsSelectors.getLastLoadedAtBySlug(state, projectSlug);
	let studiosDataLastLoadedAt = studiosSelectors.getLastLoadedAt(state);

	let now = new Date();
	let isProjectDataStale = now - projectDataLastLoadedAt > 7200000;
	let isStudiosDataStale = now - studiosDataLastLoadedAt > 7200000;

	// If the data has been loaded within the last past two hours, its ready
	return !isProjectDataStale && !isStudiosDataStale;
}

/**
 * The project's sidebar data needs to be refreshed whenever data it depends on does not exist,
 * or the data it depends on is older then 30 minutes.
 * @param state
 * @param projectSlug
 * @returns {boolean}
 */
function needsRefresh(state, projectSlug = null) {
	let isProjectLoaded = projectsSelectors.isLoadedBySlug(state, projectSlug);
	let areStudiosLoaded = studiosSelectors.isLoaded(state);

	if( !isProjectLoaded || !areStudiosLoaded ) {
		return true;
	}

	let projectDataLastLoadedAt = projectsSelectors.getLastLoadedAtBySlug(state, projectSlug);
	let studiosDataLastLoadedAt = studiosSelectors.getLastLoadedAt(state);

	let now = new Date();
	let isProjectDataStale = now - projectDataLastLoadedAt > 1800000;
	let isStudiosDataStale = now - studiosDataLastLoadedAt > 1800000;

	// If the data has been loaded within the last past two hours, its ready
	return isProjectDataStale || isStudiosDataStale;
}

export function *watchProjectSidebar() {
	yield takeLatest(LOAD, loadSidebarData);
}
