import stripTags from './strip-tags-service';
import TimeFormat from './time-format-service';
import _ from 'lodash';

const ASSService = {

	trim: (str) => {
		return str.replace(/^\s+|\s+$/g, '');
	},

	searchIndex: (lines, pattern, startIndex) => {
		let len = lines.length;
		startIndex = startIndex || 0;

		for(let i = startIndex; i < len; i++) {
			let line = lines[i];
			if(line.match(pattern)) {
				return i;
			}
		}
		return false;
	},

	extractStyles: (lines) => {
		let line;
		let styles = {};

		let currentIndex = ASSService.searchIndex(lines, /^\[V4\+ Styles\]$/);

		if(currentIndex === false) {
			return styles;
		}

		currentIndex = ASSService.searchIndex(lines, /^Format:/, ++currentIndex);

		if(currentIndex === false) {
			return styles;
		}

		line = ASSService.trim(lines[currentIndex]);
		let fields = line.replace(/^Format:\s+/, '').split(/\s*,\s*/);
		let nameIndex = fields.indexOf('Name');
		let alignmentIndex = fields.indexOf('Alignment');

		if(nameIndex === -1) {
			return styles;
		}

		// eslint-disable-next-line no-useless-escape
		let pattern = new RegExp('^' + (new Array(fields.length).join('\s*(.*?)\s*,')) + '(.*?)$');

		currentIndex = ASSService.searchIndex(lines, /^Style:/, ++currentIndex);

		while(currentIndex !== false) {
			line = ASSService.trim(lines[currentIndex]);

			let parts = line.replace(/^Style:\s+/, '').match(pattern);
			if(!parts) {
				throw new Error('Error, missing fields in a `Style` entry');
			}
			if(parts.length) {
				let style = {};
				if(alignmentIndex !== -1) {
					let alignmentNumber = parts[alignmentIndex + 1];
					style.alignment = alignmentNumber >= 7 ? 'top' : 'bottom';
				}
				styles[parts[nameIndex + 1]] = style;
			}
			currentIndex = ASSService.searchIndex(lines, /^Style:/, ++currentIndex);
		}
		return styles;
	},

	parse: (lines) => {
		let line;
		let currentIndex = 0;

		function normalizeTime(time) {
			if(time.charAt(1) === ':') {
				time = '0' + time;
			}
			return time.replace('.', ',') + '0';
		}

		function normalizeText(text) {
			return text.replace(/\\n|\\N/, '\n')
				.replace(/{\\(.*)1}(.*){\\\1[0]}/g, (match, tag, content) => {
					return '<' + tag + '>' + content + '</' + tag + '>';
				});
		}

		let styles = ASSService.extractStyles(lines);

		currentIndex = ASSService.searchIndex(lines, /^\[Events\]$/);

		if(currentIndex === false) {
			throw new Error('Error, missing `[Events]` section');
		}

		currentIndex = ASSService.searchIndex(lines, /^Format:/, ++currentIndex);

		if(currentIndex === false) {
			throw new Error('Error, missing `Format` entry');
		}

		line = ASSService.trim(lines[currentIndex]);
		let fields = line.replace(/^Format:\s+/, '').split(/\s*,\s*/);
		let startIndex = fields.indexOf('Start');
		let endIndex = fields.indexOf('End');
		let styleIndex = fields.indexOf('Style');
		let textIndex = fields.indexOf('Text');

		if(startIndex === -1 || endIndex === -1 || textIndex === -1) {
			throw new Error('Error, missing fields in a `Format` entry');
		}

		let result = [];
		// eslint-disable-next-line no-useless-escape
		let pattern = new RegExp('^' + (new Array(fields.length).join('\s*(.*?)\s*,')) + '(.*?)$');

		currentIndex = ASSService.searchIndex(lines, /^Dialogue:/, ++currentIndex);

		while(currentIndex !== false) {
			line = ASSService.trim(lines[currentIndex]);
			let parts = line.replace(/^Dialogue:\s+/, '').match(pattern);
			if(!parts) {
				throw new Error('Error, missing fields in a `Dialogue` entry');
			}
			if(parts.length) {
				let entry = {
					startTime: normalizeTime(parts[startIndex + 1]),
					stopTime: normalizeTime(parts[endIndex + 1]),
					text: normalizeText(parts[textIndex + 1]),
					alignment: 'bottom'
				};
				if(styleIndex !== -1) {
					let style = styles[parts[styleIndex + 1]];
					if(style && style.alignment) {
						entry.alignment = style.alignment;
					}
				}
				result.push(entry);
			}
			currentIndex = ASSService.searchIndex(lines, /^Dialogue:/, ++currentIndex);
		}
		return result;
	},
	export: (lines, options) => {
		let result = '';

		options = options || {};
		let font = options.font || { face: 'Arial', size: 70 };
		const marginL = options.isTiktok? 120 : 20;
		const marginR = options.isTiktok? 240 : 20;
		const marginT = options.isTiktok? 150 : 20;
		const marginB = options.isTiktok? 780 : 20;
		const playResX = options.isTiktok? 1080 : 1920;
		const playResY = options.isTiktok? 1920 : 1080;
		const alignmentTop = options.isTiktok? '5' : '8';
		const alignmentBottom = options.isTiktok? '1' : '2';
		result += '[Script Info]\n';
		result += 'ScriptType: v4.00+\n';
		result += 'PlayResX: ' + playResX + '\n';
		result += 'PlayResY: ' + playResY + '\n\n';
		result += '[V4+ Styles]\n';
		result += 'Format: Name, Fontname, Fontsize, PrimaryColour,Shadow,MarginL,MarginR,MarginV,Alignment\n';
		result += 'Style: Bottom,' + font.face + ',' + font.size + `,&H00FFFFFF,1,${marginL},${marginR},${marginB},${alignmentBottom}\n`;
		result += 'Style: Top,' + font.face + ',' + font.size + `,&H00FFFFFF,1,${marginL},${marginR},${marginT},${alignmentTop}\n\n`;

		result += '[Events]\n';
		result += 'Format: Layer, Start, End, Style, Text\n';

		function normalizeTime(time) {
			if(time.charAt(0) === '0') {
				time = time.substr(1);
			}
			return time.replace(',', '.').slice(0, -1);
		}

		function normalizeText(text) {
			text = text.replace(/&nbsp;/g, ' ');
			text = _.unescape(
				text
					.replace(/<div>/g, '')
					.replace(/<\/div>/g, '\\N')
					.replace(/\n/g, '\\N')
					.replace(/<br>/g, '\\N')
					.replace(/<i>/g, '{\\i1}')
					.replace(/<\/i>/g, '{\\i0}')
					.replace(/<b>/g, '{\\b1}')
					.replace(/<\/b>/g, '{\\b0}')
					.replace(/<u>/g, '{\\u1}')
					.replace(/<\/u>/g, '{\\u0}')
			);
			return stripTags(text);
		}

		lines.forEach((line) => {
			if(line.text === undefined) {
				return;
			}
			result += 'Dialogue: 0';
			result += ',' + normalizeTime(TimeFormat.startTime(line));
			result += ',' + normalizeTime(TimeFormat.stopTime(line));
			result += line.alignment === 'top' ? ',Top' : ',Bottom';
			result += ',' + normalizeText(line.text) + '\n';
		});
		return result;
	}
};

export default ASSService;
