import _ from 'lodash';
import _get from 'lodash.get';
import moment from 'moment';

const EmbargoService = {
	getEmbargoStatus(item, when = moment()) {
		let embargo = _get(item, 'embargo', { end: 0, start: 0 });

		// Some old assets user a single number for embargo, transform it to new schema
		if( _.isNumber(embargo) ) {
			embargo = {
				end: embargo,
				start: 0
			};
		}

		let embargoEnd = _get(embargo, 'end', 0);

		// If not embargo is set, the file is not locked
		if( !embargoEnd ) {
			return {
				isLocked: false,
				willLock: false
			};
		}

		let embargoStart = _get(embargo, 'start', 0);
		let isAfterEmbargoEnd = when.isAfter(moment(embargoEnd * 1000));
		let willRelock = !!embargoStart;

		// If an embargo is set and we are not passed the date of embargo end, the file is locked
		if( !isAfterEmbargoEnd ) {
			return {
				isLocked: true,
				willUnlock: true,
				willUnlockOn: embargoEnd
			};
		}

		// If the embargo period is over and the file is not set to relock, the file is not locked
		if( isAfterEmbargoEnd && !willRelock ) {
			return {
				isLocked: false,
				willLock: false
			};
		}

		let isBeforeEmbargoStart = when.isBefore(moment(embargoStart * 1000));

		// If the date is before the file is set to relock, the file is not locked
		if( isBeforeEmbargoStart ) {
			return {
				isLocked: false,
				willLock: true,
				willLockOn: embargoStart
			};
		}

		// The file is locked indefinitely
		return {
			isLocked: true,
			willUnlock: false
		};
	}
};

export default EmbargoService;
