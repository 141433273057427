import _ from 'lodash';
import _matches from 'lodash.matches';
import { put, take } from 'redux-saga/effects';
import _get from 'lodash.get';
import { captureError } from '../modules/pixwel/exception-handler.factory';
import FlagService from '../modules/services/flag-service';

export function identity(payload) {
	return payload;
}

export function metaIdentity(payload, meta) {
	return meta;
}

export function attachTimestamp(payload, metadata) {
	return {
		...metadata,
		requestedAt: new Date()
	};
}

export function extendQueue(queue, toAdd) {
	if( _.isArray(toAdd) ) {
		return _.union(queue, toAdd);
	}

	if( queue.indexOf(toAdd) === -1 ) {
		queue.push(toAdd);
	}

	return queue;
}

export function filterQueue(queue, toRemove) {
	if( _.isArray(toRemove) ) {
		return queue.filter((id) => !toRemove.includes(id));
	}

	return queue.filter((id) => toRemove !== id);
}

/**
 * Helper method that grabs our specific part of the global store, then passes it into the given selector.
 * This makes it so that each selector doesn't have to grab the sub-state itself, reducing code duplication.
 */
export function createSelector(storeName, selector) {
	return function selectorWrapper(state, ...rest) {
		let subStore = state[storeName];
		return selector(subStore, ...rest);
	};
}

/**
 * Custom take method that checks action type and payload (partial not exact)
 */
export function takeWithPayload(actions, payload) {
	return take(function takeMatcher(action) {
		if( !actions.include(action.type) ) {
			return false;
		}

		let matcher = _matches(payload);
		return matcher(action.payload);
	});
}

export function *updateDispatcher(channel, uploadRequest, actionFn) {
	let { id, file: { name } } = uploadRequest;

	// Capture all update events before handling the final result
	// Once the `take` receives an `END` event, this entire function
	// will just end, so we don't need to worry about the infinite loop.
	while( true ) {
		let { progress = 0, error } = yield take(channel);

		// Convert 0-100 to 0.0-1.0
		progress /= 100;

		let update = {
			id,
			name,
			progress,
			error
		};

		if( actionFn ) {
			yield put(actionFn(update));
		}
	}
}

/**
 * Handles exceptions in Redux saga catch block:
 * 1) if exception is not an error object, converts it to one
 * 2) captures error in LogRocket
 * 3) logs error to browser console
 * 4) returns error
 *
 * @param {*} e - exception thrown from try block
 * @returns {Object} - error object
 */
export function handleError(e, prependMsg = 'Error') {

	if(!e) {
		return;
	}

	if(e?.status === 404 || e?.data?.error?.code === 404 || e?.cause ) {
		return e;
	}
	if(e?.status === 405 || e?.data?.error?.code === 405) {
		captureError(e, 'Unknown Error');
		return;
	}

	let err;

	if(e.error) {
		err = new Error(e.data.error.message);
	}
	else if(e.data) {
		err = e.data.error?new Error(e.data.error.message) :new Error(e.data.message);
	}
	else if(e.message) {
		const message = e.message?.message || e.message || '';
		err = new Error(message);
		if(message.includes('404')) {
			return e;
		}
	}
	else {
		err = new Error(e);
	}

	captureError(err, `${prependMsg}: ${err.message}`);
	return err;
}

/* this functions tests if an order is requesting the translation of graphics
*  either Subbed or Dedicated/Localized
*  it is mainly used to load appropriate data for orders
* */

export const isSubtitledGfxOrder = (workRequest) => {
	return _get(workRequest, 'dng.graphics[0]', false) === 'Subtitled';
};

export const isDedicatedGfxOrder = (workRequest) => {
	return _get(workRequest, 'dng.graphics[0]', false) === 'Dedicated / Localized';
};

export const isGfxOrder = (workRequest) => {
	return isSubtitledGfxOrder(workRequest) || isDedicatedGfxOrder(workRequest);
};

export const isGfxOnlyOrder = (workRequest) => {
	return ( isDedicatedGfxOrder(workRequest) || isSubtitledGfxOrder(workRequest) )
		&& !isDialogueOrder(workRequest) && !isNarrationOrder(workRequest);
};

/* this function tests if an order is requesting the translation of the dialogue
*  Subbed option for dialogue
*  it is mainly used to load appropriate data for orders
* */
export const isDialogueOrder = (workRequest) => {
	return _get(workRequest, 'dng.dialogue[0]', false) === 'Subtitled' || workRequest.dng === 'Subtitled';
};

/* this function tests if an order is requesting the translation of the narration
*  Subbed option for narration
*  it is mainly used to load appropriate data for orders
* */
export const isNarrationOrder = (workRequest) => {
	return _get(workRequest, 'dng.narration[0]', false) === 'Subtitled';
};

export const isPrintOrder = (workRequest) => {
	return workRequest.assetCategory === 'print';
};

export const isPrintDateOrder = (workRequest) => {
	return workRequest.assetCategory === 'printdate';
};

export const isAutoGfxOrder = (workRequest) => workRequest.auto && !!_get(workRequest, 'asset.transcription.autogfx', false);

export const getOrderMode = (workRequest) => {
	if(isPrintOrder(workRequest)) {
		return 'print';
	}
	if(isPrintDateOrder(workRequest)) {
		return 'printdate';
	}
	if(isGfxOrder(workRequest)) {
		if(isSubtitledGfxOrder(workRequest) && (isDialogueOrder(workRequest) || isNarrationOrder(workRequest))) {
			return 'script';
		}
		if(isDialogueOrder(workRequest) || isNarrationOrder(workRequest)) {
			return 'script+gfx';
		}
		if(isAutoGfxOrder(workRequest)) {
			return 'autogfx';
		}
		return 'gfx';
	}
	if(isDialogueOrder(workRequest) || isNarrationOrder(workRequest)) {
		return 'script';
	}
};

export const getTranscriptionId = (workRequest) => {
	switch(getOrderMode(workRequest)) {
		case 'script':
		case 'print':
			return workRequest.asset.transcription._id;
		case 'autogfx':
			return workRequest.asset.transcription.autogfx;
		case 'gfx':
			return workRequest.asset.transcription.graphics;
		case 'script+gfx':
			return [
				workRequest.asset.transcription.dialogue,
				workRequest.auto?workRequest.asset.transcription.autogfx:workRequest.asset.transcription.graphics
			];
		default:
			return workRequest.asset.transcription._id;
	}

};

export const getTranslationId = (workRequest) => {
	switch(getOrderMode(workRequest)) {
		case 'print':
		case 'script':
			return workRequest.translation?._id;
		case 'autogfx':
		case 'gfx':
			return workRequest.graphicsTranslation?._id;
		case 'script+gfx':
			return [
				workRequest.translation?._id,
				workRequest.graphicsTranslation?._id
			];
		default:
			return workRequest.translation?._id;
	}

};
export const getTranscriptsForOrder = (workRequest) => {
	let transcripts = {
		_id: false,
		graphics: false,
		autogfx: false,
		dialogue: false
	};
	const isPrintMode = isPrintOrder(workRequest);
	const isGfxMode = isGfxOrder(workRequest);
	const isSubtitledGfx = isSubtitledGfxOrder(workRequest);
	const isDedicatedGfx = isDedicatedGfxOrder(workRequest);
	const isDialogueMode = isDialogueOrder(workRequest);
	const isNarrationMode = isNarrationOrder(workRequest);

	const isAuto = workRequest.auto;
	const isAutosubs = workRequest.autosubs;

	if(isPrintMode) {
		transcripts._id = true;
	}
	else if(isAutosubs && !isAuto) {
		transcripts._id = true;
	}
	// automated mode options
	else if(isAuto) {
		if(isGfxMode) {
			transcripts.autogfx = true;
			if(isDialogueMode) {
				transcripts.dialogue = true;
			}
		}
		else if(isDialogueMode) {
			transcripts._id = true;
		}
	}
	else {
		if(isGfxMode && !(isDialogueMode || isNarrationMode)) {
			transcripts.graphics = true;
		}
		if((isDialogueMode || isNarrationMode) && !isGfxMode) {
			transcripts._id = true;
		}
		if((isDialogueMode || isNarrationMode) && isGfxMode) {
			if(isSubtitledGfx) {
				transcripts._id = true;
			}
			else if(isDedicatedGfx) {
				transcripts.dialogue = true;
				transcripts.graphics = true;
			}
		}
	}

	return transcripts;
};
