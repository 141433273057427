var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2954cb545d24c5c949868f34b172982833d97658"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

if (process.env.NODE_ENV !== "development") {
	Sentry.init({
		dsn: "https://ac6c95ef5ab17000c892def16383b601@o74502.ingest.sentry.io/4506356271742976",
		// Replay may only be enabled for the client-side
		integrations: [
			new Sentry.Replay({
				maskAllText: false,
				blockAllMedia: false,
				networkDetailAllowUrls: [
					window.location.origin,
					"https://*.pixwel.com/*",
				],
			}),
			// new Sentry.Feedback({
			// 	// Additional SDK configuration goes in here, for example:
			// 	colorScheme: "dark",
			// 	showBranding: false,
			// }),
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 1.0,
		replaysOnErrorSampleRate: 1.0,

		// ...

		// Note: if you want to override the automatic release value, do not set a
		// `release` value here - use the environment variable `SENTRY_RELEASE`, so
		// that it will also get attached to your source maps
	});
}
