import api from './api-service';
import TranslationReplicator from './translation-replicator';
import stripTags from './strip-tags-service';
import SRTService from './srt-service';

const TranslationService = {

	getById(id) {
		return api.client.get(`/translations/${id}`)
			.then((res) => res.data)
			.catch((e) => {
				if( e.status === 404 ) {
					return null;
				}

				throw e;
			});
	},

	isSubtitlerTranslation(translation) {
		return translation
			&& !translation.file
			&& translation.lines
			&& (translation.status === 'submitted' || translation.status === 'complete');
	},

	isCustomTranslation(translation) {
		return !!translation?.file;
	},

	getTranslationMemories(id, language) {
		return api.client.get(`/translations/translate?id=${id}&language=${language}`)
			.then(({ data }) => data);
	},

	getMachineTranslations(id, language) {
		return api.client.get(`/translations/translate?id=${id}&language=${language}&machine=true`)
			.then(({ data }) => data);
	},

	getTranslationLanguages() {
		return api.client.get(`/translations/languages`)
			.then(({ data }) => data);
	},

	newlineToDivs(value) {
		let translationText = value.includes('<div>') ? value : `<div>${value}</div>`;

		// since we prefer divs over newlines, convert them
		translationText = translationText.replace(/\r\n/g, '</div><div>');
		translationText = translationText.replace(/\r/g, '</div><div>');
		translationText = translationText.replace(/\n/g, '</div><div>');

		// remove any useless empty divs
		return translationText.replace(/<div><\/div>/g, '');
	},

	autoTranslate(subtitles, applyTranslationMemories, applyMachineTranslations) {

		const {
			translations,
			tmTranslations,
			machineTranslations,
			ovTranslations
		} = subtitles;

		return translations.map((translation, i) => {
			if( translation.translationFrom === 'custom' ) {
				return translation;
			}

			const ovText = ovTranslations.printLines?.[i]?.text || ovTranslations.lines[i]?.text;
			const tmTranslationText = tmTranslations[i];
			let mtTranslationText = machineTranslations[i];
			let translationFrom;
			let translationText;
			let auto;
			let machine;

			if( applyMachineTranslations && mtTranslationText ) {
				translationText = this.newlineToDivs(mtTranslationText);
				translationFrom = 'mt';
				auto = false;
				machine = true;
			}

			if( applyTranslationMemories && tmTranslationText && stripTags(tmTranslationText).length > 0 ) {
				translationText = this.newlineToDivs(tmTranslationText);
				translationFrom = 'tm';
				auto = true;
				machine = false;
			}

			if( !translationText ) {
				translationText = ovText;
				translationFrom = 'ov';
				auto = false;
				machine = false;
			}

			return {
				...translation,
				translationFrom,
				translationText,
				auto,
				machine
			};
		});
	},

	updateTranslation(id, data) {
		return api.client.patch(`/translations/${id}`, data);
	},

	translationReplicator(asset, translation, type = 'srt') {
		return TranslationReplicator.translate(asset, translation, type);
	},

	stripTags(text) {
		return stripTags(text, '')
			.replace(/&nbsp;/g, '')
			.replace(/\r\n/g, '')
			.replace(/\n/g, '');
	},
	downloadFile: (data, type, filename) => {
		const element = document.createElement('a');
		const file = new Blob([data], {
			type: type
		});
		element.href = URL.createObjectURL(file);
		element.download = filename;
		document.body.appendChild(element);
		element.click();
	},
	downloadSrt: async(translationId, filename) => {
		let translation = await TranslationService.getById(translationId);
		TranslationService.downloadFile(SRTService.export(translation.lines), 'application/x-subrip', filename + '.srt');
	}
};

export default TranslationService;
