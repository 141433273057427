import _ from 'lodash';
import _get from 'lodash.get';
import moment from 'moment';
import EmbargoService from './embargo-service';
import api, { buildURL } from './api-service';
import TransferManager from './transfer-manager';
import SessionService from './session-service';
import EventService from './event-service';

const TAG_TRANSFORMS = {
	texted: 'txtd',
	textless: 'txtl',
	subbed: 'sub',
	subtitle: 'sub',
	subtitled: 'sub',
	dubbed: 'dub'
};

const FileService = {

	getAllFiles({ asset, language }) {
		let params = { asset, language };
		return api.client.get(buildURL('/files', params), { headers: { 'X-Range': 'resources=0-200' } })
			.then((res) => res.data);
	},

	/**
	 * Returns an object of unlocked and locked files grouped by their embargo status.
	 *
	 * The main object that is returned contains two keys:
	 * All files that are currently unlocked with be nested under the "unlocked" key.
	 * All files that are currently locked with be nested under the "locked" key.
	 *
	 * Under each of these keys files will be grouped by their relock date (for unlocked files),
	 * or their unlock date (for locked files). Files that are permanently unlocked will be grouped
	 * under the key: 0. Files that are permanently locked will be grouped under the key: Infinity.
	 * These keys were chosen to make sorting easier.
	 *
	 * Example response:
	 * {
	 *     unlocked: {
	 *         0: [fileA, fileB], // these files are unlocked with no relock date
	 *         1547414825: [fileC, fileD], // these files are unlocked until 1547414825000 (the milliseconds are truncated)
	 *     },
	 *     locked: {
	 *         1547414825: [fileE, fileF], // these files are locked until 1547414825000 (the milliseconds are truncated)
	 *         Infinity: [fileG, fileH], // these files are locked with no unlock date (both the unlock and relock date have passed)
	 *     }
	 * }
	 *
	 * @param files: AssetFile[] The files to group by embargo status
	 * @param when: Moment The date to use when calculating a file's embargo status.
	 *        Defaults to the time when the function was invoked
	 * @returns {Object}
	 */
	groupFilesByEmbargoStatus(files, when = moment()) {
		let fileGroups = {
			unlocked: {},
			locked: {}
		};

		_.forEach(files, (file) => {
			let embargoStatus = EmbargoService.getEmbargoStatus(file, when);

			if( !embargoStatus.isLocked ) {
				let embargoStart = _get(file, 'embargo.start', 0);
				let key = embargoStatus.willLock ? embargoStart : 0;

				if( !(embargoStart in fileGroups.unlocked) ) {
					fileGroups.unlocked[key] = [];
				}

				fileGroups.unlocked[key].push(file);
			}
			else {
				let embargoEnd = _get(file, 'embargo.end', 0);
				let key = embargoStatus.willUnlock ? embargoEnd : Infinity;

				if( !(embargoEnd in fileGroups.locked) ) {
					fileGroups.locked[key] = [];
				}

				fileGroups.locked[key].push(file);
			}
		});

		return fileGroups;
	},

	filterUnlockedFiles(files, when = moment()) {
		return _.filter(files, (file) => {
			let embargoStatus = EmbargoService.getEmbargoStatus(file, when);
			return !embargoStatus.isLocked;
		});
	},

	filterLockedFiles(files, when = moment()) {
		return _.filter(files, (file) => {
			let embargoStatus = EmbargoService.getEmbargoStatus(file, when);
			return embargoStatus.isLocked;
		});
	},

	hasBeenDownloaded(file) {
		return _get(file, '$aspera.downloaded', false);
	},

	getFileName(file) {
		return file?.split('/')?.pop() || '';
	},

	searchFileName(file, queryWords = []) {
		let fileName = file.name.toLowerCase();

		for( let word of queryWords ) {
			word = word.toLowerCase();

			if( fileName.includes(word) ) {
				return true;
			}
		}

		return false;
	},

	searchFileTags(file, queryWords = []) {
		for( let word of queryWords ) {
			word = word.toLowerCase();
			let transformedWord = TAG_TRANSFORMS[word];

			let tags = file.tags || [];
			for( let tag of tags ) {
				tag = tag.toLowerCase();

				// If the search word matches a tag exactly, or the transformed words, keep the file
				if( word === tag || transformedWord === tag ) {
					return true;
				}
			}
		}

		return false;
	},

	matchShareTags(file, shareTags) {
		const intersection = shareTags.filter((tag) => file.tags?.some((t) => t.includes(tag)));
		return !!intersection.length;
	},

	downloadFile(file) {
		return TransferManager.add([file]);
	},

	formatBytes(bytes, precision = 1) {
		if( isNaN(parseFloat(bytes)) || !isFinite(bytes) ) {
			return '-';
		}

		if( bytes === 0 ) {
			return '0 bytes';
		}

		const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
		const number = Math.floor(Math.log(bytes) / Math.log(1024));

		return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
	},

	downloadStandardFile(fileId) {
		const link = document.createElement('a');
		link.target = '_blank';

		return api.client.get(`/files/${fileId}`, { headers: { 'Accept': 'application/json, text/plain, */*' } })
			.then((res) => {
				return api.client.get(`/files/${fileId}`, { headers: { 'Accept': '*/*' } }).then((file) => {
					link.download = res.data.name;
					link.href = URL.createObjectURL(
						new Blob([file.data], { type: res.data.mime })
					);
					link.click();
				});
			});
	},
	downloadContinuityScript: async(scriptId) => {
		const session = await SessionService.getSession();
		EventService.createEvent(session._id, [scriptId], 'sd');

		const event = document.createEvent('MouseEvents');
		let link = document.createElement('a');
		return api.client.get(`/files/${scriptId}`, { headers: { 'Accept': 'application/json, text/plain, */*' } }, { overrideUrl: true })
			.then((result) => {
				fetch(`${process.env.NEXT_PUBLIC_REST_URL}/files/${scriptId}`)
					.then((r) => r.blob())
					.then((res) => {
						const reader = new FileReader();
						reader.readAsDataURL(res);
						reader.addEventListener('load', () => {
							link.href = reader.result;
							link.download = result.data.name;
							event.initEvent('click', true, true);
							link.dispatchEvent(event);
						});
					});
			});
	},
	downloadGraphicsProject: (url) => {
		const link = document.createElement('a');
		link.target = '_blank';
		link.download = 'download';
		link.href = url;
		link.click();
	},
	uploadAssetFile({ file, onUploadProgress }) {
		return api.client.post( '/files', file,
			{
				headers: {
					'X-File-Name': file.name,
					'Accept': 'application/json, text/plain, */*', 'Content-Type': 'application/octet-stream'
				},
				onUploadProgress
			})
			.then((res) => {
				return res.data._id;
			});
	},

	uploadImageFile(file) {
		return api.client.post( '/files', file,
			{
				headers: {
					'X-File-Name': file.name,
					'Accept': 'application/json, text/plain, */*', 'Content-Type': 'application/octet-stream'
				}
			})
			.then((res) => {
				return res.data._id;
			});
	},

	deleteFile(id) {
		return api.client.delete(`/files/${id}`).catch(console.error);
	}
};

export default FileService;
