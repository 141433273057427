import _ from 'lodash';
import _get from 'lodash.get';
import _chunk from 'lodash.chunk';
import isError from 'lodash.iserror';
import {
	takeLeading,
	takeLatest,
	select,
	put,
	call,
	fork,
	join,
	take
} from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import { createAction } from 'redux-actions';
import uuidv4 from 'uuid/v4';
import { RESET_STORES } from './session';
import {
	createSelector,
	extendQueue,
	filterQueue,
	updateDispatcher,
	handleError, isDialogueOrder, isGfxOrder
} from './common';
import WorkRequestService from '../modules/services/work-request-service';
import WorkRequestOrderService from '../modules/services/work-request-order-service';
import UploadService from '../modules/services/upload-service';
import IdService from '../modules/services/id-service';

import {
	actions as assetsActions, fetchAssets,
	selectors as assetsSelectors
} from './assets';
import {
	actions as assetTypesActions, fetchAssetTypes,
	selectors as assetTypesSelectors
} from './asset-types';
import {
	actions as projectsActions, fetchProjectsById,
	selectors as projectsSelectors
} from './projects';
import {
	actions as languagesActions, fetchLanguages,
	selectors as languagesSelectors
} from './languages';
import {
	actions as territoriesActions, fetchTerritories,
	selectors as territoriesSelectors
} from './territories';
import {
	actions as workRequestsActions
} from './work-requests';
import TranslationService from '../modules/services/translation-service';
import { redirect } from 'next/navigation';

export const STORE_NAME = 'workRequestQueueStore';

// URLs limited to just over 2000 characters. IDs are 25 characters long. That means we can query about 80 ids at a time.
const CHUNK_SIZE = 80;

function *loadPageData() {
	try {
		let assetIDs = yield select(selectors.getQueue);
		if( !assetIDs.length ) {
			return;
		}

		yield put(internalActions.loadPageRequest());

		// Fetch all languages and territories
		let languagesTask = yield fork(fetchLanguages, languagesActions.getLanguages());
		let territoriesTask = yield fork(fetchTerritories, territoriesActions.getTerritories());

		// Split asset ids into chunks (to stay within url length limits)
		let chunkedAssetIDs = _chunk(assetIDs, CHUNK_SIZE);

		// Fetch all asset chunks in parallel
		let assetsTasks = [];
		let options = { withExtended: true };
		for( let assetIds of chunkedAssetIDs ) {
			let assetTask = yield fork(fetchAssets, assetsActions.getAssetsById({ assetIds, options }));
			assetsTasks.push(assetTask);
		}

		// See if any of the chunk requests failed, if so, total wash and error out
		let chunkedAssets = yield join(...assetsTasks);
		let badAssets = [];
		chunkedAssets.forEach((asset) => {
			if( isError(asset) ) {
				throw asset;
			}
			if(_.isEmpty(asset)) {
				badAssets.push(asset);
				if(chunkedAssets.length === 1) {
					throw new Error('Asset Not Found');
				}
			}
		});
		if(badAssets.length) {
			const newQueue = chunkedAssets.filter((o) => !badAssets.includes(o));
			yield call(actions.clearQueue);
			yield put({ type: QUEUE_UPDATE, payload: newQueue.map((e) => e._id) });
			// refresh to update url with new queue
			redirect('/work-requests/new')
		}
		// Get a list of unique projects for all the assets in queue
		let assets = _.flatten(chunkedAssets).filter((asset) => !badAssets.includes(asset));
		let projectIDs = _.reduce(assets, (result, asset) => {
			result.add(asset.project);
			return result;
		}, new Set());

		// Split project ids into chunks ( to stay within url length limits)
		let chunkedProjectIDs = _chunk(Array.from(projectIDs), CHUNK_SIZE);

		// Fetch all project chunks in parallel
		let projectsTasks = [];
		for( let projectIDs of chunkedProjectIDs ) {
			let projectTask = yield fork(fetchProjectsById, projectsActions.fetchProjectsById(projectIDs));
			projectsTasks.push(projectTask);
		}

		// See if any of the chunk requests failed, if so, total wash and error out
		let chunkedProjects = [];
		if(projectsTasks.length>0) {
			chunkedProjects = yield join(...projectsTasks);
			for( let projects of chunkedProjects ) {
				if( isError(projects) ) {
					throw projects;
				}
			}
		}

		// Wait and check if languages or territores failed to laod
		let languages = yield join(languagesTask);
		let territories = yield join(territoriesTask);

		if( isError(languages) || isError(territories) ) {
			throw new Error('Failed to load language and territory data');
		}

		// Trigger an initial estimate but don't worry about the response for load success
		yield call(estimateOrder);
		yield put(internalActions.loadPageSuccess());
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadPageFailure(err));
		return err;
	}
}

function *estimateOrder() {
	let assetId;
	try {
		let order = yield select(selectors.getCurrentWorkRequestOrder);
		if( !order ) {
			throw new Error('Cannot place order. Order not found');
		}

		assetId = yield select(selectors.getCurrentWorkRequestOrderAssetID);
		yield put(internalActions.estimateOrderRequest(assetId));

		let asset = yield select((s) => assetsSelectors.getAssetById(s, assetId));
		if(!asset) {
			throw new Error('Cannot place order, Asset not found');
		}
		let assetType = yield select((s) => assetTypesSelectors.getAssetType(s, asset?.type));

		// Asset type not guarenteed to be loaded
		if( !assetType ) {
			let assetTypes = yield call(fetchAssetTypes, assetTypesActions.getAssetTypes());
			if( isError(assetTypes) ) {
				throw new Error('Failed to place order. Could not query asset types');
			}

			assetType = yield select((s) => assetTypesSelectors.getAssetType(s, asset?.type));
		}

		let estimate = yield WorkRequestOrderService.getCostEstimate(order, asset, assetType);
		if( isError(estimate) ) {
			throw estimate;
		}

		yield put(internalActions.estimateOrderSuccess({ assetId, estimate }));
		return estimate;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.estimateOrderFailure({ assetId, error: err }));
		return err;
	}
}

function *placeOrder() {
	try {
		yield put(internalActions.placeOrderSubmitWorkRequest());

		let order = yield select(selectors.getCurrentWorkRequestOrder);
		if( !order ) {
			throw new Error('Cannot place order. Order not found');
		}

		// The asset gets a fresh load when the queue is loaded so we can just use a selector
		// and grab the recently cached copy.
		let assetId = yield select(selectors.getCurrentWorkRequestOrderAssetID);

		let asset = yield select((s) => assetsSelectors.getAssetById(s, assetId));
		let assetType = yield select((s) => assetTypesSelectors.getAssetType(s, asset.type));

		let needsTranslation = WorkRequestOrderService.doesOrderNeedTranslation(order);

		// Asset type not guarenteed to be loaded
		if( !assetType ) {
			let assetTypes = yield call(fetchAssetTypes, assetTypesActions.getAssetTypes());
			if( isError(assetTypes) ) {
				throw new Error('Failed to place order. Could not query asset types');
			}

			assetType = yield select((s) => assetTypesSelectors.getAssetType(s, asset.type));
		}

		let transformedOrder = WorkRequestOrderService.toWorkRequest(order, asset, assetType);
		let workRequest = yield WorkRequestService.create(transformedOrder);

		// Update local work request store
		yield put(workRequestsActions.manualUpdateWorkRequest(workRequest));

		// Generate upload requests for each tag file
		let tagFiles = _.map(order.tags.tags, (tag, tagIndex) => {
			return _.map(tag.files, (file, fileIndex) => {
				// Generate a unique id for tracking purposes
				return {
					id: uuidv4(),
					tagIndex,
					fileIndex,
					tag,
					file,
					workRequestId: workRequest._id
				};
			});
		});
		tagFiles = _.flatten(tagFiles);

		// Generate upload requests for each file
		let files = _.map(order.additionalInfo.files, (file, fileIndex) => {
			return {
				id: uuidv4(),
				fileIndex,
				file,
				workRequestId: workRequest._id
			};
		});

		if( tagFiles.length || files.length ) {
			let allFiles = [...tagFiles, ...files];

			// Update store with initial progress data
			yield put(internalActions.placeOrderUploadFiles(allFiles));

			// Repeat until all uploads are successful (retry enabled)
			while( tagFiles.length || files.length ) {
				let uploadTasks = [];

				// Fork an upload task for each tag file
				for( let tagFile of tagFiles ) {
					let task = yield fork(uploadFile, tagFile);
					uploadTasks.push(task);
				}

				// Fork an upload task for each file
				for( let file of files ) {
					let task = yield fork(uploadFile, file);
					uploadTasks.push(task);
				}

				let uploadResults = yield join(uploadTasks);

				// Split upload results back into tag files and regular files
				let tagFileUploadResults = _.filter(uploadResults, ({ uploadRequest }) => uploadRequest.hasOwnProperty('tagIndex'));
				let fileUploadResults = _.reject(uploadResults, ({ uploadRequest }) => uploadRequest.hasOwnProperty('tagIndex'));

				// Update work request
				tagFiles = _.reduce(tagFileUploadResults, (result, tagFileUploadResult) => {
					// Requeue failed uploads
					if( isError(tagFileUploadResult.result) ) {
						result.push(tagFileUploadResult.uploadRequest);
						return result;
					}

					// Update the work request tag file paths
					let { tagIndex, fileIndex } = tagFileUploadResult.uploadRequest;
					workRequest.tags[tagIndex].files[fileIndex] = tagFileUploadResult.result;
					return result;
				}, []);

				files = _.reduce(fileUploadResults, (result, fileUploadResult) => {
					// Requeue failed uploads
					if( isError(fileUploadResult.result) ) {
						result.push(fileUploadResult.uploadRequest);
						return result;
					}

					// Update the work request file paths
					let { fileIndex } = fileUploadResult.uploadRequest;
					workRequest.files[0].files[fileIndex] = fileUploadResult.result;
					return result;
				}, []);

				// TODO: Handle possible failure
				// Update our work request (locally and remotely). It may be used again if
				// a retry is needed (i.e. a file failed to upload).
				// eslint-disable-next-line require-atomic-updates
				workRequest = yield WorkRequestService.update(workRequest._id, {
					files: workRequest.files,
					tags: workRequest.tags
				});

				yield put(internalActions.placeOrderUploadFilesDone());

				// If there were any upload failures, wait for user input (retry/cancel failed uploads)
				if( tagFiles.length || files.length ) {
					let retryOrCancel = yield take([
						PLACE_ORDER_UPLOAD_FILES_RETRY,
						PLACE_ORDER_UPLOAD_FILES_CANCEL
					]);

					// If the user selected cancel, otherwise let the loop continue for a retry
					if( retryOrCancel.type === PLACE_ORDER_UPLOAD_FILES_CANCEL ) {
						tagFiles.length = 0;
						files.length = 0;

						// We could remove the empty object from the work request tag's files, however it doesn't seem to affect anything
						// so we'll skip the extra network request (and potential error). In the future we should not put placeholders for tag files
					}
				}
			}
		}

		if( needsTranslation ) {
			yield put(internalActions.placeOrderTranslationsStart());
			// check if the order is a graphics order to update the workRequest.graphicsTranslation instead of workRequest.translation
			const graphicsTranslationId = isGfxOrder(workRequest) ? workRequest.graphicsTranslation._id: false;
			let translationId = isDialogueOrder(workRequest) ? workRequest.translation._id: false;

			let translationUpdate = {};
			let graphicsTranslationUpdate = {};
			// Current user is the default translator. Update that if a translationProvider is provided.
			if( order.localization.translationProvider ) {
				if(translationId) {
					translationUpdate.translator = IdService.convertFromId(order.localization.translationProvider);
				}
				if(graphicsTranslationId) {
					graphicsTranslationUpdate.translator = IdService.convertFromId(
						order.localization.translationProvider
					);
				}
			}

			// If translator is 3rd party subtitling vendor, does translation need approval?
			let isThirdPartyTranslator = order.localization.translationProviderType === 'email';
			if( isThirdPartyTranslator ) {
				if(translationId) {
					translationUpdate.approval = order.localization.needsApproval;
				}
				if(graphicsTranslationId) {
					graphicsTranslationUpdate.approval = order.localization.needsApproval;
				}
			}

			// If translations are provided via a file, start the file upload phase
			let needsFileUpload = order.localization.translationProviderType === 'upload';
			while( needsFileUpload ) {
				if(translationId) {
					try {
						let uploadResult = yield call(
							uploadTranslations,
							translationId,
							order.localization.translationFile
						);

						if( isError(uploadResult.result) ) {
							throw uploadResult.result;
						}

						translationUpdate.file = uploadResult.result;
						translationUpdate.status = 'submitted';
						needsFileUpload = false;
					}
					catch(e) {
						yield put(internalActions.placeOrderTranslationsFail());
						let retryOrCancel = yield take([
							PLACE_ORDER_TRANSLATIONS_RETRY,
							PLACE_ORDER_TRANSLATIONS_CANCEL
						]);

						// If the user selected cancel, otherwise let the loop continue for a retry
						if( retryOrCancel.type === PLACE_ORDER_TRANSLATIONS_CANCEL ) {
							needsFileUpload = false;
							delete translationUpdate.file;
							delete translationUpdate.status;
						}
					}
				}
				if(graphicsTranslationId) {
					try {
						let uploadResult = yield call(
							uploadTranslations,
							graphicsTranslationId,
							order.localization.translationFile
						);

						if( isError(uploadResult.result) ) {
							throw uploadResult.result;
						}

						graphicsTranslationUpdate.file = uploadResult.result;
						graphicsTranslationUpdate.status = 'submitted';
						needsFileUpload = false;
					}
					catch(e) {
						yield put(internalActions.placeOrderTranslationsFail());
						let retryOrCancel = yield take([
							PLACE_ORDER_TRANSLATIONS_RETRY,
							PLACE_ORDER_TRANSLATIONS_CANCEL
						]);

						// If the user selected cancel, otherwise let the loop continue for a retry
						if( retryOrCancel.type === PLACE_ORDER_TRANSLATIONS_CANCEL ) {
							needsFileUpload = false;
							delete graphicsTranslationUpdate.file;
							delete graphicsTranslationUpdate.status;
						}
					}
				}
			}
			if(!_.isEmpty(translationUpdate) || !_.isEmpty(graphicsTranslationUpdate)) {
				if(translationId) {
					yield call(TranslationService.updateTranslation, translationId, translationUpdate);
				}
				if(graphicsTranslationId) {
					yield call(TranslationService.updateTranslation, graphicsTranslationId, graphicsTranslationUpdate);
				}
			}
		}

		yield put(internalActions.placeOrderSuccess(workRequest._id));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.placeOrderFailure(err));
		return err;
	}
}

function *uploadTranslations(translationId, translationFile) {
	let uploadRequest = {
		translationId,
		file: translationFile
	};
		// Create an event channel so that our async updates can dispatch update events.
		// The inner upload method always resolves, that way parallel tasks are not
	let resultPromise;
	let channel = eventChannel((emit) => {
		let emitUpdate = (update) => {
			emit(update);
		};

		resultPromise = UploadService.uploadTranslation(uploadRequest.file, uploadRequest, emitUpdate)
			.then((result) => {
				return {
					uploadRequest,
					result
				};
			})
			.catch((e) => {
				let err = isError(e) ? e : new Error(e);

				// Do not emit Error directly as it will crash the channel
				emit({ error: err });
				return {
					uploadRequest,
					result: err
				};
			})
			.finally(() => {
				// End this channel
				emit(END);
			});

		return _.noop;
	});

	// We have to fork the update dispatcher because the when a channel ends, the generator
	// function is ended at the `take` and no additional code is run. We don't want this
	// generator function to end because we need to be able to return the promise to the
	// calling function. Can't do that when a generator function just ends.
	yield fork(updateDispatcher, channel, uploadRequest);

	let result = yield resultPromise;
	return result;
}

function *uploadFile(uploadRequest) {
	let uploadMethod = uploadRequest.hasOwnProperty('tagIndex')
		? UploadService.uploadTagFile
		: UploadService.uploadFile;

	// Create an event channel so that our async updates can dispatch update events.
	// The inner upload method always resolves, that way parallel tasks are not
	let resultPromise;
	let channel = eventChannel((emit) => {
		let emitUpdate = (update) => {
			emit(update);
		};

		resultPromise = uploadMethod(uploadRequest.file, uploadRequest, emitUpdate)
			.then((result) => {
				return {
					uploadRequest,
					result
				};
			})
			.catch((e) => {
				let err = isError(e) ? e : new Error(e);

				// Do not emit Error directly as it will crash the channel
				emit({ error: err });
				return {
					uploadRequest,
					result: err
				};
			})
			.finally(() => {
				// End this channel
				emit(END);
			});

		return _.noop;
	});

	// We have to fork the update dispatcher because the when a channel ends, the generator
	// function is ended at the `take` and no additional code is run. We don't want this
	// generator function to end because we need to be able to return the promise to the
	// calling function. Can't do that when a generator function just ends.
	yield fork(updateDispatcher, channel, uploadRequest, (update) => {
		return internalActions.placeOrderUploadFilesUpdate(update);
	});

	let result = yield resultPromise;
	return result;
}

export const LOAD_PAGE = 'work-request-queue.load-page';
export const LOAD_PAGE_REQUEST = 'work-request-queue.load-page.request';
export const LOAD_PAGE_SUCCESS = 'work-request-queue.load-page.success';
export const LOAD_PAGE_FAILURE = 'work-request-queue.load-page.failure';

export const ESTIMATE_ORDER = 'work-request-queue.estimate-order';
export const ESTIMATE_ORDER_REQUEST = 'work-request-queue.estimate-order.request';
export const ESTIMATE_ORDER_SUCCESS = 'work-request-queue.estimate-order.success';
export const ESTIMATE_ORDER_FAILURE = 'work-request-queue.estimate-order.failure';

export const PLACE_ORDER = 'work-request-queue.place-order';
export const PLACE_ORDER_SUBMIT_WORK_REQUEST = 'work-request-queue.place-order.submit-work-request';
export const PLACE_ORDER_UPLOAD_FILES = 'work-request-queue.place-order.upload-files';
export const PLACE_ORDER_UPLOAD_FILES_UPDATE = 'work-request-queue.place-order.upload-files-update';
export const PLACE_ORDER_UPLOAD_FILES_DONE = 'work-request-queue.place-order.upload-files-done';
export const PLACE_ORDER_UPLOAD_FILES_CANCEL = 'work-request-queue.place-order.upload-files-cancel';
export const PLACE_ORDER_UPLOAD_FILES_RETRY = 'work-request-queue.place-order.upload-files-retry';
export const PLACE_ORDER_TRANSLATIONS_START = 'work-request-queue.place-order.translations-start';
export const PLACE_ORDER_TRANSLATIONS_FAIL = 'work-request-queue.place-order.translations-fail';
export const PLACE_ORDER_TRANSLATIONS_RETRY = 'work-request-queue.place-order.translations-retry';
export const PLACE_ORDER_TRANSLATIONS_CANCEL = 'work-request-queue.place-order.translations-cancel';
export const PLACE_ORDER_TRANSLATIONS_DONE = 'work-request-queue.place-order.translations-done';
export const PLACE_ORDER_SUCCESS = 'work-request-queue.place-order.success';
export const PLACE_ORDER_COMPLETE = 'work-request-queue.place-order.complete';
export const PLACE_ORDER_FAILURE = 'work-request-queue.place-order.failure';
export const PLACE_ORDER_CANCEL = 'work-request-queue.place-order.cancel';

export const QUEUE_ADD = 'work-request-queue.queue.add';
export const QUEUE_REMOVE = 'work-request-queue.queue.remove';
export const QUEUE_UPDATE = 'work-request-queue.queue.update';
export const QUEUE_CLEAR = 'work-request-queue.queue.clear';

export const UPDATE_ORDER = 'work-request-queue.order.update';

export const INITIAL_STATE = {
	isLoading: false,
	loadError: null,
	// isPlacingOrder can be a boolean OR an object
	isPlacingOrder: false,
	currentOrderIndex: -1,
	workRequestOrders: {},
	workRequestOrderEstimates: {},
	workRequestQueue: []
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case RESET_STORES:
			return INITIAL_STATE;

		case LOAD_PAGE_REQUEST: {
			return {
				...state,
				isLoading: true,
				loadError: null
			};
		}

		case LOAD_PAGE_SUCCESS: {
			return {
				...state,
				isLoading: false,
				loadError: null
			};
		}

		case LOAD_PAGE_FAILURE: {
			return {
				...state,
				isLoading: false,
				loadError: action.payload
			};
		}

		case QUEUE_ADD: {
			let toAdd = action.payload;
			let workRequestQueue = extendQueue(state.workRequestQueue, toAdd);

			// If the work request queue is unchanged and has zero assets, state update is not needed
			if( !workRequestQueue.length ) {
				return state;
			}

			let {
				currentOrderIndex,
				workRequestOrders: wrOrders
			} = state;

			if( currentOrderIndex < 0 ) {
				currentOrderIndex = 0;

				let currentAssetID = workRequestQueue[0];
				wrOrders[currentAssetID] = WorkRequestOrderService.createBlankOrder();
			}

			return {
				...state,
				currentOrderIndex,
				workRequestQueue,
				workRequestOrders: wrOrders
			};
		}

		case QUEUE_REMOVE: {
			let toRemove = action.payload;

			// Store the asset id for the current order
			let currentOrderAssetID = state.workRequestQueue[state.currentOrderIndex];

			// Remove toRemove asset id(s) from the work request queue
			let workRequestQueue = filterQueue(state.workRequestQueue, toRemove);

			// Update current order index to its new position (may be -1 if current order asset was removed)
			let currentOrderIndex = workRequestQueue.indexOf(currentOrderAssetID);

			// Ensure assetIDs is an array even if only one element
			let assetIDs = toRemove;
			if( !_.isArray(assetIDs) ) {
				assetIDs = [assetIDs];
			}

			// Remove any on-going work request orders for removed asset ids
			let workRequestOrders = _.omit(state.workRequestOrders, assetIDs);
			let workRequestOrderEstimates = _.omit(state.workRequestOrderEstimates, assetIDs);

			// If the current order asset is among the removed asset ids, remove it and start a new order
			if( currentOrderIndex < 0 && workRequestQueue.length ) {
				currentOrderIndex = 0;

				let firstAssetID = workRequestQueue[0];
				if( !(firstAssetID in workRequestOrders) ) {
					let order = WorkRequestOrderService.createBlankOrder();
					workRequestOrders[firstAssetID] = order;
				}
			}

			return {
				...state,
				currentOrderIndex,
				workRequestQueue,
				workRequestOrders,
				workRequestOrderEstimates
			};
		}

		case QUEUE_UPDATE: {
			let workRequestQueue = action.payload;

			let {
				currentOrderIndex,
				workRequestOrders,
				workRequestOrderEstimates
			} = state;

			let toRemove = state.workRequestQueue.filter((id) => !workRequestQueue.includes(id));

			if( toRemove.length ) {
				let currentOrderAssetID = state.workRequestQueue[currentOrderIndex];
				currentOrderIndex = workRequestQueue.indexOf(currentOrderAssetID);
				workRequestOrders = _.omit(workRequestOrders, toRemove);
				workRequestOrderEstimates = _.omit(workRequestOrderEstimates, toRemove);
			}

			if( currentOrderIndex < 0 && workRequestQueue.length ) {
				currentOrderIndex = 0;

				if( !(workRequestQueue[0] in workRequestOrders) ) {
					workRequestOrders[workRequestQueue[0]] = WorkRequestOrderService.createBlankOrder();
				}
			}

			return {
				...state,
				currentOrderIndex,
				workRequestQueue,
				workRequestOrders,
				workRequestOrderEstimates
			};
		}

		case QUEUE_CLEAR: {
			return {
				...state,
				currentOrderIndex: -1,
				workRequestQueue: [],
				workRequestOrders: {},
				workRequestOrderEstimates: {}
			};
		}

		case UPDATE_ORDER: {
			if( state.currentOrderIndex < 0 ) {
				return state;
			}

			let order = action.payload;
			let assetID = state.workRequestQueue[state.currentOrderIndex];

			let workRequestOrders = {
				...state.workRequestOrders,
				[assetID]: order
			};

			return {
				...state,
				workRequestOrders
			};
		}

		case ESTIMATE_ORDER_REQUEST: {
			let assetId = action.payload;
			let existingEstimate = _get(state.workRequestOrderEstimates, [assetId, 'estimate'], {});

			return {
				...state,
				workRequestOrderEstimates: {
					...state.workRequestOrderEstimates,
					[assetId]: {
						data: null,
						...existingEstimate,
						isLoading: true,
						loadError: null
					}
				}
			};
		}

		case ESTIMATE_ORDER_SUCCESS: {
			let { assetId, estimate } = action.payload;

			return {
				...state,
				workRequestOrderEstimates: {
					...state.workRequestOrderEstimates,
					[assetId]: {
						data: estimate,
						isLoading: false,
						loadError: null
					}
				}
			};
		}

		case ESTIMATE_ORDER_FAILURE: {
			let { assetId, error } = action.payload;

			return {
				...state,
				workRequestOrderEstimates: {
					...state.workRequestOrderEstimates,
					[assetId]: {
						data: null,
						isLoading: false,
						loadError: error
					}
				}
			};
		}

		case PLACE_ORDER_SUBMIT_WORK_REQUEST: {
			return {
				...state,
				isPlacingOrder: {
					isSubmittingWorkRequest: true,
					isUploadingFiles: false,
					isUpdatingTranslations: false,
					didFailWorkRequestOrder: false,
					didFailFileUpload: false,
					didFailTranslations: false
				}
			};
		}

		case PLACE_ORDER_UPLOAD_FILES: {
			let tagFiles = action.payload;
			let fileUploadProgress = _.reduce(tagFiles, (result, tagFile) => {
				result[tagFile.id] = {
					name: tagFile.file.name,
					progress: 0
				};

				return result;
			}, {});

			return {
				...state,
				isPlacingOrder: {
					...state.isPlacingOrder,
					isSubmittingWorkRequest: false,
					isUploadingFiles: true,
					isUpdatingTranslations: false,
					fileUploadProgress
				}
			};
		}

		case PLACE_ORDER_UPLOAD_FILES_UPDATE: {
			let update = action.payload;
			let isPlacingOrder = {
				...state.isPlacingOrder,
				isUploadingFiles: true,
				fileUploadProgress: {
					...state.isPlacingOrder.fileUploadProgress,
					[update.id]: update
				}
			};

			let didFailFileUpload = _.reduce(isPlacingOrder.fileUploadProgress, (result, entry) => {
				return result || !!entry.error;
			}, false);
			isPlacingOrder.didFailFileUpload = didFailFileUpload;

			return {
				...state,
				isPlacingOrder
			};
		}

		case PLACE_ORDER_UPLOAD_FILES_DONE: {
			return {
				...state,
				isPlacingOrder: {
					...state.isPlacingOrder,
					isUploadingFiles: false
				}
			};
		}

		case PLACE_ORDER_TRANSLATIONS_START: {
			return {
				...state,
				isPlacingOrder: {
					...state.isPlacingOrder,
					isSubmittingWorkRequest: false,
					isUploadingFiles: false,
					isUpdatingTranslations: true
				}
			};
		}

		case PLACE_ORDER_TRANSLATIONS_FAIL: {
			return {
				...state,
				isPlacingOrder: {
					...state.isPlacingOrder,
					isUpdatingTranslations: false,
					didFailTranslations: true
				}
			};
		}

		case PLACE_ORDER_TRANSLATIONS_RETRY: {
			return {
				...state,
				isPlacingOrder: {
					...state.isPlacingOrder,
					isUpdatingTranslations: true,
					didFailTranslations: false
				}
			};
		}

		case PLACE_ORDER_TRANSLATIONS_CANCEL: {
			return {
				...state,
				isPlacingOrder: {
					...state.isPlacingOrder,
					isUpdatingTranslations: false,
					didFailTranslations: false
				}
			};
		}

		case PLACE_ORDER_TRANSLATIONS_DONE: {
			return {
				...state,
				isPlacingOrder: {
					...state.isPlacingOrder,
					isUpdatingTranslations: false,
					didFailTranslations: false
				}
			};
		}

		case PLACE_ORDER_SUCCESS: {
			let workRequestId = action.payload;
			return {
				...state,
				isPlacingOrder: {
					isSubmittingWorkRequest: false,
					isUploadingFiles: false,
					isUpdatingTranslations: false,
					didFailWorkRequestOrder: false,
					didFailFileUpload: false,
					didFailTranslations: false,
					workRequestId
				}
			};
		}

		case PLACE_ORDER_COMPLETE: {
			let workRequestQueue = [...state.workRequestQueue];
			let assetId = workRequestQueue[state.currentOrderIndex];

			// Remove from queue
			workRequestQueue.splice(state.currentOrderIndex, 1);

			// Remove order form data
			let workRequestOrders = {
				...state.workRequestOrders
			};
			delete workRequestOrders[assetId];

			// Remove order estimate
			let workRequestOrderEstimates = {
				...state.workRequestOrderEstimates
			};
			delete workRequestOrderEstimates[assetId];

			// Reset current order index
			let currentOrderIndex = workRequestQueue.length ? 0 : -1;

			// If the queue is not empty, make sure an order exists
			if( workRequestQueue.length ) {
				let nextAssetId = workRequestQueue[0];
				workRequestOrders[nextAssetId] = WorkRequestOrderService.createBlankOrder();
			}

			return {
				...state,
				workRequestQueue,
				workRequestOrders,
				workRequestOrderEstimates,
				currentOrderIndex,
				isPlacingOrder: false
			};
		}

		case PLACE_ORDER_FAILURE: {
			return {
				...state,
				isPlacingOrder: {
					isSubmittingWorkRequest: false,
					isUploadingFiles: false,
					isUpdatingTranslations: false,
					didFailWorkRequestOrder: true,
					didFailFileUpload: false,
					didFailTranslations: false
				}
			};
		}

		case PLACE_ORDER_CANCEL: {
			return {
				...state,
				isPlacingOrder: false
			};
		}

		default:
			return state;
	}
}

export const actions = {
	loadPageData: createAction(LOAD_PAGE),
	addToQueue: createAction(QUEUE_ADD),
	removeFromQueue: createAction(QUEUE_REMOVE),
	updateQueue: createAction(QUEUE_UPDATE),
	clearQueue: createAction(QUEUE_CLEAR),
	updateWorkRequestOrder: createAction(UPDATE_ORDER),
	estimateWorkRequestOrder: createAction(ESTIMATE_ORDER),
	placeWorkRequestOrder: createAction(PLACE_ORDER),
	completePlacedOrder: createAction(PLACE_ORDER_COMPLETE),
	cancelPlaceWorkRequestOrder: createAction(PLACE_ORDER_CANCEL),
	retryPlaceOrderFileUpload: createAction(PLACE_ORDER_UPLOAD_FILES_RETRY),
	cancelPlaceOrderFileUpload: createAction(PLACE_ORDER_UPLOAD_FILES_CANCEL),
	retryPlaceOrderTranslations: createAction(PLACE_ORDER_TRANSLATIONS_RETRY),
	cancelPlaceOrderTranslations: createAction(PLACE_ORDER_TRANSLATIONS_CANCEL)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	loadPageRequest: createAction(LOAD_PAGE_REQUEST),
	loadPageSuccess: createAction(LOAD_PAGE_SUCCESS),
	loadPageFailure: createAction(LOAD_PAGE_FAILURE),
	estimateOrderRequest: createAction(ESTIMATE_ORDER_REQUEST),
	estimateOrderSuccess: createAction(ESTIMATE_ORDER_SUCCESS),
	estimateOrderFailure: createAction(ESTIMATE_ORDER_FAILURE),
	placeOrderSubmitWorkRequest: createAction(PLACE_ORDER_SUBMIT_WORK_REQUEST),
	placeOrderUploadFiles: createAction(PLACE_ORDER_UPLOAD_FILES),
	placeOrderUploadFilesUpdate: createAction(PLACE_ORDER_UPLOAD_FILES_UPDATE),
	placeOrderUploadFilesDone: createAction(PLACE_ORDER_UPLOAD_FILES_DONE),
	placeOrderTranslationsStart: createAction(PLACE_ORDER_TRANSLATIONS_START),
	placeOrderTranslationsFail: createAction(PLACE_ORDER_TRANSLATIONS_FAIL),
	placeOrderTranslationsDone: createAction(PLACE_ORDER_TRANSLATIONS_DONE),
	placeOrderSuccess: createAction(PLACE_ORDER_SUCCESS),
	placeOrderFailure: createAction(PLACE_ORDER_FAILURE)
};

export const selectors = {
	isReady,
	isLoading: createSelector(STORE_NAME, isLoading),
	didLoadFail: createSelector(STORE_NAME, didLoadFail),
	loadError: createSelector(STORE_NAME, loadError),
	isPlacingOrder: createSelector(STORE_NAME, isPlacingOrder),
	getQueue: createSelector(STORE_NAME, getQueue),
	isAssetInQueue: createSelector(STORE_NAME, isAssetInQueue),
	getCurrentWorkRequestOrderAssetID: createSelector(STORE_NAME, getCurrentWorkRequestOrderAssetID),
	getCurrentWorkRequestOrder: createSelector(STORE_NAME, getCurrentWorkRequestOrder),
	getCurrentWorkRequestOrderEstimate: createSelector(STORE_NAME, getCurrentWorkRequestOrderEstimate),
	isLoadingCurrentOrderCostEstimate: createSelector(STORE_NAME, isLoadingCurrentOrderCostEstimate)
};

function isReady(state) {
	let queue = getQueue(state[STORE_NAME]);
	if( !queue.length ) {
		return true;
	}

	if( !languagesSelectors.isLoaded(state) ) {
		return false;
	}

	if( !territoriesSelectors.isLoaded(state) ) {
		return false;
	}

	// Make sure all the assets are loaded
	for(let i = 0; i < queue.length; ++i ) {
		let assetID = queue[i];
		if( !assetsSelectors.isExtendedAssetLoaded(state, assetID) ) {
			return false;
		}

		let asset = assetsSelectors.getAssetById(state, assetID);

		let projectID = asset.project;
		if( !projectsSelectors.isLoaded(state, projectID) ) {
			return false;
		}
	}

	return true;
}

function isLoading(state) {
	return state.isLoading;
}

function didLoadFail(state) {
	return !!(state?.loadError || _.values(state?.workRequestOrderEstimates)?.find((e) => e?.loadError)?.loadError);
}

function loadError(state) {
	return state?.loadError || _.values(state?.workRequestOrderEstimates)?.find((e) => e?.loadError)?.loadError;
}

function isPlacingOrder(state) {
	return state.isPlacingOrder;
}

function getQueue(state) {
	return state.workRequestQueue;
}

function isAssetInQueue(state, assetId) {
	return state.workRequestQueue.indexOf(assetId) > -1;
}

function getCurrentWorkRequestOrderAssetID(state) {
	if( state.currentOrderIndex < 0 ) {
		return null;
	}

	return state.workRequestQueue[state.currentOrderIndex];
}

function getCurrentWorkRequestOrder(state) {
	if( state.currentOrderIndex < 0 ) {
		return null;
	}

	let assetId = state.workRequestQueue[state.currentOrderIndex];
	let order = state.workRequestOrders[assetId];

	return order;
}

function getCurrentWorkRequestOrderEstimate(state) {
	if( state.currentOrderIndex < 0 ) {
		return null;
	}

	let assetId = state.workRequestQueue[state.currentOrderIndex];

	return _get(state.workRequestOrderEstimates, [assetId, 'data'], null);
}

function isLoadingCurrentOrderCostEstimate(state) {
	if( state.currentOrderIndex < 0 ) {
		return false;
	}

	let assetId = state.workRequestQueue[state.currentOrderIndex];

	return _get(state.workRequestOrderEstimates, [assetId, 'isLoading'], false);
}

export function *watchWorkRequestQueue() {
	yield takeLeading(LOAD_PAGE, loadPageData);
	yield takeLeading(PLACE_ORDER, placeOrder);
	yield takeLatest(ESTIMATE_ORDER, estimateOrder);
}
