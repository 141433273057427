import { useEffect } from "react";

const ConnectionSpeedChecker = () => {
	useEffect(() => {
		// Check for support of the navigator.connection API
		if ("connection" in navigator) {
			const connection = navigator.connection;

			// Check if the browser supports the 'effectiveType' property
			if ("effectiveType" in connection) {
				const speed = connection.effectiveType;
				console.log(`Your estimated connection speed is: ${speed}`);
			} else {
				console.log("Connection speed: Not supported");
			}
		} else {
			console.log("Connection speed: Not supported");
		}
	}, []);

	return null; // Render nothing
};

export default ConnectionSpeedChecker;
