import isError from 'lodash.iserror';
import { createAction } from 'redux-actions';
import { put, fork, join, takeLatest } from 'redux-saga/effects';
import { createSelector, handleError } from './common';

import {
	actions as usersActions, fetchUserByEmail,
	selectors as usersSelectors
} from './users';
import {
	actions as territoriesActions, fetchTerritories,
	selectors as territoriesSelectors
} from './territories';

export const STORE_NAME = 'registerPageStore';

function *loadPageData({ payload: email }) {
	try {
		yield put(internalActions.loadPageRequest());

		// Fork to run separately (non-blocking)
		let userTask = yield fork(fetchUserByEmail, usersActions.getUserByEmail(email));
		let territoriesTask = yield fork(fetchTerritories, territoriesActions.getTerritories());

		// Join forked task back in
		let user = yield join(userTask);
		let territories = yield join(territoriesTask);
		if( isError(user) || isError(territories) ) {
			throw new Error('Failed to load page resources');
		}

		yield put(internalActions.loadPageSuccess());
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadPageFailure(err));
		return err;
	}
}

export const LOAD = 'register-page.load';
export const LOAD_REQUEST = 'register-page.load.request';
export const LOAD_SUCCESS = 'register-page.load.success';
export const LOAD_FAILURE = 'register-page.load.failure';

export const INITIAL_STATE = {
	isLoading: false,
	loadFailed: false
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case LOAD_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case LOAD_SUCCESS:
			return {
				...state,
				isLoading: false,
				loadFailed: false
			};

		case LOAD_FAILURE:
			return {
				...state,
				isLoading: false,
				loadFailed: true
			};

		default:
			return state;
	}
}

export const actions = {
	loadPageData: createAction(LOAD)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	loadPageRequest: createAction(LOAD_REQUEST),
	loadPageSuccess: createAction(LOAD_SUCCESS),
	loadPageFailure: createAction(LOAD_FAILURE)
};

export const selectors = {
	isLoading: createSelector(STORE_NAME, isLoading),
	isFailedLoad: createSelector(STORE_NAME, isFailedLoad),
	isReady
};

function isLoading(state) {
	return state.isLoading;
}

function isFailedLoad(state) {
	return state.loadFailed;
}

function isReady(state, email) {
	// A user record may not exist. We just want to verify that we tried to obtain a user record.
	let isUserLoaded = !!usersSelectors.getUserLastLoadedByEmailAt(state, email);
	let areTerritoriesLoaded = territoriesSelectors.isLoaded(state);

	return isUserLoaded && areTerritoriesLoaded;
}

export function *watchRegisterPage() {
	yield takeLatest(LOAD, loadPageData);
}
