import moment from 'moment';
import Cookies from 'js-cookie';
import _get from 'lodash.get';
import _ from 'lodash';
import api from './api-service';
import EventService from './event-service';
import TransferManager from './transfer-manager';
import Aspera from './aspera';
import Upload from './upload';

export default function AuthorizedUploadHandler(options) {
	let uploadHandler = {
		error: null,
		state: null,
		progress: 0
	};

	const { updateCb, request: uploadRequest } = options;

	function handleError(string, object) {
		uploadHandler = {
			...uploadHandler,
			state: null,
			error: {
				string: 'userFileUpload.error.' + string,
				object: _.pick(object, ['status', 'data'])
			}
		};

		return new Error('error.' + string);
	}

	async function createRequest(options) {
		uploadHandler = {
			...uploadHandler,
			error: null,
			state: 'request',
			progress: 0,
			start: 0,
			current: 0,
			duration: 0,
			estimated: 'Calculating...',
			size: 0
		};

		return await api.client.post('/upload-request', options)
			.then((res) => res.data)
			.catch((e) => {
				handleError(e);
			});
	}

	function transferFile(file, request) {
		uploadHandler.state = 'transfer';
		const abortController = new AbortController();
		uploadHandler.abort = abortController.abort;
		let WHITELIST = [
			'key',
			'AWSAccessKeyId',
			'acl',
			'policy',
			'signature',
			'Content-Type'
		];
		let options = {
			fields: _.pick({ ...request, ...uploadRequest }, (value, key) => {
				return WHITELIST.indexOf(key) > -1
						|| key.indexOf('x-amz-meta-') > -1;
			}),
			url: request.bucket,
			method: 'POST',
			file: file,
			onUploadProgress: setProgress,
			signal: abortController.signal
		};
		if(request.language) {
			options.language = request.language;
		}
		let now = new Date().getTime();

		function setProgress(event) {
			let percent = parseInt(100.0 * event.loaded / event.total, 10);
			uploadHandler.title = file.name;
			uploadHandler.status = 'running';
			uploadHandler.active = true;
			uploadHandler.progress = percent;
			uploadHandler.bytes_written = event.loaded;
			uploadHandler.bytes_expected = file.size;
			uploadHandler.elapsed = moment.duration((new Date().getTime() - now)/1000).humanize();
			uploadHandler.remaining = '-';

			updateCb && updateCb(uploadHandler);
		}

		function getFileUrl() {
			// eslint-disable-next-line no-template-curly-in-string
			return request.bucket + '/' + request.key.replace('${filename}', file.name);
		}

		function destinationName(file) {
			let path = file.name.replace(/\\/g, '\\\\');
			return (path.charAt(0) === '/') ? path.split('/').pop() : path.split('\\\\').pop();
		}

		uploadHandler.size = file.size;
		uploadHandler.start = new Date().getTime();

		if(request.usage === 'slurpee2' && request.transferSpec) {
			// Failover when Aspera doesn't work
			if(TransferManager.pluginStatus() !== 'RUNNING') {
				return Upload.upload(options)
					.then(() => {
						uploadHandler.status = 'completed';
						return request.node_url + '/' + request.ingest + '/' + destinationName(file);
					})
					.catch(_.partial(handleError, 'transfer'));
			}
			const credentials = JSON.parse(Cookies.get('pixwel.api.credentials'));
			let specs = {
				paths: [
					{
						source: file.name,
						destination: request.ingest + '/' + destinationName(file)
					}
				],
				authentication: 'token',
				remote_user: request.transferSpec.remote_user,
				remote_host: request.transferSpec.remote_host,
				token: request.transferSpec.token,
				direction: 'send',
				create_dir: true,
				target_rate_kbps: 500000,
				resume: 'sparse_checksum',
				fasp_port: 33001,
				ssh_port: 33001,
				cookie: credentials.username
			};

			let url = request.node_url + '/' + request.ingest + '/' + destinationName(file);

			return new Promise((resolve, reject) => {
				EventService.emit('Connect:TransferStarted', Aspera.Connect.startTransfer(specs, { allow_dialogs: false }));

				uploadHandler.active = false;
				uploadHandler.remaining = 'Calculating...';

				uploadHandler.listener = (event, obj) => {
					obj.transfers.forEach((transfer) => {
						if(!_get(transfer, 'transfer_spec.paths[0].destination', false)) {
							return;
						}
						let ingestId = transfer.transfer_spec.paths[0].destination.split('/').shift();

						if(ingestId === request.ingest) {
							uploadHandler.active = true;
							uploadHandler.status = transfer.status;
							uploadHandler.title = transfer.title;
							uploadHandler.bytes_expected = transfer.bytes_expected;
							uploadHandler.bytes_written = transfer.bytes_written;
							uploadHandler.elapsed = moment.duration(transfer.elapsed_usec/1000).humanize();
							uploadHandler.remaining = moment.duration(transfer.remaining_usec/1000).humanize();
							uploadHandler.progress = transfer.percentage * 100;
							uploadHandler.abort = () => {
								uploadHandler.cancelling = true;
								Aspera.Connect.removeTransfer(transfer.uuid);
							};

							updateCb && updateCb(uploadHandler);

							if(transfer.status === 'completed') {
								uploadHandler.active = false;
								resolve(url);
							}
							if(transfer.status === 'removed') {
								uploadHandler.active = false;
								uploadHandler.cancelling = false;
								reject();
							}
						}
					});
				};

				Aspera.Connect.addEventListener('transfer', uploadHandler.listener);
			});

		}

		return Upload.upload(options)
			.then(getFileUrl)
			.catch(_.partial(handleError, 'transfer'));

	}

	function finalize(result) {
		uploadHandler.state = 'finished';
		return result;
	}

	let promise = createRequest(options.request)
		.then( ( req ) => transferFile(options.file, { ...req, ...options.request }))
		.catch((error) => handleError('Upload', error))
		.then(finalize);

	return Object.assign(promise, { uploadHandler });
}
