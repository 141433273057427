import _ from 'lodash';
import _get from 'lodash.get';
import isError from 'lodash.iserror';
import uuid from 'uuid/v4';
import { createAction } from 'redux-actions';
import { buffers, eventChannel, END } from 'redux-saga';
import {
	actionChannel,
	flush,
	fork,
	join,
	put,
	take,
	takeEvery,
	select
} from 'redux-saga/effects';
import { createSelector, updateDispatcher, handleError } from './common';
import UploadService from '../modules/services/upload-service';
import WorkRequestService from '../modules/services/work-request-service';

export const STORE_NAME = 'workRequestsStore';

export function *fetchWorkRequestById({ payload: id }) {
	try {
		yield put(internalActions.getWorkRequestByIdRequest(id));

		let workRequest = yield WorkRequestService.getById(id);
		if( workRequest === null ) {
			throw new Error('Failed to load work request');
		}

		yield put(internalActions.getWorkRequestByIdSuccess(workRequest));
		return workRequest;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getWorkRequestByIdFailure({ id, error: err }));
		return err;
	}
}

export function *updateWorkRequest({ payload }) {
	let { id, data } = payload;

	try {
		yield put(internalActions.updateWorkRequestRequest({ id }));

		let workRequest = yield WorkRequestService.update(id, data);

		yield put(internalActions.updateWorkRequestSuccess({ workRequest }));
		return workRequest;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.updateWorkRequestFailure({ id, err }));
		return err;
	}
}

function *addFile({ type, payload }) {
	let { workRequestId, file } = payload;

	if( !file.id ) {
		file.id = uuid();
	}

	try {
		yield put(internalActions.addFileRequest({ workRequestId, file }));

		let uploadResult = yield performFileUpload(workRequestId, file);
		if( isError(uploadResult.result) ) {
			throw uploadResult.result;
		}
		if(_.isEmpty(uploadResult.result)) {
			throw new Error('An Error Has Occurred While Uploading the File, Please try again');
		}

		let batchId = uuid();
		let path = uploadResult.result;
		yield put(internalActions.batchUpdate({ batchId, type, workRequestId, file: path }));

		let batchComplete = yield take(({ type, payload = {} }) => {
			let { batchIds=[] } = payload;
			return type === BATCH_UPDATE_COMPLETE
					&& payload.workRequestId === workRequestId
					&& batchIds.includes(batchId);
		});

		if( !batchComplete.payload.success ) {
			throw new Error('Failed to add file to work request');
		}

		yield put(internalActions.addFileSuccess({ workRequestId, file }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.addFileFailure({ workRequestId, file, error: err }));
		return err;
	}
}

function *addTagFile({ type, payload }) {
	let { workRequestId, tag, file } = payload;

	if( !file.id ) {
		file.id = uuid();
	}

	try {
		yield put(internalActions.addTagFileRequest({ workRequestId, tag, file }));

		let uploadResult = yield performTagFileUpload(workRequestId, tag, file);
		if( isError(uploadResult.result) ) {
			throw uploadResult.result;
		}

		let batchId = uuid();
		let path = uploadResult.result;
		yield put(internalActions.batchUpdate({ batchId, type, workRequestId, tag, file: path }));

		let batchComplete = yield take(({ type, payload = {} }) => {
			let { batchIds=[] } = payload;
			return type === BATCH_UPDATE_COMPLETE
					&& payload.workRequestId === workRequestId
					&& batchIds.includes(batchId);
		});

		if( !batchComplete.payload.success ) {
			throw new Error('Failed to add tag file to work request');
		}

		yield put(internalActions.addTagFileSuccess({ workRequestId, tag, file }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.addTagFileFailure({ workRequestId, tag, file, error: err }));
		return err;
	}
}

// Unlike other async sagas, this one relies on a queue and therefore will only put the request
// and let the queue handle success/failure events when it gets processed.
function *removeFile({ type, payload }) {
	let { workRequestId, file } = payload;

	try {
		yield put(internalActions.removeFileRequest({ workRequestId, file }));

		let batchId = uuid();
		yield put(internalActions.batchUpdate({ batchId, type, workRequestId, file }));

		let batchComplete = yield take(({ type, payload={} }) => {
			let { batchIds=[] } = payload;
			return type === BATCH_UPDATE_COMPLETE
					&& payload.workRequestId === workRequestId
					&& batchIds.includes(batchId);
		});

		if( !batchComplete.payload.success ) {
			throw new Error('Failed to remove file from work request');
		}

		yield put(internalActions.removeFileSuccess({ workRequestId, file }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.removeFileFailure({ workRequestId, file, error: err }));
		return err;
	}
}

// Unlike other async sagas, this one relies on a queue and therefore will only put the request
// and let the queue handle success/failure events when it gets processed.
function *removeTagFile({ type, payload }) {
	let { workRequestId, tag, file } = payload;

	try {
		yield put(internalActions.removeTagFileRequest({ workRequestId, tag, file }));

		let batchId = uuid();
		yield put(internalActions.batchUpdate({ batchId, type, workRequestId, tag, file }));

		let batchComplete = yield take(({ type, payload = {} }) => {
			let { batchIds=[] } = payload;
			return type === BATCH_UPDATE_COMPLETE
					&& payload.workRequestId === workRequestId
					&& batchIds.includes(batchId);
		});

		if( !batchComplete.payload.success ) {
			throw new Error('Failed to remove file from work request');
		}

		yield put(internalActions.removeTagFileSuccess({ workRequestId, tag, file }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.removeTagFileFailure({ workRequestId, tag, file, error: err }));
		return err;
	}
}

function *performFileUpload(workRequestId, file) {
	let uploadRequest = {
		id: file.id,
		workRequestId,
		file
	};

	// Create an event channel so that our async updates can dispatch update events.
	// The inner upload method always resolves, that way parallel tasks are not
	let resultPromise;
	let channel = eventChannel((emit) => {
		let emitUpdate = (update) => {
			emit(update);
		};

		resultPromise = UploadService.uploadFile(file, uploadRequest, emitUpdate)
			.then((result) => {
				return {
					uploadRequest,
					result
				};
			})
			.catch((e) => {
				let err = isError(e) ? e : new Error(e);

				// Do not emit Error directly as it will crash the channel
				emit({ error: err });
				return {
					uploadRequest,
					result: err
				};
			})
			.finally(() => {
				// End this channel
				emit(END);
			});

		// Noop unsubscribe
		return _.noop;
	});

	// We have to fork the update dispatcher because the when a channel ends, the generator
	// function is ended at the `take` and no additional code is run. We don't want this
	// generator function to end because we need to be able to return the promise to the
	// calling function. Can't do that when a generator function just ends.
	yield fork(updateDispatcher, channel, uploadRequest, (update) => {
		return internalActions.addFileUpdate({ workRequestId, update });
	});

	let result = yield resultPromise;
	return result;
}

// TODO: Find a clean way to reuse file uploader with tag file uploads
function *performTagFileUpload(workRequestId, tag, file) {
	let uploadRequest = {
		id: file.id,
		workRequestId,
		tag,
		file
	};

	// Create an event channel so that our async updates can dispatch update events.
	// The inner upload method always resolves, that way parallel tasks are not
	let resultPromise;
	let channel = eventChannel((emit) => {
		let emitUpdate = (update) => {
			emit(update);
		};

		resultPromise = UploadService.uploadFile(file, uploadRequest, emitUpdate)
			.then((result) => {
				return {
					uploadRequest,
					result
				};
			})
			.catch((e) => {
				let err = isError(e) ? e : new Error(e);

				// Do not emit Error directly as it will crash the channel
				emit({ error: err });
				return {
					uploadRequest,
					result: err
				};
			})
			.finally(() => {
				// End this channel
				emit(END);
			});

		// Noop unsubscribe
		return _.noop;
	});

	// We have to fork the update dispatcher because the when a channel ends, the generator
	// function is ended at the `take` and no additional code is run. We don't want this
	// generator function to end because we need to be able to return the promise to the
	// calling function. Can't do that when a generator function just ends.
	yield fork(updateDispatcher, channel, uploadRequest, (update) => {
		return internalActions.addTagFileUpdate({ workRequestId, tag, update });
	});

	let result = yield resultPromise;
	return result;
}

// A special update method used by files and tags to perform
// queued and batched updates. This prevents race conditions
// from multiple file additions or removals occurring quickly.
function *queueAndBatchUpdateFiles() {
	const queue = yield actionChannel(BATCH_UPDATE, buffers.expanding(10));

	// Keep a reference to our actions due to naming collisions below
	const WorkRequestActions = actions;

	while(true) {
		let actions = [];
		let groupedPayloads = {};
		let workRequestSuccess = new Set();

		try {
			// Implement a takeAll (take blocks but only returns one, flush gets all but doesn't block)
			let action = yield take(queue);
			actions = yield flush(queue);
			actions.unshift(action);

			// There is no guarantee that all these actions are for the same work request
			groupedPayloads = _.groupBy(actions, ({ payload }) => payload.workRequestId);

			let tasks = [];
			for( let [workRequestId, actions] of Object.entries(groupedPayloads) ) {
				let workRequest = yield select(selectors.getWorkRequestById, workRequestId);
				let update = {};

				for( let action of actions ) {
					let { payload } = action;
					let { type, file, tag } = payload;

					switch(type) {
						case ADD_FILE: {
							// Make a deep clone so that our changes don't mess with the data source
							if( !update.files ) {
								update.files = _.cloneDeep(workRequest.files);

								// If the attachments object has not been added, put it on
								if( !update.files.length ) {
									update.files.push({
										name: 'attachments',
										files: []
									});
								}
							}

							if(!_.isEmpty(file)) {
								update.files[0].files.push(file);
							}
							break;
						}

						case ADD_TAG_FILE: {
							// Make a deep clone so that our changes don't mess with the data source
							if( !update.tags ) {
								update.tags = _.cloneDeep(workRequest.tags);
							}

							let tagIndex = _.findIndex(update.tags, { name: tag });

							// If there is no files array yet, create one
							if( !update.tags[tagIndex].files ) {
								update.tags[tagIndex].files = [];
							}

							if(!_.isEmpty(file)) {
								update.tags[tagIndex].files.push(file);
							}
							break;
						}

						case REMOVE_FILE: {
							// Make a deep clone so that our changes don't mess with the data source
							if( !update.files ) {
								update.files = _.cloneDeep(workRequest.files);
							}

							let fileIndex = update.files[0].files.indexOf(file);
							update.files[0].files.splice(fileIndex, 1);
							break;
						}

						case REMOVE_TAG_FILE: {
							// Make a deep clone so that our changes don't mess with the data source
							if( !update.tags ) {
								update.tags = _.cloneDeep(workRequest.tags);
							}

							let tagIndex = _.findIndex(update.tags, { name: tag });
							let fileIndex = update.tags[tagIndex].files.indexOf(file);

							update.tags[tagIndex].files.splice(fileIndex, 1);
							break;
						}
					}
				}

				let action = WorkRequestActions.updateWorkRequest({ id: workRequestId, data: update });
				let task = yield fork(updateWorkRequest, action);
				tasks.push(task);
			}

			let workRequests = yield join(tasks);
			for( let workRequest of workRequests ) {
				if( !isError(workRequest) ) {
					workRequestSuccess.add(workRequest._id);
				}
			}
		}
		catch(e) {
			handleError(e);
		}
		finally {
			for( let [workRequestId, actions] of Object.entries(groupedPayloads) ) {
				let batchIds = _.map(actions, (a) => a.payload.batchId);
				let success = workRequestSuccess.has(workRequestId);
				yield put(internalActions.batchUpdateComplete({ workRequestId, batchIds, success }));
			}
		}
	}
}

export const FETCH_WORK_REQUEST_BY_ID = 'work-requests.fetch.by-id';
export const FETCH_WORK_REQUEST_BY_ID_REQUEST = 'work-requests.fetch.by-id.request';
export const FETCH_WORK_REQUEST_BY_ID_SUCCESS = 'work-requests.fetch.by-id.success';
export const FETCH_WORK_REQUEST_BY_ID_FAILURE = 'work-requests.fetch.by-id.failure';

export const UPDATE_WORK_REQUEST = 'work-requests.update';
export const UPDATE_WORK_REQUEST_REQUEST = 'work-requests.update.request';
export const UPDATE_WORK_REQUEST_SUCCESS = 'work-requests.update.success';
export const UPDATE_WORK_REQUEST_FAILURE = 'work-requests.update.failure';

export const ADD_FILE = 'work-request.add-file';
export const ADD_FILE_REQUEST = 'work-request.add-file.request';
export const ADD_FILE_UPDATE = 'work-request.add-file.update';
export const ADD_FILE_SUCCESS = 'work-request.add-file.success';
export const ADD_FILE_FAILURE = 'work-request.add-file.failure';

export const ADD_TAG_FILE = 'work-request.add-tag-file';
export const ADD_TAG_FILE_REQUEST = 'work-request.add-tag-file.request';
export const ADD_TAG_FILE_UPDATE = 'work-request.add-tag-file.update';
export const ADD_TAG_FILE_SUCCESS = 'work-request.add-tag-file.success';
export const ADD_TAG_FILE_FAILURE = 'work-request.add-tag-file.failure';

export const REMOVE_FILE = 'work-request.remove-file';
export const REMOVE_FILE_REQUEST = 'work-request.remove-file.request';
export const REMOVE_FILE_SUCCESS = 'work-request.remove-file.success';
export const REMOVE_FILE_FAILURE = 'work-request.remove-file.failure';

export const REMOVE_TAG_FILE = 'work-request.remove-tag-file';
export const REMOVE_TAG_FILE_REQUEST = 'work-request.remove-tag-file.request';
export const REMOVE_TAG_FILE_SUCCESS = 'work-request.remove-tag-file.success';
export const REMOVE_TAG_FILE_FAILURE = 'work-request.remove-tag-file.failure';

export const BATCH_UPDATE = 'work-request.batch-update';
export const BATCH_UPDATE_COMPLETE = 'work-request.batch-update.complete';

export const MANUAL_UPDATE_WORK_REQUEST = 'work-requests.manual-update';

export const INITIAL_STATE = {
	workRequests: {}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case FETCH_WORK_REQUEST_BY_ID: {
			let id = action.payload;
			let existingContainer = _get(state.workRequests, id, {
				data: null,
				lastLoadedAt: null,
				isUpdating: false,
				updateError: null
			});

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[id]: {
						...existingContainer,
						isLoading: true,
						loadError: null
					}
				}
			};
		}

		case FETCH_WORK_REQUEST_BY_ID_SUCCESS: {
			let workRequest = action.payload;

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[workRequest._id]: {
						data: workRequest,
						lastLoadedAt: new Date(),
						isLoading: false,
						loadError: null
					}
				}
			};
		}

		case FETCH_WORK_REQUEST_BY_ID_FAILURE: {
			let { id, error } = action.payload;

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[id]: {
						...state.workRequests[id],
						isLoading: false,
						loadError: error
					}
				}
			};
		}

		case UPDATE_WORK_REQUEST_REQUEST: {
			let { id } = action.payload;
			let existingContainer = _get(state.workRequests, id, {
				data: null,
				lastLoadedAt: null,
				isLoading: false,
				loadError: null
			});

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[id]: {
						...existingContainer,
						isUpdating: true,
						updateError: null
					}
				}
			};
		}

		case UPDATE_WORK_REQUEST_SUCCESS: {
			let { workRequest } = action.payload;

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[workRequest._id]: {
						...state.workRequests[workRequest._id],
						data: workRequest,
						lastLoadedAt: new Date(),
						isUpdating: false,
						updateError: null
					}
				}
			};
		}

		case UPDATE_WORK_REQUEST_FAILURE: {
			let { id, err } = action.payload;

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[id]: {
						...state.workRequests[id],
						isUpdating: false,
						updateError: err
					}
				}
			};
		}

		case MANUAL_UPDATE_WORK_REQUEST: {
			let workRequest = action.payload;

			let workRequestContainer = state.workRequests[workRequest._id];
			if( !workRequestContainer ) {
				workRequestContainer = {
					isLoading: false,
					lastLoadedAt: null
				};
			}

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[workRequest._id]: {
						...state.workRequests[workRequest._id],
						data: workRequest,
						lastLoadedAt: new Date()
					}
				}
			};
		}

		case ADD_FILE_REQUEST: {
			let { workRequestId, file } = action.payload;
			let container = _get(state.workRequests, workRequestId, {});
			let isAddingFiles = _get(container, 'isAddingFiles', {});

			isAddingFiles = {
				...isAddingFiles,
				[file.id]: {
					id: file.id,
					name: file.name,
					progress: 0
				}
			};

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[workRequestId]: {
						...container,
						isAddingFiles
					}
				}
			};
		}

		case ADD_FILE_UPDATE: {
			let { workRequestId, update } = action.payload;
			let container = _get(state.workRequests, workRequestId, {});
			let isAddingFiles = _get(container, 'isAddingFiles', {});

			isAddingFiles = {
				...isAddingFiles,
				[update.id]: update
			};

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[workRequestId]: {
						...container,
						isAddingFiles
					}
				}
			};
		}

		case ADD_FILE_SUCCESS:
		case ADD_FILE_FAILURE: {
			let { workRequestId, file } = action.payload;
			let container = _get(state.workRequests, workRequestId, {});
			let isAddingFiles = _get(container, 'isAddingFiles', {});

			isAddingFiles = _.omit(isAddingFiles, file.id);

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[workRequestId]: {
						...container,
						isAddingFiles
					}
				}
			};
		}

		case ADD_TAG_FILE_REQUEST: {
			let { workRequestId, tag, file } = action.payload;
			let container = _get(state.workRequests, workRequestId, {});
			let isAddingTagFiles = _get(container, 'isAddingTagFiles', {});
			let isAddingFiles = _get(isAddingTagFiles, tag, {});

			isAddingTagFiles = {
				...isAddingTagFiles,
				[tag]: {
					...isAddingFiles,
					[file.id]: {
						id: file.id,
						name: file.name,
						progress: 0
					}
				}
			};

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[workRequestId]: {
						...container,
						isAddingTagFiles
					}
				}
			};
		}

		case ADD_TAG_FILE_UPDATE: {
			let { workRequestId, tag, update } = action.payload;
			let container = _get(state.workRequests, workRequestId, {});
			let isAddingTagFiles = _get(container, 'isAddingTagFiles', {});
			let isAddingFiles = _get(isAddingTagFiles, tag, {});

			isAddingFiles = {
				...isAddingFiles,
				[update.id]: update
			};

			isAddingTagFiles = {
				...isAddingTagFiles,
				[tag]: isAddingFiles
			};

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[workRequestId]: {
						...container,
						isAddingTagFiles
					}
				}
			};
		}

		case ADD_TAG_FILE_SUCCESS:
		case ADD_TAG_FILE_FAILURE: {
			let { workRequestId, tag, file } = action.payload;
			let container = _get(state.workRequests, workRequestId, {});
			let isAddingTagFiles = _get(container, 'isAddingTagFiles', {});
			let isAddingFiles = _get(isAddingTagFiles, tag, {});

			isAddingFiles = _.omit(isAddingFiles, file.id);
			isAddingTagFiles = {
				...isAddingTagFiles,
				[tag]: isAddingFiles
			};

			if( _.isEmpty(isAddingFiles) ) {
				isAddingTagFiles = _.omit(isAddingTagFiles, tag);
			}

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[workRequestId]: {
						...container,
						isAddingTagFiles
					}
				}
			};
		}

		case REMOVE_FILE_REQUEST: {
			let { workRequestId, file } = action.payload;
			let container = _get(state.workRequests, workRequestId, {});
			let isRemovingFiles = _get(container, 'isRemovingFiles', {});

			isRemovingFiles = {
				...isRemovingFiles,
				[file]: true
			};

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[workRequestId]: {
						...container,
						isRemovingFiles
					}
				}
			};
		}

		case REMOVE_FILE_SUCCESS:
		case REMOVE_FILE_FAILURE: {
			let { workRequestId, file } = action.payload;
			let container = _get(state.workRequests, [workRequestId], {});
			let isRemovingFiles = _get(container, 'isRemovingFiles', {});

			isRemovingFiles = _.omit(isRemovingFiles, file);

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[workRequestId]: {
						...container,
						isRemovingFiles
					}
				}
			};
		}

		case REMOVE_TAG_FILE_REQUEST: {
			let { workRequestId, tag, file } = action.payload;
			let container = _get(state.workRequests, [workRequestId], {});
			let isRemovingTagFiles = _get(container, 'isRemovingTagFiles', {});
			let isRemovingFiles = _get(isRemovingTagFiles, tag, {});

			isRemovingFiles = {
				...isRemovingFiles,
				[file]: true
			};

			isRemovingTagFiles = {
				...isRemovingTagFiles,
				[tag]: isRemovingFiles
			};

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[workRequestId]: {
						...container,
						isRemovingTagFiles
					}
				}
			};
		}

		case REMOVE_TAG_FILE_SUCCESS:
		case REMOVE_TAG_FILE_FAILURE: {
			let { workRequestId, tag, file } = action.payload;
			let container = _get(state.workRequests, [workRequestId], {});
			let isRemovingTagFiles = _get(container, 'isRemovingTagFiles', {});
			let isRemovingFiles = _get(isRemovingTagFiles, tag, {});

			isRemovingFiles = _.omit(isRemovingFiles, file);
			isRemovingTagFiles = {
				...isRemovingTagFiles,
				[tag]: isRemovingFiles
			};

			if( _.isEmpty(isRemovingFiles) ) {
				isRemovingTagFiles = _.omit(isRemovingTagFiles, tag);
			}

			return {
				...state,
				workRequests: {
					...state.workRequests,
					[workRequestId]: {
						...container,
						isRemovingTagFiles
					}
				}
			};
		}

		default:
			return state;
	}
}

export const actions = {
	getWorkRequestById: createAction(FETCH_WORK_REQUEST_BY_ID),
	updateWorkRequest: createAction(UPDATE_WORK_REQUEST),
	manualUpdateWorkRequest: createAction(MANUAL_UPDATE_WORK_REQUEST),
	addFile: createAction(ADD_FILE),
	addTagFile: createAction(ADD_TAG_FILE),
	removeFile: createAction(REMOVE_FILE),
	removeTagFile: createAction(REMOVE_TAG_FILE)
};

/**
 * Actions that should only be invoked internally
 */
export const internalActions = {
	getWorkRequestByIdRequest: createAction(FETCH_WORK_REQUEST_BY_ID_REQUEST),
	getWorkRequestByIdSuccess: createAction(FETCH_WORK_REQUEST_BY_ID_SUCCESS),
	getWorkRequestByIdFailure: createAction(FETCH_WORK_REQUEST_BY_ID_FAILURE),
	updateWorkRequestRequest: createAction(UPDATE_WORK_REQUEST_REQUEST),
	updateWorkRequestSuccess: createAction(UPDATE_WORK_REQUEST_SUCCESS),
	updateWorkRequestFailure: createAction(UPDATE_WORK_REQUEST_FAILURE),
	addFileRequest: createAction(ADD_FILE_REQUEST),
	addFileUpdate: createAction(ADD_FILE_UPDATE),
	addFileSuccess: createAction(ADD_FILE_SUCCESS),
	addFileFailure: createAction(ADD_FILE_FAILURE),
	addTagFileRequest: createAction(ADD_TAG_FILE_REQUEST),
	addTagFileUpdate: createAction(ADD_TAG_FILE_UPDATE),
	addTagFileSuccess: createAction(ADD_TAG_FILE_SUCCESS),
	addTagFileFailure: createAction(ADD_TAG_FILE_FAILURE),
	removeFileRequest: createAction(REMOVE_FILE_REQUEST),
	removeFileSuccess: createAction(REMOVE_FILE_SUCCESS),
	removeFileFailure: createAction(REMOVE_FILE_FAILURE),
	removeTagFileRequest: createAction(REMOVE_TAG_FILE_REQUEST),
	removeTagFileSuccess: createAction(REMOVE_TAG_FILE_SUCCESS),
	removeTagFileFailure: createAction(REMOVE_TAG_FILE_FAILURE),
	batchUpdate: createAction(BATCH_UPDATE),
	batchUpdateComplete: createAction(BATCH_UPDATE_COMPLETE)
};

export const selectors = {
	isLoading: createSelector(STORE_NAME, isLoading),
	isLoaded: createSelector(STORE_NAME, isLoaded),
	didLoadFail: createSelector(STORE_NAME, didLoadFail),
	getWorkRequestById: createSelector(STORE_NAME, getWorkRequestById),
	isAddingFiles: createSelector(STORE_NAME, isAddingFiles),
	isAddingTagFiles: createSelector(STORE_NAME, isAddingTagFiles),
	isRemovingFiles: createSelector(STORE_NAME, isRemovingFiles),
	isRemovingTagFiles: createSelector(STORE_NAME, isRemovingTagFiles)
};

function getWorkRequestById(state, id) {
	let workRequestContainer = state.workRequests[id];

	if( !workRequestContainer ) {
		return null;
	}

	return workRequestContainer.data;
}

function isLoading(state, id) {
	let workRequestContainer = state.workRequests[id];

	if( !workRequestContainer ) {
		return false;
	}

	return workRequestContainer.isLoading;
}

function isLoaded(state, id) {
	let workRequestContainer = state.workRequests[id];

	if( !workRequestContainer ) {
		return false;
	}

	return !!workRequestContainer.lastLoadedAt;
}

function didLoadFail(state, id) {
	let workRequestContainer = state.workRequests[id];

	if( !workRequestContainer ) {
		return false;
	}

	return !!workRequestContainer.loadError;
}

function isAddingFiles(state, id) {
	return _get(state.workRequests, [id, 'isAddingFiles'], false);
}

function isAddingTagFiles(state, id) {
	return _get(state.workRequests, [id, 'isAddingTagFiles'], false);
}

function isRemovingFiles(state, id) {
	return _get(state.workRequests, [id, 'isRemovingFiles'], false);
}

function isRemovingTagFiles(state, id) {
	return _get(state.workRequests, [id, 'isRemovingTagFiles'], false);
}

export function *watchWorkRequests() {
	yield takeEvery(FETCH_WORK_REQUEST_BY_ID, fetchWorkRequestById);
	yield takeEvery(UPDATE_WORK_REQUEST, updateWorkRequest);
	yield takeEvery(ADD_FILE, addFile);
	yield takeEvery(ADD_TAG_FILE, addTagFile);
	yield takeEvery(REMOVE_FILE, removeFile);
	yield takeEvery(REMOVE_TAG_FILE, removeTagFile);
	yield queueAndBatchUpdateFiles();
}
