import { createAction } from 'redux-actions';
import { RESET_STORES } from './session';

export const STORE_NAME = 'shareQueueStore';

export const QUEUE_UPDATE = 'share-queue.update';

export const INITIAL_STATE = {
	isLoading: false,
	loadFailed: false,
	shareQueue: []
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case RESET_STORES:
			return INITIAL_STATE;

		case QUEUE_UPDATE: {
			return {
				...state,
				shareQueue: action.payload || []
			};
		}

		default:
			return state;
	}
}

export const actions = {
	updateQueue: createAction(QUEUE_UPDATE)
};

export const selectors = {
	getShareQueue
};

function getShareQueue(state) {
	const { shareQueue } = state.shareQueueStore;
	return shareQueue;
}
