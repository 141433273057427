import { createAction } from 'redux-actions';
import { RESET_STORES } from './session';
import { createSelector, extendQueue, filterQueue } from './common';

export const STORE_NAME = 'socialRequestStore';

export const QUEUE_ADD = 'social-request.queue.add';
export const QUEUE_REMOVE = 'social-request.queue.remove';
export const QUEUE_CLEAR = 'social-request.queue.clear';

export const INITIAL_STATE = {
	socialRequestQueue: []
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case RESET_STORES:
			return INITIAL_STATE;

		case QUEUE_ADD: {
			let toAdd = action.payload;
			let socialRequestQueue = extendQueue(state.socialRequestQueue, toAdd);

			return {
				...state,
				socialRequestQueue
			};
		}

		case QUEUE_REMOVE: {
			let toRemove = action.payload;
			let socialRequestQueue = filterQueue(state.socialRequestQueue, toRemove);

			return {
				...state,
				socialRequestQueue
			};
		}

		case QUEUE_CLEAR: {
			return {
				...state,
				socialRequestQueue: []
			};
		}

		default:
			return state;
	}
}

export const actions = {
	addToQueue: createAction(QUEUE_ADD),
	removeFromQueue: createAction(QUEUE_REMOVE),
	clearQueue: createAction(QUEUE_CLEAR)
};

export const selectors = {
	getQueue: createSelector(STORE_NAME, getQueue),
	isAssetInQueue: createSelector(STORE_NAME, isAssetInQueue)
};

function getQueue(state) {
	return state.socialRequestQueue;
}

function isAssetInQueue(state, assetId) {
	return state.socialRequestQueue.indexOf(assetId) > -1;
}
