import(/* webpackMode: "eager" */ "/vercel/path0/4x/app/container.js");
import(/* webpackMode: "eager" */ "/vercel/path0/4x/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/4x/node_modules/@vercel/analytics/dist/react/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/4x/node_modules/@vercel/speed-insights/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/4x/node_modules/antd/dist/antd.dark.min.css");
import(/* webpackMode: "eager" */ "/vercel/path0/4x/node_modules/video.js/dist/video-js.css");
import(/* webpackMode: "eager" */ "/vercel/path0/4x/node_modules/react-dates/lib/css/_datepicker.css");
import(/* webpackMode: "eager" */ "/vercel/path0/4x/node_modules/rc-tooltip/assets/bootstrap.css");
import(/* webpackMode: "eager" */ "/vercel/path0/4x/node_modules/react-circular-progressbar/dist/styles.css");
import(/* webpackMode: "eager" */ "/vercel/path0/4x/node_modules/react-toastify/dist/ReactToastify.css");
import(/* webpackMode: "eager" */ "/vercel/path0/4x/scss/pixwel.scss")