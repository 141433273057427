import _ from 'lodash';
import UserService from './user-service';
import api, { buildURL } from './api-service';
import { isDedicatedGfxOrder } from '../../store/common';
import _get from 'lodash.get';

const RESOURCES_HEADER = /^resources \d+-\d+\/(\d+|\*)$/;

const WorkRequestService = {
	canEditTranslation() {
		return this.status === 'incomplete' || this.status === 'submitted';
	},

	create(data) {
		return api.client.post('/workrequests', data)
			.then((res) => res.data);
	},

	getById(id) {
		return api.client.get(`/workrequests/${id}`)
			.then((res) => {
				return {
					...res.data,
					canEditTranslation: this.canEditTranslation
				};
			})
			.catch((err) => {
				throw _get(err, 'response', false) || err;
			});
	},

	getReport(query={}, page=0, limit=50) {
		let start = page * limit;
		let end = ((page + 1) * limit) - 1;

		const promise = api.client.get(
			buildURL('/reports/workRequests', query),
			{ headers: { 'X-Range': `resources=${start}-${end}` } }
		);

		return promise
			.then((results) => {
				let totalItems = results.data.length;
				let totalPages = 1;
				let matches = results.headers['x-content-range']?.match(RESOURCES_HEADER) || null;

				if( matches !== null && !_.isUndefined(matches[1]) && matches[1] !== '*') {
					totalItems = matches[1];
					totalPages = Math.ceil(totalItems / limit);
				}

				return {
					results: results.data,
					page,
					totalPages,
					totalItems
				};
			});
	},

	update(id, data) {
		return api.client.patch(`/workrequests/${id}`, data)
			.then((res) => {
				return {
					...res.data,
					canEditTranslation: this.canEditTranslation
				};
			});
	},

	/**
	 * Checks whether an order's deliverables must be edited
	 * on 2.x. Theatrical orders must have the same details
	 * and recipients for each DCP type.
	 *
	 * @param {object} workRequest - 2.x schema
	 * @returns {boolean} - if true, must be edited on 2.x
	 */
	shouldEditOn2x(workRequest) {

		if(!workRequest.dcp) {
			return false;
		}

		let {
			recipients,
			dcp: { items: dcpItems, details: dcpDetails }
		} = workRequest;

		// if no DCP items (not DCP) or a single DCP selected, OK to edit on 3.x
		if( dcpItems?.length < 2 ) {
			return false;
		}

		let allDcpRecipientIds = [];

		Object.keys(recipients).forEach((type) => {
			if( dcpItems?.includes(type) ) {
				allDcpRecipientIds = [
					...allDcpRecipientIds,
					recipients[type].map((r) => r._id)
				];
			}
		});

		// array of unique ids from all DCP recipients
		let uniqueIds = [...new Set(allDcpRecipientIds)];

		let detailsMatch = true;
		let recipientsMatch = true;

		dcpItems?.forEach((item) => {
			if( !_.isEqual(dcpDetails[dcpItems[0]], dcpDetails[item]) ) {
				detailsMatch = false;
			}

			if( !_.difference(recipients[item].map((r) => r._id), uniqueIds).length === 0 ) {
				recipientsMatch = false;
			}
		});

		// if there's any mismatch, order must be edited on 2.x
		if( !detailsMatch || !recipientsMatch ) {
			return true;
		}

		return false;
	},

	shouldApproveTranslation(workRequest, user) {
		const ownerId = workRequest.from._id || workRequest.from;
		const isOwner = ownerId === user._id;

		const isAdmin = UserService.isAdmin(user);
		const isAdminManager = UserService.isAdminManager(user);
		const isGfxOrder = isDedicatedGfxOrder(workRequest);
		const needsApproval = isGfxOrder
			? !!workRequest.graphicsTranslation?.approval
			: !!workRequest.translation?.approval;

		return needsApproval && (isAdmin || isAdminManager || isOwner);
	},

	shouldSubmitTranslationForApproval(workRequest, user) {
		const ownerId = workRequest.from._id || workRequest.from;
		const isNotOwner = ownerId !== user._id;
		const isGfxOrder = isDedicatedGfxOrder(workRequest);

		const isAdmin = UserService.isAdmin(user);
		const isAdminManager = UserService.isAdminManager(user);
		const needsApproval = isGfxOrder
			? !!workRequest.graphicsTranslation?.approval
			: !!workRequest.translation?.approval;

		return needsApproval && (!isAdmin && !isAdminManager && isNotOwner);
	},

	rejectTag(workRequestId, tagName, description) {
		const url = `/workrequests/rejectTag/${workRequestId}`;

		return api.client.put(url, { tag: tagName || null, description });
	}
};

export default WorkRequestService;
