import _ from 'lodash';
import _get from 'lodash.get';
import { createAction } from 'redux-actions';
import { put, select, takeEvery } from 'redux-saga/effects';
import { createSelector, handleError } from './common';
import NotificationService from '../modules/services/notification-service';

export const STORE_NAME = 'notificationsStore';

const DEFAULT_CONTAINER = {
	isError: false,
	isUpdating: false,
	summary: {},
	notificationsByType: {
		workRequest: {
			data: [],
			isLoadingMore: false,
			isLoadingNew: false,
			pagination: {}
		},
		embargo: {
			data: [],
			isLoadingMore: false,
			isLoadingNew: false,
			pagination: {}
		},
		other: {
			data: [],
			isLoadingMore: false,
			isLoadingNew: false,
			pagination: {}
		}
	},
	filters: {
		type: 'workRequest',
		q: '',
		read: 0
	}
};

const PAGE_SIZE = 70;

function *markRead({ payload }) {
	let { type, notification } = payload;
	let summary = yield select((state) => _get(state[STORE_NAME], ['summary', type], {}));

	notification.read = !notification.read;
	notification.viewed = !notification.viewed;
	summary.unread = --summary.unread;

	try {
		yield put(internalActions.markReadNotificationsRequest({ type, notification }));
		yield NotificationService.markRead(notification._id);
		yield put(internalActions.markReadNotificationsSuccess({ type, notification }));
	}
	catch(e) {
		notification.read = !notification.read;
		notification.viewed = !notification.viewed;
		summary.unread = ++summary.unread;
		const err = handleError(e);
		yield put(internalActions.markReadNotificationsFailure({ notification, err }));
		return err;
	}
}

function *markUnread({ payload }) {
	let { type, notification } = payload;
	let summary = yield select((state) => _get(state[STORE_NAME], ['summary', type], {}));

	notification.read = !notification.read;
	notification.viewed = !notification.viewed;
	summary.unread = ++summary.unread;

	try {
		yield put(internalActions.markUnreadNotificationsRequest({ type, notification }));
		yield NotificationService.markUnread(notification._id);
		yield put(internalActions.markUnreadNotificationsSuccess({ type, notification }));
	}
	catch(e) {
		notification.read = !notification.read;
		notification.viewed = !notification.viewed;
		summary.unread = --summary.unread;
		const err = handleError(e);
		yield put(internalActions.markUnreadNotificationsFailure({ notification, err }));
		return err;
	}
}

function *markAllRead({ payload }) {
	let { type } = payload;
	let summary = yield select((state) => _get(state[STORE_NAME], ['summary', type], {}));

	let oldUnread = summary.unread;
	summary.unread = 0;

	try {
		yield put(internalActions.markAllReadNotificationsRequest({ type }));
		yield NotificationService.markAllRead(type);
		yield put(internalActions.markAllReadNotificationsSuccess({ type }));
	}
	catch(e) {
		summary.unread = oldUnread;
		const err = handleError(e);
		yield put(internalActions.markAllReadNotificationsFailure({ err }));
		return err;
	}
}

function *fetchNewNotifications({ payload }) {
	let { filters } = payload;

	// Check if we're already fetching new notifications for this type
	let isLoadingNew = yield select(selectors.isLoadingNew);
	if( isLoadingNew ) {
		return;
	}

	// Get container, get range params
	let pagination = yield select((state) => _get(state[STORE_NAME], ['notificationsByType', filters.type, 'pagination'], null));
	let start = 0;
	let end = PAGE_SIZE;

	if( pagination ) {
		end = pagination.newCount;
	}

	if( end - start <= 0 ) {
		return;
	}

	try {
		yield put(internalActions.loadNewNotificationsRequest({ filters }));

		let summary = yield NotificationService.getSummary();

		let pagedResults = yield NotificationService.getPagedNotifications(filters, { limit: end });
		let {
			results: notifications,
			pagination
		} = pagedResults;

		yield put(internalActions.loadNewNotificationsSuccess({ summary, filters, notifications, pagination }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadNewNotificationsFailure({ filters, err }));
		return err;
	}
}

function *fetchMoreNotifications({ payload }) {
	let { filters } = payload;

	// Check if we're already fetching new notifications for this type
	let isLoadingNew = yield select(selectors.isLoadingNew, filters.type);
	if( isLoadingNew ) {
		return;
	}

	// Get container, get range params
	let currentPage = yield select((state) => _get(state[STORE_NAME], ['notificationsByType', filters.type, 'pagination', 'currentPage'], -1));

	try {
		yield put(internalActions.loadMoreNotificationsRequest({ filters, currentPage }));

		let summary = yield NotificationService.getSummary();

		let pagedResults = yield NotificationService.getPagedNotifications(filters, {
			page: currentPage + 1,
			limit: PAGE_SIZE
		});

		let {
			results: notifications,
			pagination
		} = pagedResults;

		yield put(internalActions.loadMoreNotificationsSuccess({ summary, filters, notifications, pagination }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadMoreNotificationsFailure({ filters, err }));
		return err;
	}
}

export const MANUAL_UPDATE_NOTIFICATIONS = 'notifications.manual-update';

export const FETCH_NEW_NOTIFICATIONS = 'notifications.fetch-new';
export const FETCH_NEW_NOTIFICATIONS_REQUEST = 'notifications.fetch-new.request';
export const FETCH_NEW_NOTIFICATIONS_SUCCESS = 'notifications.fetch-new.success';
export const FETCH_NEW_NOTIFICATIONS_FAILURE = 'notifications.fetch-new.failure';

export const FETCH_MORE_NOTIFICATIONS = 'notifications.fetch-more';
export const FETCH_MORE_NOTIFICATIONS_REQUEST = 'notifications.fetch-more.request';
export const FETCH_MORE_NOTIFICATIONS_SUCCESS = 'notifications.fetch-more.success';
export const FETCH_MORE_NOTIFICATIONS_FAILURE = 'notifications.fetch-more.failure';

export const MARK_READ_NOTIFICATIONS = 'notifications.mark-read';
export const MARK_READ_NOTIFICATIONS_REQUEST = 'notifications.mark-read.request';
export const MARK_READ_NOTIFICATIONS_SUCCESS = 'notifications.mark-read.success';
export const MARK_READ_NOTIFICATIONS_FAILURE = 'notifications.mark-read.failure';

export const MARK_UNREAD_NOTIFICATIONS = 'notifications.mark-unread';
export const MARK_UNREAD_NOTIFICATIONS_REQUEST = 'notifications.mark-unread.request';
export const MARK_UNREAD_NOTIFICATIONS_SUCCESS = 'notifications.mark-unread.success';
export const MARK_UNREAD_NOTIFICATIONS_FAILURE = 'notifications.mark-unread.failure';

export const MARK_ALL_READ_NOTIFICATIONS = 'notifications.mark-all-read';
export const MARK_ALL_READ_NOTIFICATIONS_REQUEST = 'notifications.mark-all-read.request';
export const MARK_ALL_READ_NOTIFICATIONS_SUCCESS = 'notifications.mark-all-read.success';
export const MARK_ALL_READ_NOTIFICATIONS_FAILURE = 'notifications.mark-all-read.failure';

export const INITIAL_STATE = {
	notificationsByType: {}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {

		case MARK_ALL_READ_NOTIFICATIONS_REQUEST: {
			let { type } = action.payload;

			let oldNotifications = _get(
				state,
				['notificationsByType', type, 'data'],
				[]
			);

			let notifications = oldNotifications.map((n) => {
				n.read = true;
				return n;
			});

			return {
				...state,
				isUpdating: true,
				notificationsByType: {
					...state.notificationsByType,
					[type]: {
						data: notifications
					}
				}
			};
		}

		case MARK_ALL_READ_NOTIFICATIONS_SUCCESS: {
			let { type } = action.payload;

			let oldNotifications = _get(
				state,
				['notificationsByType', type, 'data'],
				[]
			);

			let notifications = oldNotifications.map((n) => {
				n.read = true;
				return n;
			});

			return {
				...state,
				isUpdating: false,
				notificationsByType: {
					...state.notificationsByType,
					[type]: {
						data: notifications
					}
				}
			};
		}

		case MARK_ALL_READ_NOTIFICATIONS_FAILURE: {
			return {
				...state,
				isError: true,
				isUpdating: false,
				notificationsByType: {
					[state.filters.type]: {
						data: []
					}
				}
			};
		}

		case MARK_READ_NOTIFICATIONS_REQUEST: {
			return {
				...state
			};
		}

		case MARK_READ_NOTIFICATIONS_SUCCESS: {
			return {
				...state
			};
		}

		case MARK_READ_NOTIFICATIONS_FAILURE: {
			return {
				...state
			};
		}

		case MARK_UNREAD_NOTIFICATIONS_REQUEST: {
			return {
				...state
			};
		}

		case MARK_UNREAD_NOTIFICATIONS_SUCCESS: {
			return {
				...state
			};
		}

		case MARK_UNREAD_NOTIFICATIONS_FAILURE: {
			return {
				...state
			};
		}

		case MANUAL_UPDATE_NOTIFICATIONS: {
			let { type, notification } = action.payload;

			let data = _get(
				state,
				['notificationsByType', type, 'data'],
				[]
			);

			data.unshift(notification);

			let oldSummary = _get(state, ['summary'], {});
			let summary = _.clone(oldSummary);

			if( oldSummary[type] ) {
				summary[type].unread = ++oldSummary[type].unread;
			}

			return {
				...state,
				summary,
				notificationsByType: {
					...state.notificationsByType,
					[type]: {
						...state.notificationsByType[type],
						data
					}
				}
			};

		}

		case FETCH_NEW_NOTIFICATIONS_REQUEST: {
			let { filters } = action.payload;
			let container = DEFAULT_CONTAINER;

			return {
				...container,
				...state,
				filters,
				isError: false,
				notificationsByType: {
					...state.notificationsByType,
					[filters.type]: {
						...state.notificationsByType[filters.type],
						isLoadingNew: true,
						pagination: {
							isLoadingMore: false
						}
					}
				}
			};
		}

		case FETCH_NEW_NOTIFICATIONS_SUCCESS: {
			let { summary, filters, notifications, pagination } = action.payload;

			return {
				...state,
				filters,
				summary,
				notificationsByType: {
					...state.notificationsByType,
					[filters.type]: {
						data: [
							...notifications
						],
						isLoadingNew: false,
						pagination: {
							isLoadingMore: false,
							hasMore: (pagination.page + 1) * PAGE_SIZE < pagination.total,
							currentPage: pagination.page,
							total: pagination.total
						}
					}
				}
			};
		}

		case FETCH_NEW_NOTIFICATIONS_FAILURE: {
			return {
				...state,
				isError: true,
				notificationsByType: {
					...state.notificationsByType,
					[state.filters.type]: {
						...state.notificationsByType[state.filters.type],
						isLoadingNew: false,
						data: []
					}
				}
			};
		}

		case FETCH_MORE_NOTIFICATIONS_REQUEST: {
			let { filters, currentPage } = action.payload;
			let container = DEFAULT_CONTAINER;

			return {
				...container,
				...state,
				filters,
				notificationsByType: {
					...state.notificationsByType,
					[filters.type]: {
						...state.notificationsByType[filters.type],
						isLoadingNew: !currentPage,
						pagination: {
							isLoadingMore: true
						}
					}
				}
			};
		}

		case FETCH_MORE_NOTIFICATIONS_SUCCESS: {
			let { summary, filters, notifications, pagination } = action.payload;

			let oldNotifications = _get(
				state,
				['notificationsByType', filters.type, 'data'],
				[]
			);

			return {
				...state,
				filters,
				summary,
				notificationsByType: {
					...state.notificationsByType,
					[filters.type]: {
						data: [
							...oldNotifications,
							...notifications
						],
						isLoadingNew: false,
						pagination: {
							isLoadingMore: false,
							hasMore: (pagination.page + 1) * PAGE_SIZE < pagination.total,
							currentPage: pagination.page,
							total: pagination.total
						}
					}
				}
			};
		}

		case FETCH_MORE_NOTIFICATIONS_FAILURE: {
			let { filters } = action.payload;
			let container = _get(
				state,
				['notificationsByType', filters.type],
				DEFAULT_CONTAINER
			);

			return {
				...state,
				isError: true,
				notificationsByType: {
					...state.notificationsByType,
					[filters.type]: {
						...container,
						isLoadingNew: false,
						isLoadingMore: false,
						data: []
					}
				}
			};
		}

		default:
			return state;
	}
}

export const actions = {
	manualUpdateNotifications: createAction(MANUAL_UPDATE_NOTIFICATIONS),
	loadNewNotifications: createAction(FETCH_NEW_NOTIFICATIONS),
	loadMoreNotifications: createAction(FETCH_MORE_NOTIFICATIONS),
	markReadNotifications: createAction(MARK_READ_NOTIFICATIONS),
	markUnreadNotifications: createAction(MARK_UNREAD_NOTIFICATIONS),
	markAllReadNotifications: createAction(MARK_ALL_READ_NOTIFICATIONS)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	markAllReadNotificationsRequest: createAction(MARK_ALL_READ_NOTIFICATIONS_REQUEST),
	markAllReadNotificationsSuccess: createAction(MARK_ALL_READ_NOTIFICATIONS_SUCCESS),
	markAllReadNotificationsFailure: createAction(MARK_ALL_READ_NOTIFICATIONS_FAILURE),
	markUnreadNotificationsRequest: createAction(MARK_UNREAD_NOTIFICATIONS_REQUEST),
	markUnreadNotificationsSuccess: createAction(MARK_UNREAD_NOTIFICATIONS_SUCCESS),
	markUnreadNotificationsFailure: createAction(MARK_UNREAD_NOTIFICATIONS_FAILURE),
	markReadNotificationsRequest: createAction(MARK_READ_NOTIFICATIONS_REQUEST),
	markReadNotificationsSuccess: createAction(MARK_READ_NOTIFICATIONS_SUCCESS),
	markReadNotificationsFailure: createAction(MARK_READ_NOTIFICATIONS_FAILURE),
	loadNewNotificationsRequest: createAction(FETCH_NEW_NOTIFICATIONS_REQUEST),
	loadNewNotificationsSuccess: createAction(FETCH_NEW_NOTIFICATIONS_SUCCESS),
	loadNewNotificationsFailure: createAction(FETCH_NEW_NOTIFICATIONS_FAILURE),
	loadMoreNotificationsRequest: createAction(FETCH_MORE_NOTIFICATIONS_REQUEST),
	loadMoreNotificationsSuccess: createAction(FETCH_MORE_NOTIFICATIONS_SUCCESS),
	loadMoreNotificationsFailure: createAction(FETCH_MORE_NOTIFICATIONS_FAILURE)
};

export const selectors = {
	getNotifications: createSelector(STORE_NAME, getNotifications),
	getNotificationsCount: createSelector(STORE_NAME, getNotificationsCount),
	isLoadingNew: createSelector(STORE_NAME, isLoadingNew),
	isLoadingMore: createSelector(STORE_NAME, isLoadingMore),
	hasMore: createSelector(STORE_NAME, hasMore),
	hasUnread: createSelector(STORE_NAME, hasUnread),
	isUpdating: createSelector(STORE_NAME, isUpdating),
	isError: createSelector(STORE_NAME, isLoadingError),
	getSummary: createSelector(STORE_NAME, getSummary)
};

function getNotifications(state) {
	let container = {
		...DEFAULT_CONTAINER,
		...state
	};

	let type = container.filters.type || 'workRequest';
	let filters = (container.filters.read === 0) ? { read: false } : null;

	return _.filter(_get(state, ['notificationsByType', type, 'data'], []), filters);
}

function getSummary(state) {
	let container = {
		...DEFAULT_CONTAINER,
		...state
	};
	return _get(state, ['summary'], container.summary);
}

function getNotificationsCount(state, type) {
	return _get(state, ['notificationsByType', type, 'total'], 0);
}

function isLoadingNew(state) {
	let container = {
		...DEFAULT_CONTAINER,
		...state
	};
	return _get(state, ['notificationsByType', container.filters.type, 'isLoadingNew'], false);
}

function isLoadingMore(state) {
	let container = {
		...DEFAULT_CONTAINER,
		...state
	};
	return _get(state, ['notificationsByType', container.filters.type, 'pagination', 'isLoadingMore'], false);
}

function hasMore(state) {
	let container = {
		...DEFAULT_CONTAINER,
		...state
	};
	return _get(state, ['notificationsByType', container.filters.type, 'pagination', 'hasMore'], true);
}

function hasUnread(state) {
	return ['workRequest', 'embargo', 'other']
		.map((type) => {
			return _get(state, ['summary', type, 'unread'], 0);
		})
		.some((num) => num > 0);
}

function isUpdating(state) {
	return _get(state, ['isUpdating'], false);
}

function isLoadingError(state) {
	return _get(state, ['isError'], false);
}

export function *watchNotifications() {
	yield takeEvery(FETCH_NEW_NOTIFICATIONS, fetchNewNotifications);
	yield takeEvery(FETCH_MORE_NOTIFICATIONS, fetchMoreNotifications);
	yield takeEvery(MARK_READ_NOTIFICATIONS, markRead);
	yield takeEvery(MARK_UNREAD_NOTIFICATIONS, markUnread);
	yield takeEvery(MARK_ALL_READ_NOTIFICATIONS, markAllRead);
}
