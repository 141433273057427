import _ from 'lodash';
import api from './api-service';

const GET_GROUPS_QUERY = `query getGroups($q: String, $groupsId: [ID]) {
  groups(q: $q, id: $groupsId, limit: 600) {
  	totalCount
    nodes {
      value: id
      label : name
    }
  }
}
`;

const GroupService = {

	getGroups(q, groupsId) {
		return api.graphql.request(GET_GROUPS_QUERY, q? { q }: { groupsId });
	},

	/**
	 * Given a collection of groups, return the unique addresses within all of those groups
	 * @param {Array} groups, e.g. groups collection on a User
	 * @returns {Array} Unique addresses from those groups
	 */
	getUniqueAddresses(groups) {
		function hashAddress(address) {
			return (
				address.name
				+ address.address
				+ address.city
				+ address.state
				+ address.postalCode
				+ address.country
			);
		}

		let allAddresses = _.flatten(groups, 'addresses');
		_.remove(allAddresses, _.isUndefined);

		let uniqueAddresses = _.uniq(allAddresses, hashAddress);

		return uniqueAddresses;
	}
};

export default GroupService;
