import _ from 'lodash';
import _get from 'lodash.get';
import { createAction } from 'redux-actions';
import { put, takeEvery } from 'redux-saga/effects';
import { createSelector, handleError } from './common';
import { RESET_STORES } from './session';
import IngestService from '@/modules/services/ingest-service';

export const STORE_NAME = 'ingestsStore';

export function *fetchIngestsByWorkRequest({ payload }) {
	let { workRequestId } = payload;

	try {
		yield put(internalActions.getIngestsByWorkRequestRequest({ workRequestId }));

		let ingests = yield IngestService.getIngestsForWorkRequest(workRequestId);

		yield put(internalActions.getIngestsByWorkRequestSuccess({ workRequestId, ingests }));
		return ingests;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getIngestsByWorkRequestFailure({ workRequestId, err }));
		return err;
	}
}

export const FETCH_INGESTS_BY_WORK_REQUEST = 'ingests.fetch-by-work-request';
export const FETCH_INGESTS_BY_WORK_REQUEST_REQUEST = 'ingests.fetch-by-work-request.request';
export const FETCH_INGESTS_BY_WORK_REQUEST_SUCCESS = 'ingests.fetch-by-work-request.success';
export const FETCH_INGESTS_BY_WORK_REQUEST_FAILURE = 'ingests.fetch-by-work-request.failure';

export const MANUAL_ADD = 'ingests.add.manual';

export const INITIAL_STATE = {
	ingests: {},
	ingestsByWorkRequest: {}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case RESET_STORES:
			return INITIAL_STATE;

		case FETCH_INGESTS_BY_WORK_REQUEST_REQUEST: {
			let { workRequestId } = action.payload;
			let existingContainer = _get(state.ingestsByWorkRequest, [workRequestId], {});

			return {
				...state,
				ingestsByWorkRequest: {
					...state.ingestsByWorkRequest,
					[workRequestId]: {
						lastLoadedAt: null,
						data: null,
						...existingContainer,
						isLoading: true
					}
				}
			};
		}

		case FETCH_INGESTS_BY_WORK_REQUEST_SUCCESS: {
			let { workRequestId, ingests } = action.payload;
			let updatedIngests = { ...state.ingests };
			let workRequestIngestIds = [];
			let now = new Date();

			_.forEach(ingests, (ingest) => {
				updatedIngests[ingest._id] = {
					isLoading: false,
					lastLoadedAt: now,
					loadError: null,
					data: ingest
				};

				workRequestIngestIds.push(ingest._id);
			});

			return {
				...state,
				ingests: updatedIngests,
				ingestsByWorkRequest: {
					...state.ingestsByWorkRequest,
					[workRequestId]: {
						isLoading: false,
						lastLoadedAt: now,
						loadError: null,
						data: workRequestIngestIds
					}
				}
			};
		}

		case FETCH_INGESTS_BY_WORK_REQUEST_FAILURE: {
			let { workRequestId, err } = action.payload;
			let existingContainer = state.ingestsByWorkRequest[workRequestId];

			return {
				...state,
				ingestsByWorkRequest: {
					...state.ingestsByWorkRequest,
					[workRequestId]: {
						...existingContainer,
						isLoading: false,
						loadError: err
					}
				}
			};
		}

		case MANUAL_ADD: {
			let { ingest } = action.payload;
			let workRequestId = ingest.workRequest;
			let now = new Date();

			let existingContainer = _get(state.ingestsByWorkRequest, [workRequestId], {
				isLoading: false,
				lastLoadedAt: null,
				data: []
			});

			return {
				...state,
				ingests: {
					...state.ingests,
					[ingest._id]: {
						isLoading: false,
						lastLoadedAt: now,
						data: ingest
					}
				},
				ingestsByWorkRequest: {
					...state.ingestsByWorkRequest,
					[workRequestId]: {
						...existingContainer,
						data: _.uniq([...existingContainer.data, ingest._id])
					}
				}
			};
		}

		default:
			return state;
	}
}

export const actions = {
	getIngestsByWorkRequest: createAction(FETCH_INGESTS_BY_WORK_REQUEST),
	addIngestManually: createAction(MANUAL_ADD)
};

/**
 * Actions that should only be invoked internally
 */
export const internalActions = {
	getIngestsByWorkRequestRequest: createAction(FETCH_INGESTS_BY_WORK_REQUEST_REQUEST),
	getIngestsByWorkRequestSuccess: createAction(FETCH_INGESTS_BY_WORK_REQUEST_SUCCESS),
	getIngestsByWorkRequestFailure: createAction(FETCH_INGESTS_BY_WORK_REQUEST_FAILURE)
};

export const selectors = {
	areWorkRequestIngestsLoading: createSelector(STORE_NAME, areWorkRequestIngestsLoading),
	areWorkRequestIngestsLoaded: createSelector(STORE_NAME, areWorkRequestIngestsLoaded),
	getWorkRequestIngestsLastLoadedAt: createSelector(STORE_NAME, getWorkRequestIngestsLastLoadedAt),
	getWorkRequestIngests: createSelector(STORE_NAME, getWorkRequestIngests)
};

function areWorkRequestIngestsLoading(state, workRequestId) {
	return _get(state.ingestsByWorkRequest, [workRequestId, 'isLoading'], false);
}

function areWorkRequestIngestsLoaded(state, workRequestId) {
	return !!getWorkRequestIngestsLastLoadedAt(state, workRequestId);
}

function getWorkRequestIngestsLastLoadedAt(state, workRequestId) {
	return _get(state.ingestsByWorkRequest, [workRequestId, 'lastLoadedAt'], null);
}

function getWorkRequestIngests(state, workRequestId) {
	let workRequestIngests = _get(state.ingestsByWorkRequest, [workRequestId, 'data'], []);

	return _.map(workRequestIngests, (id) => state.ingests[id].data);
}

export function *watchIngests() {
	yield takeEvery(FETCH_INGESTS_BY_WORK_REQUEST, fetchIngestsByWorkRequest);
}
