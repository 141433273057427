import { useState, useEffect } from 'react';
import ToastService from '@/modules/services/toast-service';

const UpdateChecker = () => {
	const [currentVersion, setCurrentVersion] = useState(null);
	const [isToastVisible, setIsToastVisible] = useState(false);

	useEffect(() => {
		const checkVersion = async() => {
			try {
				const response = await fetch('/version.txt', {
					cache: 'no-store'
				});
				const versionText = await response.text();
				if(
					currentVersion
					&& versionText !== currentVersion
					&& !isToastVisible
				) {
					setIsToastVisible(true);
					ToastService.createWithAction(
						'info',
						`🎉 A new version of the Platform is available!`,
						'Reload',
						() => {
							window.location.reload();
						},
						{
							dismissOnTimeout: false,
							autoClose: false
						}
					);
				}
				else {
					setCurrentVersion(versionText);
				}
			}
			catch(error) {
				console.error('Failed to fetch version.txt:', error);
			}
		};

		// Check version.txt occasionally
		const versionCheckInterval = setInterval(checkVersion, 5000);

		return () => {
			clearInterval(versionCheckInterval);
		};
	}, [currentVersion, isToastVisible]);

	return null;
};

export default UpdateChecker;
