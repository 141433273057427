import isError from 'lodash.iserror';
import { createAction } from 'redux-actions';
import { put, fork, join, takeLatest } from 'redux-saga/effects';
import { handleError } from './common';

import {
	actions as studiosActions,
	selectors as studiosSelectors,
	fetchStudios
} from './studios';

import {
	actions as projectsActions,
	selectors as projectsSelectors,
	fetchProjectById
} from './projects';
import ProjectService from '@/modules/services/project-service';

export const STORE_NAME = 'projectCRUDStore';

function *loadPageData({ payload: projectId }) {
	try {
		yield put(internalActions.loadPageRequest());

		let studiosTask = yield fork(fetchStudios, studiosActions.getStudios());
		let projectTask;
		let billingRates;

		if( projectId ) {
			projectTask = yield fork(fetchProjectById, projectsActions.getProjectById(projectId));
			billingRates = yield ProjectService.getBillingRates({ projectId });
		}

		let studios = yield join(studiosTask);
		let project;

		if( projectTask ) {
			project = yield join(projectTask);
		}

		if( isError(studios) || isError(project) ) {
			throw new Error('Failed to load page resources');
		}

		yield put(internalActions.loadPageSuccess({ project, billingRates }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadPageFailure(err));
		return err;
	}
}

export const LOAD = 'project-crud.load';
export const LOAD_REQUEST = 'project-crud.load.request';
export const LOAD_SUCCESS = 'project-crud.load.success';
export const LOAD_FAILURE = 'project-crud.load.failure';

export const UPDATE_MAIN = 'project-crud.update-main';
export const UPDATE_SIDEBAR = 'project-crud.update-sidebar';
export const UPDATE_BILLING_RATES = 'project-crud.update-billing-rates';

export const INITIAL_STATE = {
	isLoading: false,
	loadFailed: false,
	main: {
		posterFile: ''
	},
	sidebar: {
		project: {},
		studio: {}
	}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case LOAD_REQUEST:
			return {
				...state,
				isLoading: true,
				billingRates: null
			};

		case LOAD_SUCCESS: {
			let { billingRates } = action.payload;

			return {
				...state,
				isLoading: false,
				loadFailed: false,
				billingRates
			};
		}

		case LOAD_FAILURE:
			return {
				...state,
				isLoading: false,
				loadFailed: true
			};

		case UPDATE_MAIN:
			return {
				...state,
				main: {
					...action.payload
				}
			};

		case UPDATE_SIDEBAR:
			return {
				...state,
				sidebar: {
					...action.payload
				}
			};

		case UPDATE_BILLING_RATES: {
			let billingRates = action.payload;

			return {
				...state,
				billingRates
			};
		}

		default:
			return state;
	}
}

export const actions = {
	loadPageData: createAction(LOAD),
	updateMain: createAction(UPDATE_MAIN),
	updateSidebar: createAction(UPDATE_SIDEBAR),
	updateBillingRates: createAction(UPDATE_BILLING_RATES)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	loadPageRequest: createAction(LOAD_REQUEST),
	loadPageSuccess: createAction(LOAD_SUCCESS),
	loadPageFailure: createAction(LOAD_FAILURE)
};

export const selectors = {
	isLoading,
	isReady,
	getPosterFile,
	getStudio,
	getProject,
	getBillingRates
};

function isLoading(state) {
	let projectCRUDStore = state[STORE_NAME];

	return projectCRUDStore.isLoading;
}

function isReady(state, projectId) {
	if( isLoading(state) ) {
		return false;
	}

	let areStudiosLoaded = studiosSelectors.isLoaded(state);
	let isProjectLoaded = projectId ? projectsSelectors.getProjectById(state, projectId) : true;

	return areStudiosLoaded && isProjectLoaded;
}

function getPosterFile(state) {
	let projectCRUDStore = state[STORE_NAME];

	return projectCRUDStore.main.posterFile;
}

function getStudio(state) {
	let projectCRUDStore = state[STORE_NAME];

	return projectCRUDStore.sidebar.studio;
}

function getProject(state) {
	let projectCRUDStore = state[STORE_NAME];

	return projectCRUDStore.sidebar.project;
}

function getBillingRates(state) {
	let projectCRUDStore = state[STORE_NAME];

	return projectCRUDStore.billingRates;
}

export function *watchProjectCrud() {
	yield takeLatest(LOAD, loadPageData);
}
