const SUBService = {
	parse: (lines) => {
		let result = [];
		let current = { text: '' };

		lines.forEach((line) => {
			line = line.replace(/^\s+|\s+$/g, '');
			// eslint-disable-next-line no-useless-escape
			let subTime = line.match(/^([0-9:\.]+),([0-9:\.]+)$/);

			if(subTime) {
				if(current.text) {
					result.push(current);
				}
				current = { startTime: subTime[1], stopTime: subTime[2], text: '' };
				return;
			}

			if(line) {
				if(current.startTime === undefined || current.stopTime === 'undefined') {
					throw new Error('Malformed SUB file');
				}
				current.text += (current.text) ? '\n' + line : line;
			}
		});

		if(current.text) {
			result.push(current);
		}
		return result;
	}
};
export default SUBService;
