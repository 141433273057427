import _ from 'lodash';
import axios from 'axios';

const Upload = {
	addFieldToFormData: (config, formData, val, key) => {
		if(val !== undefined) {
			if(_.isDate(val)) {
				val = val.toISOString();
			}
			if(_.isString(val)) {
				formData.append(key, val);
			}
			else if(config.sendFieldsAs === 'form') {
				if(_.isObject(val)) {
					for(let k in val) {
						if(val.hasOwnProperty(k)) {
							Upload.addFieldToFormData(config, formData, val[k], key + '[' + k + ']');
						}
					}
				}
				else {
					formData.append(key, val);
				}
			}
			else {
				val = _.isString(val) ? val : JSON.stringify(val);
				if(config.sendFieldsAs === 'json-blob') {
					formData.append(key, new Blob([val], { type: 'application/json' }));
				}
				else {
					formData.append(key, val);
				}
			}
		}
	},
	upload: (config) => {
		config.headers = config.headers || {};
		config.headers['Content-Type'] = undefined;
		config.transformRequest = config.transformRequest
			? (_.isArray(config.transformRequest)
				? config.transformRequest
				: [config.transformRequest])
			: [];
		config.transformRequest.push((data) => {
			let formData = new FormData();
			let allFields = {};
			let key;
			for(key in config.fields) {
				if(config.fields.hasOwnProperty(key)) {
					allFields[key] = config.fields[key];
				}
			}
			if(data) {
				allFields.data = data;
			}
			for(key in allFields) {
				if(allFields.hasOwnProperty(key)) {
					let val = allFields[key];
					if(config.formDataAppender) {
						config.formDataAppender(formData, key, val);
					}
					else {
						Upload.addFieldToFormData(config, formData, val, key);
					}
				}
			}

			if(config.file !== null) {
				let fileFormName = config.fileFormDataName || 'file';

				if(_.isArray(config.file)) {
					let isFileFormNameString = _.isString(fileFormName);
					for(let i = 0; i < config.file.length; i++) {
						formData.append(isFileFormNameString ? fileFormName : fileFormName[i], config.file[i],
							(config.fileName && config.fileName[i]) || config.file[i].name);
					}
				}
				else {
					formData.append(fileFormName, config.file, config.fileName || config.file.name);
				}
			}
			return formData;
		});
		config.method = config.method || 'POST';
		config.headers = config.headers || {};
		delete config.headers['Authorization'];
		return axios.request(config);
	}
};

export default Upload;
