import _ from 'lodash';
import keyBy from 'lodash.keyby';
import { createAction } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { handleError } from './common';
import AssetTypeService from '@/modules/services/asset-type-service';

export const STORE_NAME = 'assetTypesStore';

export function *fetchAssetTypes({ payload }) {
	try {
		yield put(internalActions.getAssetTypesRequest(payload));
		const assetTypes = yield AssetTypeService.getAllAssetTypes();
		yield put(internalActions.getAssetTypesSuccess(assetTypes));
		return assetTypes;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getAssetTypesFailure(err));
		return err;
	}
}

export const FETCH_ASSET_TYPES = 'asset-types.fetch';
export const FETCH_ASSET_TYPES_REQUEST = 'asset-types.fetch.request';
export const FETCH_ASSET_TYPES_SUCCESS = 'asset-types.fetch.success';
export const FETCH_ASSET_TYPES_FAILURE = 'asset-types.fetch.failure';

export const INITIAL_STATE = {
	isLoading: false,
	lastLoadedAt: null,
	allAssetTypes: [],
	structuredAssetTypes: {}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case FETCH_ASSET_TYPES_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case FETCH_ASSET_TYPES_SUCCESS: {
			let now = new Date();
			let allAssetTypes = action.payload;
			let structuredAssetTypes = keyBy(allAssetTypes, 'name');

			return {
				...state,
				allAssetTypes,
				structuredAssetTypes,
				isLoading: false,
				lastLoadedAt: now
			};
		}

		case FETCH_ASSET_TYPES_FAILURE:
			return {
				...state,
				isLoading: false
			};

		default:
			return state;
	}
}

export const actions = {
	getAssetTypes: createAction(FETCH_ASSET_TYPES)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	getAssetTypesRequest: createAction(FETCH_ASSET_TYPES_REQUEST),
	getAssetTypesSuccess: createAction(FETCH_ASSET_TYPES_SUCCESS),
	getAssetTypesFailure: createAction(FETCH_ASSET_TYPES_FAILURE)
};

export const selectors = {
	getAssetTypes,
	getAssetType,
	isLoading,
	isLoaded,
	getLastLoadedAt
};

function getAssetTypes(state) {
	let assetTypesStore = state[STORE_NAME];

	return assetTypesStore.allAssetTypes;
}

function getAssetType(state, type) {
	let assetTypesStore = state[STORE_NAME];

	return _.find(assetTypesStore.allAssetTypes, { name: type });
}

function isLoading(state) {
	let assetTypesStore = state[STORE_NAME];

	return assetTypesStore.isLoading;
}

function isLoaded(state) {
	let assetTypesStore = state[STORE_NAME];

	return !!assetTypesStore.lastLoadedAt;
}

function getLastLoadedAt(state) {
	let assetTypesStore = state[STORE_NAME];

	return assetTypesStore.lastLoadedAt;
}

export function *watchAssetTypes() {
	yield takeLatest(FETCH_ASSET_TYPES, fetchAssetTypes);
}
