import _ from 'lodash';
import isError from 'lodash.iserror';
import { createAction } from 'redux-actions';
import { put, fork, join, takeLatest } from 'redux-saga/effects';
import { createSelector, handleError } from './common';

import {
	actions as studiosActions, fetchStudios,
	selectors as studiosSelectors
} from './studios';
import {
	actions as projectsActions, fetchProjects,
	selectors as projectsSelectors
} from './projects';

export const STORE_NAME = 'projectsIndexStore';

function *loadPageData() {
	try {
		yield put(internalActions.loadPageRequest());

		let studiosTask = yield fork(fetchStudios, studiosActions.getStudios());
		let projectsTask = yield fork(fetchProjects, projectsActions.getProjects());

		let studios = yield join(studiosTask);
		let projects = yield join(projectsTask);

		if( isError(studios) || isError(projects) || !projects ) {
			throw new Error('Failed to load page resources');
		}

		yield put(internalActions.loadPageSuccess());
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadPageFailure(err));
		return err;
	}
}

export const LOAD = 'projects-index.load';
export const LOAD_REQUEST = 'projects-index.load.request';
export const LOAD_SUCCESS = 'projects-index.load.success';
export const LOAD_FAILURE = 'projects-index.load.failure';

export const UPDATE_SAVED_FILTERS = 'projects-index.saved-filters.update';

export const INITIAL_STATE = {
	isLoading: false,
	loadFailed: false,
	savedFilters: null
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case LOAD_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case LOAD_SUCCESS:
			return {
				...state,
				isLoading: false,
				loadFailed: false
			};

		case LOAD_FAILURE:
			return {
				...state,
				isLoading: false,
				loadFailed: true
			};

		case UPDATE_SAVED_FILTERS: {
			// Clone the object so that we don't get reference side effects
			let savedFilters = _.cloneDeep(action.payload);

			return {
				...state,
				savedFilters
			};
		}

		default:
			return state;
	}
}

export const actions = {
	loadPageData: createAction(LOAD),
	updateSavedFilters: createAction(UPDATE_SAVED_FILTERS)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	loadPageRequest: createAction(LOAD_REQUEST),
	loadPageSuccess: createAction(LOAD_SUCCESS),
	loadPageFailure: createAction(LOAD_FAILURE)
};

export const selectors = {
	isReady,
	needsRefresh,
	isLoading: createSelector(STORE_NAME, isLoading),
	isFailedLoad: createSelector(STORE_NAME, isFailedLoad),
	getSavedFilters: createSelector(STORE_NAME, getSavedFilters)
};

function isLoading(state) {
	return state.isLoading;
}

function isReady(state) {
	let areProjectsLoaded = projectsSelectors.isLoaded(state);
	let areStudiosLoaded = studiosSelectors.isLoaded(state);

	if( !areProjectsLoaded || !areStudiosLoaded ) {
		return false;
	}

	let projectsDataLastLoadedAt = projectsSelectors.getLastLoadedAt(state);
	let studiosDataLastLoadedAt = studiosSelectors.getLastLoadedAt(state);

	let now = new Date();
	let isProjectsDataStale = now - projectsDataLastLoadedAt > 7200000;
	let isStudiosDataStale = now - studiosDataLastLoadedAt > 7200000;

	// If the data has been loaded within the last past two hours, its ready
	return !isProjectsDataStale && !isStudiosDataStale;
}

function needsRefresh(state) {
	let areProjectsLoaded = projectsSelectors.isLoaded(state);
	let areStudiosLoaded = studiosSelectors.isLoaded(state);

	if( !areProjectsLoaded || !areStudiosLoaded ) {
		return true;
	}

	let projectsDataLastLoadedAt = projectsSelectors.getLastLoadedAt(state);
	let studiosDataLastLoadedAt = studiosSelectors.getLastLoadedAt(state);

	let now = new Date();
	let isProjectsDataStale = now - projectsDataLastLoadedAt > 1800000;
	let isStudiosDataStale = now - studiosDataLastLoadedAt > 1800000;

	// If the data has been loaded within the last past two hours, its ready
	return isProjectsDataStale || isStudiosDataStale;
}

function getSavedFilters(state) {
	// Return a copy of the filters to avoid state side effects
	return _.cloneDeep(state.savedFilters);
}

function isFailedLoad(state) {
	return state.loadFailed;
}

export function *watchProjectsIndex() {
	yield takeLatest(LOAD, loadPageData);
}
