import _ from 'lodash';
import api, { buildURL } from './api-service';

const FIELDS = `
id
source
size
status
download
archived
context
asset {
	id
	name
	slug
	type
	mediaType
	creative
	project {
		id
		filePrefix
		studio {
			previewWatermarkFormat
		}
	}
	file
}
selected
created
filename
ffprobe
scratchPreview
owner {
	name
	emailNormal
}
encodes {
	name
	status
}
`;

export const GET_INGEST_QUERY = `
query getIngest($id: ID!) {
	ingest(id: $id) {
		${FIELDS}
	}
}
`;

export const GET_BATCH_QUERY = `
query getIngestBatch($limit: Int, $batch: Float, $status: [String], $archived: Boolean) {
	ingests(limit: $limit, batch: $batch, status: $status, archived: $archived) {
		nodes {
			id
			asset {
				mediaType
				creative
			}
			source
			selected
			ffprobe
		}
	}
}
`;

export const SAVE_INGEST_QUERY = `
mutation saveIngest ($input: IngestInput) {
	updateIngest(input: $input) {
		ingest {
			${FIELDS}
		}
	}
}
`;

const IngestService = {

	create: (data) => {
		return api.client.post('/ingests', data)
			.then((res) => res.data);
	},

	update: (id, data) => {
		return api.client.patch(`/ingests/${id}`, data)
			.then((res) => res.data);
	},

	getIngestsForWorkRequest: (workRequestId) => {
		return api.client.get(buildURL('/ingests', {
			workRequest: workRequestId
		}))
			.then((res) => res.data);
	},

	getCountriesFromTerritories: (territories) => {
		return _(territories)
			.pluck('countries')
			.flatten()
			.filter((country) => country?.code)
			.uniq('code')
			.sortBy('name')
			.unshift({
				name: 'Worldwide',
				code: 'WW'
			})
			.value();
	},

	getTagLayout: (tags, mediaType, assetType, showAvOptions) => {
		const POSITION_HINTS = {
			image: {
				right: ['Text']
			},
			video: {
				right: ['Standard', 'Aspect', 'Dimension', 'Definition'],
				span: ['Text', 'Resolution', 'Codec']
			},
			zip: {
				right: ['Standard', 'Aspect', 'Dimension', 'Definition'],
				span: ['Text', 'Resolution', 'Codec']
			},
			document: {
				right: ['Standard', 'Aspect', 'Dimension', 'Definition'],
				span: ['Text', 'Resolution', 'Codec']
			}
		};

		const IMAGE_PICK_TAGS = [
			'cmyk', 'rgb', 'flattened', 'layered'
		];

		const AV_OMIT_TAGS = [...IMAGE_PICK_TAGS, 'previewonly'];

		// See https://pixwel.atlassian.net/browse/PLATFORM-1998
		const AV_OMIT_TYPES = ['Framerate', 'Frame Mode', 'Standard', 'Definition', 'Codec', 'Resolution', 'Aspect'];

		const RENDERED_GROUPS = [
			'SubDub',
			'Text',
			'Codec',
			'Resolution',
			'Framerate',
			'Frame Mode',
			'Extra'
		];

		const MULTIPLE_GROUPS = [
			'SubDub'
		];

		let filteredTags = [];

		if( mediaType === 'image' ) {
			filteredTags = tags.filter((tag) => tag.type && IMAGE_PICK_TAGS.includes(tag.name));
		}

		if( mediaType === 'audio' || mediaType === 'video' ) {
			filteredTags = tags.filter(
				(tag) => tag.type
					&& !AV_OMIT_TAGS.includes(tag.name)
					&& (showAvOptions ? true : !AV_OMIT_TYPES.includes(tag.type))
			);
		}

		if( mediaType === 'zip' || mediaType === 'document' ) {
			if( assetType === 'image' ) {
				filteredTags = tags.filter((tag) => tag.type && IMAGE_PICK_TAGS.includes(tag.name));
			}
			if( assetType === 'video' ) {
				filteredTags = tags.filter(
					(tag) => tag.type
						&& !AV_OMIT_TAGS.includes(tag.name)
						&& (showAvOptions ? true : !AV_OMIT_TYPES.includes(tag.type))
				);
			}
		}

		return _(filteredTags)
			.groupBy('type')
			.transform((result, tags, group) => {
				const hints = POSITION_HINTS[mediaType];
				let key = 'left';

				if( hints?.right.includes(group) ) {
					key = 'right';
				}

				if( hints?.span?.includes(group) ) {
					key = 'span';
				}

				return result[key].push({
					name: group,
					tags: _(tags)
						.sortBy('name')
						.map((tag) => {
							return _.pick(tag, ['name', 'display']);
						})
						.value(),

					rendered: RENDERED_GROUPS.includes(group),
					multiple: MULTIPLE_GROUPS.includes(group)
				});
			}, {
				left: [],
				right: [],
				span: []
			})
			.value();
	},

	deepDiff: (o1, o2, carry) => {
		return _.transform(o1, (result, value, key) => {
			if( _.isEqual(value, o2[key]) ) {
				return;
			}

			if( _.isArray(value) ) {
				result[key] = value;
				return;
			}

			if( _.isObject(value) && !_.isDate(value) ) {
				result[key] = {};
				return IngestService.deepDiff(value, o2[key] ?? {}, result[key]);
			}

			result[key] = value;
		}, carry || {});
	},

	flattenObj: (object, prefix, carry) => {
		return _.transform(object, (result, value, key) => {
			key = prefix ? `${prefix}.${key}` : key;

			if( _.isObject(value) && !_.isDate(value) ) {
				return IngestService.flattenObj(value, key, result);
			}

			result[key] = value;
		}, carry || {});
	},

	getIngest: (id) => {
		return api.graphql.request(GET_INGEST_QUERY, { id });
	},

	saveIngest: (input) => {
		return api.graphql.request(SAVE_INGEST_QUERY, { input });
	},

	processIngest: (input) => {
		const query = `
			mutation processIngest ($input: IngestInput) {
				updateIngest(input: $input) {
					ingest {
						${FIELDS}
					}
				}
			}
		`;
		return api.graphql.request(query, { input });
	},

	reprocessIngest: (input) => {
		const query = `
			mutation reprocessIngest ($input: IngestInput) {
				updateIngest(input: $input) {
					ingest {
						${FIELDS}
					}
				}
			}
		`;
		return api.graphql.request(query, { input });
	},

	archiveIngest: (input) => {
		const query = `
			mutation archiveIngest ($input: IngestInput) {
				updateIngest(input: $input) {
					ingest {
						${FIELDS}
					}
				}
			}
		`;
		return api.graphql.request(query, { input });
	},

	getBatch: async(ingest, query) => {
		const params = {
			batch: ingest.context.group,
			limit: 150,
			...query || { status: ['waiting'] }
		};

		const response = await api.graphql.request(GET_BATCH_QUERY, params);

		return response.ingests.nodes
			.filter((i) => i.id !== ingest.id);
	}
};

export default IngestService;
