import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IMAGES } from '../../../../constants';

const Svg = styled.svg`
	display: inline-block;
	vertical-align: middle;
	width: ${({ sizeX }) => sizeX ? `${sizeX}rem` : 'auto'};
	height: ${({ sizeY }) => sizeY ? `${sizeY}rem` : 'auto'};
`;

const SvgImage = (props) => {
	const {
		use,
		sizeX,
		sizeY
	} = props;

	if( !IMAGES.hasOwnProperty(use) ) {
		return null;
	}

	const { viewBox, markup } = IMAGES[use];

	return (
		<Svg
			sizeX={sizeX}
			sizeY={sizeY}
			viewBox={viewBox}
			{...props}
		>
			{markup}
		</Svg>
	);
};

SvgImage.propTypes = {
	use: PropTypes.string.isRequired,
	sizeX: PropTypes.number,
	sizeY: PropTypes.number
};

export default SvgImage;
