import _ from 'lodash';
import { CATALOG_MANIFEST } from '@/config/config.3x';
import api from './api-service';
import axios from 'axios';

const LocaleService = {
	catalogs: [],
	lang: 'en',

	async initialize() {
		LocaleService.catalogs = CATALOG_MANIFEST;
		const browserLanguage = 'en-US'
		// const browserLanguage = window.navigator.language || window.navigator.userLanguage;

		let language = browserLanguage.toLowerCase();
		if(_.find(LocaleService.catalogs, { name: language })) {
			return LocaleService.loadLocale(language);
		}

		language = language.split(/(_|-)/)[0];
		if(_.find(LocaleService.catalogs, { name: language })) {
			return LocaleService.loadLocale(language);
		}

		return LocaleService.loadLocale('en');
	},

	async loadLocale(lang) {
		const i = LocaleService._findUrl(lang, true) || LocaleService._findUrl(lang, false);
		lang = LocaleService.catalogs[i]['lang'];
		if(LocaleService.catalogs[i]['loaded']) {
			LocaleService.lang = lang;
			return lang;
		}
		const { data } = await axios.get(LocaleService.catalogs[i]['url']);
		LocaleService.catalogs[i]['loaded'] = true;
		LocaleService.catalogs[lang] = data;
		LocaleService.lang = lang;
		return lang;
	},

	_findUrl(lang, strict) {
		for(const i in LocaleService.catalogs) {
			if(strict && (LocaleService.catalogs[i]['lang'] === lang)) {
				return i;
			}
			if(!strict && (LocaleService.catalogs[i]['lang'] === lang.split(/(_|-)/)[0])) {
				return i;
			}
		}
		return null;
	},

	getLocaleValue(key, params) {
		if(!(LocaleService.lang in LocaleService.catalogs)) {
			LocaleService.catalogs[LocaleService.lang] = {};
		}
		let message = LocaleService.catalogs[LocaleService.lang][key];
		if(_.isObject(message)) {
			let nb = params.count > 1 ? 'n' : params.count;
			if(!message[nb]) {
				for(nb in message) {
					break;
				}
			}
			message = message[nb];
		}

		return LocaleService.interpolate(message, params);
	},

	interpolate(message, params) {
		if(!params) {
			return message;
		}
		const match = message?.match(/{{(.*)}}/);
		if(match) {
			for(let i=0; i < match.length; i+=2) {
				const toReplace = match[i];
				const replace = params[match[i+1].trim()];
				message = message.replace(toReplace, replace);
			}
		}
		return message;
	}
};

export default LocaleService;
