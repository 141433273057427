import api from './api-service';
import _ from 'lodash';

const TranslationReplicator = {
	loadTranscription: (asset) => {
		if(!asset.transcription || !asset.transcription._id || !asset.$links.transcription) {
			return new Error('Asset does not contain translations');
		}

		return api.client.get(`/Translations/${asset.transcription._id}` );
	},

	loadGfxTranscription: (asset) => {
		if(!asset.transcription || !asset.transcription.graphics) {
			return new Error('Asset does not contain graphics translations');
		}

		return api.client.get(`/Translations/${asset.transcription.graphics}` );
	},

	prepareTranslation: (transcription, translation, category) => {
		let LINE_SOURCES = {
			av: 'lines',
			print: 'printLines'
		};

		let lineSource = LINE_SOURCES[category];
		let ovLines = TranslationReplicator.cleanLines(transcription, lineSource);

		if(!translation) {
			return TranslationReplicator.createNewTranslation(ovLines, lineSource, transcription);
		}

		return TranslationReplicator.extendExistingTranslation(translation, ovLines, lineSource);
	},

	cleanLines: (transcription, source) => {
		return _.map(transcription[source], (line) => {
			line.text = line.text.replace(/\r/g, '');
			return line;
		});
	},

	createNewTranslation: (ovLines, source, transcription) => {
		let translation = { $$ovId: transcription._id };

		if(ovLines.length) {
			translation[source] = _.map(ovLines, (ovLine) => {
				return { ...ovLine, $$ovText: ovLine.text };
			});
		}

		return translation;
	},

	extendExistingTranslation: (translation, ovLines, source) => {
		translation[source] = _.map(translation[source], (line, index) => {
			let ovLine = ovLines[index];
			if(!ovLine) {
				return line;
			}

			return { ...line, $$ovText: ovLine.text };
		});

		return translation;
	},

	translate: async(asset, translation, type = 'srt') => {
		const category = asset.mediaType === 'video' ? 'av' : 'print';

		const transcription = type === 'srt'? await TranslationReplicator.loadTranscription(asset) : await TranslationReplicator.loadGfxTranscription(asset);

		return TranslationReplicator.prepareTranslation(
			transcription.data,
			translation,
			category
		);
	}
};

export default TranslationReplicator;
