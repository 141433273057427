import Cookies from "js-cookie";
import gql from "graphql-tag";
import ErrorService from "./error-service";
import AuthService from "./auth-service";
// import ToastService from "./toast-service";
import _get from "lodash.get";
import { redirect } from "next/navigation";
// import tracker from "@/config/tracker";

let apiBase = process.env.NEXT_PUBLIC_REST_URL;
let gqlBase = process.env.NEXT_PUBLIC_GRAPHQL_URL;

let baseUrl = `${apiBase}/api`;
let headers = {
	Accept: "application/json",
	"Content-Type": "application/json",
};

function isString(value) {
	return typeof value === "string";
}

let api = {
	client: {
		session: null,
		get: async (url, params) => {
			await setupAPI();
			const newHeaders = {
				...headers,
				...params?.headers,
				...(params?.headers?.Authorization ? {} : { Authorization: `Basic ${getToken()}` }),
			};
			return fetch(`${baseUrl}${url}`, {
				method: "GET",
				cache: params?.cache,
				headers: newHeaders,
			}).then(async (res) => {
				if (res.ok) {
					let headers = {};
					for (var pair of res.headers.entries()) {
						headers[pair[0]] = pair[1];
					}
					let data = await res.json();
					return { url, data, headers };
				} else {
					throw new Error(res.statusText);
				}
			});
		},
		post: async (url, params, opts) => {
			await setupAPI();
			let finalUrl = opts?.gql ? `${gqlBase}/${url}` : `${baseUrl}/${url}`;
			return fetch(finalUrl, {
				method: "POST",
				headers: {
					...headers,
					...(getToken() || opts?.gql ? { Authorization: `Basic ${getToken()}` } : {}),
				},
				body: JSON.stringify(params),
				...opts,
			}).then(async (res) => {
				if (res.ok) {
					let headers = {};
					for (var pair of res.headers.entries()) {
						headers[pair[0]] = pair[1];
					}
					let data = await res.json();
					return { data, headers };
				} else {
					throw new Error(res.statusText);
				}
			});
		},
		put: async (url, params) => {
			await setupAPI();
			return fetch(`${baseUrl}/${url}`, {
				method: "PUT",
				headers: {
					...headers,
					...params?.headers,
					...(getToken() ? { Authorization: `Basic ${getToken()}` } : {}),
				},
				body: JSON.stringify(params),
			}).then(async (res) => {
				if (res.ok) {
					let headers = {};
					for (var pair of res.headers.entries()) {
						headers[pair[0]] = pair[1];
					}
					let data = await res.json();
					return { data, headers };
				} else {
					throw new Error(res.statusText);
				}
			});
		},
		delete: async (url, params) => {
			await setupAPI();
			return fetch(`${baseUrl}/${url}`, {
				method: "DELETE",
				headers: {
					...headers,
					...params?.headers,
					...(getToken() ? { Authorization: `Basic ${getToken()}` } : {}),
				},
			}).then(async (res) => {
				if (res.ok) {
					let headers = {};
					for (var pair of res.headers.entries()) {
						headers[pair[0]] = pair[1];
					}
					return { data: null, headers };
				} else {
					throw new Error(res.statusText);
				}
			});
		},
		patch: async (url, params) => {
			await setupAPI();
			return fetch(`${baseUrl}/${url}`, {
				method: "PATCH",
				headers: {
					...headers,
					...params?.headers,
					...(getToken() ? { Authorization: `Basic ${getToken()}` } : {}),
				},
				body: JSON.stringify(params),
			}).then(async (res) => {
				if (res.ok) {
					let headers = {};
					for (var pair of res.headers.entries()) {
						headers[pair[0]] = pair[1];
					}
					let data = await res.json();
					return { data, headers };
				} else {
					throw new Error(res.statusText);
				}
			});
		},
	},
	graphql: {},
};

const handle401Error = (error) => {
	// ToastService.create("error", `Unauthorized: please try logging in again`, {
	// 	duration: 2000,
	// 	toastId: "error-401",
	// });
	AuthService.removeToken();
	try {
		window.location.href = "/login";
	} catch(e) {
		throw new Error("Unauthorized: please try logging in again");
	}
};

function getToken() {
	if (!api.session) {
		return;
	}
	return Buffer.from(`${api.session.email}:token-${api.session.token}`).toString(
		"base64"
	);
}

export async function setupAPI(newSession, freshSetup) {
	if (typeof window === "undefined") {
		const cookieStore = (await import("next/headers")).cookies();
		const session = cookieStore.get("pixwel.api.credentials");
		if (session) {
			api.session = JSON.parse(session.value);
		} else {
			api.session = null;
		}
	} else {
		let cookieSession = Cookies.get("pixwel.api.credentials");
		if (cookieSession) {
				let session = JSON.parse(cookieSession);
				if (session) {
					api.session = session;
				} else {
					api.session = null;
				}
		} else {
			api.session = null;
		}
	}
}

	api.graphql.request = (query, variables, opts = {}) => {
		let parsed = gql`
			${query}
		`;
		let opName = parsed.definitions[0].name.value;
		let url = `?${opName}`;
		// let url = gqlBase + `?${opName}`;
		// let url = window.Cypress ? `gql?op=${opName}` : gqlBase + `?${opName}`;
		return api.client
			.post(
				url,
				{
					query,
					variables,
				},
				{ ...opts, gql: true }
			)
			.then((res) => {
				if (res.data.errors) {
					if (
						_get(res, "data.errors[0].extensions.code", false) ===
						"UNAUTHENTICATED"
					) {
						handle401Error(res.data.errors);
					} else {
						let errorMessage = "";
						if (Array.isArray(res.data.errors)) {
							errorMessage = ErrorService.getErrorMessage(
								res.data.errors || []
							);
						}
						throw new Error(errorMessage);
					}
				}

				if (!res.data.data && res?.request?.method === "GET") {
					throw new Error("An unexpected error has occurred");
				}
				return res.data.data;
			})
			.catch((error) => {
				if (error.message.includes("Unauthorized")) {
					return redirect("/login");
				}
				if (error?.response?.status === 401) {
					handle401Error(error);
				} else {
					const errorMessage = isString(error)
						? error
						: isString(error.message) && error.message !== "error"
						? error.message
						: isString(error.response?.data)
						? error.response?.data
						: "An unexpected error has occurred";
					throw new Error(errorMessage);
				}
			});
	};

export function buildURL(path, params) {
	let urlParams = new URLSearchParams();

	if (!params) {
		return path;
	}
	for (const [key, val] of Object.entries(params)) {
		if (typeof val === "object" && val !== null) {
			for (const [_key, _val] of Object.entries(val)) {
				if (_val) {
					urlParams.append(`${key}[${_key}]`, _val);
				}
			}
		} else if (val || typeof val === "boolean") {
			urlParams.append(key, val);
		}
	}

	let url = path.startsWith("/") ? path : `/${path}`;
	return Object.keys(params).length === 0
		? url
		: `${url}?${urlParams.toString()}`;
}

export default api;
