import isError from 'lodash.iserror';
import {
	takeLatest,
	takeLeading,
	fork,
	join,
	put
} from 'redux-saga/effects';
import { createAction } from 'redux-actions';
import { createSelector, handleError } from './common';
import WorkRequestService from '../modules/services/work-request-service';

import {
	actions as studiosActions, fetchStudios,
	selectors as studiosSelectors
} from './studios';
import {
	actions as assetTypesActions, fetchAssetTypes,
	selectors as assetTypesSelectors
} from './asset-types';

export const STORE_NAME = 'workRequestsPageStore';

function *loadPageData() {
	try {
		yield put(internalActions.loadPageRequest());
		let studiosTask = yield fork(fetchStudios, studiosActions.getStudios());
		let assetTypesTask = yield fork(fetchAssetTypes, assetTypesActions.getAssetTypes());

		let studios = yield join(studiosTask);
		let assetTypes = yield join(assetTypesTask);

		if( isError(studios) || isError(assetTypes) ) {
			throw new Error('Failed to load page resources');
		}

		yield put(internalActions.loadPageSuccess());
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadPageFailure({ error: err }));
		return err;
	}
}

function *queryWorkRequests({ payload }) {
	try {
		let { query, page, limit } = payload;

		yield put(internalActions.queryRequest({ query, page }));

		let pagedResult = yield WorkRequestService.getReport(query, page-1, limit);

		yield put(internalActions.querySuccess({ pagedResult }));
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.queryFailure({ error: err }));
		return err;
	}
}

export const LOAD_PAGE = 'work-requests-page.load-page';
export const LOAD_PAGE_REQUEST = 'work-requests-page.load-page.request';
export const LOAD_PAGE_SUCCESS = 'work-requests-page.load-page.success';
export const LOAD_PAGE_FAILURE = 'work-requests-page.load-page.failure';

export const QUERY = 'work-requests-page.query';
export const QUERY_REQUEST = 'work-requests-page.query.request';
export const QUERY_SUCCESS = 'work-requests-page.query.success';
export const QUERY_FAILURE = 'work-requests-page.query.failure';

export const INITIAL_STATE = {
	currentPage: 1,
	totalPages: 1,
	isLoading: false,
	loadError: null,
	isQuerying: false,
	query: {},
	queryResults: [],
	queryError: null
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case LOAD_PAGE_REQUEST: {
			return {
				...state,
				isLoading: true,
				loadError: null,
				queryResults: []
			};
		}

		case LOAD_PAGE_SUCCESS: {
			return {
				...state,
				isLoading: false,
				loadError: null
			};
		}

		case LOAD_PAGE_FAILURE: {
			return {
				...state,
				isLoading: false,
				loadError: action.payload
			};
		}

		case QUERY_REQUEST: {
			const { query, page } = action.payload;

			// Leave old query results so that the visuals aren't flashing
			return {
				...state,
				currentPage: page,
				isQuerying: true,
				query: query,
				queryError: null
			};
		}

		case QUERY_SUCCESS: {
			const { pagedResult } = action.payload;

			return {
				...state,
				isQuerying: false,
				queryResults: pagedResult.results,
				totalPages: pagedResult.totalPages
			};
		}

		case QUERY_FAILURE: {
			const { error } = action.payload;

			// If an error occurs, clear old results that we left around
			// during the load. View should be cleared anyways with an error.
			return {
				...state,
				isQuerying: false,
				queryResults: [],
				queryError: error
			};
		}

		default:
			return state;
	}
}

export const actions = {
	loadPageData: createAction(LOAD_PAGE),
	queryWorkRequests: createAction(QUERY)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	loadPageRequest: createAction(LOAD_PAGE_REQUEST),
	loadPageSuccess: createAction(LOAD_PAGE_SUCCESS),
	loadPageFailure: createAction(LOAD_PAGE_FAILURE),
	queryRequest: createAction(QUERY_REQUEST),
	querySuccess: createAction(QUERY_SUCCESS),
	queryFailure: createAction(QUERY_FAILURE)
};

export const selectors = {
	isReady,
	isLoading: createSelector(STORE_NAME, isLoading),
	didLoadFail: createSelector(STORE_NAME, didLoadFail),
	isQuerying: createSelector(STORE_NAME, isQuerying),
	didQueryFail: createSelector(STORE_NAME, didQueryFail),
	getQueryResults: createSelector(STORE_NAME, getQueryResults),
	getPagination: createSelector(STORE_NAME, getPagination)
};

function isReady(state) {
	return studiosSelectors.isLoaded(state)
		&& assetTypesSelectors.isLoaded(state);
}

function isLoading(state) {
	return state.isLoading;
}

function didLoadFail(state) {
	return !!state.loadError;
}

function isQuerying(state) {
	return state.isQuerying;
}

function didQueryFail(state) {
	return !!state.queryError;
}

function getQueryResults(state) {
	return state.queryResults;
}

function getPagination(state) {
	const { currentPage, totalPages } = state;

	return {
		currentPage,
		totalPages
	};
}

export function *watchWorkRequestsPage() {
	yield takeLeading(LOAD_PAGE, loadPageData);
	yield takeLatest(QUERY, queryWorkRequests);
}
