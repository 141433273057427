import { createAction } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { createSelector, handleError } from './common';
import UserService from '@/modules/services/user-service';

export const STORE_NAME = 'usersStore';

export function *fetchUserByEmail({ payload: email }) {
	try {
		yield put(internalActions.getUserByEmailRequest(email));
		const user = yield UserService.getUserByEmail(email);
		yield put(internalActions.getUserByEmailSuccess({ user, email }));
		return user;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getUserByEmailFailure({ email, err }));
		return err;
	}
}

export const FETCH_USER_BY_EMAIL = 'users.by-email.fetch';
export const FETCH_USER_BY_EMAIL_REQUEST = 'users.by-email.fetch.request';
export const FETCH_USER_BY_EMAIL_SUCCESS = 'users.by-email.fetch.success';
export const FETCH_USER_BY_EMAIL_FAILURE = 'users.by-email.fetch.failure';

export const INITIAL_STATE = {
	isLoading: false,
	lastLoadedAt: null,
	users: {},
	emailToId: {}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case FETCH_USER_BY_EMAIL_REQUEST: {
			let email = action.payload;

			let emailToId = {
				...state.emailToId,
				[email]: {
					userId: null,
					lastLoadedAt: null,
					...state.emailToId[email],
					isLoading: true
				}
			};

			return {
				...state,
				emailToId
			};
		}

		case FETCH_USER_BY_EMAIL_SUCCESS: {
			let { user, email } = action.payload;
			let now = new Date();

			let emailToId = {
				...state.emailToId,
				[email]: {
					isLoading: false,
					userId: user && user._id,
					lastLoadedAt: now,
					loadError: null
				}
			};

			let users = state.users;
			if( user ) {
				users = {
					...state.users,
					[user._id]: {
						isLoading: false,
						lastLoadedAt: now,
						data: user,
						loadError: null
					}
				};
			}

			return {
				...state,
				emailToId,
				users
			};
		}

		case FETCH_USER_BY_EMAIL_FAILURE: {
			let { email, err } = action.payload;

			let emailToId = {
				...state.emailToId,
				[email]: {
					isLoading: false,
					userId: null,
					loadError: err,
					lastLoadedAt: state.emailToId[email].lastLoadedAt
				}
			};

			return {
				...state,
				emailToId
			};
		}

		default:
			return state;
	}
}

export const actions = {
	getUserByEmail: createAction(FETCH_USER_BY_EMAIL)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	getUserByEmailRequest: createAction(FETCH_USER_BY_EMAIL_REQUEST),
	getUserByEmailSuccess: createAction(FETCH_USER_BY_EMAIL_SUCCESS),
	getUserByEmailFailure: createAction(FETCH_USER_BY_EMAIL_FAILURE)
};

export const selectors = {
	getUserById: createSelector(STORE_NAME, getUserById),
	getUserByEmail: createSelector(STORE_NAME, getUserByEmail),
	getUserLastLoadedByEmailAt: createSelector(STORE_NAME, getUserLastLoadedByEmailAt),
	isLoadingUserByEmail: createSelector(STORE_NAME, isLoadingUserByEmail),
	isFailedLoadUserByEmail: createSelector(STORE_NAME, isFailedLoadUserByEmail)
};

function getUserById(state, id) {
	if( !(id in state.users) ) {
		return null;
	}

	let userContainer = state.users[id];

	return userContainer.data;
}

function getUserByEmail(state, email) {
	if( !(email in state.emailToId) ) {
		return null;
	}

	let emailRecord = state.emailToId[email];
	let userId = emailRecord.userId;

	if( !userId ) {
		return null;
	}

	return state.users[userId].data;
}

function getUserLastLoadedByEmailAt(state, email) {
	if( !(email in state.emailToId) ) {
		return null;
	}

	let emailRecord = state.emailToId[email];

	return emailRecord.lastLoadedAt;
}

function isLoadingUserByEmail(state, email) {
	if( !(email in state.emailToId) ) {
		return false;
	}

	let emailRecord = state.emailToId[email];

	return emailRecord.isLoading;
}

function isFailedLoadUserByEmail(state, email) {
	if( !(email in state.emailToId) ) {
		return false;
	}

	let emailRecord = state.emailToId[email];

	return !!emailRecord.loadError;
}

export function *watchUsers() {
	yield takeLatest(FETCH_USER_BY_EMAIL, fetchUserByEmail);
}
