import api from './api-service';

const ASSET_TYPES_QUERY = `query getAssetTypes {
  assetTypes {
    nodes {
      name
      group
      category
    }
  }
}
`;

const AssetTypeService = {

	createInstance(data) {
		return {
			...data,
			filterByLength: () => {
				return [
					'tv-spot.us',
					'tv-spot.intl',
					'tv-spot.home-video',
					'film-clip.us',
					'film-clip.intl',
					'film-clip.home-video'
				].indexOf(data.name) > -1;
			}
		};
	},

	getAllAssetTypes() {
		return api.graphql.request(ASSET_TYPES_QUERY).then((res) => res?.assetTypes?.nodes);
	}
};

export default AssetTypeService;
