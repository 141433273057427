import { combineReducers } from 'redux';
import { reducer as sessionReducer, STORE_NAME as SESSION_STORE_NAME } from '@/store/session';
import { reducer as localeReducer, STORE_NAME as LOCALE_STORE_NAME } from '@/store/locale';
import { reducer as projectsReducer, STORE_NAME as PROJECTS_STORE_NAME } from '@/store/projects';
import { reducer as studiosReducer, STORE_NAME as STUDIOS_STORE_NAME } from '@/store/studios';
import { reducer as groupsReducer, STORE_NAME as GROUPS_STORE_NAME } from '@/store/groups';
import { reducer as assetsReducer, STORE_NAME as ASSETS_STORE_NAME } from '@/store/assets';
import { reducer as assetTypesReducer, STORE_NAME as ASSET_TYPES_STORE_NAME } from '@/store/asset-types';
import { reducer as previewsReducer, STORE_NAME as PREVIEWS_STORE_NAME } from '@/store/previews';
import { reducer as filesReducer, STORE_NAME as FILES_STORE_NAME } from '@/store/files';
import { reducer as languagesReducer, STORE_NAME as LANGUAGES_STORE_NAME } from '@/store/languages';
import { reducer as territoriesReducer, STORE_NAME as TERRITORIES_STORE_NAME } from '@/store/territories';
import { reducer as usersReducer, STORE_NAME as USERS_STORE_NAME } from '@/store/users';
import { reducer as workRequestsReducer, STORE_NAME as WORK_REQUESTS_STORE_NAME } from '@/store/work-requests';
import { reducer as translationsReducer, STORE_NAME as TRANSLATIONS_STORE_NAME } from '@/store/translations';
import { reducer as ingestsReducer, STORE_NAME as INGESTS_STORE_NAME } from '@/store/ingests';
import { reducer as ingestUploadReducer, STORE_NAME as INGEST_UPLOAD_STORE_NAME } from '@/store/ingest-upload';
import { reducer as commentsReducer, STORE_NAME as COMMENTS_STORE_NAME } from '@/store/comments';
import { reducer as notificationsReducer, STORE_NAME as NOTIFICATIONS_STORE_NAME } from '@/store/notifications';
import {
	reducer as projectsIndexReducer, STORE_NAME as PROJECTS_INDEX_PAGE_STORE_NAME
} from '@/store/projects-index';
import { reducer as projectSidebarReducer } from '@/store/project-sidebar';
import { reducer as projectPageReducer, STORE_NAME as PROJECT_PAGE_STORE_NAME } from '@/store/project-page';
import { reducer as assetPageReducer, STORE_NAME as ASSET_PAGE_STORE_NAME } from '@/store/asset-page';
import { reducer as shareQueueReducer, STORE_NAME as SHARE_QUEUE_STORE_NAME } from '@/store/share-queue';
import {
	reducer as shareEditPageReducer, STORE_NAME as SHARE_EDIT_PAGE_STORE_NAME
} from '@/store/share-edit-page';
import { reducer as sharesReducer } from '@/store/shares';
import { reducer as sharesPageReducer, STORE_NAME as SHARES_PAGE_STORE_NAME } from '@/store/shares-page';
import {
	reducer as shareViewPageReducer, STORE_NAME as SHARE_VIEW_PAGE_STORE_NAME
} from '@/store/share-view-page';
import {
	reducer as shareFeedbackPageReducer, STORE_NAME as SHARE_FEEDBACK_PAGE_STORE_NAME
} from '@/store/share-feedback-page';
import { reducer as downloadsPageReducer, STORE_NAME as DOWNLOADS_PAGE_STORE_NAME } from '@/store/downloads-page';
import { reducer as projectCRUDReducer, STORE_NAME as PROJECT_CRUD_STORE_NAME } from '@/store/project-crud';
import {
	reducer as workRequestQueueReducer, STORE_NAME as WORK_REQUEST_QUEUE_STORE_NAME
} from '@/store/work-request-queue';
import { reducer as socialRequestReducer, STORE_NAME as SOCIAL_REQUEST_STORE_NAME } from '@/store/social-request';
import { reducer as registerPageReducer, STORE_NAME as REGISTER_PAGE_STORE_NAME } from '@/store/register-page';
import { reducer as subtitlerPageReducer, STORE_NAME as SUBTITLER_PAGE_STORE_NAME } from '@/store/subtitler-page';
import {
	reducer as workRequestsPageReducer, STORE_NAME as WORK_REQUESTS_PAGE_STORE_NAME
} from '@/store/work-requests-page';
import {
	reducer as workRequestPageReducer, STORE_NAME as WORK_REQUEST_PAGE_STORE_NAME
} from '@/store/work-request-page';

export const rootReducer = combineReducers({
	[SESSION_STORE_NAME]: sessionReducer,
	[LOCALE_STORE_NAME]: localeReducer,
	[PROJECTS_STORE_NAME]: projectsReducer,
	[STUDIOS_STORE_NAME]: studiosReducer,
	[GROUPS_STORE_NAME]: groupsReducer,
	[ASSETS_STORE_NAME]: assetsReducer,
	[ASSET_TYPES_STORE_NAME]: assetTypesReducer,
	[PREVIEWS_STORE_NAME]: previewsReducer,
	[FILES_STORE_NAME]: filesReducer,
	[LANGUAGES_STORE_NAME]: languagesReducer,
	[TERRITORIES_STORE_NAME]: territoriesReducer,
	[USERS_STORE_NAME]: usersReducer,
	[WORK_REQUESTS_STORE_NAME]: workRequestsReducer,
	[TRANSLATIONS_STORE_NAME]: translationsReducer,
	[INGESTS_STORE_NAME]: ingestsReducer,
	[INGEST_UPLOAD_STORE_NAME]: ingestUploadReducer,
	[COMMENTS_STORE_NAME]: commentsReducer,
	[NOTIFICATIONS_STORE_NAME]: notificationsReducer,
	[PROJECTS_INDEX_PAGE_STORE_NAME]: projectsIndexReducer,
	projectSidebarStore: projectSidebarReducer,
	[PROJECT_PAGE_STORE_NAME]: projectPageReducer,
	[ASSET_PAGE_STORE_NAME]: assetPageReducer,
	[SHARE_QUEUE_STORE_NAME]: shareQueueReducer,
	[SHARE_EDIT_PAGE_STORE_NAME]: shareEditPageReducer,
	sharesStore: sharesReducer,
	[SHARES_PAGE_STORE_NAME]: sharesPageReducer,
	[SHARE_VIEW_PAGE_STORE_NAME]: shareViewPageReducer,
	[SHARE_FEEDBACK_PAGE_STORE_NAME]: shareFeedbackPageReducer,
	[DOWNLOADS_PAGE_STORE_NAME]: downloadsPageReducer,
	[PROJECT_CRUD_STORE_NAME]: projectCRUDReducer,
	[WORK_REQUEST_QUEUE_STORE_NAME]: workRequestQueueReducer,
	[SOCIAL_REQUEST_STORE_NAME]: socialRequestReducer,
	[REGISTER_PAGE_STORE_NAME]: registerPageReducer,
	[SUBTITLER_PAGE_STORE_NAME]: subtitlerPageReducer,
	[WORK_REQUESTS_PAGE_STORE_NAME]: workRequestsPageReducer,
	[WORK_REQUEST_PAGE_STORE_NAME]: workRequestPageReducer
});

export default rootReducer;
