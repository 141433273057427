import _ from 'lodash';
import _padStart from 'lodash.padstart';
import _padEnd from 'lodash.padend';
import ToastService from './toast-service';
import SessionService from './session-service';
import api from './api-service';
import SRTService from './srt-service';
import SUBService from './sub-service';
import ASSService from './ass-service';
import stripTags from './strip-tags-service';
import TimeFormatService from './time-format-service';
import FlagService from './flag-service';

const DEFAULT_TRANSLATION = {
	line: 0,
	ovText: '',
	translationText: '',
	translationFrom: 'ov',
	alignment: 'bottom',
	textAlign: 'center',
	inTime: '00:00:00,000',
	outTime: '00:00:00,000',
	split: null,
	merged: null
};

const SubtitleService = {

	parse: (data) => {
		let lines = data.split('\n');
		switch(SubtitleService.detectFormat(lines)) {
			case 'srt':
				return SRTService.parse(data);
			case 'sub':
				return SUBService.parse(lines);
			case 'ass':
				return ASSService.parse(lines);
		}
	},

	detectFormat: (lines) => {
		let firstChar = lines[0].replace(/s+/g, '').charCodeAt(0);
		if(firstChar === '1'.charCodeAt(0)) {
			return 'srt';
		}
		else if(firstChar === '['.charCodeAt(0)) {
			return 'ass';
		}
		return 'sub';
	},

	importSubtitles: (file, ovTranslations) => {

		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = () => {
				try {
					let translationLines = SubtitleService.parse(reader.result);

					// TODO: Attempt to support importing translations that don't match ov line count
					if( translationLines.length !== ovTranslations.length ) {
						const errorMsg = 'Cannot import translations with a different cell count than OV cell count';
						ToastService.create('error', errorMsg);
						throw new Error(errorMsg);
					}

					let translations = _.map(translationLines, (translationLine, i) => {
						let translation = SubtitleService.to3xTranslation(translationLine, ovTranslations[i].text);
						translation.line = i + 1;

						return _.defaults(translation, DEFAULT_TRANSLATION);
					});

					resolve(translations);
				}
				catch(e) {
					reject(e);
				}
			};
			reader.readAsText(file);
		});
	},

	exportSubtitlesAsSrt: (subtitles, filename) => {
		let { workRequestId, translations } = subtitles;

		if( !filename ) {
			filename = `${workRequestId}`;
		}

		// Convert to structure Subtitle expects
		let lines = _.map(translations, (translation) => ({
			text: translation.translationText.replaceAll('<div>', '').replaceAll('</div>', '\n'),
			startTime: translation.inTime,
			stopTime: translation.outTime,
			alignment: translation.alignment
		}));

		let translation = {
			lines
		};
		return SubtitleService.downloadSrt(translation, filename);
	},

	exportSubtitlesAsAss: (subtitles, filename, isTiktok = false) => {
		let { workRequestId, translations, translationFont } = subtitles;

		if( !filename ) {
			filename = `${workRequestId}`;
		}

		let lines = _.map(translations, (translation) => ({
			text: translation.translationText,
			startTime: translation.inTime,
			stopTime: translation.outTime,
			alignment: translation.alignment
		}));

		let translation = {
			lines
		};

		return SubtitleService.downloadAss(translation, filename, { translationFont, isTiktok });
	},

	downloadFile: (data, type, filename) => {
		const element = document.createElement('a');
		const file = new Blob([data], {
			type: type
		});
		element.href = URL.createObjectURL(file);
		element.download = filename;
		document.body.appendChild(element);
		element.click();
	},

	downloadSrt: (translation, filename) => {
		SubtitleService.downloadFile(SRTService.export(translation.lines), 'application/x-subrip', filename + '.srt');
	},

	downloadAss: (translation, filename, { translationFont, isTiktok = false }={}) => {
		SubtitleService.downloadFile(ASSService.export(translation.lines, { font: translationFont, isTiktok }), 'application/x-ass', filename + '.ass');
	},

	downloadContinuityScript: async(asset) => {
		const session = await SessionService.getSession();
		return api.client.post('/events', {
			from: session._id,
			files: [asset.transcription.script],
			type: 'sd'
		}).then((res) => {
			return api.client.get(`/files/${res.data.files[0]}`);
		});
	},

	// @todo Test this one when uploading InsertReport is enable
	downloadInsertReport: async(asset) => {
		const session = await SessionService.getSession();
		return api.client.post('/events', {
			from: session._id,
			files: [asset.file.insertReport],
			type: 'rd'
		}).then((res) => {
			return api.client.get(`/files/${res.data.files[0]}`);
		});
	},

	downloadCueSheet: async(asset) => {
		const session = await SessionService.getSession();
		return api.client.post('/events', {
			from: session._id,
			files: [asset.file?.cueSheet],
			type: 'rd'
		}).then((res) => {
			return api.client.get(`/files/${res.data.files[0]}`);
		});
	},

	// @todo Test this one when uploading graphics is enable
	downloadGraphicsProject: async(asset) => {
		const session = await SessionService.getSession();
		return api.client.post('/events', {
			from: session._id,
			files: [asset.file.graphicsProject],
			type: 'rd'
		}).then((res) => {
			return api.client.get(`/files/${res.data.files[0]}`);
		});
	},

	/**
	 * Converts work request translation lines to 3.x structure, taking
	 * split/merged segments into account
	 *
	 * @param   {array} translations   workRequest.translation.lines
	 * @param   {array} ovTranslations OV translation lines
	 * @param   {array} graphicsTranslations Graphics lines
	 * @param 	{array} dialogueTranslations Dialogue lines
	 * @returns {array} 3.x translations
	 */
	map3xTranslations(translations, ovTranslations, graphicsTranslations, dialogueTranslations) {
		let result = [];
		let ovIndex = 0;

		for( let i = 0; i < translations.length; i++ ) {
			const translationLine = translations[i];
			let ovText;

			if( translationLine.merged ) {
				// line was merged
				ovText = `${translationLine.merged[0]} ${translationLine.merged[1]}`;
				ovIndex += 1; // jump over merged OV line
			}
			else if( translationLine.split ) {
				// line was split
				ovText = ovTranslations[ovIndex]?.text ?? '';

				// advance to next OV line only if this is split line 2 of 2
				if( translationLine.split !== translations[i+1]?.split ) {
					ovIndex++;
				}
			}
			else {
				ovText = ovTranslations[ovIndex]?.text || '';
				if(
					ovTranslations[ovIndex]?.gfx
					&& translationLine?.text === ovTranslations[ovIndex]?.text
				) {
					translationLine.gfx = !!ovTranslations[ovIndex]?.gfx;
				}
				ovIndex++;
			}

			if(ovIndex >= translations.length) {
				ovIndex = translations.length - 1;
			}

			if(ovIndex <= ovTranslations.length) {
				let translation3x = SubtitleService.to3xTranslation(
					translationLine, ovText, graphicsTranslations, dialogueTranslations
				);
				translation3x.line = i + 1;

				// If the same ovText exist in both DIA and GRAPHICS, then two GFX translation will be generated by SubtitleService.to3xTranslation
				// We need to set the second one as DIA
				if(
					translation3x.gfx
					&& _.find(result,
						(row) => {
							let resultOvText = SubtitleService.trimLines(row.ovText);
							let translationOvText = SubtitleService.trimLines(translation3x.ovText);
							return resultOvText === translationOvText;
						})
				) {
					translation3x.gfx = false;
				}

				result.push(translation3x);
			}
		}

		return result;
	},

	/**
	 * Transforms 2x translation structure to 3x translation structure
	 */
	to3xTranslation(translationLine, ovTranslationText, graphicsTranslations, dialogueTranslations) {
		const ovText = ovTranslationText.trim();
		const text = translationLine.text.trim();

		const translationText = to3xTranslationText(text);

		const translationFrom = translationLine.auto
			? 'tm'
			: translationLine.machine
				? 'mt'
				: SubtitleService.isCellTranslated({ ovText, translationText })
					? 'custom'
					: 'ov';

		const res = {
			ovText,
			translationText,
			translationFrom,
			inTime: translationLine.startTime,
			outTime: translationLine.stopTime,
			alignment: translationLine.alignment || 'bottom',
			textAlign: translationLine.textAlign || 'center',
			split: translationLine.split || null,
			merged: translationLine.merged || null
		};
		if(translationLine.ctMeta) {
			res.ctMeta = translationLine.ctMeta;
		}
		if(translationLine.gfx) {
			res.gfx = translationLine.gfx;
		}
		else if( dialogueTranslations && graphicsTranslations ) {
			// If the translation exist in graphics script, then it's a gfx
			// If the translation is both in dialogue and graphics, set as gfx and update on next step
			res.gfx = !!graphicsTranslations.find(
				(e) => SubtitleService.trimLines(res.ovText) === SubtitleService.trimLines(e.text)
			);
		}
		return res;
	},

	sortLinesByTimeCodes(lines, automatedLocalization) {
		return lines.sort((a, b) => {
			return TimeFormatService.timeDiff(b.startTime, a.startTime);
		}).map((line, index) => ({ ...line, number: index +1, automatedLocalization }));
	},

	sortLinesByRange(lines, automatedLocalization) {
		return lines.sort((a, b) => {
			return a.range[0] - b.range[0] || a.range[1] - b.range[1];
		}).map((line, index) => ({ ...line, number: index +1, automatedLocalization }));
	},

	trimLines(line) {
		return stripTags(line.replace(/(\r\n|\n|\r| )/gm, '').toLowerCase());
	},
	/**
	 * Transforms 3x translation structure to a 2x translation structure
	 */
	to2xTranslation(translation, i, graphicsTranslations, dialogueTranslations) {
		const text = stripTags(translation.translationText, '<div><br><b><i><u><span>')
			.replace(/&nbsp;/g, '')
			.replace(/\r\n/g, '')
			.replace(/\n/g, '');

		let res = {
			number: i + 1,
			text,
			auto: translation.translationFrom === 'tm',
			custom: translation.translationFrom === 'custom',
			machine: translation.translationFrom === 'mt',
			startTime: translation.inTime,
			stopTime: translation.outTime,
			alignment: translation.alignment,
			textAlign: translation.textAlign,
			split: translation.split || null,
			merged: translation.merged || null,
			range: [
				SubtitleService.timecodeToOffset(translation.inTime),
				SubtitleService.timecodeToOffset(translation.outTime)
			]
		};
		if(translation.ctMeta) {
			res.ctMeta = translation.ctMeta;
		}
		if(FlagService.isFeatureEnabled('graphics-localization') && graphicsTranslations && dialogueTranslations) {
			res.gfx = graphicsTranslations
				.find((e) => SubtitleService.trimLines(text) === SubtitleService.trimLines(e.text))
				&& !dialogueTranslations
					.find((e) => SubtitleService.trimLines(text) === SubtitleService.trimLines(e.text));
		}

		return res;
	},

	map3xPrintTranslations(translations, ovTranslations) {
		let result = [];
		let ovIndex = 0;

		for( let i = 0; i < translations.length; i++ ) {
			const translationLine = translations[i];
			let ovText = ovTranslations[ovIndex].text;
			ovIndex++;

			let translation3x = SubtitleService.to3xPrintTranslation(translationLine, ovText);
			translation3x.line = i + 1;

			result.push(translation3x);
		}

		return result;
	},

	to3xPrintTranslation(translationLine, ovTranslationText) {
		const ovText = ovTranslationText.trim();
		const text = translationLine.text.trim();

		const translationText = to3xTranslationText(text);

		const translationFrom = translationLine.auto
			? 'tm'
			: translationLine.machine
				? 'mt'
				: SubtitleService.isCellTranslated({ ovText, translationText })
					? 'custom'
					: 'ov';

		const position = translationLine.position;

		return {
			ovText,
			translationText,
			translationFrom,
			position
		};
	},

	to2xPrintTranslation(translation) {
		const text = stripTags(translation.translationText, '<div><br><b><i><u><span>')
			.replace(/&nbsp;/g, '')
			.replace(/\r\n/g, '')
			.replace(/\n/g, '');

		return {
			text,
			auto: translation.translationFrom === 'tm',
			custom: translation.translationFrom === 'custom',
			machine: translation.translationFrom === 'mt',
			alignment: 'bottom',
			position: translation.position
		};
	},

	resetToOv(translationLine) {
		let ovText =
			translationLine.ovText.indexOf('<div>') === 0
				? translationLine.ovText
				: `<div>${translationLine.ovText}</div>`;
		return {
			...translationLine,
			translationText: ovText,
			translationFrom: 'ov',
			auto: false,
			machine: false
		};
	},

	isCellTranslated(translation) {
		let { ovText, translationText } = translation;
		let strippedOvText = ovText.replace(/\s+/g, '').replace(/<div>|<\/div>/g, '');
		let strippedTranslationText = translationText.replace(/\s+/g, '').replace(/<div>|<\/div>/g, '');
		return strippedOvText !== strippedTranslationText;
	},

	timecodeToOffset(timecode) {
		const [timeHms, timeFrac] = timecode.split(',');
		const [timeHr, timeMin, timeSec] = timeHms.split(':');

		const inSeconds =
			parseInt(timeHr) * 3600
			+ parseInt(timeMin) * 60
			+ parseInt(timeSec)
			+ parseInt(timeFrac) * 0.001;

		return inSeconds;
	},

	offsetToTimecode(offset) {
		const timeHr = String(Math.trunc(offset / 3600));
		const timeMin = String(Math.trunc((offset - timeHr * 3600) / 60));
		const timeSec = String(Math.trunc(offset - timeHr * 3600 - timeMin * 60));
		const timeFrac = String(offset).split('.')[1] || '000';

		const hh = _padStart(timeHr, 2, '0');
		const mm = _padStart(timeMin, 2, '0');
		const ss = _padStart(timeSec, 2, '0');
		const frac = _padEnd(timeFrac.substr(0, 3), 3, '0');

		return `${hh}:${mm}:${ss},${frac}`;
	},

	/**
	 * Transforms 3x subtitles to WebVTT format base64-encoded string
	 */
	subsToEncodedVtt(translations) {

		let result = 'WEBVTT\n\n';

		translations.forEach((line) => {
			if( line.ovText === undefined ) {
				return;
			}

			let text = line.automatedLocalization && line.gfx && !line.showSubs? '' : this.htmlToVtt(line.translationText);

			// TODO: adjust for offsets
			const inTime = TimeFormatService.normalize(line.inTime);
			const outTime = TimeFormatService.normalize(line.outTime);
			result += inTime + ' --> ' + outTime + '\n';
			result += text + '\n\n';
		});

		return 'data:text/plain;base64,' + b64EncodeUnicode(result);
	},

	htmlToVtt(str) {

		// Convert </div> and <br> to newlines
		let result = str?.replace(/<br><\/div>/g, '\n');
		result = result?.replace(/<br>/g, '\n');
		result = result?.replace(/<\/div>/g, '\n');

		// Strip any remaining tags or HTML entities other than <b> <i> and <u>
		result = _.unescape(stripTags(result, '<b><i><u>'));
		return result;
	},

	timecodeConvertFps(time, fps) {
		let result = time.split(',');

		result[1] = parseInt(result[1] * fps / 1000);

		if( result[1] < 10 ) {
			result[1] = '0' + result[1];
		}

		return fps === 29.97 ? result.join(';') : result.join(':');
	},

	splitSegment(translations, i, fps = 24) {
		let beforeSplit = [...translations];
		let toSplitArr = beforeSplit.splice(i);
		let afterSplit = toSplitArr.splice(1).map((ln) => ({ ...ln, line: ln.line + 1 }));

		const toSplit = toSplitArr[0];

		const splitInSec = SubtitleService.timecodeToOffset(toSplit.inTime);
		const splitOutSec = SubtitleService.timecodeToOffset(toSplit.outTime);
		const durationSec = (splitOutSec - splitInSec) / 2;
		const newOutTime = SubtitleService.offsetToTimecode(splitInSec + durationSec);
		const newInTime = SubtitleService.offsetToTimecode(splitInSec + durationSec + (1/fps));

		const split = [
			{
				...toSplit,
				inTime: toSplit.inTime,
				outTime: newOutTime,
				split: toSplit.ovText
			},
			{
				...toSplit,
				inTime: newInTime,
				outTime: toSplit.outTime,
				line: toSplit.line + 1,
				split: toSplit.ovText
			}
		];

		return [
			...beforeSplit,
			...split,
			...afterSplit
		];
	},

	mergeDownSegment(translations, i) {
		let beforeMerge = [...translations];
		let toMerge = beforeMerge.splice(i);
		let afterMerge = toMerge.splice(2).map((ln) => ({ ...ln, line: ln.line - 1 }));

		// combine OV text with space between
		const ovText = toMerge.map((ln) => ln.ovText).join(' ');

		// combine translation text w/o space (wrapped in divs)
		const translationText = toMerge.map((ln) => ln.translationText).join('');

		const merge = [
			{
				...toMerge[0],
				ovText,
				translationText,
				outTime: toMerge[1].outTime,
				split: null,
				merged: [
					toMerge[0].ovText,
					toMerge[1].ovText
				]
			}
		];

		return [
			...beforeMerge,
			...merge,
			...afterMerge
		];
	},

	revertMerge(translations, i, fps = 24) {
		let beforeSplit = [...translations];
		let toSplitArr = beforeSplit.splice(i);
		let afterSplit = toSplitArr.splice(1).map((ln) => ({ ...ln, line: ln.line + 1 }));

		const toSplit = toSplitArr[0];

		const splitInSec = SubtitleService.timecodeToOffset(toSplit.inTime);
		const splitOutSec = SubtitleService.timecodeToOffset(toSplit.outTime);
		const durationSec = (splitOutSec - splitInSec) / 2;
		const newOutTime = SubtitleService.offsetToTimecode(splitInSec + durationSec);
		const newInTime = SubtitleService.offsetToTimecode(splitInSec + durationSec + (1/fps));

		// `merged` value is array of original segments' OV text
		const ovTextArr = toSplit.merged;

		// wrap OV text in divs
		const translationTextArr = ovTextArr.map((ovText) => to3xTranslationText(ovText));

		const split = [
			{
				...toSplit,
				ovText: ovTextArr[0],
				translationText: translationTextArr[0],
				inTime: toSplit.inTime,
				outTime: newOutTime,
				split: null,
				merged: null,
				auto: false,
				machine: false,
				translationFrom: 'ov'
			},
			{
				...toSplit,
				ovText: ovTextArr[1],
				translationText: translationTextArr[1],
				inTime: newInTime,
				outTime: toSplit.outTime,
				line: toSplit.line + 1,
				split: null,
				merged: null,
				auto: false,
				machine: false,
				translationFrom: 'ov'
			}
		];

		return [
			...beforeSplit,
			...split,
			...afterSplit
		];
	},

	revertSplit(translations, i) {
		let beforeMerge = [...translations];
		let toMerge = beforeMerge.splice(i);
		let afterMerge = toMerge.splice(2).map((ln) => ({ ...ln, line: ln.line - 1 }));

		// `split` value is original OV text
		const ovText = toMerge[0].split;

		// wrap original OV text in divs
		const translationText = to3xTranslationText(ovText);

		const merge = [
			{
				...toMerge[0],
				ovText,
				translationText,
				outTime: toMerge[1].outTime,
				split: null,
				merged: null,
				auto: false,
				machine: false,
				translationFrom: 'ov'
			}
		];

		return [
			...beforeMerge,
			...merge,
			...afterMerge
		];
	},

	getPrintSubtitlerMode(printTranslation) {
		if( printTranslation === 'full' ) {
			return 'print';
		}

		if( printTranslation === 'date' ) {
			return 'printdate';
		}

		return null;
	}
};

export default SubtitleService;

/**
 * Escapes Unicode characters to prevent `Character Out Of Range` exception
 * See: https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
 * @param {string} str - string to encode
 * @returns {string} - encoded string
 */
function b64EncodeUnicode(str) {
	return window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
		function toSolidBytes(match, p1) {
			return String.fromCharCode('0x' + p1);
		}));
}

/**
 * If the text has a div, it's likely already been converted to 3x. If it
 * doesn't, then replace all the carriage returns and line feeds with divs
 * @param {string} text translation to convert
 */
function to3xTranslationText(text) {
	return text.indexOf('<div>') === 0
		? text
		: text
			.replace(/\r/g, '')
			.split('\n')
			.map((t) => `<div>${t}</div>`)
			.join('');
}
