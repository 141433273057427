import _ from 'lodash';
import keyBy from 'lodash.keyby';
import { createAction } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { handleError } from './common';
import LanguageService from '@/modules/services/language-service';

export const STORE_NAME = 'languagesStore';

export function *fetchLanguages({ payload }) {
	try {
		yield put(internalActions.getLanguagesRequest(payload));
		const languages = yield LanguageService.getAllLanguages();
		yield put(internalActions.getLanguagesSuccess(languages));
		return languages;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getLanguagesFailure(err));
		return err;
	}
}

export const FETCH_LANGUAGES = 'languages.fetch';
export const FETCH_LANGUAGES_REQUEST = 'languages.fetch.request';
export const FETCH_LANGUAGES_SUCCESS = 'languages.fetch.success';
export const FETCH_LANGUAGES_FAILURE = 'languages.fetch.failure';

export const INITIAL_STATE = {
	isLoading: false,
	lastLoadedAt: null,
	languages: []
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case FETCH_LANGUAGES_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case FETCH_LANGUAGES_SUCCESS: {
			let now = new Date();
			let languages = keyBy(action.payload, 'name');

			return {
				...state,
				languages,
				isLoading: false,
				lastLoadedAt: now
			};
		}

		case FETCH_LANGUAGES_FAILURE:
			return {
				...state,
				isLoading: false
			};

		default:
			return state;
	}
}

export const actions = {
	getLanguages: createAction(FETCH_LANGUAGES)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	getLanguagesRequest: createAction(FETCH_LANGUAGES_REQUEST),
	getLanguagesSuccess: createAction(FETCH_LANGUAGES_SUCCESS),
	getLanguagesFailure: createAction(FETCH_LANGUAGES_FAILURE)
};

export const selectors = {
	getLanguages,
	isLoading,
	isLoaded,
	getLastLoadedAt
};

function getLanguages(state) {
	let { languagesStore } = state;

	return _.values(languagesStore.languages);
}

function isLoading(state) {
	let { languagesStore } = state;

	return languagesStore.isLoading;
}

function isLoaded(state) {
	let { languagesStore } = state;

	return !!languagesStore.lastLoadedAt;
}

function getLastLoadedAt(state) {
	let { languagesStore } = state;

	return languagesStore.lastLoadedAt;
}

export function *watchLanguages() {
	yield takeLatest(FETCH_LANGUAGES, fetchLanguages);
}
