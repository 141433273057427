import styled from 'styled-components';
import SvgImage from '../svg-image';

export const Graphic = styled(SvgImage)`
	width: 100%;
	height: 18rem;
`;

export const Text = styled.p`
	margin: 2.5rem 0;
	font-size: 1.5rem;
	line-height: 1.3;
	text-align: center;
	max-width: 40rem;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.75rem;

	&.small {
		${Graphic} {
			margin-top: 4rem;
			height: 8rem;
		}

		${Text} {
			margin: 1rem 0;
			font-size: 1rem;
		}
	}
`;
