import api, { buildURL } from './api-service';

const PreviewService = {

	getAllPreviews(params) {
		return api.client.get(buildURL('/previews', params))
			.then((res) => res?.data);
	},

	getSignedPreviews(assetId, languageId, revisionIds) {

		return api.client.get(
			buildURL('/previews', {
				asset: assetId,
				language: languageId,
				ids: revisionIds
			}),
			{ headers: { 'X-Range': `resources=0-29` } }
		)
			.then((res) => {
				return res?.data;
			});
	}
};

export default PreviewService;
