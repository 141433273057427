import api from './api-service';

const OfflineService = {
	createOffline(workRequestId, tag, path) {
		let payload = {
			workRequest: workRequestId,
			tag,
			path
		};

		return api.client.post('/offlines', payload);
	},

	deleteOffline(data) {
		return api.client.delete(`/offlines/${data._id}`);
	},

	update(id, data) {
		return api.client.patch(`/offlines/${id}`, data);
	}
};

export default OfflineService;