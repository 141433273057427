import _ from 'lodash';
import keyBy from 'lodash.keyby';
import { createAction } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { handleError } from './common';
import GroupService from '@/modules/services/group-service';

export const STORE_NAME = 'groupsStore';

export function *fetchGroups({ payload }) {
	try {
		yield put(internalActions.getGroupsRequest(payload));
		const groups = yield GroupService.getGroups(payload);

		yield put(internalActions.getGroupsSuccess(groups));
		return groups;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getGroupsFailure(err));
		return err;
	}
}

export const FETCH_GROUPS = 'groups.fetch';
export const FETCH_GROUPS_REQUEST = 'groups.fetch.request';
export const FETCH_GROUPS_SUCCESS = 'groups.fetch.success';
export const FETCH_GROUPS_FAILURE = 'groups.fetch.failure';

export const INITIAL_STATE = {
	isLoading: false,
	lastLoadedAt: null,
	allGroups: [],
	structuredGroups: {}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case FETCH_GROUPS_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case FETCH_GROUPS_SUCCESS: {
			let now = new Date();
			let allGroups = action.payload;
			let structuredGroups = keyBy(allGroups, 'name');

			return {
				...state,
				allGroups,
				structuredGroups,
				isLoading: false,
				lastLoadedAt: now
			};
		}

		case FETCH_GROUPS_FAILURE:
			return {
				...state,
				isLoading: false
			};

		default:
			return state;
	}
}

export const actions = {
	getGroups: createAction(FETCH_GROUPS)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	getGroupsRequest: createAction(FETCH_GROUPS_REQUEST),
	getGroupsSuccess: createAction(FETCH_GROUPS_SUCCESS),
	getGroupsFailure: createAction(FETCH_GROUPS_FAILURE)
};

export const selectors = {
	getGroups,
	getGroup,
	isLoading,
	isLoaded,
	getLastLoadedAt
};

function getGroups(state) {
	let groupsStore = state[STORE_NAME];

	return groupsStore.allGroups;
}

function getGroup(state, type) {
	let groupsStore = state[STORE_NAME];

	return _.find(groupsStore.allGroups, { name: type });
}

function isLoading(state) {
	let groupsStore = state[STORE_NAME];

	return groupsStore.isLoading;
}

function isLoaded(state) {
	let groupsStore = state[STORE_NAME];

	return !!groupsStore.lastLoadedAt;
}

function getLastLoadedAt(state) {
	let groupsStore = state[STORE_NAME];

	return groupsStore.lastLoadedAt;
}

export function *watchGroups() {
	yield takeLatest(FETCH_GROUPS, fetchGroups);
}
