import { createAction } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { RESET_STORES } from './session';
import { handleError } from './common';
import ShareService from '@/modules/services/share-service';

export function *fetchShareById({ payload: shareId }) {
	try {
		yield put(internalActions.getShareByIdRequest(shareId));

		let share = yield ShareService.getShareById(shareId);

		if( share === null ) {
			throw new Error('Failed to load share');
		}

		yield put(internalActions.getShareByIdSuccess(share));
		return share;
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.getShareByIdFailure({ shareId, err }));
		return err;
	}
}

export const FETCH_SHARE_BY_ID = 'share-by-id.fetch';
export const FETCH_SHARE_BY_ID_REQUEST = 'share-by-id.fetch.request';
export const FETCH_SHARE_BY_ID_SUCCESS = 'share-by-id.fetch.success';
export const FETCH_SHARE_BY_ID_FAILURE = 'share-by-id.fetch.failure';

export const INITIAL_STATE = {
	isLoading: false,
	lastLoadedAt: null,
	shares: {}
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case RESET_STORES:
			return INITIAL_STATE;

		case FETCH_SHARE_BY_ID_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case FETCH_SHARE_BY_ID_SUCCESS: {
			let now = new Date();
			let share = action.payload;

			return {
				...state,
				shares: {
					...state.shares,
					[share._id]: {
						isLoading: false,
						lastLoadedAt: now,
						data: share
					}
				},
				isLoading: false,
				lastLoadedAt: now
			};
		}

		case FETCH_SHARE_BY_ID_FAILURE:
			return {
				...state,
				isLoading: false
			};

		default:
			return state;
	}
}

export const actions = {
	getShareById: createAction(FETCH_SHARE_BY_ID)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	getShareByIdRequest: createAction(FETCH_SHARE_BY_ID_REQUEST),
	getShareByIdSuccess: createAction(FETCH_SHARE_BY_ID_SUCCESS),
	getShareByIdFailure: createAction(FETCH_SHARE_BY_ID_FAILURE)
};

export const selectors = {
	getShareById,
	isLoaded
};

function getShareById(state, id) {
	let shareContainer = state.sharesStore.shares[id];

	if( !shareContainer ) {
		return null;
	}

	return shareContainer.data;
}

function isLoaded(state, id) {
	let shareContainer = state.sharesStore.shares[id];

	if( !shareContainer ) {
		return false;
	}

	return !!shareContainer.lastLoadedAt;
}

export function *watchShares() {
	yield takeLatest(FETCH_SHARE_BY_ID, fetchShareById);
}
