import isError from 'lodash.iserror';
import { createAction } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleError } from './common';

import {
	actions as sharesActions, fetchShareById,
	selectors as sharesSelectors
} from './shares';
import {
	actions as projectsActions, fetchProjects,
	selectors as projectsSelectors
} from './projects';

export const STORE_NAME = 'shareFeedbackPageStore';

function *loadPageData({ payload: shareId }) {
	try {
		yield put(internalActions.loadPageDataRequest());

		let share = yield call(fetchShareById, sharesActions.getShareById(shareId));
		if( isError(share) ) {
			throw new Error('Failed to load share');
		}

		let projects = yield call(fetchProjects, projectsActions.getProjects({ archived: false }));
		if( isError(projects) ) {
			throw new Error('Failed to load projects');
		}

		yield put(internalActions.loadPageDataSuccess());
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadPageDataFailure(err));
		return err;
	}
}

export const LOAD = 'share-feedback.load';
export const LOAD_REQUEST = 'share-feedback.load.request';
export const LOAD_SUCCESS = 'share-feedback.load.success';
export const LOAD_FAILURE = 'share-feedback.load.failure';

export const INITIAL_STATE = {
	isLoading: false,
	loadFailed: false
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case LOAD_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}

		case LOAD_SUCCESS: {
			return {
				...state,
				isLoading: false,
				loadFailed: false
			};
		}

		case LOAD_FAILURE: {
			return {
				...state,
				isLoading: false,
				loadFailed: true
			};
		}

		default:
			return state;
	}
}

export const actions = {
	loadPageData: createAction(LOAD)
};

/**
 * Actions that should only be invoked internally
 */
const internalActions = {
	loadPageDataRequest: createAction(LOAD_REQUEST),
	loadPageDataSuccess: createAction(LOAD_SUCCESS),
	loadPageDataFailure: createAction(LOAD_FAILURE)
};

export const selectors = {
	isLoading,
	isReady,
	didLoadFail
};

function isLoading(state) {
	let { shareFeedbackPageStore } = state;

	return shareFeedbackPageStore.isLoading;
}

function isReady(state, id) {
	let isShareDataLoaded = sharesSelectors.isLoaded(state, id);
	let isProjectsDataLoaded = projectsSelectors.isLoaded(state);

	return isShareDataLoaded && isProjectsDataLoaded;
}

function didLoadFail(state) {
	let { shareFeedbackPageStore } = state;

	return shareFeedbackPageStore.loadFailed;
}

export function *watchFeedbackPage() {
	yield takeLatest(LOAD, loadPageData);
}
