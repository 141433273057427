import isError from 'lodash.iserror';
import { createAction } from 'redux-actions';
import { put, fork, join, takeLatest } from 'redux-saga/effects';
import { handleError } from './common';

import {
	actions as sharesActions,
	selectors as sharesSelectors
} from './shares';
import {
	actions as assetTypesActions, fetchAssetTypes,
	selectors as assetTypesSelectors
} from './asset-types';
import {
	actions as projectsActions, fetchProjects,
	selectors as projectsSelectors
} from './projects';
import { fetchShareById } from '@/store/shares';

export const STORE_NAME = 'shareEditPageStore';

function *loadPageData({ payload: shareId }) {
	try {
		yield put(internalActions.loadPageDataRequest());

		const shareTask = yield fork(fetchShareById, sharesActions.getShareById(shareId));
		const assetTypesTask = yield fork(fetchAssetTypes, assetTypesActions.getAssetTypes());
		const projectsTask = yield fork(fetchProjects, projectsActions.getProjects());

		const share = yield join(shareTask);
		const assetTypes = yield join(assetTypesTask);
		const projects = yield join(projectsTask);

		if( isError(share) || isError(assetTypes) || isError(projects) ) {
			throw new Error('Failed to load page resources');
		}

		yield put(internalActions.loadPageDataSuccess());
	}
	catch(e) {
		const err = handleError(e);
		yield put(internalActions.loadPageDataFailure(err));
		return err;
	}
}

export const LOAD = 'edit-share.load';
export const LOAD_REQUEST = 'edit-share.load.request';
export const LOAD_SUCCESS = 'edit-share.load.success';
export const LOAD_FAILURE = 'edit-share.load.failure';

export const INITIAL_STATE = {
	isLoading: false,
	loadFailed: false
};

export function reducer(state = INITIAL_STATE, action) {
	switch(action.type) {
		case LOAD_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}

		case LOAD_SUCCESS: {
			return {
				...state,
				isLoading: false,
				loadFailed: false
			};
		}

		case LOAD_FAILURE: {
			return {
				...state,
				isLoading: false,
				loadFailed: true
			};
		}

		default:
			return state;
	}
}

export const actions = {
	loadPageData: createAction(LOAD)
};

const internalActions = {
	loadPageDataRequest: createAction(LOAD_REQUEST),
	loadPageDataSuccess: createAction(LOAD_SUCCESS),
	loadPageDataFailure: createAction(LOAD_FAILURE)
};

export const selectors = {
	isLoading,
	isReady
};

function isLoading(state) {
	const { shareEditPageStore } = state;

	return shareEditPageStore.isLoading;
}

function isReady(state, id) {
	const isShareDataLoaded = sharesSelectors.isLoaded(state, id);
	const areAssetTypesLoaded = assetTypesSelectors.isLoaded(state);
	const isProjectsDataLoaded = projectsSelectors.isLoaded(state);

	return isShareDataLoaded && areAssetTypesLoaded && isProjectsDataLoaded;
}

export function *watchShareEditPage() {
	yield takeLatest(LOAD, loadPageData);
}
