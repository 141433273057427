import { EventEmitter } from 'events';
import api from './api-service';

const em = new EventEmitter();

export const EventService = {
	on: (event, fn) => {
		return em.on(event, fn);
	},

	broadcast: (event, ...args) => {
		return em.emit(event, ...args);
	},

	emit: (event, ...args) => {
		return em.emit(event, ...args);
	},

	createEvent: (from, files, type) => {
		api.client.post('/events', { from, files, type });
	}
};

export default EventService;
